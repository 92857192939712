import React from "react";
import Ellipsis from "@material-ui/icons/MoreHoriz";

import "./MenuItemWithEllipsis.scss";
import { Button } from "@material-ui/core";

export default function MenuItemWithEllipsis({
  itemName,
  index,
  handlerForClickOnEllipsis,
}) {
  return (
    <div className="menu-item-with-ellipsis-container">
      <div>{itemName}</div>
      <Button onClick={() => handlerForClickOnEllipsis(index)}>
        <Ellipsis fontSize={"small"} />
      </Button>
    </div>
  );
}
