import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AllGanttRows from "./AllGanttRows";
import AllCrewRows from "./AllCrewRows.js";
import {
  setEntityViolationIdsAction,
  setTimeViolationParentIdsAction
} from "../../../../redux/crewSchedulesReducer";
import {
  getAllEntityViolation,
  getAllTimeViolation
} from "../../../../utils/allGanttRowsUtils";
import "./CrewGanttContainer.scss";

export default function CrewGanttContainer(props) {
  const dispatch = useDispatch();
  const violations = useSelector(store => store.crewSchedules.violations);
  useEffect(() => {
    if (
      violations !== null &&
      violations !== undefined &&
      Object.keys(violations).length !== 0
    ) {
      const allEntityViolation = getAllEntityViolation(
        violations.entityViolations
      );
      dispatch(setEntityViolationIdsAction(allEntityViolation));
      const allTimeViolations = getAllTimeViolation(violations.timeViolations);
      dispatch(setTimeViolationParentIdsAction(allTimeViolations));
    }
  }, [violations, dispatch]);

  return (
    <div className="all-disp-container">
      {/**
       * This component is divided into 2 parts:
       * - a. AllCrewRows - The left fixed section that has info about crew and open flights which is only vertically scrollable.
       * - b. AllGanttRows - The right section having the gantt charts for crew pairings which is horizontally and vertically scrollable.
       */}
      <div
        className="all-disp-main"
        onScroll={props.leftScrollHandler}
        ref={props.forwardRef}
      >
        <AllCrewRows forwardRef={props.crewPanelElement} />
        <AllGanttRows />
      </div>
    </div>
  );
}
