import {
  DEFAULT_TIMEZONE,
  SOLVER
} from "../constants/disruptions/disruptionSummary";

const ACTIONS = {
  setDefaultTimeZone: "SET_DEFAULT_TIMEZONE",
  setSolverWindowDefaults: "SET_SOLVER_WINDOW_DEFAULTS"
};

const initialState = {
  defaultTimeZone: DEFAULT_TIMEZONE,
  solver: {
    contextualWindowStartHourOffset:
      SOLVER.DEFAULT_CONTEXTUAL_WINDOW_START_HOUR_OFFSET,
    contextualWindowEndHourOffset:
      SOLVER.DEFAULT_CONTEXTUAL_WINDOW_END_HOUR_OFFSET
  }
};

export function tenantConfigReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_DEFAULT_TIMEZONE":
      newState = {
        ...prevState,
        defaultTimeZone: action.defaultTimeZone
      };
      break;
    case "SET_SOLVER_WINDOW_DEFAULTS":
      newState = {
        ...prevState,
        solver: { ...prevState.solver, ...action.solverWindowDefaults }
      };
      break;
    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setDefaultTimeZoneAction(defaultTimeZone) {
  return { type: ACTIONS.setDefaultTimeZone, defaultTimeZone };
}

export function setSolverWindowDefaultsAction(solverWindowDefaults) {
  return { type: ACTIONS.setSolverWindowDefaults, solverWindowDefaults };
}
