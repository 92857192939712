import { Auth } from "aws-amplify";

export const checkWhetherLoggedIn = () => {
  return new Promise((resolve, reject) => {
    Auth.currentUserInfo()
      .then(data => {
        if (data) {
          resolve();
        } else {
          reject({ message: "user not logged in." });
        }
      })
      .catch(err => reject());
  });
};

export const getCurrentUserInfo = () => {
  return new Promise((resolve, reject) => {
    Auth.currentUserInfo()
      .then(data => {
        if (data) {
          resolve(data);
        } else {
          reject({ message: "user not logged in." });
        }
      })
      .catch(err =>
        reject({ message: "error fetching current user", error: err })
      );
  });
};

export const getAccessTokenForUser = async () => {
  const currentAuthSession = await Auth.currentSession();
  const accessTokenString = currentAuthSession.getAccessToken().getJwtToken();
  return accessTokenString;
};

export const getCurrentAuthenticatedUser = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then(data => {
        if (data) resolve(data);
        else reject({ message: "No data received for the current user" });
      })
      .catch(error =>
        reject({
          message: "error fetching current authenticated user",
          error: error
        })
      );
  });
};

export const updateUserInfo = async (user, key, value) => {
  return new Promise((resolve, reject) => {
    Auth.updateUserAttributes(user, {
      [key]: value
    })
      .then(data => {
        if (data) resolve(data);
        else reject({ message: "No data received after updating user info" });
      })
      .catch(error =>
        reject({ message: "error updating current user", error: error })
      );
  });
};

export const getCurrentSessionInfo = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(data => {
        if (data) {
          resolve(data);
        } else {
          reject({ message: "user not logged in." });
        }
      })
      .catch(err =>
        reject({
          message: "error fetching current session details",
          error: err
        })
      );
  });
};
