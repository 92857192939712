export const MAP_FROM_DRAWER_TYPE_TO_DRAWER_NAME = {
  RULE_MANAGER: "Rule Manager",
  STRATEGY_MANAGER: "Strategy Manager",
  USER_PROFILE: "User Profile"
};

export const DEFAULT_RULE_SET = "Default RuleSet";

export const DEFAULT_STRATEGY = "Default Strategy";

export const ERROR_MESSAGES = {
  ZERO_LENGTH: "This is a required field",
  ALREADY_IN_USE: "Name already in use, please use a different one."
};

export const CREW_TYPE = {
  IN_FLIGHT: "IN_FLIGHT",
  FLIGHT_OPS: "FLIGHT_OPS"
};

export const LABEL_FOR_NEW_RULE_SET_TEXT_FIELD = "RuleSet name";

export const LABEL_FOR_NEW_STRATEGY_TEXT_FIELD = "Strategy name";

export const TIME_METRIC_MAP = {
  HOUR: "hours",
  MINUTE: "mins",
  DAY: "days",
  WEEK: "weeks",
  SECOND: "secs",
  MONTH: "months"
};

export const RULE_TYPE_TO_REDUX_PROPERTY_MAP = {
  IN_FLIGHT_RULES: "inFlightRules",
  FLIGHT_OPS_RULES: "flightOpsRules"
};

export const BLACK_COLOR_WITH_ALPHA_80 = "rgba(0,0,0,0.8)";

export const FILTER_DROP_DOWN_HEIGHT = "7vh";

export const HEIGHT_TO_BE_SUBSTRACTED_WHEN_FILTER_OPEN = 5;

export const LOCAL_TIME_ZONE_ATTRIBUTE_KEY = "custom:user_local_timezone";

export const LOCAL_TIME_ZONE_OFFSET_IN_MINUTE_KEY = "offsetMin";

export const LOCAL_TIME_ZONE_OFFSET_IN_UTC_KEY = "offsetUTC";

export const LOCAL_TIME_ZONE_NAME_KEY = "zone";

export const DEFAULT_TIMEZONE = JSON.stringify({
  zone: "America/Indiana/Indianapolis",
  offsetUTC: "-05:00",
  offsetMin: -300,
  showGanttInUTC: false
});

export const SOLVER = {
  DEFAULT_CONTEXTUAL_WINDOW_START_HOUR_OFFSET: 2,
  DEFAULT_CONTEXTUAL_WINDOW_END_HOUR_OFFSET: 48
};

export const TOGGLER_HELPER_TEXT = {
  ganttInUTC:
    "When turned ON, the Gantt timeline and flight departure and arrival times are shown in UTC. When turned OFF, the Gantt timeline is shown in below configured user’s local time zone and flight departure and arrival times are shown in local station’s time zones.",
  warnings:
    "When turned ON, the Gantt shows schedule warnings along with violations. When turned OFF, the Gantt shows only schedule violations."
};

export const LIMIT_ON_THE_RUN_SOLVER_WINDOW = 3; //set to 3 days as part of CRWEB-640

export const SUMMARY_DASHBOARD = {
  VIOLATIONS: { LEVELS: { VIOLATIONS: "VIOLATION", WARNINGS: "WARNING" } }
};

export const DASHBOARD_FILTERS_FOR_WHICH_WARNING_VALUE_SHOULD_BE_HIDDEN = [
  "CXLD"
];
