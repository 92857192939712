import { SORT_TYPE } from "./disruptions/filter";

export const FILTER_TYPE = {
  AUTOCOMPLETE: "AUTOCOMPLETE",
  DATE_RANGE: "DATE_RANGE"
};

export const AIRLINE_FILTER_TITLE = "Airline";
export const DATE_RANGE_FILTER_TITLE = "Date range";
export const ORIGIN_FILTER_TITLE = "Origin";
export const DESTINATION_FILTER_TITLE = "Destination";

export const HOP_COUNT_FILTER_TITLE = "Number of legs";
export const HOP_COUNT_FILTER_OPTIONS = [
  { name: "Direct", value: 0, label: "Direct" },
  { name: "2 Legs or fewer", value: 1, label: "2 Legs or fewer" },
  { name: "3 Legs or fewer", value: 2, label: "3 Legs or fewer" }
];

export const MIN_SIT_TIME_FILTER_TITLE = "Min. Sit Time";
export const MIN_SIT_TIME_FILTER_OPTIONS = [
  { name: "20", value: 20, label: "20" },
  { name: "30", value: 30, label: "30" },
  { name: "40", value: 40, label: "40" }
];

export const DEADHEAD_FILTER_DISPLAY_CONFIG = [
  {
    title: AIRLINE_FILTER_TITLE,
    optionKey: "airline",
    filterType: FILTER_TYPE.AUTOCOMPLETE,
    multiple: true,
    stateKey: "airline",
    freeSolo: false,
    type: "text"
  },
  {
    title: DATE_RANGE_FILTER_TITLE,
    optionKey: "date",
    filterType: FILTER_TYPE.DATE_RANGE,
    multiple: false,
    stateKey: "date",
    type: "date"
  },
  {
    title: ORIGIN_FILTER_TITLE,
    optionKey: "airport",
    filterType: FILTER_TYPE.AUTOCOMPLETE,
    multiple: false,
    optionDisplayConfig: {
      sortType: SORT_TYPE.ALPHABETIC
    },
    stateKey: "origin",
    freeSolo: false,
    type: "text"
  },
  {
    title: DESTINATION_FILTER_TITLE,
    optionKey: "airport",
    filterType: FILTER_TYPE.AUTOCOMPLETE,
    multiple: false,
    optionDisplayConfig: {
      sortType: SORT_TYPE.ALPHABETIC
    },
    stateKey: "destination",
    freeSolo: false,
    type: "text"
  },
  {
    title: HOP_COUNT_FILTER_TITLE,
    optionKey: "hopCount",
    filterType: FILTER_TYPE.AUTOCOMPLETE,
    multiple: false,
    stateKey: "hopCount",
    freeSolo: false,
    type: "text"
  },
  {
    title: MIN_SIT_TIME_FILTER_TITLE,
    optionKey: "minSitTime",
    filterType: FILTER_TYPE.AUTOCOMPLETE,
    multiple: false,
    stateKey: "minSitTime",
    freeSolo: true,
    type: "number"
  }
];
