import React from "react";
import { Route } from "react-router-dom";

import ErrorBoundary from "../misc/ErrorBoundary";

const PublicRoute = props => {
  return (
    <ErrorBoundary>
      <Route {...props}>{props.children}</Route>
    </ErrorBoundary>
  );
};

export default PublicRoute;
