const ACTIONS = {
  setIntermediateDataForSchedules: "SET_INTERMEDIATE_DATA_FOR_SCHEDULES",
  setIntermediateDataForLegality: "SET_INTERMEDIATE_DATA_FOR_LEGALITY",
  setIntermediateDataForSolver: "SET_INTERMEDIATE_DATA_FOR_SOLVER",
  setIntermediateDataForPartialRefresh:
    "SET_INTERMEDIATE_DATA_FOR_PARTIAL_REFRESH",
  setIntermediateDataForOpenFlying: "SET_INTERMEDIATE_DATA_FOR_OPEN_FLYING"
};

const initialState = {
  intermediateDataForSchedules: {
    dataString: "",
    nextChunk: 0
  },
  intermediateDataForLegality: {
    dataString: "",
    nextChunk: 0
  },
  intermediateDataForSolver: {
    dataString: "",
    nextChunk: 0
  },
  intermediateDataForPartialRefresh: {
    dataString: "",
    nextChunk: 0
  },
  intermediateDataForOpenFlying: {
    dataString: "",
    nextChunk: 0
  }
};

export function websocketIntermediateDataReducer(
  prevState = initialState,
  action
) {
  let newState;

  switch (action.type) {
    case "SET_INTERMEDIATE_DATA_FOR_SCHEDULES":
      newState = {
        ...prevState,
        intermediateDataForSchedules: action.intermediateDataForSchedules
      };
      break;
    case "SET_INTERMEDIATE_DATA_FOR_LEGALITY":
      newState = {
        ...prevState,
        intermediateDataForLegality: action.intermediateDataForLegality
      };
      break;
    case "SET_INTERMEDIATE_DATA_FOR_SOLVER":
      newState = {
        ...prevState,
        intermediateDataForSolver: action.intermediateDataForSolver
      };
      break;
    case "SET_INTERMEDIATE_DATA_FOR_PARTIAL_REFRESH":
      newState = {
        ...prevState,
        intermediateDataForPartialRefresh:
          action.intermediateDataForPartialRefresh
      };
      break;
    case "SET_INTERMEDIATE_DATA_FOR_OPEN_FLYING":
      newState = {
        ...prevState,
        intermediateDataForOpenFlying: action.intermediateDataForOpenFlying
      };
      break;
    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setIntermediateDataForSchedulesAction(
  intermediateDataForSchedules
) {
  return {
    type: ACTIONS.setIntermediateDataForSchedules,
    intermediateDataForSchedules
  };
}

export function setIntermediateDataForLegalityAction(
  intermediateDataForLegality
) {
  return {
    type: ACTIONS.setIntermediateDataForLegality,
    intermediateDataForLegality
  };
}

export function setIntermediateDataForSolverAction(intermediateDataForSolver) {
  return {
    type: ACTIONS.setIntermediateDataForSolver,
    intermediateDataForSolver
  };
}

export function setIntermediateDataForPartialRefreshAction(
  intermediateDataForPartialRefresh
) {
  return {
    type: ACTIONS.setIntermediateDataForPartialRefresh,
    intermediateDataForPartialRefresh
  };
}

export function setIntermediateDataForOpenFlyingAction(
  intermediateDataForOpenFlying
) {
  return {
    type: ACTIONS.setIntermediateDataForOpenFlying,
    intermediateDataForOpenFlying
  };
}
