import React from "react";
import { Checkbox } from "@material-ui/core";
import "./CheckBox.scss";
export default function CustomCheckBox({
  stateValue,
  action,
  dispatcher,
  stateChangeHandler,
  index,
  disabled,
  additionalParamsForHandler
}) {
  return (
    <div className="custom-check-box-container">
      <Checkbox
        color="primary"
        checked={stateValue !== undefined ? stateValue : true}
        onChange={event => {
          event.stopPropagation();
          stateChangeHandler
            ? stateChangeHandler(
                event.target.checked,
                index,
                additionalParamsForHandler
                  ? { ...additionalParamsForHandler, dispatcher: dispatcher }
                  : null
              )
            : action && dispatcher(action(event.target.checked));
        }}
        disabled={disabled}
      />
    </div>
  );
}
