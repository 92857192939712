import React from "react";
import { useSelector } from "react-redux";
import { IconButton, InputLabel } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { SOLVER_SCENARIO } from "../../../../constants/routes";
import {
  getSolverStatusTextAndIcon,
  shareScenario
} from "../../../../utils/solverRunInfoUtils";

import "./SolverRunInfo.scss";
import { useLocation } from "react-router";

export default function SolverRunInfo() {
  const selectedScenarioData = useSelector(store => {
    const { selectedScenario, allScenarioMetadata } = store.solverData;

    const [correspondingScenarioMetadata] = allScenarioMetadata.filter(
      scenarioMetadata => scenarioMetadata.id === selectedScenario.id
    );

    return {
      ...correspondingScenarioMetadata,
      ...selectedScenario
    };
  });

  const [isShareUrlCopied, setIsShareUrlCopied] = React.useState(false);

  const location = useLocation();
  const isShareScreen = location.pathname.includes(SOLVER_SCENARIO);
  /**
   * window.location.host - returns the domain name of the web host
   */
  const getSolverShareUrl = React.useCallback(
    () => `${window.location.host}/solver-scenario/${selectedScenarioData.id}`,
    [selectedScenarioData.id]
  );

  const [statusText, StatusIcon] =
    getSolverStatusTextAndIcon(selectedScenarioData);

  const handleOnClickOfShareButton = React.useCallback(
    event => {
      event.stopPropagation();
      shareScenario(
        selectedScenarioData.id,
        getSolverShareUrl(),
        setIsShareUrlCopied
      );
    },
    [getSolverShareUrl, selectedScenarioData.id]
  );

  React.useEffect(() => setIsShareUrlCopied(false), [selectedScenarioData.id]);

  return (
    <div className="solver-run-info align-items-center justify-content-right orient-horizontally">
      <div>
        <InputLabel shrink={true}>Scenario Name</InputLabel>
        <div className="solver-run-name-section">
          {selectedScenarioData.scenarioName}
        </div>
      </div>
      <div>
        <InputLabel shrink={true}>Status</InputLabel>
        <div className="solver-run-status-section orient-horizontally">
          <div className="place-items-center">{StatusIcon}</div>
          <div>{statusText}</div>
        </div>
      </div>
      <>
        {!isShareScreen ? (
          <>
            <div>
              <InputLabel shrink={true}>Share</InputLabel>
              <div
                className={`share-button ${isShareUrlCopied ? "share-url-copied" : ""
                  } `}
              >
                <div>
                  <IconButton onClick={handleOnClickOfShareButton}>
                    <Share />
                  </IconButton>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    </div>
  );
}
