import {
  ENTITY_TYPE,
  NUMBER_OF_DAYS_PER_FETCH
} from "../constants/disruptions/crewGanttViewer";
import {
  FLIGHT_OPS_RANKS,
  IN_FLIGHT_RANKS
} from "../constants/disruptions/filter";
import { isInArray } from "./arrayUtils";
import { REST_BASED_RULENAMES } from "../constants/disruptions/rules";
import {
  MILLISECONDS_IN_A_DAY,
  MILLISECONDS_IN_A_MINUTE
} from "../constants/disruptions/timeline";
import { getEntityTypeKey } from "../components/partials/violation-drill-down-section/utils";

/**
 * returns an object that contains id of all the violators
 * @param {Array} violations
 */
export function getAllEntityViolation(violations) {
  const returnObject = {
    violatingFlightIds: [],
    violatingPairingIds: [],
    violatingDutyIds: []
  };
  violations.forEach(violation => {
    if (violation.violator.entityType === ENTITY_TYPE.FLIGHT)
      returnObject.violatingFlightIds.push(violation.violator.entityId);
    if (violation.violator.entityType === ENTITY_TYPE.DUTY)
      returnObject.violatingDutyIds.push(violation.violator.entityId);
    if (violation.violator.entityType === ENTITY_TYPE.PAIRING)
      returnObject.violatingPairingIds.push(violation.violator.entityId);
  });

  return returnObject;
}

/**
 * returns an array of all the violations as per @param o key
 * @param {*} timeViolations
 * @param {*} key
 */
export function getAllTimeViolation(timeViolations) {
  const returnObject = {
    sitViolationsId: [],
    layoverViolationsId: [],
    crewDutyViolationIdPairs: [],
    crewPairingViolationIdPairs: [],
    restViolationParentIds: []
  };

  timeViolations.forEach(timeViolation => {
    if (timeViolation.parent.entityType === ENTITY_TYPE.DUTY)
      returnObject.sitViolationsId.push(timeViolation.parent.entityId);
    if (timeViolation.parent.entityType === ENTITY_TYPE.PAIRING)
      returnObject.layoverViolationsId.push(timeViolation.parent.entityId);
    if (
      timeViolation.parent.entityType === ENTITY_TYPE.CREW &&
      timeViolation.entityBefore.entityType === ENTITY_TYPE.DUTY
    ) {
      returnObject.crewDutyViolationIdPairs.push({
        crewId: timeViolation.parent.entityId,
        dutyId: timeViolation.entityBefore.entityId
      });
    }
    if (
      timeViolation.parent.entityType === ENTITY_TYPE.CREW &&
      timeViolation.entityBefore.entityType === ENTITY_TYPE.PAIRING &&
      REST_BASED_RULENAMES.indexOf(timeViolation.ruleIdentity.ruleName) < 0
    ) {
      returnObject.crewPairingViolationIdPairs.push({
        crewId: timeViolation.parent.entityId,
        pairingId: timeViolation.entityBefore.entityId
      });
    }
    if (
      timeViolation.parent.entityType === ENTITY_TYPE.CREW &&
      timeViolation.entityBefore.entityType === ENTITY_TYPE.PAIRING &&
      REST_BASED_RULENAMES.indexOf(timeViolation.ruleIdentity.ruleName) > -1
    ) {
      returnObject.restViolationParentIds.push({
        crewId: timeViolation.parent.entityId,
        pairingId: timeViolation.entityBefore.entityId
      });
    }
  });

  return returnObject;
}

export function shouldIncludeCrewMember(crewMember, filterCriteria) {
  return (
    (filterCriteria.flightOps &&
      isInArray(crewMember.rank, FLIGHT_OPS_RANKS)) ||
    (filterCriteria.inFlight && isInArray(crewMember.rank, IN_FLIGHT_RANKS))
  );
}

/**
 * returns the start and the end date for the current fetch
 * @param {*} x | current x position of gantt
 * @param {*} y | current y position of gantt
 * @param {*} isScrollForward | is scrolling forward or backward
 * @param {*} dateRangeSelection | dateRange object from redux
 * @param {*} isFirstFetch | is it a first fetch
 */
export function findStartAndEndDateForHorizontalScheduleFetch(
  x,
  y,
  isScrollForward,
  dateRangeSelection,
  {
    isFirstFetch,
    isVertical,
    isRefresh,
    rangeInDaysInSingleViewPort,
    hourMinuteOffset
  }
) {
  let pageStartDate;
  let pageEndDate;

  let offsetToBeAdded = NUMBER_OF_DAYS_PER_FETCH;

  /**
   * if we have rangeInDaysInSingleViewPort then scale the offset to be fetched accordingly
   */
  if (rangeInDaysInSingleViewPort) {
    offsetToBeAdded = offsetToBeAdded + rangeInDaysInSingleViewPort / 2; //setting the number of extra days to fetch to half of rangeInDaysInSingleViewPort
  }

  //if moving forward
  if (isScrollForward) {
    let startIndex = x - 1 >= 0 ? x - 1 : x;
    //start date is current start date of gantt + current postion(day) in the gantt
    pageStartDate = new Date(
      Number(new Date(dateRangeSelection.startDate)) +
        startIndex * MILLISECONDS_IN_A_DAY
    ).toISOString();

    //end date is start date + offset days
    pageEndDate = new Date(
      Number(new Date(pageStartDate)) +
        Number(offsetToBeAdded * MILLISECONDS_IN_A_DAY)
    ).toISOString();
  } else {
    //moving backward
    //start index is start - offset days
    let startIndex = x - offsetToBeAdded;
    pageStartDate = new Date(
      Number(new Date(dateRangeSelection.startDate)) +
        startIndex * MILLISECONDS_IN_A_DAY
    ).toISOString();

    pageEndDate = new Date(
      Number(new Date(dateRangeSelection.startDate)) + x * MILLISECONDS_IN_A_DAY
    ).toISOString();
  }

  return {
    startDate: pageStartDate
      .substring(0, 10)
      .concat(
        hourMinuteOffset ? `T${hourMinuteOffset}:00+00:00` : "T00:00:00+00:00"
      ), //set to UTC 00:00:00 hours
    endDate: pageEndDate.substring(0, 10).concat("T00:00:00+00:00"),
    currentPosition: { x: x, y: isVertical ? y + 1 : y },
    isFirstFetch: isFirstFetch,
    isVertical: isVertical,
    isRefresh: isRefresh
  };
}

export function processEntityViolations(
  violations,
  existingViolations,
  violationMetadata,
  ruleDisplayConfig
) {
  violations &&
    violations.forEach(violation => {
      const ruleIdentity = violation.ruleIdentity;
      const ruleIdentityAsKey = `${ruleIdentity.ruleName}-${ruleIdentity.ruleCategory}-${ruleIdentity.ruleType}`;

      if (violationMetadata) {
        violationMetadata[violation.ruleIdentity.violationId] = {
          reduxKey: `entityViolations/${getEntityTypeKey(
            "ENTITY",
            violation
          )}/${violation.violator.entityId}/${ruleIdentityAsKey}`,
          originalMuteStatus: violation.ruleIdentity.isMuted
            ? violation.ruleIdentity.isMuted
            : false,
          changedMuteStatus: violation.ruleIdentity.isMuted
            ? violation.ruleIdentity.isMuted
            : false
        };
      }

      const colorForViolation = getColorForViolation(
        violation,
        ruleDisplayConfig
      );

      violation.color = colorForViolation;

      if (violation.violator.entityType === ENTITY_TYPE.FLIGHT) {
        if (
          existingViolations.flight[violation.violator.entityId] === undefined
        ) {
          existingViolations.flight[violation.violator.entityId] = {};
        }

        existingViolations.flight[violation.violator.entityId][
          ruleIdentityAsKey
        ] = violation;
      }
      if (violation.violator.entityType === ENTITY_TYPE.DUTY) {
        if (
          existingViolations.duty[violation.violator.entityId] === undefined
        ) {
          existingViolations.duty[violation.violator.entityId] = {};
        }
        existingViolations.duty[violation.violator.entityId][
          ruleIdentityAsKey
        ] = violation;
      }
      if (violation.violator.entityType === ENTITY_TYPE.PAIRING) {
        if (
          existingViolations.pairing[violation.violator.entityId] === undefined
        ) {
          existingViolations.pairing[violation.violator.entityId] = {};
        }
        existingViolations.pairing[violation.violator.entityId][
          ruleIdentityAsKey
        ] = violation;
      }
    });

  return existingViolations;
}

export function processTimeViolations(
  violations,
  existingViolations,
  violationMetadata,
  ruleDisplayConfig
) {
  violations &&
    violations.forEach(violation => {
      const ruleIdentity = violation.ruleIdentity;
      const ruleIdentityAsKey = `${ruleIdentity.ruleName}-${ruleIdentity.ruleCategory}-${ruleIdentity.ruleType}`;
      const keyToBeInserted = `${violation.parent.entityId}-${violation.entityBefore.entityId}`;

      if (violationMetadata) {
        violationMetadata[violation.ruleIdentity.violationId] = {
          reduxKey: `timeViolations/${getEntityTypeKey(
            "TIME",
            violation
          )}/${keyToBeInserted}/${ruleIdentityAsKey}`,
          originalMuteStatus: violation.ruleIdentity.isMuted
            ? violation.ruleIdentity.isMuted
            : false,
          changedMuteStatus: violation.ruleIdentity.isMuted
            ? violation.ruleIdentity.isMuted
            : false
        };
      }

      const colorForViolation = getColorForViolation(
        violation,
        ruleDisplayConfig
      );

      violation.color = colorForViolation;

      if (violation.parent.entityType === ENTITY_TYPE.DUTY) {
        if (existingViolations.duty[keyToBeInserted] === undefined) {
          existingViolations.duty[keyToBeInserted] = {};
        }

        existingViolations.duty[keyToBeInserted][ruleIdentityAsKey] = violation;
      }
      if (violation.parent.entityType === ENTITY_TYPE.PAIRING) {
        if (existingViolations.pairing[keyToBeInserted] === undefined) {
          existingViolations.pairing[keyToBeInserted] = {};
        }
        existingViolations.pairing[keyToBeInserted][ruleIdentityAsKey] =
          violation;
      }
      if (
        violation.parent.entityType === ENTITY_TYPE.CREW &&
        violation.entityBefore.entityType === ENTITY_TYPE.DUTY
      ) {
        if (
          existingViolations.crew.crewDutyViolation[keyToBeInserted] ===
          undefined
        ) {
          existingViolations.crew.crewDutyViolation[keyToBeInserted] = {};
        }
        existingViolations.crew.crewDutyViolation[keyToBeInserted][
          ruleIdentityAsKey
        ] = violation;
      }
      if (
        violation.parent.entityType === ENTITY_TYPE.CREW &&
        violation.entityBefore.entityType === ENTITY_TYPE.PAIRING &&
        REST_BASED_RULENAMES.indexOf(violation.ruleIdentity.ruleName) < 0
      ) {
        if (
          existingViolations.crew.crewPairingViolation[keyToBeInserted] ===
          undefined
        ) {
          existingViolations.crew.crewPairingViolation[keyToBeInserted] = {};
        }
        existingViolations.crew.crewPairingViolation[keyToBeInserted][
          ruleIdentityAsKey
        ] = violation;
      }
      if (
        violation.parent.entityType === ENTITY_TYPE.CREW &&
        violation.entityBefore.entityType === ENTITY_TYPE.PAIRING &&
        REST_BASED_RULENAMES.indexOf(violation.ruleIdentity.ruleName) > -1
      ) {
        if (
          existingViolations.crew.restViolation[keyToBeInserted] === undefined
        ) {
          existingViolations.crew.restViolation[keyToBeInserted] = {};
        }
        existingViolations.crew.restViolation[keyToBeInserted][
          ruleIdentityAsKey
        ] = violation;
      }
    });

  return existingViolations;
}

export function getTMinusOneDateString() {
  let date = new Date(
    Number(new Date()) - MILLISECONDS_IN_A_DAY / 2
  ).toISOString();
  return date.substring(0, date.length - 5);
}

export function getIsoDateString(offset = 0) {
  let date = new Date(
    Number(new Date()) + MILLISECONDS_IN_A_DAY * offset
  ).toISOString();

  return date.substring(0, date.length - 5);
}

/**
 * returns a back-dated date object
 * @param {*} offset number of days from the current date
 */
export function getPreviousDate(offset = 1) {
  return new Date(Number(new Date()) - Number(offset * MILLISECONDS_IN_A_DAY))
    .toISOString()
    .substring(0, 10)
    .concat("T00:00:00+00:00");
}

export function getAnchorDate() {
  return (
    Number(
      new Date(new Date().toISOString().substring(0, 11).concat("00:00:00"))
    ) - Number(new Date().getTimezoneOffset() * MILLISECONDS_IN_A_MINUTE)
  );
}

function getColorForViolation(violation, ruleDisplayConfig) {
  const {
    ruleIdentity: { ruleName, ruleCategory, ruleType }
  } = violation;

  return ruleDisplayConfig[ruleName][ruleCategory][ruleType]["color"];
}
