import { batch } from "react-redux";
import { DST_CHANGE_TYPES } from "../constants/daylightSavings";
import { DEADHEAD_FLIGHT, RTG_FLIGHT } from "../constants/disruptions/color";
import {
  CODE_FOR_OPERATIONAL_DUTY,
  CODE_FOR_RESERVE_DUTY,
  NUMBER_OF_FORWARD_DAYS_TO_FETCH,
  NUMBER_OF_PREVIOUS_DAYS_TO_FETCH
} from "../constants/disruptions/crewGanttViewer";
import {
  LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
  LOCAL_TIME_ZONE_NAME_KEY
} from "../constants/disruptions/disruptionSummary";
import { DEQUAL_STATUS_MAP } from "../constants/disruptions/filter";
import {
  FLIGHT_NUMBER_DISPLAY_ENUM,
  FLIGHT_STATUS_KEY,
  UNPLANNED_FLIGHT_DIVERSION_STATUSES
} from "../constants/disruptions/pairing";
import { CODES_TO_ICON_MAP } from "../constants/disruptions/referenceIcons";
import {
  MILLISECONDS_IN_A_DAY,
  MILLISECONDS_IN_A_MINUTE
} from "../constants/disruptions/timeline";
import {
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN,
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_AND_ITS_DESC_TO_BE_SHOWN,
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_TO_BE_SHOWN,
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_NUMBER_TO_BE_SHOWN
} from "../constants/disruptions/waitingTime";
import {
  setCanFetchMoreCrewsAction,
  setCrewSchedulesAction,
  setIsCrewSchedulesNotDefinedAction,
  setIsFetchingMoreCrewAction,
  setIsOpenTimeNotDefinedAction,
  setOpenTimeAction,
  showLoaderOnGanttAction
} from "../redux/crewSchedulesReducer";
import {
  setIsFilterBeingAppliedAction,
  setScheduleDataGridAction,
  setTimeWindowFromAllPairings
} from "../redux/searchCriteriaReducer";
import { isEmptyArray, isInArray, isNonEmptyArray } from "./arrayUtils";
import { getTimezoneOffsetForGivenDateTimeAndUserSelectedZone } from "./dateTimeUtils";
import { getAirportLocalTime } from "./flightUtils";
import { isNonEmptyObject } from "./jsonUtils";
import {
  findFlightsAndPairingWithOpenPositions,
  getPairingIdsSortedByTime,
  updateTimeWindowForPairingsIfDataNotWithinRange
} from "./middlewareUtils";
import {
  checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity,
  findUtcArrivalTimeOfFlight,
  findUtcDepartureTimeOfFlight,
  getStartAndEndOfDuty,
  getTimeAfterApplyingOffsetsBasedOnDstInfo,
  shouldShowFlightDetailsV2
} from "./pairingUtils";
import {
  getDifferenceInDays,
  getleftMarginForEntity,
  getNumberOfHoursBetweenDates,
  getNumberOfMinutesBetweenDates,
  getTwoDigitStringFromNumber,
  getWidthForEntity
} from "./timelineUtils";

import { shouldShowTimeInfoOnDutyBlock } from "./dutyUtils";

/**
 * takes in params required to compute the time window and returns the updated time window for which the gantt has to be drawn
 * @param {*} state
 * @param {*} eventData
 * @param {*} timeWindowOfCurrentFetch
 * @param {*} middlewareFlags
 * @returns updated time window for which the gantt has to be drawn
 */
export const updateTimeWindow = (
  state,
  eventData,
  timeWindowOfCurrentFetch,
  middlewareFlags
) => {
  let timeWindowBeforeUpdate = { ...state.searchCriteria.dateRange };
  let updatedTimeWindow = {};

  /**
   * if its a first fetch or filter application fetch then take the time window received from the backend
   */
  if (middlewareFlags.isFirstFetch || middlewareFlags.isFilterBeingApplied) {
    /**
     * start date is set to filter date.from
     */
    let startDate = new Date(
      new Date(eventData.selectedFilters.date.from)
        .toISOString()
        .substring(0, 10)
        .concat("T00:00:00+00:00")
    );

    /**
     * since the schedule contains data from [date.from - NUMBER_OF_PREVIOUS_DAYS_TO_FETCH] setting the start date accordingly
     */
    startDate = new Date(
      Number(startDate) -
        NUMBER_OF_PREVIOUS_DAYS_TO_FETCH * MILLISECONDS_IN_A_DAY
    );

    /**
     * end date is set to filter date.to
     */
    let endDate = new Date(
      new Date(eventData.selectedFilters.date.to)
        .toISOString()
        .substring(0, 10)
        .concat("T00:00:00+00:00")
    );

    /**
     * since the schedule contains data from [date.to +  NUMBER_OF_FORWARD_DAYS_TO_FETCH] setting the start date accordingly
     */
    endDate = new Date(
      Number(endDate) + NUMBER_OF_FORWARD_DAYS_TO_FETCH * MILLISECONDS_IN_A_DAY
    );

    middlewareFlags.isFilterBeingApplied = false;

    updatedTimeWindow = {
      earliestStartDate: startDate,
      latestEndDate: endDate,
      totalWindowInDays:
        getDifferenceInDays(new Date(startDate), new Date(endDate), {
          shouldRoundOff: true,
          getAbsoluteValue: true
        }) + 3,
      isFirstFetch: true
    };
  } else {
    /**
     * update the time window if the fetch window is out of the current timeline window
     */
    updatedTimeWindow = updateTimeWindowForPairingsIfDataNotWithinRange(
      timeWindowBeforeUpdate.startDate,
      timeWindowOfCurrentFetch.start,
      timeWindowBeforeUpdate.endDate,
      timeWindowOfCurrentFetch.end
    );
  }

  return updatedTimeWindow;
};

/**
 * update the gird metadata that is used to perform fetching of new data based on the newly fetched data
 * @param {*} state
 * @param {*} updatedTimeWindow
 * @param {*} currentPosition
 * @param {*} anchorDate
 * @returns
 */
export const updateGridMetaData = (
  state,
  updatedTimeWindow,
  currentPosition,
  anchorDate,
  crewSchedules,
  nonDuplicateCrewMembersFromScheduleFetched
) => {
  const scheduleDataGrid = {
    ...state.searchCriteria.getSchedulesParameters.scheduleDataGrid
  };

  /**
   * relative index(difference) from the anchor date to the start of the current date
   */
  let startIndex =
    (Number(new Date(updatedTimeWindow.earliestStartDate)) -
      Number(anchorDate)) /
    MILLISECONDS_IN_A_DAY;

  /**
   * relative index(difference) from the anchor date to the end of the current date
   */
  let endIndex =
    (Number(new Date(updatedTimeWindow.latestEndDate)) - Number(anchorDate)) /
    MILLISECONDS_IN_A_DAY;

  if (
    scheduleDataGrid[currentPosition.y] === undefined ||
    scheduleDataGrid[currentPosition.y].lastDate === undefined
  )
    scheduleDataGrid[currentPosition.y] = {
      lastDate: null,
      data: {}
    };

  /**
   * fill the grid with 'true' for indexes between startIndex and endIndex
   */
  for (let i = Math.floor(startIndex); i < Math.floor(endIndex); i++) {
    scheduleDataGrid[currentPosition.y].data[i] = true;
    /**
     * store the last date for which the gantt has data for that page(currentPosition.y)
     */
    if (i === Math.floor(endIndex) - 1) {
      scheduleDataGrid[currentPosition.y].lastDate =
        i * MILLISECONDS_IN_A_DAY + Number(anchorDate);
    }
  }

  /**
   * update metadata along y direction
   */
  let yMap = updateYMap(
    state,
    currentPosition,
    crewSchedules,
    nonDuplicateCrewMembersFromScheduleFetched
  );

  return { scheduleDataGrid: scheduleDataGrid, yMap: yMap };
};

/**
 * takes in the newly fetched schedule does some processing on them and returns the updated schedule
 * @param {*} dispatcher
 * @param {*} crewSchedules
 * @param {*} scheduleFetched
 * @param {*} middlewareFlags
 * @returns the updated schedule
 */
export const processScheduleFetched = (
  dispatcher,
  crewSchedules,
  scheduleFetched,
  middlewareFlags,
  updatedTimeWindow,
  lookUpData
) => {
  /**
   * if there are crewMembers that were part of the current fetch
   */
  if (scheduleFetched.crewMembers.length > 0) {
    let nonDuplicateCrewMembersFromScheduleFetched = [];

    /**
     * for every fetched crew member do
     */
    scheduleFetched.crewMembers.forEach(crewMember => {
      let indexOfCrewFromSchedules = findIndexOfCrewMember(
        crewSchedules,
        crewMember
      );

      /**
       * if there is a crew member already present then
       */
      if (indexOfCrewFromSchedules !== -1) {
        /**
         * for each newly received pairing id add it to the crews pairing object if not already present
         */
        updatePairingArrayOfCrew(
          crewMember.pairings,
          crewSchedules,
          indexOfCrewFromSchedules
        );
      } else {
        /**
         * if the crew is not already present and if the fetch is of type vertical or first then push the crewMember to array
         */
        if (middlewareFlags.isVertical || middlewareFlags.isFirstFetch) {
          nonDuplicateCrewMembersFromScheduleFetched.push(crewMember);
        }
      }
    });

    scheduleFetched.crewMembers = [
      ...crewSchedules.crewMembers,
      ...nonDuplicateCrewMembersFromScheduleFetched
    ];

    /**
     * get all dequalification info for the crewMembers
     */
    processCrewsDequalificationStatus(
      scheduleFetched.crewMembers,
      updatedTimeWindow
    );

    scheduleFetched.flights = {
      ...crewSchedules.flights,
      ...scheduleFetched.flights
    };

    scheduleFetched.duties = {
      ...crewSchedules.duties,
      ...scheduleFetched.duties
    };

    // if there are duplicate pairing that shows up replace old with the newer one
    Object.keys(scheduleFetched.pairings).forEach(pairingId => {
      crewSchedules.pairings[pairingId] = scheduleFetched.pairings[pairingId];
    });

    scheduleFetched.pairings = {
      ...crewSchedules.pairings
    };

    /**
     * rearranging the pairing array in crew Object according to their start time
     **/
    scheduleFetched.crewMembers.forEach((crewMember, index) => {
      lookUpData.crewMembers[crewMember.id] = {
        ...lookUpData.crewMembers[crewMember.id],
        indexOfCrewInStore: index
      };

      if (isNonEmptyArray(crewMember.pairings)) {
        crewMember.pairings = getPairingIdsSortedByTime(
          crewMember.pairings,
          scheduleFetched.pairings
        );

        crewMember.pairings.forEach(pairingId => {
          lookUpData.pairings[pairingId] = {
            ...lookUpData.pairings[pairingId],
            immediateParentId: crewMember.id
          };
        });
      }
    });

    return {
      scheduleFetched: scheduleFetched,
      nonDuplicateCrewMembersFromScheduleFetched:
        nonDuplicateCrewMembersFromScheduleFetched.length
    };
  } else {
    //TODO: add a fall back screen when there are no results after applying filter
    dispatcher(setIsCrewSchedulesNotDefinedAction(false));
    dispatcher(setIsOpenTimeNotDefinedAction(false));
    if (middlewareFlags.isVertical)
      dispatcher(setCanFetchMoreCrewsAction(false));
    dispatcher(setIsFetchingMoreCrewAction(false));
  }

  return {
    scheduleFetched: crewSchedules,
    nonDuplicateCrewMembersFromScheduleFetched: 0
  };
};

/**
 * update the gird metadata(vertical) that is used to perform fetching of new data based on the newly fetched data
 * @param {*} state
 * @param {*} currentPosition
 * @param {*} crewSchedules
 * @param {*} nonDuplicateCrewMembersFromScheduleFetched
 * @returns
 */
export const updateYMap = (
  state,
  currentPosition,
  crewSchedules,
  nonDuplicateCrewMembersFromScheduleFetched
) => {
  let yMap = [...state.searchCriteria.getSchedulesParameters.yPositionMap];
  /**
   * if the current page is already present in yMap then do not insert as this is a horizontal fetch
   */
  if (!isInArray(currentPosition.y, yMap))
    for (
      let i = crewSchedules.crewMembers.length;
      i <=
      crewSchedules.crewMembers.length +
        nonDuplicateCrewMembersFromScheduleFetched;
      i++
    ) {
      yMap[i] = currentPosition.y;
    }

  return yMap;
};

/**
 * takes in the updated schedule and updates the store with the same
 * @param {*} dispatcher
 * @param {*} updatedTimeWindow
 * @param {*} updatedGridData
 * @param {*} updatedYMap
 * @param {*} scheduleFetchedAfterProcessing
 * @param {*} middlewareFlags
 */
export const updateStoreWithNewScheduleData = ({
  dispatcher,
  updatedTimeWindow,
  updatedGridMetaData,
  scheduleFetchedAfterProcessing,
  middlewareFlags,
  uiConfig,
  userInfo,
  lookUpData
}) => {
  /**
   * API allows any React updates in an event loop tick to be batched together into a single render pass.
   * React already uses this internally for its own event handler callbacks.
   */
  batch(() => {
    /**
     * if the fetch is a first fetch then setting isCrewSchedulesNotDefined to true
     */
    if (middlewareFlags.isFirstFetch)
      dispatcher(setIsCrewSchedulesNotDefinedAction(true));
    dispatcher(
      setTimeWindowFromAllPairings({ ...updatedTimeWindow, uiConfig, userInfo })
    );
    dispatcher(
      setScheduleDataGridAction({
        scheduleDataGrid: updatedGridMetaData.scheduleDataGrid,
        yPositionMap: updatedGridMetaData.yMap
      })
    );

    let openTimeData = {
      openFlightIds: [],
      openPairingIds: []
    };

    if (middlewareFlags.isAnOpenFlightFetch) {
      openTimeData = findFlightsAndPairingWithOpenPositions(
        scheduleFetchedAfterProcessing.flights,
        scheduleFetchedAfterProcessing.pairings
      );
      dispatcher(setOpenTimeAction(openTimeData));
    }

    scheduleFetchedAfterProcessing.lookUpData = lookUpData;

    dispatcher(setCrewSchedulesAction(scheduleFetchedAfterProcessing));

    middlewareFlags.scheduleProcessingComplete = true;
    dispatcher(setIsFilterBeingAppliedAction(false));
    dispatcher(showLoaderOnGanttAction(false));
  });
};

export const findIndexOfCrewMember = (crewSchedules, crewMember) => {
  for (let i = 0; i < crewSchedules.crewMembers.length; i++) {
    /**
     * find index of crew member if already present
     */
    if (crewSchedules.crewMembers[i].id === crewMember.crewId) {
      return i;
    }
  }

  return -1;
};

export const updatePairingArrayOfCrew = (
  pairings,
  crewSchedules,
  indexOfCrewFromSchedules
) => {
  pairings.forEach(pairingId => {
    if (
      !isInArray(
        pairingId,
        crewSchedules.crewMembers[indexOfCrewFromSchedules].pairings
      )
    )
      crewSchedules.crewMembers[indexOfCrewFromSchedules].pairings.push(
        pairingId
      );
  });
};

export const processScheduleResponseAndUpdateStore = ({
  store,
  eventData,
  startAndEndOfCurrentFetch,
  middlewareFlags,
  crewSchedules,
  payload,
  currentPosition,
  anchorDate,
  uiConfig,
  userInfo,
  dateRange,
  webWorkerService
}) => {
  /**
   * processing of schedule data starts here, therefore setting fetching flag to true
   */
  store.dispatch(setIsFetchingMoreCrewAction(true));

  const lookUpData = {
    flights: {},
    duties: {},
    pairings: {},
    crewMembers: {}
  };

  /**
   * update the time window for which the timeline is drawn
   */
  let updatedTimeWindow = updateTimeWindow(
    store.getState(),
    eventData,
    startAndEndOfCurrentFetch,
    middlewareFlags
  );

  /**
   * process the received schedule data along with the already exsiting ones
   */
  let scheduleFetchedAfterProcessing = processScheduleFetched(
    store.dispatch,
    crewSchedules,
    payload,
    middlewareFlags,
    updatedTimeWindow,
    lookUpData
  );

  /**
   * update the data grid (meta data) using which new data is fetched
   */
  let updatedGridMetaData = updateGridMetaData(
    store.getState(),
    updatedTimeWindow,
    currentPosition,
    anchorDate,
    crewSchedules,
    scheduleFetchedAfterProcessing.nonDuplicateCrewMembersFromScheduleFetched
  );

  if (window.Worker) {
    webWorkerService.invokeScheduleProcessor({
      eventType: "GET_SCHEDULES",
      eventData: {
        scheduleFetchedAfterProcessing,
        showGanttInUTC: userInfo.showGanttInUTC,
        userAttributes: userInfo.userAttributes,
        dateRange: { ...dateRange, ...updatedTimeWindow },
        lookUpData
      },
      context: {
        shouldInitiateViolationProcessing: false,
        postProcessingFunction: updateStoreWithNewScheduleData,
        postProcessingFunctionParameters: {
          dispatcher: store.dispatch,
          updatedTimeWindow,
          updatedGridMetaData,
          middlewareFlags,
          uiConfig,
          userInfo
        }
      }
    });
  }
};

/**
 * loop through all the crew members and calculate the dequalified info for each
 * @param {*} crewMembers
 * @param {*} timeWindow
 */
export const processCrewsDequalificationStatus = (crewMembers, timeWindow) => {
  isNonEmptyArray(crewMembers) &&
    crewMembers.forEach(crewMember => {
      crewMember.dequalificationInfo = getIsCrewDequalified(
        crewMember.qualifications,
        crewMember.rank,
        timeWindow
      );
    });
};

/**
 * returns an object containing info on the dequalification status of the crew and the positions of the de-qualification
 * @param {*} qualifications
 */
export function getIsCrewDequalified(qualifications, rank, timeWindow) {
  let isDequalified = false;
  let indicesOfDequalification = [];
  let dateOfFirstDequalification = null;
  if (isNonEmptyArray(qualifications)) {
    qualifications
      .sort(
        (a, b) => new Date(a.utcExpiryDateTime) - new Date(b.utcExpiryDateTime)
      )
      .forEach((qualification, index) => {
        /**
         * check if the qualification expiry date is falling in the current time window of the gantt
         */
        if (
          new Date(qualification.utcExpiryDateTime) <
            timeWindow.latestEndDate &&
          new Date(qualification.utcExpiryDateTime) >
            timeWindow.earliestStartDate &&
          isInArray(qualification.rank, DEQUAL_STATUS_MAP[rank])
        ) {
          isDequalified = true; //set flag to true
          indicesOfDequalification.push(index); //push the index of the expired qualification
          if (
            dateOfFirstDequalification === null ||
            dateOfFirstDequalification >
              new Date(qualification.utcExpiryDateTime)
          ) {
            dateOfFirstDequalification = new Date(
              qualification.utcExpiryDateTime
            );
          }
        }
      });
  } else {
    //if there is no qualification information then set dequalified flag as true
    isDequalified = true;
  }

  return {
    isCrewDequalified: isDequalified,
    indices: indicesOfDequalification,
    dateOfFirstDequalification: dateOfFirstDequalification
  };
}

const getTypeOfPairingAndLayoverInfo = (
  dutyIds,
  allDuties,
  userAttributes,
  dateRange,
  utcStartTime
) => {
  let countOfReserveDuties = 0;
  const allLayoverInfo = [];

  if (!isNonEmptyArray(dutyIds))
    return { typeOfPairing: "RSV", allLayoverInfo };

  const dstRelatedInfo = {
    typeOfDSTChange: DST_CHANGE_TYPES.NO_CHANGE,
    dstOffset: 0
  };

  dutyIds.forEach((dutyId, index) => {
    if (
      allDuties[dutyId] &&
      allDuties[dutyId].dutyCode &&
      allDuties[dutyId].dutyCode !== CODE_FOR_OPERATIONAL_DUTY &&
      isEmptyArray(allDuties[dutyId].flightIds)
    ) {
      countOfReserveDuties++;
    }

    if (
      index !== 0 &&
      allDuties[dutyId] &&
      allDuties[dutyIds[index - 1]] &&
      new Date(getStartAndEndOfDuty(allDuties[dutyId]).utcStartTime) >
        new Date(getStartAndEndOfDuty(allDuties[dutyIds[index - 1]]).utcEndTime)
    ) {
      const layoverStart = new Date(
        getStartAndEndOfDuty(allDuties[dutyIds[index - 1]]).utcEndTime
      );
      const layoverEnd = new Date(
        getStartAndEndOfDuty(allDuties[dutyId]).utcStartTime
      );

      checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity(
        layoverStart,
        layoverEnd,
        userAttributes,
        dstRelatedInfo
      );

      allLayoverInfo.push({
        width: getWidthForEntity(
          getTimeAfterApplyingOffsetsBasedOnDstInfo(
            layoverEnd,
            dstRelatedInfo,
            DST_CHANGE_TYPES.DST_CHANGE_POSITIVE
          ),
          Number(layoverStart),
          dateRange.widthOfOneDay
        ), //add the dst offset to the end time when there is a positive change, eg: -300 -> -240; in this case the width to be drawn will be more than the computed value
        leftPosition: getleftMarginForEntity(
          getTimeAfterApplyingOffsetsBasedOnDstInfo(
            layoverStart,
            dstRelatedInfo,
            DST_CHANGE_TYPES.DST_CHANGE_NEGATIVE
          ), //add the dst offset to the start time when there is a negative change, eg: -240 -> -300; in this case the left position from which entity will be drawn will be less than the prev computed value
          dateRange.widthOfOneDay,
          new Date(utcStartTime)
        ),
        hours: getTwoDigitStringFromNumber(
          getNumberOfHoursBetweenDates(layoverStart, layoverEnd)
        ),
        minutes: getTwoDigitStringFromNumber(
          getNumberOfMinutesBetweenDates(layoverStart, layoverEnd)
        ),
        startTime: layoverStart
      });
    }
  });

  return {
    typeOfPairing:
      countOfReserveDuties !== 0 && countOfReserveDuties === dutyIds.length
        ? "RSV"
        : "OPR",
    allLayoverInfo
  };
};

const getStartTimeEndTimeLeftAndWidthAttributes = (
  start,
  end,
  dateRange,
  showGanttInUTC,
  userAttributes,
  parentEntityStartTime
) => {
  let startInEpochMilliseconds = 0,
    endInEpochMillis = 0;

  if (!showGanttInUTC) {
    let localOffsetStart = getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
      start,
      userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][LOCAL_TIME_ZONE_NAME_KEY]
    );

    let localOffsetEnd = getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
      end,
      userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][LOCAL_TIME_ZONE_NAME_KEY]
    );

    startInEpochMilliseconds = Number(
      Number(new Date(start)) +
        Number(localOffsetStart * MILLISECONDS_IN_A_MINUTE)
    );
    endInEpochMillis = Number(
      Number(new Date(end)) + Number(localOffsetEnd * MILLISECONDS_IN_A_MINUTE)
    );
  } else {
    startInEpochMilliseconds = Number(new Date(start));
    endInEpochMillis = Number(new Date(end));
  }

  const width = getWidthForEntity(
    endInEpochMillis,
    startInEpochMilliseconds,
    dateRange.widthOfOneDay
  );

  const left = getleftMarginForEntity(
    startInEpochMilliseconds,
    dateRange.widthOfOneDay,
    parentEntityStartTime
  );

  return {
    startTime: startInEpochMilliseconds,
    endTime: endInEpochMillis,
    left,
    width
  };
};

export const calculatePositionAndWidthOfEntities = (
  newSchedule,
  showGanttInUTC,
  userAttributes,
  updatedTimeWindow,
  lookUpData
) => {
  const widthAndLeftOfEntities = calculateWidthAndLeftOfEntities(
    newSchedule.scheduleFetched.pairings,
    newSchedule.scheduleFetched.duties,
    newSchedule.scheduleFetched.flights,
    updatedTimeWindow,
    showGanttInUTC,
    userAttributes
  );

  newSchedule.scheduleFetched.pairings = {
    ...newSchedule.scheduleFetched.pairings,
    ...widthAndLeftOfEntities.pairingData
  };

  newSchedule.scheduleFetched.duties = {
    ...newSchedule.scheduleFetched.duties,
    ...widthAndLeftOfEntities.dutyData
  };

  newSchedule.scheduleFetched.flights = {
    ...newSchedule.scheduleFetched.flights,
    ...widthAndLeftOfEntities.flightData
  };
};

const getAllRequiredDataForFlight = (
  flight,
  isGanttShownInUTC,
  updatedTimeWindow
) => {
  const toReturn = {
    isPPP: false,
    isRTG: false,
    shouldShowTimeInfo: false,
    flightNumberReplacementIcon: null,
    depHoursFlight: "",
    depMinutesFlight: "",
    arrHoursFlight: "",
    arrMinutesFlight: "",
    color: ""
  };

  const {
    origin,
    destination,
    flightNumber,
    flightStatus,
    width,
    coalescedStart,
    coalescedEnd
  } = flight;

  toReturn.isRTG =
    origin === destination && String(flightStatus).includes("-return");
  toReturn.isPPP = coalescedStart === coalescedEnd;

  toReturn.color = getColorOfFlight({ ...flight, isRTG: toReturn.isRTG });

  toReturn.shouldShowFlightDetails = shouldShowFlightDetailsV2(
    updatedTimeWindow.rangeInDaysInSingleViewPort,
    toReturn.isRTG,
    width
  );

  toReturn.flightNumberDisplayMetadata = getFlightNumberDisplayMetadata(
    flightNumber,
    width,
    updatedTimeWindow.rangeInDaysInSingleViewPort,
    toReturn.isRTG
  );

  toReturn.applyTimelineWindowTwoDaysClass =
    (updatedTimeWindow.rangeInDaysInSingleViewPort > 1 ||
      width < MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN) &&
    !toReturn.isRTG;

  if (toReturn.shouldShowFlightDetails) {
    /**
     * flight departure and arrival in UTC - used when the gantt is shown in UTC time
     */
    let flightDepartureTime = new Date(findUtcDepartureTimeOfFlight(flight));
    let flightArrivalTime = new Date(findUtcArrivalTimeOfFlight(flight));

    if (isGanttShownInUTC) {
      toReturn.depHoursFlight = getTwoDigitStringFromNumber(
        flightDepartureTime.getUTCHours()
      );
      toReturn.depMinutesFlight = getTwoDigitStringFromNumber(
        flightDepartureTime.getUTCMinutes()
      );
      toReturn.arrHoursFlight = getTwoDigitStringFromNumber(
        flightArrivalTime.getUTCHours()
      );
      toReturn.arrMinutesFlight = getTwoDigitStringFromNumber(
        flightArrivalTime.getUTCMinutes()
      );
    } else {
      /**
       * flight departure and arrival in their respective airport local time - used when the gantt is shown in local times
       */
      let airportLocalDepartureTimes = getAirportLocalTime(
        flightDepartureTime,
        flight.originTZ
      );
      let airportLocalArrivalTimes = getAirportLocalTime(
        flightArrivalTime,
        flight.destinationTZ
      );

      toReturn.depHoursFlight = getTwoDigitStringFromNumber(
        airportLocalDepartureTimes.getUTCHours()
      );
      toReturn.depMinutesFlight = getTwoDigitStringFromNumber(
        airportLocalDepartureTimes.getUTCMinutes()
      );
      toReturn.arrHoursFlight = getTwoDigitStringFromNumber(
        airportLocalArrivalTimes.getUTCHours()
      );
      toReturn.arrMinutesFlight = getTwoDigitStringFromNumber(
        airportLocalArrivalTimes.getUTCMinutes()
      );
    }
  }
  return toReturn;
};

const getTypeOfDutyAndSitTimeInfo = (
  flightIds,
  allFlights,
  dateRange,
  startTime,
  userAttributes,
  dutyCode
) => {
  const allSitTimeInfo = [];

  const dstRelatedInfo = {
    typeOfDSTChange: DST_CHANGE_TYPES.NO_CHANGE,
    dstOffset: 0
  };

  flightIds.forEach((flightId, index) => {
    if (index === 0) return;
    const firstFlight = allFlights[flightIds[index - 1]];
    const secondFlight = allFlights[flightId];
    if (firstFlight && secondFlight) {
      const isUnplannedDiversion =
        UNPLANNED_FLIGHT_DIVERSION_STATUSES.includes(
          firstFlight[FLIGHT_STATUS_KEY]
        ) &&
        UNPLANNED_FLIGHT_DIVERSION_STATUSES.includes(
          secondFlight[FLIGHT_STATUS_KEY]
        );

      const sitStart = new Date(findUtcArrivalTimeOfFlight(firstFlight));
      const sitEnd = new Date(findUtcDepartureTimeOfFlight(secondFlight));

      checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity(
        sitStart,
        sitEnd,
        userAttributes,
        dstRelatedInfo
      );

      allSitTimeInfo.push({
        ...getAllSitTimeProperties(
          sitStart,
          sitEnd,
          startTime,
          isUnplannedDiversion,
          dstRelatedInfo,
          dateRange
        ),
        key: firstFlight.id
      });
    }
  });

  return {
    allSitTimeInfo,
    typeOfDuty:
      dutyCode === CODE_FOR_OPERATIONAL_DUTY
        ? CODE_FOR_OPERATIONAL_DUTY
        : CODE_FOR_RESERVE_DUTY
  };
};

export const getAllSitTimeProperties = (
  sitTimeStart,
  sitTimeEnd,
  utcStartTime,
  isUnplannedDiversion = false,
  dstRelatedInfo,
  dateRange
) => {
  return {
    width: getWidthForEntity(
      getTimeAfterApplyingOffsetsBasedOnDstInfo(
        sitTimeEnd,
        dstRelatedInfo,
        DST_CHANGE_TYPES.DST_CHANGE_POSITIVE
      ),
      Number(sitTimeStart),
      dateRange.widthOfOneDay
    ),
    leftPosition: getleftMarginForEntity(
      getTimeAfterApplyingOffsetsBasedOnDstInfo(
        sitTimeStart,
        dstRelatedInfo,
        DST_CHANGE_TYPES.DST_CHANGE_NEGATIVE
      ),
      dateRange.widthOfOneDay,
      new Date(utcStartTime)
    ),
    hours: getTwoDigitStringFromNumber(
      getNumberOfHoursBetweenDates(sitTimeStart, sitTimeEnd)
    ),
    minutes: getTwoDigitStringFromNumber(
      getNumberOfMinutesBetweenDates(sitTimeStart, sitTimeEnd)
    ),
    startTime: sitTimeStart,
    isUnplannedDiversion
  };
};

const getFlightNumberDisplayMetadata = (
  flightNumber,
  width,
  rangeInDaysInSingleViewPort,
  isRTG
) => {
  if (isRTG) {
    return FLIGHT_NUMBER_DISPLAY_ENUM.RTG_BLOCK;
  }

  const replaceFlightNumberWithIcon = isInArray(
    flightNumber,
    Object.keys(CODES_TO_ICON_MAP)
  );

  if (!replaceFlightNumberWithIcon) {
    const isFlightBoxBigEnoughToShowFlightNumber =
      rangeInDaysInSingleViewPort < 4 &&
      width > MINIMUM_VIEW_WIDTH_FOR_FLIGHT_NUMBER_TO_BE_SHOWN;

    if (isFlightBoxBigEnoughToShowFlightNumber) {
      return FLIGHT_NUMBER_DISPLAY_ENUM.SHOW_FLIGHT_NUMBER;
    } else {
      return FLIGHT_NUMBER_DISPLAY_ENUM.DO_NOT_SHOW_FLIGHT_NUMBER;
    }
  }

  const showFlightIconAndDesc =
    width > MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_AND_ITS_DESC_TO_BE_SHOWN;

  if (showFlightIconAndDesc) {
    return FLIGHT_NUMBER_DISPLAY_ENUM.SHOW_FLIGHT_NUMBER_ICON_AND_DESC;
  }

  const showFlightIconOnly =
    width > MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_TO_BE_SHOWN;

  if (showFlightIconOnly) {
    return FLIGHT_NUMBER_DISPLAY_ENUM.SHOW_ICON;
  }
};

export const getColorOfFlight = flightData => {
  if (flightData.isRTG) {
    return RTG_FLIGHT;
  }

  if (flightData.flightType === "DHD") {
    return DEADHEAD_FLIGHT;
  }
};

export const calculateWidthAndLeftOfEntities = (
  allPairings,
  allDuties,
  allFlights,
  updatedTimeWindow,
  showGanttInUTC,
  userAttributes
) => {
  const widthAndLeftOfEntities = {
    pairingData: {},
    dutyData: {},
    flightData: {}
  };

  if (isNonEmptyObject(allPairings)) {
    Object.values(allPairings).forEach(pairing => {
      const pairingId = pairing["id"] || pairing["pairingId"];

      const {
        dutyIds,
        utcStartTime: utcStartTimePairing,
        utcEndTime: utcEndTimePairing,
        originTZ: pairingOriginTZ,
        destinationTZ: pairingDestinationTZ
      } = pairing;

      const {
        left: pairingLeft,
        width: pairingWidth,
        startTime: startTimePairingConverted,
        endTime: endTimePairingConverted
      } = getStartTimeEndTimeLeftAndWidthAttributes(
        utcStartTimePairing,
        utcEndTimePairing,
        updatedTimeWindow,
        showGanttInUTC,
        userAttributes,
        Number(
          new Date(
            updatedTimeWindow.earliestStartDate || updatedTimeWindow.startDate
          )
        )
      );

      const { typeOfPairing, allLayoverInfo } = getTypeOfPairingAndLayoverInfo(
        dutyIds,
        allDuties,
        userAttributes,
        updatedTimeWindow,
        utcStartTimePairing
      );

      if (isNonEmptyObject(dutyIds)) {
        dutyIds.forEach(dutyId => {
          const duty = allDuties[dutyId];

          const { flightIds, dutyCode } = duty;
          const { utcStartTime: utcStartTimeDuty, utcEndTime: utcEndTimeDuty } =
            getStartAndEndOfDuty(duty);

          const {
            startTime: startTimeDutyConverted,
            left: dutyLeft,
            width: dutyWidth
          } = getStartTimeEndTimeLeftAndWidthAttributes(
            utcStartTimeDuty,
            utcEndTimeDuty,
            updatedTimeWindow,
            showGanttInUTC,
            userAttributes,
            startTimePairingConverted
          );

          const requiredDataForDuty = getRequiredDataForDuty(
            utcStartTimeDuty,
            utcEndTimeDuty,
            dutyCode,
            flightIds,
            dutyWidth,
            showGanttInUTC,
            pairingOriginTZ,
            pairingDestinationTZ
          );

          const { allSitTimeInfo } = getTypeOfDutyAndSitTimeInfo(
            flightIds,
            allFlights,
            updatedTimeWindow,
            utcStartTimeDuty,
            userAttributes
          );

          if (isNonEmptyObject(flightIds)) {
            flightIds.forEach(flightId => {
              const flight = allFlights[flightId];

              const startTimeFlight = findUtcDepartureTimeOfFlight(flight),
                endTimeFlight = findUtcArrivalTimeOfFlight(flight);

              const { left: flightLeft, width: flightWidth } =
                getStartTimeEndTimeLeftAndWidthAttributes(
                  startTimeFlight,
                  endTimeFlight,
                  updatedTimeWindow,
                  showGanttInUTC,
                  userAttributes,
                  Number(new Date(startTimeDutyConverted))
                );

              const {
                isPPP,
                isRTG,
                depHoursFlight,
                depMinutesFlight,
                arrHoursFlight,
                arrMinutesFlight,
                shouldShowFlightDetails,
                flightNumberDisplayMetadata,
                applyTimelineWindowTwoDaysClass,
                color
              } = getAllRequiredDataForFlight(
                {
                  ...flight,
                  width: flightWidth,
                  coalescedStart: startTimeFlight,
                  coalescedEnd: endTimeFlight
                },
                showGanttInUTC,
                updatedTimeWindow
              );

              if (!widthAndLeftOfEntities.flightData[flightId]) {
                widthAndLeftOfEntities.flightData[flightId] = {};
                widthAndLeftOfEntities.flightData[flightId].leftAndWidths = {};
              }

              widthAndLeftOfEntities.flightData[flightId] = {
                ...flight,
                isPPP,
                isRTG,
                depHours: depHoursFlight,
                depMinutes: depMinutesFlight,
                arrHours: arrHoursFlight,
                arrMinutes: arrMinutesFlight,
                shouldShowFlightDetails,
                flightNumberDisplayMetadata,
                applyTimelineWindowTwoDaysClass,
                color,
                leftAndWidths: {
                  ...widthAndLeftOfEntities.flightData[flightId].leftAndWidths,
                  [dutyId]: {
                    left: flightLeft,
                    width: flightWidth
                  }
                },
                id: flightId
              };
            });
          }

          widthAndLeftOfEntities.dutyData[dutyId] = {
            ...duty,
            left: dutyLeft,
            width: dutyWidth,
            allSitTimeInfo,
            id: dutyId,
            ...requiredDataForDuty
          };
        });
      }

      widthAndLeftOfEntities.pairingData[pairingId] = {
        ...pairing,
        left: pairingLeft,
        width: pairingWidth,
        typeOfPairing,
        allLayoverInfo,
        startTimePairingConverted,
        endTimePairingConverted,
        id: pairingId
      };
    });
  }
  return widthAndLeftOfEntities;
};

const getRequiredDataForDuty = (
  utcStartTime,
  utcEndTime,
  dutyCode,
  flightIds,
  width,
  isGanttShownInUTC,
  pairingOriginTZ,
  pairingDestinationTZ
) => {
  const toReturn = {};

  toReturn.shouldShowTimeInfoOnDuty = shouldShowTimeInfoOnDutyBlock(
    dutyCode,
    flightIds,
    width
  );

  if (toReturn.shouldShowTimeInfoOnDuty) {
    let dutyDepartureTime = null,
      dutyArrivalTime = null;

    if (isGanttShownInUTC) {
      dutyDepartureTime = new Date(utcStartTime);
      dutyArrivalTime = new Date(utcEndTime);
    } else {
      dutyDepartureTime = getAirportLocalTime(
        new Date(utcStartTime),
        pairingOriginTZ
      );
      dutyArrivalTime = getAirportLocalTime(
        new Date(utcEndTime),
        pairingDestinationTZ
      );
    }

    toReturn.depHoursDuty = getTwoDigitStringFromNumber(
      dutyDepartureTime.getUTCHours()
    );
    toReturn.depMinutesDuty = getTwoDigitStringFromNumber(
      dutyDepartureTime.getUTCMinutes()
    );
    toReturn.arrHoursDuty = getTwoDigitStringFromNumber(
      dutyArrivalTime.getUTCHours()
    );
    toReturn.arrMinutesDuty = getTwoDigitStringFromNumber(
      dutyArrivalTime.getUTCMinutes()
    );
  }

  return toReturn;
};
