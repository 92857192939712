import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import PublicRoute from "./PublicRoute";
import * as ROUTES from "../../constants/routes";

const PrivateRoute = props => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const location = useLocation();

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.LANDING,
          state: { from: location }
        }}
      />
    );
  }

  return <PublicRoute {...props} />;
};

export default PrivateRoute;
