import { TextField } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFilterAction } from "../../../redux/searchCriteriaReducer";

import "./TextFieldFilter.scss";

export default function TextFieldFilter({
  filterName,
  filterKey,
  filterLabel,
  className
}) {
  const dispatch = useDispatch();

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const selectedFilter = useSelector(
    store => store.searchCriteria.selectedFilter
  );

  const selectedFilterForSolverOutput = useSelector(
    store => store.searchCriteria.selectedFilterForSolverOutput
  );

  const handleOnInputForTextFieldSearch = React.useCallback(
    event => {
      dispatch(
        setSelectedFilterAction({
          isCurrentSchedule: isShowingASolverRun ? false : true,
          filterName: filterKey,
          selectedFilter: event.target.value
        })
      );
    },
    [dispatch, isShowingASolverRun, filterKey]
  );

  return (
    <div className="textfield-filter-container">
      <TextField
        className={className}
        label={filterLabel}
        onInput={handleOnInputForTextFieldSearch}
        value={
          isShowingASolverRun
            ? selectedFilterForSolverOutput[filterKey]
            : selectedFilter[filterKey]
        }
        InputLabelProps={{
          shrink:
            (selectedFilter[filterKey] &&
              selectedFilter[filterKey].length > 0) ||
            (selectedFilterForSolverOutput[filterKey] &&
              selectedFilterForSolverOutput[filterKey].length > 0)
              ? true
              : false
        }}
      />
    </div>
  );
}
