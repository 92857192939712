/**
 * returns an array that contains elements from sourceArray and elementsToBeAdded(only those that are not already present in sourceArray)
 * @param {Array} sourceArray | base array
 * @param {Array} elementsToBeAdded | array whose element has to be added to base
 */
export function pushElementIfNotPresent(sourceArray, elementsToBeAdded) {
  const returnArray = [];

  if (sourceArray) returnArray.push(...sourceArray);

  elementsToBeAdded.map(
    element =>
      returnArray.indexOf(element) === -1 && returnArray.push(Number(element))
  );

  return returnArray;
}

/**
 * returns an array that is sorted in ascending order numerically
 * @param {Array} arrayToBeSorted
 */
export function sortArrayNumerically(arrayToBeSorted) {
  return arrayToBeSorted.sort((a, b) => a - b);
}

/**
 * returns true if given input is an Array and also if it is non-empty
 */
export function isNonEmptyArray(input) {
  return Array.isArray(input) && input.length > 0;
}

/**
 * returns true if given input is an Array and also if it is empty
 */
export function isEmptyArray(input) {
  return Array.isArray(input) && input.length === 0;
}

/**
 * returns true if value is present in the array
 */
export function isInArray(value, array) {
  return array.indexOf(value) > -1;
}

/**
 * returns the array which is rotated count number of times
 * @param {Array} array
 * @param {Boolean} reverse
 * @param {Number} count
 */
export function arrayRotate(array, reverse, count) {
  while (count > 0) {
    if (reverse) array.unshift(array.pop());
    else array.push(array.shift());
    count--;
  }
  return array;
}

export const getSpreadSafeArray = arrayToBeSpread =>
  isNonEmptyArray(arrayToBeSpread) ? arrayToBeSpread : [];
