import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconButton } from "@material-ui/core";
import { ShortText } from "@material-ui/icons";

import { setIsViolationDrilledDownDrawerVisibleAction } from "../../../redux/showAndHideReducer";
import {
  setReduxKeysForViolatingData,
  setSelectedViolationAction
} from "../../../redux/violationDrillDownReducer";

import { isNonEmptyArray } from "../../../utils/arrayUtils";

import { getAllViolationMessages } from "../violation-drill-down-section/utils";

export default function ViolationCommentMarker({
  parentViolatingData,
  currentViolatingData,
  className
}) {
  const dispatch = useDispatch();

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const handleClickOnViolationMessageMarker = React.useCallback(
    (event, violatingData) => {
      event.stopPropagation();

      const violationMessages = getAllViolationMessages(violatingData);

      dispatch(
        setReduxKeysForViolatingData(
          violationMessages.map(violation => violation.reduxKey)
        )
      );

      /**
       * Since there is no intermediate screen for user to select the violation
       * setting the first element as the selected violation
       */
      dispatch(
        setSelectedViolationAction({
          violationType: violationMessages[0].violationType,
          violationId: violationMessages[0].violationId
        })
      );
      dispatch(setIsViolationDrilledDownDrawerVisibleAction(true));
    },
    [dispatch]
  );

  /**
   * do not show markers in solver output screen
   */
  return (
    !isShowingASolverRun && (
      <div className={className ? className : "violation-comment-marker"}>
        <div className="current-marker">
          {isNonEmptyArray(currentViolatingData) ? (
            <IconButton
              onClick={event =>
                handleClickOnViolationMessageMarker(event, currentViolatingData)
              }
            >
              <ShortText />
            </IconButton>
          ) : null}
        </div>
        <div className="parent-marker">
          {isNonEmptyArray(parentViolatingData) ? (
            <IconButton
              onClick={event =>
                handleClickOnViolationMessageMarker(event, parentViolatingData)
              }
            >
              <ShortText />
            </IconButton>
          ) : null}
        </div>
      </div>
    )
  );
}
