import {
  Button,
  ClickAwayListener,
  MenuItem,
  Select,
  Switch,
  Tooltip
} from "@material-ui/core";
import { Close, InfoOutlined } from "@material-ui/icons";
import Axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BASE_ENDPOINT,
  DATA_RETURNED_ON_SUCCESSFUL_UPDATION_OF_CUSTOM_COGNITO_ATTRIBUTE,
  GET_TIMEZONES
} from "../../../../constants/api";
import {
  LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
  LOCAL_TIME_ZONE_OFFSET_IN_MINUTE_KEY,
  TOGGLER_HELPER_TEXT
} from "../../../../constants/disruptions/disruptionSummary";
import { setIsUserProfileDrawerVisibleAction } from "../../../../redux/showAndHideReducer";
import { setUserAttributesAction } from "../../../../redux/userInfoReducer";
import {
  getAccessTokenForUser,
  getCurrentAuthenticatedUser,
  getCurrentUserInfo,
  updateUserInfo
} from "../../../../utils/authUtils";
import { logErrorMessageForMonitoring } from "../../../../utils/errorUtils";
import Arrow from "../../../partials/arrow/Arrow";
import LoadingState from "../../../partials/LoadingState/LoadingState";
import useStatus from "../../../customHooks/useStatus";
import { updateGanttOnUserAction } from "../../../../redux/webSocketReducer";

const USER_PROFILE_STATES = {
  LOADING: "LOADING",
  UPDATING: "UPDATING",
  READY: "READY"
};

const MESSAGES = {
  TIME_ZONE_UPDATE_ERROR:
    "Error occurred while updating the time zone. Please try again.",
  UPDATING_GANTT: "Updating Gantt...",
  UPDATING_TIME_ZONE: "Updating local time zone...",
  LOADING: "Loading...",
  EMPTY_STRING: "",
  SHOW_WARNINGS_UPDATE_ERROR:
    "Error occurred while updating the show/hide warnings choice. Please try again."
};

export default function UserProfile() {
  /**
   * redux dispatcher
   */
  const dispatch = useDispatch();

  /**
   * determines which component to be loaded
   */
  const { Status, setStatus } = useStatus(USER_PROFILE_STATES.LOADING);

  /**
   * redux subscriptions
   */
  const showGanttInUTC = useSelector(store => store.userInfo.showGanttInUTC);
  const userAttributes = useSelector(store => store.userInfo.userAttributes);

  /**
   * component states
   */
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [updatingMessage, setUpdatingMessage] = React.useState(
    MESSAGES.EMPTY_STRING
  );
  const [didErrorOccur, setDidErrorOccur] = React.useState({
    error: false,
    errorMessage: MESSAGES.EMPTY_STRING
  });
  const [allTimeZoneData, setAllTimeZoneData] = React.useState([]);

  /**
   * side effects
   */
  React.useEffect(() => {
    const getAllTimeZones = async () => {
      const token = await getAccessTokenForUser();
      const url = BASE_ENDPOINT + GET_TIMEZONES;
      const headers = {
        Authorization: token
      };
      const response = await Axios.get(url, {
        headers: headers
      });

      setAllTimeZoneData(response.data);
      setIsLoading(false);
    };

    setIsLoading(true);
    getAllTimeZones();
  }, []);

  React.useEffect(() => {
    !isLoading && !isUpdating
      ? setStatus(USER_PROFILE_STATES.READY)
      : isLoading
      ? setStatus(USER_PROFILE_STATES.LOADING)
      : setStatus(USER_PROFILE_STATES.UPDATING);
  }, [isLoading, isUpdating, setStatus]);

  /**
   * react callbacks
   */
  const updateTimeZoneInfoInCognito = React.useCallback(
    newData => {
      getCurrentAuthenticatedUser().then(user =>
        updateUserInfo(
          user,
          LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
          JSON.stringify(newData)
        )
          .then(updateResult => {
            if (
              updateResult ===
              DATA_RETURNED_ON_SUCCESSFUL_UPDATION_OF_CUSTOM_COGNITO_ATTRIBUTE
            ) {
              getCurrentUserInfo()
                .then(data => {
                  /**
                   * converting the timezone info stored in cognito as String to Object
                   */
                  data.attributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY] = JSON.parse(
                    data.attributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY]
                  );
                  dispatch(setUserAttributesAction(data.attributes));
                  dispatch(
                    updateGanttOnUserAction({
                      changedSchedule: undefined,
                      doNotRefreshScreen: true
                    })
                  );
                  setDidErrorOccur({
                    error: false,
                    errorMessage: MESSAGES.EMPTY_STRING
                  });
                })
                .catch(error => {
                  logErrorMessageForMonitoring(JSON.stringify(error));
                  setDidErrorOccur({
                    error: true,
                    errorMessage: MESSAGES.TIME_ZONE_UPDATE_ERROR
                  });
                })
                .finally(() => {
                  setIsUpdating(false);
                  setUpdatingMessage(MESSAGES.EMPTY_STRING);
                });
            }
          })
          .catch(error => {
            setIsUpdating(false);
            setUpdatingMessage(MESSAGES.EMPTY_STRING);
            logErrorMessageForMonitoring(JSON.stringify(error));
            setDidErrorOccur({
              error: true,
              errorMessage: MESSAGES.TIME_ZONE_UPDATE_ERROR
            });
          })
      );
    },
    [dispatch]
  );

  const handleChangeOfTimeZoneSwitch = React.useCallback(
    event => {
      setIsUpdating(true);
      setUpdatingMessage(MESSAGES.UPDATING_GANTT);
      const newData = {
        ...userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY],
        showGanttInUTC: event.target.checked
      };
      updateTimeZoneInfoInCognito(newData);
    },
    [updateTimeZoneInfoInCognito, userAttributes]
  );

  const handleChangeOnTimezoneSelect = React.useCallback(
    event => {
      setIsUpdating(true);
      setUpdatingMessage(MESSAGES.UPDATING_TIME_ZONE);
      const newData = {
        ...event.target.value,
        showGanttInUTC: showGanttInUTC
      };
      updateTimeZoneInfoInCognito(newData);
    },
    [showGanttInUTC, updateTimeZoneInfoInCognito]
  );

  const handleClickOnCloseButtonAndClickAway = React.useCallback(
    () => dispatch(setIsUserProfileDrawerVisibleAction(false)),
    [dispatch]
  );

  const Header = React.useCallback(
    () => (
      <div className="header">
        <div className="header-text">User Profile</div>
        <div className="close">
          <Button onClick={handleClickOnCloseButtonAndClickAway}>
            <Close color="primary" fontSize={"small"} />
          </Button>
        </div>
      </div>
    ),
    [handleClickOnCloseButtonAndClickAway]
  );

  const createInfoTooltip = React.useCallback(
    topic => (
      <div className="local-time-helper-text">{TOGGLER_HELPER_TEXT[topic]}</div>
    ),
    []
  );

  const InfoIcon = React.useCallback(
    ({ topic }) => (
      <Tooltip title={createInfoTooltip(topic)}>
        <span className="info-icon">
          <InfoOutlined fontSize={"small"} />
        </span>
      </Tooltip>
    ),
    [createInfoTooltip]
  );

  const Body = React.useCallback(
    () => (
      <div className="user-profile-body">
        <div className="user-profile-options">
          <div>
            <div className="user-profile-option-heading">Time Zone</div>
            <div className="timezone-toggle-section user-profile-sub-options">
              <div className="timezone-toggle-info-text">
                Show Gantt in UTC
                <InfoIcon topic={"ganttInUTC"} />
              </div>
              <div className="timezone-switch">
                <Switch
                  checked={showGanttInUTC}
                  onChange={handleChangeOfTimeZoneSwitch}
                  color="secondary"
                />
              </div>
            </div>
            <div className="timezone-select-section user-profile-sub-options">
              <span className="local-timezone-select-info-text">
                Local time zone
              </span>
              <Select
                id="all-timezone-select"
                MenuProps={{ disablePortal: true }}
                renderValue={timezone =>
                  `UTC(${timezone.offsetUTC}) ${timezone.zone}`
                }
                onChange={handleChangeOnTimezoneSelect}
                value={userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY]}
                IconComponent={props => <Arrow iconClass={props} />}
                disableUnderline={true}
              >
                {allTimeZoneData
                  .sort(
                    (a, b) =>
                      a[LOCAL_TIME_ZONE_OFFSET_IN_MINUTE_KEY] -
                      b[LOCAL_TIME_ZONE_OFFSET_IN_MINUTE_KEY]
                  )
                  .map((timezone, index) => (
                    <MenuItem
                      value={timezone}
                      key={index}
                    >{`UTC(${timezone.offsetUTC}) ${timezone.zone}`}</MenuItem>
                  ))}
              </Select>
            </div>
          </div>
        </div>
        <>
          {didErrorOccur && (
            <div className="error-message">{didErrorOccur.errorMessage}</div>
          )}
        </>
      </div>
    ),
    [
      allTimeZoneData,
      didErrorOccur,
      handleChangeOfTimeZoneSwitch,
      handleChangeOnTimezoneSelect,
      showGanttInUTC,
      userAttributes
    ]
  );

  const Loading = React.useCallback(
    ({ loadingMessage }) => (
      <div className="loader">
        <LoadingState height={"80vh"} loadingMessage={loadingMessage} />
      </div>
    ),
    []
  );

  return (
    <div>
      <ClickAwayListener onClickAway={handleClickOnCloseButtonAndClickAway}>
        <div className="user-profile-container">
          <>
            <Header />
          </>
          <>
            <Status
              {...{
                [USER_PROFILE_STATES.LOADING]: (
                  <Loading loadingMessage={MESSAGES.LOADING} />
                )
              }}
              {...{
                [USER_PROFILE_STATES.UPDATING]: (
                  <Loading loadingMessage={updatingMessage} />
                )
              }}
              {...{
                [USER_PROFILE_STATES.READY]: <Body />
              }}
            />
          </>
        </div>
      </ClickAwayListener>
    </div>
  );
}
