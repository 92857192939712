import React from "react";
import { useSelector } from "react-redux";

import CrewRow from "../CrewRow";

import {
  OPEN_PAIRING,
  OPEN_SEGMENT
} from "../../../../../constants/disruptions/openTime";

export default function OpenTimeCrew() {
  const openTimeIds = useSelector(store => store.crewSchedules.openTimeIds);

  return (
    <div className="open-flight-and-pairing-container">
      <div className="open-flight-container">
        {openTimeIds.openFlightIds &&
          openTimeIds.openFlightIds.length > 0 &&
          openTimeIds.openFlightIds[0].length !== 0 &&
          openTimeIds.openFlightIds.map((openFlightId, index) => (
            <CrewRow
              key={openFlightId}
              isOpen={true}
              openFlyingRowNumber={index}
              type={OPEN_SEGMENT}
            />
          ))}
      </div>

      <div className="open-pairing-container">
        {openTimeIds.openPairingIds &&
          openTimeIds.openPairingIds.length > 0 &&
          openTimeIds.openPairingIds[0].length !== 0 &&
          openTimeIds.openPairingIds.map((openPairingId, index) => (
            <CrewRow
              key={openPairingId}
              isOpen={true}
              openFlyingRowNumber={index}
              type={OPEN_PAIRING}
            />
          ))}
      </div>
    </div>
  );
}
