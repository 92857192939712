export const WAIT_TYPE_ENTITY_KEY_MAP = {
  SIT: "flights",
  LAYOVER: "duties",
  REST: "pairings"
};

export const WAIT_TYPE_ENTITY_START_END_KEY_MAP = {
  SIT: {
    BEFORE: "utcEffectiveArrDateTime",
    AFTER: "utcEffectiveDepDateTime"
  },
  LAYOVER: {
    BEFORE: "utcEndTime",
    AFTER: "utcStartTime"
  },
  REST: {
    BEFORE: "utcEndTime",
    AFTER: "utcStartTime"
  }
};

export const WAIT_TYPE_ENTITY_MAP = {
  SIT: "FLIGHT",
  LAYOVER: "DUTY",
  REST: "PAIRING"
};

export const WAIT_TIME_TYPES = {
  SIT: "SIT",
  LAYOVER: "LAYOVER",
  REST: "REST"
};

export const MINIMUM_VIEW_WIDTH_FOR_TEXT_TO_BE_SHOWN = 1.83;
export const MINIMUM_VIEW_WIDTH_FOR_ICON_TO_BE_SHOWN = 1.3;
export const MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN = 3.4;
export const MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN_WHEN_RTG = 4.2;
export const MINIMUM_VIEW_WIDTH_FOR_FLIGHT_NUMBER_TO_BE_SHOWN = 2;
export const WIDTH_FOR_PPP_FLIGHT = 2.01;
export const MINIMUM_WIDTH_FOR_RTG_FLIGHT = 0.6;
export const MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_TO_BE_SHOWN = 1.4;
export const MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_AND_ITS_DESC_TO_BE_SHOWN = 3;
export const MINIMUM_VIEW_WIDTH_FOR_FULL_DUTY_CODE_TO_BE_SHOWN = 3;
export const MINIMUM_VIEW_WIDTH_FOR_ICON_AND_ITS_DESC_TO_BE_SHOWN = 2.5;
export const MINIMUM_VIEW_WIDTH_FOR_TIME_INFO_ON_RESERVE_BLOCK_TO_BE_SHOWN = 3.5;
