import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import DisruptionSummary from "../HomePage/DisruptionsSummary/DisruptionSummary";
import AllDisruptions from "../HomePage/AllDisruptions/AllDisruptions";
import {
  setCrewSchedulesAction,
  setIsCrewSchedulesNotDefinedAction
} from "../../redux/crewSchedulesReducer";
import { getTimeWindowFromAllPairings } from "../../utils/timelineUtils";
import { setTimeWindowFromAllPairings } from "../../redux/searchCriteriaReducer";
import { convertSolverFileContentToSchedulesJSON } from "../../utils/ganttViewerUtil";

const GanttViewer = () => {
  /**
   * ================ LOADING INITIAL SCHEDULE DATA =============
   */
  const dispatch = useDispatch();
  React.useEffect(() => {
    process.env.REACT_APP_FILENAME &&
      axios.get(process.env.REACT_APP_FILENAME).then(response => {
        let fileContent = response.data;

        const scheduleData = convertSolverFileContentToSchedulesJSON(
          fileContent
        );
        dispatch(setCrewSchedulesAction(scheduleData));
        dispatch(setIsCrewSchedulesNotDefinedAction(false));
        let timeWindowFromAllPairings = getTimeWindowFromAllPairings(
          scheduleData.pairings
        );

        /**
         * if in case, start of earliest pairing and end of latest pairing
         * don't span across 24 hours, we'll get 0 days as the total window.
         * So, making it 1 day to show timeline at least as big as 1 day in any case.
         * else, add one more day to the total window to account the hours of the end date as well
         *  */

        if (timeWindowFromAllPairings.totalWindowInDays === 0) {
          timeWindowFromAllPairings.totalWindowInDays = 1;
        } else {
          timeWindowFromAllPairings.totalWindowInDays += 1;
        }
        dispatch(setTimeWindowFromAllPairings(timeWindowFromAllPairings));
      });
  }, [dispatch]);
  //================ END: LOADING INITIAL SCHEDULE DATA =============

  return (
    <>
      {process.env.REACT_APP_FILENAME ? (
        <div className="gantt-viewer-main">
          {/**
          This component is divided into 2 part:
          1. DisruptionSummary - This section stays fixed at the top and has all the disruption stats and filtering options
          2. AllDisruptions - This component is divided into 2 part:
          - a. The timeline section which stays fixed at the top
          - b. The CrewGanttContainer section which contains the gantt charts and crew details
          */}
          <DisruptionSummary />
          <AllDisruptions />
        </div>
      ) : (
        <div>No input json provided</div>
      )}
    </>
  );
};

export default GanttViewer;
