import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import { Close, InfoRounded as InfoIcon } from "@material-ui/icons";

import { useDispatch, useSelector } from "react-redux";
import { TO_DATE_OFFSET_FOR_DATE_RANGE_FILTER } from "../../../../constants/disruptions/filter";
import { MILLISECONDS_IN_A_DAY } from "../../../../constants/disruptions/timeline";
import {
  applyFilterForSolverOutputAction,
  resetCurrentSolverScenarioScheduleAction
} from "../../../../redux/crewSchedulesReducer";
import {
  resetSelectedFilterAction,
  resetSelectedFilterForSolverAction
} from "../../../../redux/searchCriteriaReducer";
import {
  setIsDateTimeDialogVisibleAction,
  setIsErrorModalVisibleAction
} from "../../../../redux/showAndHideReducer";
import { applyFilterAction } from "../../../../redux/webSocketReducer";
import { processViolationPreferencesToBeApplied } from "../../../../utils/middlewareUtils";

export default function FilterClearApply() {
  const dispatch = useDispatch();

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const selectedFilterForSolverOutput = useSelector(
    store => store.searchCriteria.selectedFilterForSolverOutput
  );

  const selectedFilter = useSelector(
    store => store.searchCriteria.selectedFilter
  );

  const violationMetadata = useSelector(
    store => store.crewSchedules.violationMetadata
  );

  const handleClickOnClearButton = React.useCallback(() => {
    dispatch(setIsDateTimeDialogVisibleAction(false));
    if (navigator.onLine) {
      if (!isShowingASolverRun) {
        /**
         * setting from date to current date
         */
        let fromDate = new Date()
          .toISOString()
          .substring(0, 10)
          .concat("T00:00");
        /**
         * setting to date to the required offset
         */
        let toDate = new Date(
          Number(new Date(fromDate)) +
            MILLISECONDS_IN_A_DAY * TO_DATE_OFFSET_FOR_DATE_RANGE_FILTER
        );

        dispatch(
          resetSelectedFilterAction({ fromDate: fromDate, toDate: toDate })
        );
        dispatch(applyFilterAction());
      } else {
        dispatch(resetSelectedFilterForSolverAction());
        dispatch(resetCurrentSolverScenarioScheduleAction());
      }
    } else {
      //display an error popup saying no internet
      dispatch(
        setIsErrorModalVisibleAction({
          isVisible: true,
          message:
            "Could not clear filters, please check your internet and try again"
        })
      );
    }
  }, [dispatch, isShowingASolverRun]);

  const isToDateLessThanFromDate = React.useCallback(
    (from, to) => new Date(to) < new Date(from),
    []
  );

  const handleClickOnApplyButton = React.useCallback(() => {
    dispatch(setIsDateTimeDialogVisibleAction(false));

    if (
      (isShowingASolverRun &&
        isToDateLessThanFromDate(
          selectedFilterForSolverOutput.date.from,
          selectedFilterForSolverOutput.date.to
        )) ||
      (!isShowingASolverRun &&
        isToDateLessThanFromDate(
          selectedFilter.date.from,
          selectedFilter.date.to
        ))
    ) {
      //display an error popup asking to chose a to-date less than from-date
      dispatch(
        setIsErrorModalVisibleAction({
          isVisible: true,
          message: "You chose a to-date less than from-date..."
        })
      );
      return;
    }

    if (navigator.onLine) {
      if (!isShowingASolverRun) {
        dispatch(applyFilterAction());
      } else {
        dispatch(
          applyFilterForSolverOutputAction(selectedFilterForSolverOutput)
        );
      }
    } else {
      //display an error popup saying no internet
      dispatch(
        setIsErrorModalVisibleAction({
          isVisible: true,
          message:
            "Could not apply the filter, please check your internet and try again"
        })
      );
    }
  }, [
    dispatch,
    selectedFilterForSolverOutput,
    isShowingASolverRun,
    selectedFilter,
    isToDateLessThanFromDate
  ]);

  const getApplyTooltipText = React.useCallback(() => {
    const violationPreferenceChanges =
      processViolationPreferencesToBeApplied(violationMetadata);
    let textToBeReturned = "";

    const muteCount =
      violationPreferenceChanges.entity.muted.length +
      violationPreferenceChanges.time.muted.length;
    const unMuteCount =
      violationPreferenceChanges.entity.unmuted.length +
      violationPreferenceChanges.time.unmuted.length;

    if (muteCount > 0 && unMuteCount === 0) {
      textToBeReturned = `${muteCount} violation${
        muteCount > 1 ? "s" : ""
      } muted.`;
    } else if (unMuteCount > 0 && muteCount === 0) {
      textToBeReturned = `${unMuteCount} violation${
        muteCount > 1 ? "s" : ""
      } un-muted.`;
    } else if (unMuteCount > 0 && muteCount > 0) {
      textToBeReturned = `${muteCount} violation${
        muteCount > 1 ? "s" : ""
      } muted, ${unMuteCount} violation${muteCount > 1 ? "s" : ""} un-muted.`;
    }
    return textToBeReturned;
  }, [violationMetadata]);

  const outputWithTooltip = (
    <>
      <div className={`clear-apply-button apply-with-border`}>
        <Tooltip
          title={
            <React.Fragment>
              <b className="tooltip-text">{getApplyTooltipText()}</b>
            </React.Fragment>
          }
          placement="bottom"
        >
          <Button
            key={"apply-button"}
            variant={"outlined"}
            onClick={handleClickOnApplyButton}
          >
            <div className="apply-button-container">
              <span className="clear-text">Apply</span>

              <span className="apply-icon">
                <InfoIcon fontSize="small" />
              </span>
            </div>
          </Button>
        </Tooltip>
      </div>
      <div className="clear-apply-button">
        <Button key={"button"} onClick={handleClickOnClearButton}>
          <span className="clear-text">Clear</span>
          <Close fontSize="small" />
        </Button>
      </div>
    </>
  );

  const outputWithoutTooltip = (
    <>
      <div className="clear-apply-button">
        <Button key={"apply-button"} onClick={handleClickOnApplyButton}>
          <div className="apply-button-container">
            <span className="clear-text">Apply</span>
          </div>
        </Button>
      </div>
      <div className="clear-apply-button">
        <Button key={"button"} onClick={handleClickOnClearButton}>
          <span className="clear-text">Clear</span>
          <Close fontSize="small" />
        </Button>
      </div>
    </>
  );

  return (
    <div className="clear-apply-button-container">
      {getApplyTooltipText() !== "" ? outputWithTooltip : outputWithoutTooltip}
    </div>
  );
}
