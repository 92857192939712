import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem
} from "@material-ui/core";

import DeadheadReportRedirect from "./deadhead/DeadheadReportRedirect";
import CumulativeTimeReportRedirect from './cumulative-time/CumulativeTimeReportRedirect'

export default function ReportsRedirect() {
  return (
    <Accordion expanded={true}>
      <AccordionSummary>Reports</AccordionSummary>
      <AccordionDetails className="reports-dropdown-main">
        <MenuItem>
          <DeadheadReportRedirect />
        </MenuItem>
        <MenuItem>
          <CumulativeTimeReportRedirect />
        </MenuItem>
      </AccordionDetails>
    </Accordion>
  )
}
