import { DST_CHANGE_TYPES } from "../constants/daylightSavings";
import { getTimeAfterApplyingOffsetsBasedOnDstInfo } from "./pairingUtils";
import {
  getleftMarginForEntity,
  getNumberOfHoursBetweenDates,
  getNumberOfMinutesBetweenDates,
  getTwoDigitStringFromNumber,
  getWidthForEntity
} from "./timelineUtils";

export const getAllRestTimeProperties = (
  restTimeStart,
  restTimeEnd,
  utcStartTimeForPairing,
  violation,
  dstRelatedInfo,
  dateRange,
  violationMetadata
) => {
  return {
    width: getWidthForEntity(
      getTimeAfterApplyingOffsetsBasedOnDstInfo(
        restTimeEnd,
        dstRelatedInfo,
        DST_CHANGE_TYPES.DST_CHANGE_POSITIVE
      ),
      Number(restTimeStart),
      dateRange.widthOfOneDay
    ),
    leftPosition: getleftMarginForEntity(
      getTimeAfterApplyingOffsetsBasedOnDstInfo(
        restTimeStart,
        dstRelatedInfo,
        DST_CHANGE_TYPES.DST_CHANGE_NEGATIVE
      ),
      dateRange.widthOfOneDay,
      new Date(utcStartTimeForPairing)
    ),
    hours: getTwoDigitStringFromNumber(
      getNumberOfHoursBetweenDates(restTimeStart, restTimeEnd)
    ),
    minutes: getTwoDigitStringFromNumber(
      getNumberOfMinutesBetweenDates(restTimeStart, restTimeEnd)
    ),
    startTime: restTimeStart,
    violations:
      violationMetadata.timeViolation[violation.ruleIdentity.violationId]
        .reduxKey,
    color: violation.color
  };
};

export const aggregateViolationMetadata = (aggregator, current) => {
  if (aggregator.warningColor === null)
    aggregator.warningColor = current.warningColor;
  if (aggregator.violationColor === null)
    aggregator.violationColor = current.violationColor;
  aggregator.reduxKeys = [...aggregator.reduxKeys, ...current.reduxKeys];
  aggregator.warningCount = aggregator.warningCount + current.warningCount;
  aggregator.violationCount =
    aggregator.violationCount + current.violationCount;
};

export const getDiagnosticLevelAndColor = (
  violationCount,
  warningCount,
  violationColor,
  warningColor
) => {
  if (violationCount > 0) {
    return [false, violationColor];
  }

  if (warningCount > 0) {
    return [true, warningColor];
  }

  return [false, null];
};
