import React from "react";

import "./SummaryInfo.scss";
import { MenuItem, Select, Switch } from "@material-ui/core";
import Arrow from "../../../partials/arrow/Arrow";
import { batch, useDispatch, useSelector } from "react-redux";

import {
  resetSelectedFilterForSolverAction,
  restoreDateRangeOnReturnToCurrentScheduleAction,
  setIsShowingASolverRunAction,
  setShowSolverInputRowsAction
} from "../../../../redux/searchCriteriaReducer";
import {
  setIsLoadingSolverOutputDataAction,
  setSelectedScenarioAction,
  setSelectedScenarioSolverOutputAction
} from "../../../../redux/solverDataReducer";
import { isInArray } from "../../../../utils/arrayUtils";
import { makeSolverOutputAsCrewScheduleAndCopyOriginalSchedule } from "../../../../utils/solverUtil";
import { solverOutputRequestAction } from "../../../../redux/webSocketReducer";
import { DEFAULT_SCENARIO_ID } from "../../../../constants/disruptions/filter";
import {
  setCopyOfScheduleWhileShowingSolverRunAction,
  setCrewSchedulesAction,
  setOpenTimeAction,
  setProcessedViolationsAction
} from "../../../../redux/crewSchedulesReducer";
import { setIsErrorModalVisibleAction } from "../../../../redux/showAndHideReducer";

import { isScenarioInProgress } from "../../../../utils/summaryInfoUtils";
import {
  ReportProblemRounded,
  FiberManualRecord as SolverScenarioInProgressIndicator
} from "@material-ui/icons";
import { SOLVER_SCENARIO_IN_PROGRESS_FILL_COLOR } from "../../../../constants/disruptions/color";
import { SOLVER_SCENARIO } from "../../../../constants/routes";

export default function SummaryInfo() {
  const dispatch = useDispatch();

  const showSolverInputRows = useSelector(
    store => store.searchCriteria.showSolverInputRows
  );

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const handleChangeForShowingSolverInputs = React.useCallback(() => {
    dispatch(setShowSolverInputRowsAction(!showSolverInputRows));
  }, [dispatch, showSolverInputRows]);

  const selectedScenario = useSelector(
    store => store.solverData.selectedScenario
  );

  const cachedSolverOutput = useSelector(
    store => store.solverData.cachedSolverOutput
  );

  const crewSchedules = useSelector(store => store.crewSchedules);

  const dateRangeSelection = useSelector(
    store => store.searchCriteria.dateRange
  );

  const isShareScreen = window.location.href.includes(SOLVER_SCENARIO);

  const userAttributes = useSelector(store => store.userInfo.userAttributes);

  const handleScenarioSelection = React.useCallback(
    event => {
      if (selectedScenario.id === event.target.value.id) {
        return;
      }

      if (event.target.value.percentageCompleted === 0) {
        dispatch(
          setIsErrorModalVisibleAction({
            isVisible: true,
            message:
              "No solution has been generated for the current scenario yet. We will notify you when a solution is generated"
          })
        );
        return;
      }
      if (navigator.onLine) {
        dispatch(setSelectedScenarioAction(event.target.value));

        //check if the current scenario output is cached, if no then fetch it from backend
        if (
          isInArray(
            event.target.value.id.toString(),
            Object.keys(cachedSolverOutput)
          )
        ) {
          dispatch(setIsLoadingSolverOutputDataAction(true));
          batch(() => {
            dispatch(resetSelectedFilterForSolverAction()); //remove existing locally applied scenario filter
            dispatch(setIsShowingASolverRunAction(true));
            makeSolverOutputAsCrewScheduleAndCopyOriginalSchedule(
              crewSchedules,
              dateRangeSelection,
              cachedSolverOutput[event.target.value.id].solution,
              false,
              dispatch,
              { setIsLoadingSolverOutputData: false },
              userAttributes
            );
            dispatch(
              setSelectedScenarioSolverOutputAction({
                ...cachedSolverOutput[event.target.value.id]
              })
            );
          });

          setTimeout(() => dispatch(setIsLoadingSolverOutputDataAction(false)));
        } else {
          dispatch(solverOutputRequestAction(event.target.value));
        }

        if (
          event.target.value.id &&
          event.target.value.id === DEFAULT_SCENARIO_ID
        ) {
          dispatch(setIsLoadingSolverOutputDataAction(true));
          batch(() => {
            dispatch(
              restoreDateRangeOnReturnToCurrentScheduleAction(
                crewSchedules.copyOfScheduleWhileShowingSolverRun
                  .dateRangeSelection
              )
            );
            dispatch(setIsShowingASolverRunAction(false));

            dispatch(
              setCrewSchedulesAction({
                pairings:
                  crewSchedules.copyOfScheduleWhileShowingSolverRun.pairings,
                crewMembers:
                  crewSchedules.copyOfScheduleWhileShowingSolverRun.crewMembers,
                duties:
                  crewSchedules.copyOfScheduleWhileShowingSolverRun.duties,
                flights:
                  crewSchedules.copyOfScheduleWhileShowingSolverRun.flights
              })
            );
            dispatch(
              setOpenTimeAction(
                crewSchedules.copyOfScheduleWhileShowingSolverRun.openTimeIds
              )
            );

            /**
             * restore the violations associated with Current Schedule
             */
            dispatch(
              setProcessedViolationsAction(
                crewSchedules.copyOfScheduleWhileShowingSolverRun
                  .processedViolations
              )
            );
            dispatch(setCopyOfScheduleWhileShowingSolverRunAction({}));
          });
          setTimeout(() => dispatch(setIsLoadingSolverOutputDataAction(false)));
        }
      } else {
        //dispatch action to show alert modal
        dispatch(
          setIsErrorModalVisibleAction({
            isVisible: true,
            message: "Please check your internet and try again"
          })
        );
      }
    },
    [
      selectedScenario.id,
      dispatch,
      cachedSolverOutput,
      crewSchedules,
      dateRangeSelection,
      userAttributes
    ]
  );

  const allScenarioMetadata = useSelector(
    store => store.solverData.allScenarioMetadata
  );

  const ScenarioNameWithLoadingIcon = ({
    scenarioName,
    percentageCompleted,
    error
  }) => {
    return (
      <div className="scenario-name-with-loading-icon">
        <div>
          <div>
            {scenarioName}
            {isScenarioInProgress(percentageCompleted) && (
              <SolverScenarioInProgressIndicator
                style={{
                  fill: SOLVER_SCENARIO_IN_PROGRESS_FILL_COLOR,
                  fontSize: "14px"
                }}
                className="solver-scenario-in-progress-indicator"
              />
            )}
          </div>
        </div>
        {error && <ReportProblemRounded fontSize={"small"} />}
      </div>
    );
  };

  return (
    <div className="summary-info">
      {isShowingASolverRun ? (
        <CounterBlockWithTitleAndValue title="Solver Input">
          <Switch
            checked={showSolverInputRows}
            color="secondary"
            onChange={handleChangeForShowingSolverInputs}
          />
        </CounterBlockWithTitleAndValue>
      ) : null}
      <div className="scenario-select">
        {!isShareScreen ? (
          <Select
            id="showHide"
            onChange={handleScenarioSelection}
            IconComponent={props => <Arrow iconClass={props} />}
            disableUnderline={true}
            value={selectedScenario}
            renderValue={value => value.scenarioName}
          >
            {allScenarioMetadata
              .sort((a, b) => b.id - a.id) //Sort the scenarios based on id so that the most recent run is on the top
              .map((value, index) => (
                <MenuItem
                  className="showhide-select-option"
                  value={value}
                  key={value.id}
                >
                  <ScenarioNameWithLoadingIcon {...value} />
                </MenuItem>
              ))}
          </Select>
        ) : null}
      </div>
    </div>
  );
}

// TODO: Move to own component
const CounterBlockWithTitleAndValue = ({ title, value, children }) => (
  <div className="info-block">
    <div className="title">{title}</div>
    <div className="value">{value ? value : children}</div>
  </div>
);
