import React from "react";
import { Fade, Modal } from "@material-ui/core";

export default function CustomModal({ className, ComponentToBeRendered }) {
  return (
    <Modal open={true}>
      <Fade in={true}>
        <div className={className}>{ComponentToBeRendered}</div>
      </Fade>
    </Modal>
  );
}
