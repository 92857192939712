import { batch } from "react-redux";
import { DST_CHANGE_TYPES } from "../constants/daylightSavings";
import {
  DIAGNOSTIC_LEVEL,
  DUTY_RELEASE_TIME_RULENAMES,
  DUTY_REPORT_TIME_RULENAMES
} from "../constants/disruptions/rules";
import {
  setCrewSchedulesAction,
  setProcessedViolationsAction,
  setViolationMetadataAction,
  showLoaderOnGanttAction
} from "../redux/crewSchedulesReducer";
import {
  processEntityViolations,
  processTimeViolations
} from "./allGanttRowsUtils";
import { isNonEmptyArray } from "./arrayUtils";
import { getAllSitTimeProperties } from "./getScheduleUtils";
import {
  checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity,
  findEarliestFlightForDuty,
  findLatestFlightForDuty,
  findUtcArrivalTimeOfFlight,
  findUtcDepartureTimeOfFlight,
  getStartAndEndOfDuty
} from "./pairingUtils";

import {
  aggregateViolationMetadata,
  getAllRestTimeProperties,
  getDiagnosticLevelAndColor
} from "./utils";

export const processRuleDisplayConfig = (
  alreadyProcessedViolations,
  entireLegalityResponseFetched
) => {
  if (
    alreadyProcessedViolations.ruleDisplayConfig &&
    isNonEmptyArray(Object.keys(alreadyProcessedViolations.ruleDisplayConfig))
  ) {
    return {
      ...alreadyProcessedViolations.ruleDisplayConfig,
      ...entireLegalityResponseFetched.ruleDisplayConfig
    };
  } else {
    return entireLegalityResponseFetched.ruleDisplayConfig;
  }
};

export const processLegalityResponseAndUpdateStore = ({
  alreadyProcessedViolations,
  currentScheduleViolationMetadata,
  payload,
  dispatcher,
  scheduleData,
  userAttributes,
  dateRange,
  webWorkerService,
  middlewareFlags
}) => {
  let ruleDisplayConfig = processRuleDisplayConfig(
    alreadyProcessedViolations,
    payload
  );

  const updatedViolationMetadata = { ...currentScheduleViolationMetadata };

  const processedEntityViolation = processEntityViolations(
    payload.entityViolations,
    alreadyProcessedViolations.entityViolations,
    updatedViolationMetadata.entityViolation,
    ruleDisplayConfig
  );

  const processedTimeViolation = processTimeViolations(
    payload.timeViolations,
    alreadyProcessedViolations.timeViolations,
    updatedViolationMetadata.timeViolation,
    ruleDisplayConfig
  );

  if (window.Worker) {
    webWorkerService.invokeLegalityProcessor({
      eventType: "LEGALITY_DATA",
      eventData: {
        scheduleData,
        processedEntityViolation,
        processedTimeViolation,
        ruleDisplayConfig,
        updatedViolationMetadata,
        userAttributes,
        dateRange
      },
      context: {
        shouldInitiateViolationProcessing: false,
        postProcessingFunction: updateStoreWithNewScheduleData,
        postProcessingFunctionParameters: {
          dispatcher,
          middlewareFlags
        }
      }
    });
  }
};

export const updateStoreWithNewScheduleData = ({
  dispatcher,
  scheduleData,
  processedEntityViolation,
  processedTimeViolation,
  ruleDisplayConfig,
  updatedViolationMetadata,
  middlewareFlags
}) => {
  batch(() => {
    dispatcher(setCrewSchedulesAction({ ...scheduleData }));

    dispatcher(
      setProcessedViolationsAction({
        entityViolations: processedEntityViolation,
        timeViolations: processedTimeViolation,
        ruleDisplayConfig: ruleDisplayConfig
      })
    );

    dispatcher(setViolationMetadataAction(updatedViolationMetadata));

    dispatcher(showLoaderOnGanttAction(false));

    middlewareFlags.scheduleProcessingComplete = false;
  });
};

export const processScheduleDataAfterViolationResponse = (
  scheduleData,
  { processedEntityViolation, processedTimeViolation },
  ruleDisplayConfig,
  updatedViolationMetadata,
  lookUpData,
  userAttributes,
  dateRange
) => {
  scheduleData.crewMembers.forEach((crewMember, indexOfCrew) => {
    const { pairings, flightOps, crewId } = crewMember;

    enrichCrewMemberWithViolationsOnPairings(
      scheduleData,
      { processedEntityViolation, processedTimeViolation },
      ruleDisplayConfig,
      updatedViolationMetadata,
      lookUpData,
      userAttributes,
      dateRange,
      indexOfCrew,
      crewId,
      flightOps,
      pairings
    );
  });
};

const enrichCrewMemberWithViolationsOnPairings = (
  scheduleData,
  { processedEntityViolation, processedTimeViolation },
  ruleDisplayConfig,
  updatedViolationMetadata,
  lookUpData,
  userAttributes,
  dateRange,
  indexOfCrew,
  crewId,
  flightOps,
  pairings
) => {
  isNonEmptyArray(pairings) &&
    pairings.forEach((pairingId, indexOfPairing) => {
      const pairingViolationData = {
        color: null,
        reduxKeys: []
      };

      enrichCrewMemberWithRestViolationData(
        scheduleData,
        { processedEntityViolation, processedTimeViolation },
        ruleDisplayConfig,
        updatedViolationMetadata,
        lookUpData,
        userAttributes,
        dateRange,
        indexOfCrew,
        crewId,
        pairingId,
        indexOfPairing,
        pairings,
        pairingViolationData
      );

      enrichCrewMemberWithPairingEntityViolationData(
        scheduleData,
        { processedEntityViolation, processedTimeViolation },
        ruleDisplayConfig,
        updatedViolationMetadata,
        lookUpData,
        userAttributes,
        dateRange,
        indexOfCrew,
        crewId,
        flightOps,
        pairingId,
        indexOfPairing,
        pairings,
        pairingViolationData
      );

      enrichCrewMemberWithPairingTimeViolationData(
        scheduleData,
        { processedEntityViolation, processedTimeViolation },
        ruleDisplayConfig,
        updatedViolationMetadata,
        lookUpData,
        userAttributes,
        dateRange,
        indexOfCrew,
        crewId,
        pairingId,
        flightOps,
        indexOfPairing,
        pairings,
        pairingViolationData.color
      );

      const { dutyIds } = scheduleData.pairings[pairingId];

      isNonEmptyArray(dutyIds) &&
        dutyIds.forEach(dutyId => {
          const dutyViolationDataAggregator = {
            violationColor: null,
            warningColor: null,
            reduxKeys: [],
            violationCount: 0,
            warningCount: 0
          };

          if (scheduleData.crewMembers[indexOfCrew].dutyViolation === undefined)
            scheduleData.crewMembers[indexOfCrew].dutyViolation = {};

          scheduleData.crewMembers[indexOfCrew].dutyViolation[dutyId] = {};

          enrichCrewMemberWithDutyEntityViolation(
            scheduleData,
            { processedEntityViolation, processedTimeViolation },
            ruleDisplayConfig,
            updatedViolationMetadata,
            lookUpData,
            userAttributes,
            dateRange,
            indexOfCrew,
            crewId,
            pairingId,
            flightOps,
            indexOfPairing,
            pairings,
            pairingViolationData.color,
            dutyId,
            dutyViolationDataAggregator
          );

          enrichCrewMemberWithDutyCrewViolation(
            scheduleData,
            { processedEntityViolation, processedTimeViolation },
            ruleDisplayConfig,
            updatedViolationMetadata,
            lookUpData,
            userAttributes,
            dateRange,
            indexOfCrew,
            crewId,
            pairingId,
            flightOps,
            indexOfPairing,
            pairings,
            pairingViolationData.color,
            dutyId,
            dutyViolationDataAggregator
          );

          enrichCrewMemberWithDutyTimeViolation(
            scheduleData,
            { processedEntityViolation, processedTimeViolation },
            ruleDisplayConfig,
            updatedViolationMetadata,
            lookUpData,
            userAttributes,
            dateRange,
            indexOfCrew,
            crewId,
            pairingId,
            flightOps,
            indexOfPairing,
            pairings,
            pairingViolationData.color,
            dutyId,
            dutyViolationDataAggregator
          );

          const [isWarning, color] = getDiagnosticLevelAndColor(
            dutyViolationDataAggregator.violationCount,
            dutyViolationDataAggregator.warningCount,
            dutyViolationDataAggregator.violationColor,
            dutyViolationDataAggregator.warningColor
          );

          scheduleData.crewMembers[indexOfCrew].dutyViolation[
            dutyId
          ].violations = dutyViolationDataAggregator.reduxKeys.join("&&");

          if (dutyViolationDataAggregator.reduxKeys.length > 0) {
            scheduleData.crewMembers[indexOfCrew].dutyViolation[
              dutyId
            ].borderColor = color;
            scheduleData.crewMembers[indexOfCrew].dutyViolation[
              dutyId
            ].isWarning = isWarning;
          } else {
            scheduleData.crewMembers[indexOfCrew].dutyViolation[
              dutyId
            ].borderColor = pairingViolationData.color;
          }

          const { flightIds } = scheduleData.duties[dutyId];

          isNonEmptyArray(flightIds) &&
            flightIds.forEach((flightId, index) => {
              enrichCrewMemberWithFlightViolation(
                scheduleData,
                { processedEntityViolation, processedTimeViolation },
                ruleDisplayConfig,
                updatedViolationMetadata,
                lookUpData,
                userAttributes,
                dateRange,
                indexOfCrew,
                crewId,
                pairingId,
                flightOps,
                indexOfPairing,
                pairings,
                pairingViolationData.color,
                dutyId,
                flightId,
                index
              );
            });
        });
    });
};

const enrichCrewMemberWithRestViolationData = (
  scheduleData,
  { processedEntityViolation, processedTimeViolation },
  ruleDisplayConfig,
  updatedViolationMetadata,
  lookUpData,
  userAttributes,
  dateRange,
  indexOfCrew,
  crewId,
  pairingId,
  indexOfPairing,
  pairings,
  pairingViolationData
) => {
  const currentPairing = scheduleData.pairings[pairingId];

  const restViolation =
    processedTimeViolation.crew.restViolation[`${crewId}-${pairingId}`];

  if (restViolation) {
    if (indexOfPairing !== pairings.length - 1) {
      const nextPairing = scheduleData.pairings[pairings[indexOfPairing + 1]];

      const dstRelatedInfo = {
        typeOfDSTChange: DST_CHANGE_TYPES.NO_CHANGE,
        dstOffset: 0
      };

      Object.keys(restViolation).forEach(ruleidentityKey => {
        const violation = restViolation[ruleidentityKey];

        const isWarning =
          violation.ruleIdentity.diagnosticLevel === DIAGNOSTIC_LEVEL.WARNING;

        const restProperties = getAllRestTimeProperties(
          new Date(currentPairing.utcEndTime),
          new Date(nextPairing.utcStartTime),
          new Date(currentPairing.utcStartTime),
          violation,
          dstRelatedInfo,
          dateRange,
          updatedViolationMetadata
        );

        scheduleData.crewMembers[indexOfCrew].restViolations = {
          ...scheduleData.crewMembers[indexOfCrew].restViolations,
          [pairingId]: {
            ...restProperties,
            isWarning
          }
        };
      });
    }
  }
};

const enrichCrewMemberWithPairingTimeViolationData = (
  scheduleData,
  { processedEntityViolation, processedTimeViolation },
  ruleDisplayConfig,
  updatedViolationMetadata,
  lookUpData,
  userAttributes,
  dateRange,
  indexOfCrew,
  crewId,
  pairingId,
  flightOps,
  indexOfPairing,
  pairings,
  pairingViolationColor
) => {
  const { dutyIds, allLayoverInfo } = scheduleData.pairings[pairingId];

  if (scheduleData.crewMembers[indexOfCrew].layoverViolation === undefined) {
    scheduleData.crewMembers[indexOfCrew].layoverViolation = {};
  }

  scheduleData.crewMembers[indexOfCrew].layoverViolation[pairingId] = {};

  if (dutyIds.length > 1) {
    dutyIds.forEach((dutyId, index) => {
      if (
        index !== 0 &&
        scheduleData.duties[dutyId] &&
        scheduleData.duties[dutyIds[index - 1]]
      ) {
        const layoverViolation =
          processedTimeViolation.pairing[`${pairingId}-${dutyIds[index - 1]}`];

        scheduleData.crewMembers[indexOfCrew].layoverViolation[pairingId][
          index - 1
        ] = {};

        if (allLayoverInfo[index - 1] && pairingViolationColor !== null) {
          scheduleData.crewMembers[indexOfCrew].layoverViolation[pairingId][
            index - 1
          ].borderColor = pairingViolationColor;
        }

        if (layoverViolation) {
          const layoverViolationData = {
            violationColor: null,
            warningColor: null,
            reduxKeys: [],
            violationCount: 0,
            warningCount: 0
          };

          Object.keys(layoverViolation).forEach(key => {
            const violation = layoverViolation[key];

            const isFlightOpsViolation = key.split("-")[1] === "FLIGHT_OPS";

            if (flightOps !== isFlightOpsViolation) return;

            const isWarning =
              violation.ruleIdentity.diagnosticLevel ===
              DIAGNOSTIC_LEVEL.WARNING;

            if (isWarning) {
              layoverViolationData.warningCount =
                layoverViolationData.warningCount + 1;
              if (layoverViolationData.warningColor === null) {
                layoverViolationData.warningColor = violation.color;
              }
            } else {
              layoverViolationData.violationCount =
                layoverViolationData.violationCount + 1;
              if (layoverViolationData.violationColor === null) {
                layoverViolationData.violationColor = violation.color;
              }
            }

            layoverViolationData.reduxKeys.push(
              updatedViolationMetadata.timeViolation[
                violation.ruleIdentity.violationId
              ].reduxKey
            );
          });

          if (allLayoverInfo[index - 1]) {
            const [isWarning, color] = getDiagnosticLevelAndColor(
              layoverViolationData.violationCount,
              layoverViolationData.warningCount,
              layoverViolationData.violationColor,
              layoverViolationData.warningColor
            );

            scheduleData.crewMembers[indexOfCrew].layoverViolation[pairingId][
              index - 1
            ].color = color;

            scheduleData.crewMembers[indexOfCrew].layoverViolation[pairingId][
              index - 1
            ].violations = layoverViolationData.reduxKeys.join("&&");
            scheduleData.crewMembers[indexOfCrew].layoverViolation[pairingId][
              index - 1
            ].isWarning = isWarning;
          }
        }
      }
    });
  }
};

const enrichCrewMemberWithPairingEntityViolationData = (
  scheduleData,
  { processedEntityViolation, processedTimeViolation },
  ruleDisplayConfig,
  updatedViolationMetadata,
  lookUpData,
  userAttributes,
  dateRange,
  indexOfCrew,
  crewId,
  flightOps,
  pairingId,
  indexOfPairing,
  pairings,
  pairingViolation
) => {
  const entityViolations = processedEntityViolation.pairing[pairingId];

  if (entityViolations) {
    const reduxKeys = [];

    Object.keys(entityViolations).forEach(key => {
      const violation = entityViolations[key];

      const isFlightOpsViolation = key.split("-")[1] === "FLIGHT_OPS";

      if (flightOps !== isFlightOpsViolation) return;

      if (pairingViolation.color === null)
        pairingViolation.color = violation.color;

      reduxKeys.push(
        updatedViolationMetadata.entityViolation[
          violation.ruleIdentity.violationId
        ].reduxKey
      );
    });

    scheduleData.crewMembers[indexOfCrew].pairingViolation = {
      ...scheduleData.crewMembers[indexOfCrew].pairingViolation,
      [pairingId]: {
        color: pairingViolation.color,
        violations: reduxKeys.join("&&")
      }
    };
  }
};

const enrichCrewMemberWithDutyEntityViolation = (
  scheduleData,
  { processedEntityViolation, processedTimeViolation },
  ruleDisplayConfig,
  updatedViolationMetadata,
  lookUpData,
  userAttributes,
  dateRange,
  indexOfCrew,
  crewId,
  pairingId,
  flightOps,
  indexOfPairing,
  pairings,
  pairingViolationColor,
  dutyId,
  dutyViolationDataAggregator
) => {
  const duty = scheduleData.duties[dutyId];

  const { flightIds } = duty;

  const { utcStartTime, utcEndTime } = getStartAndEndOfDuty(duty);

  const dutyViolationData = {
    violationColor: null,
    warningColor: null,
    reduxKeys: [],
    violationCount: 0,
    warningCount: 0
  };

  const releaseAndReportTimeViolation = {
    releaseTimeViolation: {},
    reportTimeViolation: {}
  };

  const entityViolations = processedEntityViolation.duty[dutyId];

  if (entityViolations) {
    Object.keys(entityViolations).forEach(key => {
      const violation = entityViolations[key];

      const isFlightOpsViolation = key.split("-")[1] === "FLIGHT_OPS";

      const isWarning =
        violation.ruleIdentity.diagnosticLevel === DIAGNOSTIC_LEVEL.WARNING;

      if (flightOps !== isFlightOpsViolation) return;

      if (isWarning) {
        dutyViolationData.warningCount = dutyViolationData.warningCount + 1;
        if (dutyViolationData.warningColor === null) {
          dutyViolationData.warningColor = violation.color;
        }
      } else {
        dutyViolationData.violationCount = dutyViolationData.violationCount + 1;
        if (dutyViolationData.violationColor === null) {
          dutyViolationData.violationColor = violation.color;
        }
      }

      dutyViolationData.reduxKeys.push(
        updatedViolationMetadata.entityViolation[
          violation.ruleIdentity.violationId
        ].reduxKey
      );

      //check if report time
      {
        const hasReportTimeViolation =
          DUTY_REPORT_TIME_RULENAMES.indexOf(violation.ruleIdentity.ruleName) >
          -1;

        if (hasReportTimeViolation) {
          const earliestFlightForDuty = findEarliestFlightForDuty(
            scheduleData.flights,
            flightIds
          );
          if (earliestFlightForDuty) {
            const dstRelatedInfo = {
              typeOfDSTChange: DST_CHANGE_TYPES.NO_CHANGE,
              dstOffset: 0
            };

            const sitStart = new Date(utcStartTime);
            const sitEnd = new Date(
              findUtcDepartureTimeOfFlight(earliestFlightForDuty)
            );

            checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity(
              sitStart,
              sitEnd,
              userAttributes,
              dstRelatedInfo
            );

            releaseAndReportTimeViolation.reportTimeViolation = {
              ...getAllSitTimeProperties(
                sitStart,
                sitEnd,
                utcStartTime,
                false,
                dstRelatedInfo,
                dateRange
              ),
              color: violation.color,
              violations: [
                updatedViolationMetadata.entityViolation[
                  violation.ruleIdentity.violationId
                ].reduxKey
              ].join("&&"),
              isWarning: isWarning
            };
          }
        }
      }

      //check if release time
      {
        const hasReleaseTimeViolation =
          DUTY_RELEASE_TIME_RULENAMES.indexOf(violation.ruleIdentity.ruleName) >
          -1;

        if (hasReleaseTimeViolation) {
          const latestFlightForDuty = findLatestFlightForDuty(
            scheduleData.flights,
            duty.flightIds
          );

          if (latestFlightForDuty) {
            const dstRelatedInfo = {
              typeOfDSTChange: DST_CHANGE_TYPES.NO_CHANGE,
              dstOffset: 0
            };

            const sitStart = new Date(
              findUtcArrivalTimeOfFlight(latestFlightForDuty)
            );
            const sitEnd = new Date(utcEndTime);

            checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity(
              sitStart,
              sitEnd,
              userAttributes,
              dstRelatedInfo
            );

            releaseAndReportTimeViolation.releaseTimeViolation = {
              ...getAllSitTimeProperties(
                sitStart,
                sitEnd,
                false,
                dstRelatedInfo,
                dateRange
              ),
              color: violation.color,
              violations: [
                updatedViolationMetadata.entityViolation[
                  violation.ruleIdentity.violationId
                ].reduxKey
              ].join("&&"),
              isWarning: isWarning
            };
          }
        }
      }
    });
  }

  const hasReportTimeViolation =
    Object.keys(releaseAndReportTimeViolation.reportTimeViolation).length > 0;

  const hasReleaseTimeViolation =
    Object.keys(releaseAndReportTimeViolation.releaseTimeViolation).length > 0;

  if (hasReportTimeViolation) {
    scheduleData.crewMembers[indexOfCrew].dutyViolation[
      dutyId
    ].reportTimeViolation = {
      ...releaseAndReportTimeViolation.reportTimeViolation
    };
  }

  if (hasReleaseTimeViolation) {
    scheduleData.crewMembers[indexOfCrew].dutyViolation[
      dutyId
    ].releaseTimeViolation = {
      ...releaseAndReportTimeViolation.releaseTimeViolation
    };
  }

  aggregateViolationMetadata(dutyViolationDataAggregator, dutyViolationData);
};

const enrichCrewMemberWithDutyTimeViolation = (
  scheduleData,
  { processedEntityViolation, processedTimeViolation },
  ruleDisplayConfig,
  updatedViolationMetadata,
  lookUpData,
  userAttributes,
  dateRange,
  indexOfCrew,
  crewId,
  pairingId,
  flightOps,
  indexOfPairing,
  pairings,
  pairingViolationColor,
  dutyId,
  dutyViolationDataAggregator
) => {
  const duty = scheduleData.duties[dutyId];

  const { flightIds, allSitTimeInfo } = duty;

  if (scheduleData.crewMembers[indexOfCrew].sitViolation === undefined) {
    scheduleData.crewMembers[indexOfCrew].sitViolation = {};
  }

  scheduleData.crewMembers[indexOfCrew].sitViolation[dutyId] = {};

  const sitViolationData = {
    violationColor: null,
    warningColor: null,
    reduxKeys: [],
    violationCount: 0,
    warningCount: 0
  };

  flightIds.forEach((_, index) => {
    if (index === 0) return;

    let sitTimeViolation =
      processedTimeViolation.duty[`${dutyId}-${flightIds[index - 1]}`];

    scheduleData.crewMembers[indexOfCrew].sitViolation[dutyId][index - 1] = {};

    if (sitTimeViolation) {
      const sitViolation = [];
      Object.keys(sitTimeViolation).forEach(key => {
        const violation = sitTimeViolation[key];

        const isFlightOpsViolation = key.split("-")[1] === "FLIGHT_OPS";

        if (flightOps !== isFlightOpsViolation) return;

        const isWarning =
          violation.ruleIdentity.diagnosticLevel === DIAGNOSTIC_LEVEL.WARNING;

        if (isWarning) {
          sitViolationData.warningCount = sitViolationData.warningCount + 1;
          if (sitViolationData.warningColor === null) {
            sitViolationData.warningColor = violation.color;
          }
        } else {
          sitViolationData.violationCount = sitViolationData.violationCount + 1;
          if (sitViolationData.violationColor === null) {
            sitViolationData.violationColor = violation.color;
          }
        }

        sitViolation.push(
          updatedViolationMetadata.timeViolation[
            violation.ruleIdentity.violationId
          ].reduxKey
        );

        sitViolationData.reduxKeys.push(
          updatedViolationMetadata.timeViolation[
            violation.ruleIdentity.violationId
          ].reduxKey
        );
      });

      if (allSitTimeInfo[index - 1] && sitViolation.length > 0) {
        const [isWarning, color] = getDiagnosticLevelAndColor(
          sitViolationData.violationCount,
          sitViolationData.warningCount,
          sitViolationData.violationColor,
          sitViolationData.warningColor
        );

        scheduleData.crewMembers[indexOfCrew].sitViolation[dutyId][
          index - 1
        ].color = color;

        scheduleData.crewMembers[indexOfCrew].sitViolation[dutyId][
          index - 1
        ].violations = sitViolationData.reduxKeys.join("&&");

        scheduleData.crewMembers[indexOfCrew].sitViolation[dutyId][
          index - 1
        ].isWarning = isWarning;
      }
    }
  });

  aggregateViolationMetadata(dutyViolationDataAggregator, sitViolationData);
};

const enrichCrewMemberWithDutyCrewViolation = (
  scheduleData,
  { processedEntityViolation, processedTimeViolation },
  ruleDisplayConfig,
  updatedViolationMetadata,
  lookUpData,
  userAttributes,
  dateRange,
  indexOfCrew,
  crewId,
  pairingId,
  flightOps,
  indexOfPairing,
  pairings,
  pairingViolationColor,
  dutyId,
  dutyViolationDataAggregator
) => {
  const crewDutyViolation =
    processedTimeViolation.crew.crewDutyViolation[`${crewId}-${dutyId}`];

  if (!crewDutyViolation) return;

  const crewDutyViolationData = {
    violationColor: null,
    warningColor: null,
    reduxKeys: [],
    violationCount: 0,
    warningCount: 0
  };

  Object.keys(crewDutyViolation).forEach(key => {
    const violation = crewDutyViolation[key];

    const isFlightOpsViolation = key.split("-")[1] === "FLIGHT_OPS";

    if (flightOps !== isFlightOpsViolation) return;

    const isWarning =
      violation.ruleIdentity.diagnosticLevel === DIAGNOSTIC_LEVEL.WARNING;

    if (flightOps !== isFlightOpsViolation) return;

    if (isWarning) {
      crewDutyViolationData.warningCount =
        crewDutyViolationData.warningCount + 1;
      if (crewDutyViolationData.warningColor === null) {
        crewDutyViolationData.warningColor = violation.color;
      }
    } else {
      crewDutyViolationData.violationCount =
        crewDutyViolationData.violationCount + 1;
      if (crewDutyViolationData.violationColor === null) {
        crewDutyViolationData.violationColor = violation.color;
      }
    }

    crewDutyViolationData.reduxKeys.push(
      updatedViolationMetadata.timeViolation[violation.ruleIdentity.violationId]
        .reduxKey
    );
  });

  aggregateViolationMetadata(
    dutyViolationDataAggregator,
    crewDutyViolationData
  );
};

const enrichCrewMemberWithFlightViolation = (
  scheduleData,
  { processedEntityViolation, processedTimeViolation },
  ruleDisplayConfig,
  updatedViolationMetadata,
  lookUpData,
  userAttributes,
  dateRange,
  indexOfCrew,
  crewId,
  pairingId,
  flightOps,
  indexOfPairing,
  pairings,
  pairingViolationColor,
  dutyId,
  flightId,
  indexOfFlight
) => {
  const flightViolation = processedEntityViolation.flight[flightId];

  let previousSitTimeViolationData = null;

  if (indexOfFlight !== 0) {
    if (
      scheduleData.duties[dutyId] &&
      scheduleData.duties[dutyId].allSitTimeInfo &&
      scheduleData.duties[dutyId].allSitTimeInfo[indexOfFlight - 1] &&
      scheduleData.duties[dutyId].allSitTimeInfo[indexOfFlight - 1].width < 0.1
    ) {
      previousSitTimeViolationData =
        scheduleData.crewMembers[indexOfCrew].sitViolation[dutyId][
          indexOfFlight - 1
        ];
    }
  }

  if (!scheduleData.crewMembers[indexOfCrew].flightViolation) {
    scheduleData.crewMembers[indexOfCrew].flightViolation = {};
  }

  scheduleData.crewMembers[indexOfCrew].flightViolation[flightId] = {};

  if (!flightViolation && !previousSitTimeViolationData) return;

  const flightViolationData = {
    color: null,
    reduxKeys: []
  };

  flightViolation &&
    Object.keys(flightViolation).forEach(key => {
      const violation = flightViolation[key];
      const isFlightOpsViolation = key.split("-")[1] === "FLIGHT_OPS";

      if (flightOps !== isFlightOpsViolation) return;

      if (!flightViolationData.color) {
        flightViolationData.color = violation.color;
      }

      flightViolationData.reduxKeys.push(
        updatedViolationMetadata.entityViolation[
          violation.ruleIdentity.violationId
        ].reduxKey
      );
    });

  if (previousSitTimeViolationData && previousSitTimeViolationData.violations) {
    flightViolationData.reduxKeys.push(
      ...previousSitTimeViolationData.violations.split("&&")
    );
    if (!flightViolationData.color) {
      flightViolationData.color = previousSitTimeViolationData.color;
    }
  }

  scheduleData.crewMembers[indexOfCrew].flightViolation[flightId] = {
    color: flightViolationData.color,
    violations: flightViolationData.reduxKeys.join("&&")
  };
};
