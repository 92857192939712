const ACTIONS = {
  setTheme: "SET_THEME"
};

/**
 * initial state of the reducer
 */
const initialState = "light";
/**
 * theme reducer will handle actions like setting of theme
 * @param {Object} prevState
 * @param {String} action
 */
export function themeReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_THEME":
      newState = action.theme;
      break;
    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setThemeAction(theme) {
  return { type: ACTIONS.setTheme, theme: theme };
}
