import React from "react";
import { useSelector } from "react-redux";
import { SITE_ORANGE_COLOR } from "../../../constants/disruptions/color";
import {
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN,
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_AND_ITS_DESC_TO_BE_SHOWN,
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_TO_BE_SHOWN,
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_NUMBER_TO_BE_SHOWN
} from "../../../constants/disruptions/waitingTime";
import { isInArray } from "../../../utils/arrayUtils";
import { convertCommaSeperatedStringToArrayOfStrings } from "../../../utils/filterUtils";
import SimpleTooltipMessage from "../simpletooltipmessages/SimpleTooltipMessage";
import { Tooltip } from "@material-ui/core";
import { CODES_TO_ICON_MAP } from "../../../constants/disruptions/referenceIcons";
import { shouldShowFlightDetails } from "../../../utils/pairingUtils";

export default function FlightNumber({ flightData, flight, dateRange, isRTG }) {
  const selectedFlightNumberFilter = useSelector(
    store => store.searchCriteria.selectedFilter.flightNumber
  );

  const selectedFlightNumberFilterForSolverOutput = useSelector(
    store => store.searchCriteria.selectedFilterForSolverOutput.flightNumber
  );

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  /**
   * replace true with the config to replace or not replace flight number
   */
  const shouldIconReplaceFlightNumber = React.useCallback(
    flightNumber =>
      true && isInArray(flightNumber, Object.keys(CODES_TO_ICON_MAP)),
    []
  );

  const [replaceFlightNumber, setReplaceFlightNumber] = React.useState(false);

  React.useEffect(
    () =>
      setReplaceFlightNumber(
        shouldIconReplaceFlightNumber(flight.flightNumber)
      ),
    [shouldIconReplaceFlightNumber, flight.flightNumber]
  );

  const shouldShowFlightDetailsMemoised = React.useCallback(() => {
    return shouldShowFlightDetails(
      dateRange.rangeInDaysInSingleViewPort,
      flightData
    );
  }, [flightData, dateRange.rangeInDaysInSingleViewPort]);

  const RTGBlock = React.useCallback(
    () => (
      <div
        className={`rtg-text-block ${
          shouldShowFlightDetailsMemoised()
            ? "added-margin-top"
            : "no-margin-top"
        }`}
      >
        <div>R</div>
        <div>T</div>
        <div>G</div>
      </div>
    ),
    [shouldShowFlightDetailsMemoised]
  );

  const getBackgroundColor = React.useCallback(
    () =>
      `${
        !isRTG &&
        isInArray(
          flight.flightNumber,
          isShowingASolverRun
            ? [
                ...convertCommaSeperatedStringToArrayOfStrings(
                  selectedFlightNumberFilterForSolverOutput
                )
              ]
            : [
                ...convertCommaSeperatedStringToArrayOfStrings(
                  selectedFlightNumberFilter
                )
              ]
        )
          ? SITE_ORANGE_COLOR
          : "initial"
      }`,
    [
      flight.flightNumber,
      isShowingASolverRun,
      isRTG,
      selectedFlightNumberFilter,
      selectedFlightNumberFilterForSolverOutput
    ]
  );

  /**
   * returns true if flight number should be replaced by an icon
   * and the flight width is longer than the min width for an icon to be shown
   */
  const showIconOnScreen = React.useCallback(
    () =>
      replaceFlightNumber &&
      flightData.flightLengthInViewWidthUnits >
        MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_TO_BE_SHOWN,
    [flightData.flightLengthInViewWidthUnits, replaceFlightNumber]
  );

  const IconForFlightNumber = React.useCallback(
    ({ flightNumber }) => (
      <Tooltip
        title={
          <SimpleTooltipMessage
            className={"simple-tooltip-message"}
            tooltipMessage={flightNumber}
          />
        }
      >
        {CODES_TO_ICON_MAP[flightNumber]}
      </Tooltip>
    ),
    []
  );

  const willFlightIconDescFit = React.useCallback(flightLength => {
    return (
      flightLength > MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_AND_ITS_DESC_TO_BE_SHOWN
    );
  }, []);

  /**
   * if - flight number should be replaced by icon then replace it with respective icon
   * if - is RTG block then replace it with RTG block
   * else - use the flight number
   */
  const renderFlightNumberOrIcon = React.useCallback(() => {
    return replaceFlightNumber ? (
      <span
        style={{
          display: "grid",
          gridAutoFlow: "column",
          fontSize: "10px"
        }}
      >
        <IconForFlightNumber flightNumber={flight.flightNumber} />
        <>
          {/**do not show icon desc if it will bleed out */}
          {willFlightIconDescFit(flightData.flightLengthInViewWidthUnits) &&
            flight.flightNumber}
        </>
      </span>
    ) : isRTG ? (
      <RTGBlock />
    ) : (
      flight.flightNumber
    );
  }, [
    isRTG,
    flight.flightNumber,
    replaceFlightNumber,
    flightData.flightLengthInViewWidthUnits,
    willFlightIconDescFit
  ]);

  return (
    <>
      {(dateRange.rangeInDaysInSingleViewPort < 4 &&
        flightData.flightLengthInViewWidthUnits >
          MINIMUM_VIEW_WIDTH_FOR_FLIGHT_NUMBER_TO_BE_SHOWN) ||
      isRTG ||
      showIconOnScreen() ? (
        <span
          className={`flight-number ${
            (dateRange.rangeInDaysInSingleViewPort > 1 ||
              flightData.flightLengthInViewWidthUnits <
                MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN) &&
            !isRTG
              ? `timeline-window-two-days`
              : `noop`
          }`}
        >
          <span
            style={{
              background: getBackgroundColor(),
              height: replaceFlightNumber ? "18px" : "initial"
            }}
          >
            {renderFlightNumberOrIcon()}
          </span>
        </span>
      ) : null}
    </>
  );
}
