import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Timeline as ThreadedComments } from "@material-ui/lab";
import { Divider, CircularProgress } from "@material-ui/core";

import AddCommentSection from "./AddCommentSection";
import Comment from "./Comment";

import { getCommentForSelectedViolation } from "./utils";

import "./CommentSection.scss";

export default function CommentSection({ selectedViolation }) {
  const dispatch = useDispatch();

  const userAttributes = useSelector(store => store.userInfo.userAttributes);

  const allCommentData = useSelector(
    store => store.violationDrilledDownData.commentData
  );

  const getViolationKey = React.useCallback(
    () => selectedViolation.violationId + "_" + selectedViolation.violationType,
    [selectedViolation]
  );

  const [isLoading, setIsLoading] = React.useState(true);

  const [commentData, setCommentData] = React.useState(
    allCommentData[getViolationKey()]
  );

  const commentContainerRef = React.useRef();

  const getUserName = React.useCallback(
    (givenName, familyName) => `${givenName} ${familyName}`,
    []
  );

  const userName = React.useMemo(
    () =>
      getUserName(userAttributes["given_name"], userAttributes["family_name"]),
    [userAttributes, getUserName]
  );

  const scrollToBottom = React.useCallback(() => {
    if (commentContainerRef && commentContainerRef.current) {
      commentContainerRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, []);

  const isCommentByCurrentUser = React.useCallback(
    commenterName => commenterName === userName,
    [userName]
  );

  React.useEffect(() => {
    scrollToBottom();
  }, [commentData, scrollToBottom]);

  React.useEffect(() => {
    setCommentData(allCommentData[getViolationKey()]);
  }, [allCommentData, getViolationKey]);

  const getCommentForSelectedViolationMemoised = React.useCallback(
    async () =>
      getCommentForSelectedViolation(selectedViolation, setIsLoading, dispatch),
    [dispatch, selectedViolation]
  );

  const isCommentDataNotCached = React.useCallback(
    () =>
      !Object.keys(allCommentData).includes(
        selectedViolation.violationId + "_" + selectedViolation.violationType
      ),
    [allCommentData, selectedViolation]
  );

  React.useEffect(() => {
    if (isCommentDataNotCached()) {
      setIsLoading(true);
      getCommentForSelectedViolationMemoised();
    } else {
      setIsLoading(false);
    }
  }, [getCommentForSelectedViolationMemoised, isCommentDataNotCached]);

  const Seperator = React.useCallback(() => {
    return <Divider orientation={"horizontal"} />;
  }, []);

  const areCommentsPresent = React.useCallback(
    () => commentData.length > 0,
    [commentData]
  );

  const Comments = React.useCallback(() => {
    return (
      <ThreadedComments>
        {commentData.map(
          ({ commentId, commentBy, comment, commentDateTime }, index) => (
            <Comment
              key={commentId}
              commentId={commentId}
              commentDateTime={commentDateTime}
              commentBy={commentBy}
              comment={comment}
              shouldDrawConnector={index !== commentData.length - 1}
              isCommentByCurrentUser={isCommentByCurrentUser(commentBy)}
              selectedViolation={selectedViolation}
            />
          )
        )}
        <div ref={commentContainerRef}></div>
      </ThreadedComments>
    );
  }, [commentData, isCommentByCurrentUser, selectedViolation]);

  const NoComments = React.useCallback(
    () => (
      <div className="no-comments-info-text">
        {getViolationKey() === "NO_ID_NO_TYPE"
          ? "Commenting not allowed for this violation." //if there is no violationId for a violation i.e previous solver run then disable commenting as comments cannot be mapped
          : "No comments yet..."}
      </div>
    ),
    [getViolationKey]
  );

  const Loading = React.useCallback(
    () => (
      <div className="loading">
        <CircularProgress />
      </div>
    ),
    []
  );

  const CommentsContainer = React.useCallback(
    () => (
      <div className="comments-container">
        {isLoading ? (
          <Loading />
        ) : areCommentsPresent() ? (
          <Comments />
        ) : (
          <NoComments />
        )}
      </div>
    ),
    [isLoading, areCommentsPresent]
  );

  return (
    <div className="comment-view-container">
      <>
        <CommentsContainer />
      </>
      <>
        <Seperator />
      </>
      <>
        <AddCommentSection
          selectedViolation={selectedViolation}
          userName={userName}
        />
      </>
    </div>
  );
}
