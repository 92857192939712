import {
  fetchDeadheadData,
  fetchCumulativeTimeData,
} from '../utils/axiosMiddlewareUtils'

const axiosMiddleware = () => {
  return store => next => action => {
    switch (action.type) {
      case "FETCH_DEADHEAD_DATA":
        fetchDeadheadData(store);
        break;
      case "FETCH_CUMULATIVE_TIME_DATA":
        fetchCumulativeTimeData(store);
        break;

      default:
        return next(action);
    }
  };
};

export default axiosMiddleware();
