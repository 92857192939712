/**
 * flattens the entire json.
 * Primary use-case that this function is serving is flattening the translation files as React-Intl only supports flattened JSON.
 * Refrence: https://stackoverflow.com/questions/45783677/react-intl-accessing-nested-messages
 * @param {JSON} nestedMessages
 * @param {string} prefix
 */
export const flattenMessages = (nestedMessages, prefix = "") => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

/**
 * returns true if object is not empty
 */
export function isNonEmptyObject(jsonObject) {
  return Object.keys(jsonObject).length > 0;
}
