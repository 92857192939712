export function convertSolverFileContentToSchedulesJSON(fileContent) {
  let schedule = {};

  if (
    fileContent.schedule &&
    fileContent.schedule.crewMembers &&
    fileContent.schedule.pairings
  ) {
    schedule.crewMembers = fileContent.schedule.crewMembers.map(crewMember => {
      crewMember.pairings = [];
      Object.keys(fileContent.schedule.pairingAssignments).forEach(pairing => {
        if (
          fileContent.schedule.pairingAssignments[pairing].indexOf(
            crewMember.id || crewMember.crewId
          ) > -1
        ) {
          crewMember.pairings.push(pairing);
        }
      });
      return crewMember;
    });
    schedule.pairings = {};
    fileContent.schedule.pairings.forEach(pairing => {
      pairing.dutyIds = pairing.dutyIds.map(dutyId => dutyId);
      schedule.pairings[pairing.pairingId] = pairing;
    });
    schedule.duties = {};
    fileContent.schedule.duties.forEach(duty => {
      duty.flightIds = duty.flightIds.map(flightId => flightId);
      schedule.duties[duty.dutyId] = duty;
    });
    schedule.flights = {};
    fileContent.schedule.operatingFlights
      ? fileContent.schedule.operatingFlights.forEach(
          operatingFlight =>
            (schedule.flights[operatingFlight.flightId] = operatingFlight)
        )
      : fileContent.schedule.flights.forEach(
          operatingFlight =>
            (schedule.flights[operatingFlight.flightId] = operatingFlight)
        );
  }
  return schedule;
}
