import { SELECT_ALL_DESELECT_ALL_VALUES_MAP } from "../constants/disruptions/durationSelect";
import { OPEN_PAIRING, OPEN_SEGMENT } from "../constants/disruptions/openTime";
import { setIncludedCrewMemberForSolverAction } from "../redux/searchCriteriaReducer";
import { setIncludedOpenFlyingRowsAction } from "../redux/solverDataReducer";

export const handleChangeInSelectAllDeselectAllCheckbox = (
  currentState,
  index,
  parameters
) => {
  const {
    idsOfAllCrewInRedux,
    checkBoxComponentStateHandler,
    numberOfOpenSegmentRows,
    numberOfOpenPairingRows,
    dispatcher
  } = parameters;

  if (currentState === SELECT_ALL_DESELECT_ALL_VALUES_MAP.DESELECT_ALL) {
    //dispatch an action to clear the includedCrewForSolverRun to an empty array
    dispatcher(setIncludedCrewMemberForSolverAction([]));
    dispatcher(
      setIncludedOpenFlyingRowsAction({ type: OPEN_SEGMENT, includedRows: [] })
    );
    dispatcher(
      setIncludedOpenFlyingRowsAction({ type: OPEN_PAIRING, includedRows: [] })
    );
    dispatcher(checkBoxComponentStateHandler(currentState));
  } else {
    //dispatch an action to set includedCrewForSolverRun as an array of crewIds of all crew present in redux
    dispatcher(setIncludedCrewMemberForSolverAction([...idsOfAllCrewInRedux]));
    dispatcher(
      setIncludedOpenFlyingRowsAction({
        type: OPEN_SEGMENT,
        includedRows: [...Array(numberOfOpenSegmentRows).keys()]
      })
    );
    dispatcher(
      setIncludedOpenFlyingRowsAction({
        type: OPEN_PAIRING,
        includedRows: [...Array(numberOfOpenPairingRows).keys()]
      })
    );
    dispatcher(checkBoxComponentStateHandler(currentState));
  }
};
