export const wsConnect = connectionParam => ({
  type: "WS_CONNECT",
  connectionParam
});
export const wsConnected = host => ({ type: "WS_CONNECTED", host });
export const wsDisconnected = host => ({ type: "WS_DISCONNECTED", host });

const websocketInitialState = {};

export const webSocketReducer = (
  state = { ...websocketInitialState },
  action
) => {
  switch (action.type) {
    case "WS_CONNECT":
      return { ...state, host: action.host };
    default:
      return state;
  }
};

export const fetchSchedulesAction = requestParams => ({
  type: "WS_FETCH_SCHEDULES",
  requestParams
});
export const pingAction = () => ({ type: "WS_PING" });
export const solverRequestAction = requestParams => ({
  type: "WS_SOLVER_REQUEST",
  requestParams
});
export const solverOutputRequestAction = requestParams => ({
  type: "WS_SOLVER_OUTPUT_REQUEST",
  requestParams
});

export const applyFilterAction = selectedFilter => ({
  type: "WS_APPLY_FILTER",
  selectedFilter
});

export const sendPartailRefreshRequestAction = utcLastModifiedTime => ({
  type: "WS_PARTIAL_REFRESH_REQUEST",
  utcLastModifiedTime
});

export const getOpenFlyingDataAction = () => ({
  type: "WS_GET_OPEN_FLYING_DATA"
});

export const updateGanttOnUserAction = schedule => ({
  type: "UPDATE_GANTT_ON_USER_ACTION",
  schedule
});
