import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setDateRangeAction } from "../../../../redux/searchCriteriaReducer";
import { Select, MenuItem } from "@material-ui/core";
import Arrow from "../../../partials/arrow/Arrow";
import { MATERIAL_UI_SELECT_FOR_DURATION_SELECTION } from "../../../../constants/material-ui-classnames/muiSelect";
import { sortArrayNumerically } from "../../../../utils/arrayUtils";

import "./SelectionTimelineContainer.scss";
import {
  DATE_RANGE_SELECT_VALUES,
  SELECT_ALL_DESELECT_ALL_VALUES_MAP
} from "../../../../constants/disruptions/durationSelect";
import CustomCheckBox from "../../../partials/checkbox/CustomCheckBox";

import { handleChangeInSelectAllDeselectAllCheckbox } from "../../../../utils/durationSelectUtils";

export default function DurationSelection() {
  const dispatch = useDispatch();
  const dateRangeSelection = useSelector(
    store => store.searchCriteria.dateRange
  );

  const idsOfAllCrewInRedux = useSelector(store =>
    store.crewSchedules.crewMembers.map(crewMember => crewMember.crewExternalId)
  );

  const selectStyleClasses = {
    root: MATERIAL_UI_SELECT_FOR_DURATION_SELECTION.ROOT,
    icon: MATERIAL_UI_SELECT_FOR_DURATION_SELECTION.ICON,
    iconOpen: MATERIAL_UI_SELECT_FOR_DURATION_SELECTION.ICON_OPEN,
    select: MATERIAL_UI_SELECT_FOR_DURATION_SELECTION.SELECT,
    selectMenu: MATERIAL_UI_SELECT_FOR_DURATION_SELECTION.SELECT_MENU
  };

  /**
   * this array is used to provide values to the dateRangeSelect component.
   *
   */
  const dateRangeSelectValues = sortArrayNumerically(DATE_RANGE_SELECT_VALUES);

  const [selectAllOrDeselectAllState, setSelectAllOrDeselectAllState] =
    React.useState(SELECT_ALL_DESELECT_ALL_VALUES_MAP.DESELECT_ALL);

  return (
    <div className="selection-main">
      <div className="select-deselect-all-checkbox-container">
        <CustomCheckBox
          stateValue={selectAllOrDeselectAllState}
          stateChangeHandler={handleChangeInSelectAllDeselectAllCheckbox}
          dispatcher={dispatch}
          additionalParamsForHandler={{
            idsOfAllCrewInRedux: idsOfAllCrewInRedux,
            checkBoxComponentStateHandler: setSelectAllOrDeselectAllState
          }}
        />
      </div>
      <div className="show-hide-select-container">
        {/* TODO: commenting as CRWEB-419, uncomment this and remove the <div> below when ready to implement actual functionality
        <Select
          id="showHide"
          onChange={event => {
            console.log(event);
          }}
          IconComponent={props => <Arrow iconClass={props} />}
          disableUnderline={true}
          classes={selectStyleClasses}
          value={"Show All"}
        >
          {SHOW_HIDE_SELECT_VALUES.map((value, index) => (
            <MenuItem
              className="showhide-select-option"
              value={value}
              key={index}
            >
              {value}
            </MenuItem>
          ))}
        </Select> */}
        <div>Days in view</div>
      </div>
      {/**
       * drop-down to select the number of days worth info to be visible in the view port at a time
       * first option is dynamically filled and is the total window (latestEnd - earliestStart)
       * remaining options are constant and are enabled or disabled depending on whether they fall inside the window or not
       */}
      <div className="date-range-select-container">
        <Select
          id="dateRangeSelect"
          onChange={event => {
            dispatch(setDateRangeAction(parseFloat(event.target.value, 10)));
          }}
          IconComponent={props => <Arrow iconClass={props} />}
          disableUnderline={true}
          classes={selectStyleClasses}
          value={dateRangeSelection.rangeInDaysInSingleViewPort || 2}
        >
          {dateRangeSelectValues.map((value, index) => (
            <MenuItem
              className="daterange-select-option"
              value={value}
              disabled={dateRangeSelection.entireRangeInDays < value}
              key={index}
            >
              {value < 1
                ? `${value * 24}h`
                : value % 7 !== 0
                ? `${value}d`
                : `${value / 7}w`}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
