import { LIMIT_ON_THE_RUN_SOLVER_WINDOW } from "../constants/disruptions/disruptionSummary";
import { MILLISECONDS_IN_A_DAY } from "../constants/disruptions/timeline";

/**
 * returns the time in epoch milliseconds for the passed margin
 * @param {*} margin | position of the picker in view-width
 * @param {*} widthOfOneDay
 * @param {*} startOfGanttInEpochMillis
 */
export function getTimeInEpochMillisecondsForMargin(
  margin,
  widthOfOneDay,
  startOfGanttInEpochMillis
) {
  return (
    (margin * MILLISECONDS_IN_A_DAY) / widthOfOneDay +
    Number(startOfGanttInEpochMillis)
  );
}

/**
 * returns the percentage of 'value' wrt min and max
 * @param {number} value
 * @param {number} min
 * @param {number} max
 */
export function getPercentageUsingRangeProvided(value, min, max) {
  return ((value - min) / (max - min)) * 100;
}

/**
 * returns all the pairingIds of pairings which falls between rangeStart and rangeEnd
 * @param {*} rangeStart
 * @param {*} rangeEnd
 */
export function getPairingsBetweenRanges(rangeStart, rangeEnd, pairings) {
  const startDate = new Date(rangeStart);
  const endDate = new Date(rangeEnd);

  let allPairingIds = Object.keys(pairings);

  const returnArray = [];

  allPairingIds.forEach(pairingId => {
    let pairing = pairings[pairingId];
    let pairingStart = new Date(pairing.utcStartTime);
    let pairingEnd = new Date(pairing.utcRestStartTime);

    if (pairingStart < endDate && pairingEnd > startDate) {
      returnArray.push(pairingId);
    }
  });

  return returnArray;
}

/**
 * returns the position value for the rangepicker
 * @param {*} timeInEpochMillis
 * @param {*} widthOfOneDay
 * @param {*} startOfGanttInEpochMillis
 */
export function getPositionsForRangePickerHandles(
  timeInEpochMillis,
  widthOfOneDay,
  startOfGanttInEpochMillis
) {
  return (
    (timeInEpochMillis - Number(startOfGanttInEpochMillis)) *
    (widthOfOneDay / MILLISECONDS_IN_A_DAY)
  );
}

/**
 * current implementation revolves around the idea that
 * if the selected range is too far away(> LIMIT_ON_THE_RUN_SOLVER_WINDOW)
 * from the position of the picker start/end (depending on which end of the picker the selection is)
 * then move the start to the selected range
 * and set end to be  start time + LIMIT_ON_THE_RUN_SOLVER_WINDOW
 * @param {*} currentValue
 * @param {*} startValue
 * @param {*} endValue
 * @param {*} rangeSelectionForSolver
 * @param {*} selectedRangeDateTime
 * @returns [[pickerToMove, shouldMoveEndPickerWithStartPicker];
 */
export function getWhichPickerToMove(
  currentValue,
  startValue,
  endValue,
  rangeSelectionForSolver,
  selectedRangeDateTime
) {
  /**
   * epoch corresponding to range start
   */
  let rangeStartDateTime = rangeSelectionForSolver.startDateAndTime;

  /**
   * epoch corresponding to range end
   */
  let rangeEndDateTime = rangeSelectionForSolver.endDateAndTime;

  let pickerToMove = "start";

  let shouldMoveEndPickerWithStartPicker = false;

  if (startValue === endValue) {
    /**
     * start === end is usually the case when gantt is showing a date that is
     * far off from the current time(where the range picker would be)
     * here we move the start
     */

    pickerToMove = "start";
    shouldMoveEndPickerWithStartPicker = true;
  } else if (currentValue < startValue) {
    /**
     * Here, always move the start picker
     * move the end too if the difference between the start and end is large
     */

    if (
      new Date(rangeStartDateTime) - new Date(selectedRangeDateTime) >
      LIMIT_ON_THE_RUN_SOLVER_WINDOW * MILLISECONDS_IN_A_DAY
    ) {
      shouldMoveEndPickerWithStartPicker = true;
    }
  } else if (currentValue > endValue) {
    /**
     * Here, move start if the difference is too much else move right
     */

    if (
      new Date(selectedRangeDateTime) - new Date(rangeEndDateTime) >
      LIMIT_ON_THE_RUN_SOLVER_WINDOW * MILLISECONDS_IN_A_DAY
    ) {
      pickerToMove = "start";
      shouldMoveEndPickerWithStartPicker = true;
    } else {
      pickerToMove = "end";
    }
  } else {
    /**
     * Here, the selection is between start and end
     * In this case, move the picker that is closest to the point of selection
     */

    if (currentValue - startValue <= endValue - currentValue) {
      pickerToMove = "start";
    } else {
      pickerToMove = "end";
    }
  }

  return [pickerToMove, shouldMoveEndPickerWithStartPicker];
}
