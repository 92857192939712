import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import hash from "object-hash";
import { Add, Close } from "@material-ui/icons";
import {
  ClickAwayListener,
  MenuItem,
  Button,
  Select,
  TextField,
  Tooltip
} from "@material-ui/core";

import {
  LABEL_FOR_NEW_STRATEGY_TEXT_FIELD,
  DEFAULT_STRATEGY,
  ERROR_MESSAGES
} from "../../../../constants/disruptions/disruptionSummary";

import CustomCheckBox from "../../../partials/checkbox/CustomCheckBox";
import Arrow from "../../../partials/arrow/Arrow";
import LoadingState from "../../../partials/LoadingState/LoadingState";
import SimpleTooltipMessage from "../../../partials/simpletooltipmessages/SimpleTooltipMessage";
import ConfirmDialog from "../../../partials/ConfirmDialog/ConfirmDialog";

import { setIsStrategyDrawerVisibleAction } from "../../../../redux/showAndHideReducer";
import {
  setSelectedStrategyAction,
  setIsStrategySelectedAction,
  setFetchedStrategyDataToStoreAction,
  resetStrategyAction,
  setAllStrategyMetaDataAction
} from "../../../../redux/ruleAndStrategyManagerReducer";

import { isInArray } from "../../../../utils/arrayUtils";
import { getAccessTokenForUser } from "../../../../utils/authUtils";
import {
  processStrategy,
  flattenStrategy,
  performApiCallToGetAllMetaData,
  getAllNamesFromMetaData
} from "../../../../utils/disruptionSummaryUtils";

import { LIST_STRATEGIES, BASE_ENDPOINT } from "../../../../constants/api";
import { USER_ROLES } from "../../../../constants/auth/signUp";

export default function StrategyManager() {
  const dispatch = useDispatch();

  /**
   * redux subscription
   */
  const selectedStrategy = useSelector(
    store => store.ruleAndStrategy.selectedStrategy
  );

  const isStrategyManagerDrawerVisible = useSelector(
    store => store.showAndHide.isStrategyManagerDrawerVisible
  );
  const currentWorkingStrategy = useSelector(
    store => store.ruleAndStrategy.currentWorkingStrategy
  );

  const fetchedStrategy = useSelector(
    store => store.ruleAndStrategy.fetchedStrategy
  );

  const allStrategyMetaData = useSelector(
    store => store.ruleAndStrategy.allStrategyMetaData
  );

  const userGroups = useSelector(store => store.userInfo.userGroups);

  /**
   * component states
   */
  const [
    isAddNewStrategyScreenVisible,
    setIsAddNewStrategyScreenVisible
  ] = React.useState(false);

  const [isStrategyFetched, setIsStrategyFetched] = React.useState(false);
  const [newStrategyName, setNewStrategyName] = React.useState({
    name: "",
    errorText: "",
    error: false
  });

  //if user has "Strategy" group in his usergroups then give him permission to edit strategies
  const [hasPermissionToEdit] = React.useState(
    isInArray(USER_ROLES.Strategy, userGroups)
  );

  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = React.useState(
    false
  );

  const [notification, setNotification] = React.useState({
    shouldNotify: false,
    message: ""
  });

  /**
   * react callbacks
   */
  const dispatchChangeInCheckboxState = React.useCallback(
    (checked, index) => {
      setNotification({ shouldNotify: false, message: "" });
      dispatch(
        setIsStrategySelectedAction({
          name: Object.keys(currentWorkingStrategy.strategies)[index],
          isSelected: checked
        })
      );
    },
    [dispatch, currentWorkingStrategy.strategies]
  );

  const isDirty = React.useCallback(() => {
    let isThereChange = false;

    /**
     * prevents the isDirty when user closes the strategy manager drawer before the strategy data is fetched
     */
    if (Object.keys(currentWorkingStrategy).length === 0) return isThereChange;

    const currentStrategy = [
      ...flattenStrategy(currentWorkingStrategy.strategies)
    ];

    if (
      hash(currentStrategy, { unorderedArrays: true }) !==
      hash(fetchedStrategy.strategies, { unorderedArrays: true })
    )
      isThereChange = true;
    return isThereChange;
  }, [fetchedStrategy.strategies, currentWorkingStrategy]);

  const fetchStrategyDataForId = React.useCallback(
    async id => {
      setIsStrategyFetched(false);

      const token = await getAccessTokenForUser();
      const url = BASE_ENDPOINT + LIST_STRATEGIES + `/${id}`;
      const headers = {
        Authorization: token
      };
      const response = await Axios.get(url, {
        headers: headers
      });

      if (response.data !== undefined) {
        let strategyData = processStrategy(response.data);
        dispatch(
          setFetchedStrategyDataToStoreAction({
            current: strategyData,
            fetched: response.data
          })
        );
        setIsStrategyFetched(true);
      }
    },
    [dispatch]
  );

  const closeStrategyDrawer = React.useCallback(() => {
    dispatch(setIsStrategyDrawerVisibleAction(false));
  }, [dispatch]);

  const closeConfirmDialog = React.useCallback(() => {
    setIsConfirmDialogVisible(false);
  }, []);

  const handleClickAwayEvent = React.useCallback(() => {
    if (isDirty()) setIsConfirmDialogVisible(true);
    else dispatch(setIsStrategyDrawerVisibleAction(false));
  }, [isDirty, dispatch]);

  const handleOnInputForNewStrategyNameTextField = React.useCallback(event => {
    setNewStrategyName({
      error: false,
      name: event.target.value,
      errorText: ""
    });
  }, []);

  const handleOnChangeForStrategySelect = React.useCallback(
    event => {
      dispatch(setSelectedStrategyAction(event.target.value));
    },
    [dispatch]
  );

  const getDefaultStrategy = React.useCallback(() => {
    let defaultStrategy;
    allStrategyMetaData.forEach(strategy => {
      if (strategy.strategyName === "Default Strategy") {
        defaultStrategy = strategy;
      }
    });
    return defaultStrategy;
  }, [allStrategyMetaData]);

  const handleOnClickForAddButton = React.useCallback(() => {
    setNotification({ shouldNotify: false, message: "" });
    setIsAddNewStrategyScreenVisible(true);
    dispatch(setSelectedStrategyAction(getDefaultStrategy()));
  }, [dispatch, getDefaultStrategy]);

  const handleOnClickForCancelButton = React.useCallback(() => {
    setNotification({ shouldNotify: false, message: "" });
    if (isAddNewStrategyScreenVisible) setIsAddNewStrategyScreenVisible(false);
    dispatch(resetStrategyAction());
  }, [dispatch, isAddNewStrategyScreenVisible]);

  const publishStrategyData = React.useCallback(async (newStrategy, id) => {
    const token = await getAccessTokenForUser();
    const headers = {
      Authorization: token,
      "Content-Type": "Application/json"
    };

    if (id) {
      let url = BASE_ENDPOINT + LIST_STRATEGIES;
      return new Promise((resolve, reject) => {
        Axios.patch(url, newStrategy, { headers: headers })
          .then(value => resolve(value))
          .catch(reason => reject(reason));
      });
    } else {
      let url = BASE_ENDPOINT + LIST_STRATEGIES;
      return new Promise((resolve, reject) => {
        Axios.post(url, newStrategy, { headers: headers })
          .then(value => resolve(value))
          .catch(reason => reject(reason));
      });
    }
  }, []);

  const handleOnClickForSubmitButton = React.useCallback(() => {
    if (isAddNewStrategyScreenVisible) {
      if (newStrategyName.name.length === 0) {
        setNewStrategyName({
          name: "",
          error: true,
          errorText: ERROR_MESSAGES.ZERO_LENGTH
        });
      } else if (
        isInArray(
          newStrategyName.name,
          getAllNamesFromMetaData(allStrategyMetaData, "strategyName")
        )
      ) {
        setNewStrategyName({
          ...newStrategyName,
          error: true,
          errorText: ERROR_MESSAGES.ALREADY_IN_USE
        });
      } else {
        let newStrategy = {
          strategyName: newStrategyName.name,
          strategies: [...flattenStrategy(currentWorkingStrategy.strategies)]
        };

        setIsStrategyFetched(false);

        publishStrategyData(newStrategy)
          .then(() => {
            performApiCallToGetAllMetaData(LIST_STRATEGIES).then(value => {
              dispatch(setAllStrategyMetaDataAction(value));

              if (value !== null) {
                let currentStrategy;
                value.forEach(strategy => {
                  if (strategy.strategyName === newStrategyName.name)
                    currentStrategy = strategy;
                });

                dispatch(setSelectedStrategyAction(currentStrategy));
                setIsAddNewStrategyScreenVisible(false);
              }
            });
          })
          .catch(reason => {
            setIsStrategyFetched(true);
            setNotification({
              shouldNotify: true,
              message: "Strategy was not added."
            });
          });
      }
    } else {
      let newStrategy = {
        id: selectedStrategy.id,
        strategyName: currentWorkingStrategy.name,
        strategies: [...flattenStrategy(currentWorkingStrategy.strategies)]
      };

      publishStrategyData(newStrategy)
        .then(value => {
          setNotification({
            shouldNotify: true,
            message: "Strategy successfully updated."
          });
          //remove the message after 5 seconds
          setTimeout(() => {
            setNotification({
              shouldNotify: false,
              message: ""
            });
          }, 5000);

          performApiCallToGetAllMetaData(LIST_STRATEGIES).then(value => {
            dispatch(setAllStrategyMetaDataAction(value));

            if (value !== null) {
              let currentStrategy;
              value.forEach(strategy => {
                if (strategy.strategyName === currentWorkingStrategy.name)
                  currentStrategy = strategy;
              });

              dispatch(setSelectedStrategyAction(currentStrategy));
              setIsAddNewStrategyScreenVisible(false);
            }
          });
        })
        .catch(reason => {
          setIsStrategyFetched(true);
          setNotification({
            shouldNotify: true,
            message: "Strategy was not updated."
          });
        });
    }
  }, [
    allStrategyMetaData,
    currentWorkingStrategy,
    dispatch,
    isAddNewStrategyScreenVisible,
    newStrategyName,
    publishStrategyData,
    selectedStrategy.id
  ]);

  const handleOnClickForCloseButton = React.useCallback(() => {
    dispatch(setIsStrategyDrawerVisibleAction(false));
  }, [dispatch]);

  /**
   * side effects
   */
  React.useEffect(() => {
    if (selectedStrategy.id) {
      fetchStrategyDataForId(selectedStrategy.id);
    }
    setNotification({ shouldNotify: false, message: "" });
  }, [
    isStrategyManagerDrawerVisible,
    selectedStrategy,
    fetchStrategyDataForId
  ]);

  return (
    <div>
      <ClickAwayListener onClickAway={handleClickAwayEvent}>
        <div className="strategy-manager-container">
          <>
            <div className="header">
              <div className="header-text">Strategy Manager</div>
              <div className="close">
                <Button onClick={handleOnClickForCloseButton}>
                  <Close color="primary" fontSize={"small"} />
                </Button>
              </div>
            </div>
          </>
          <>
            <div className="text-field-select-menu-container">
              {isAddNewStrategyScreenVisible ? (
                <div>
                  <div className="field">
                    <TextField
                      name="rule-name"
                      id="rule-name"
                      variant="outlined"
                      fullWidth
                      label={LABEL_FOR_NEW_STRATEGY_TEXT_FIELD}
                      required
                      onInput={handleOnInputForNewStrategyNameTextField}
                      error={newStrategyName.error}
                      helperText={newStrategyName.errorText}
                      placeholder={DEFAULT_STRATEGY}
                      focused={true}
                    />
                  </div>
                </div>
              ) : (
                <div className="select-menu">
                  <Select
                    id="showHide"
                    onChange={handleOnChangeForStrategySelect}
                    IconComponent={props => <Arrow iconClass={props} />}
                    disableUnderline={true}
                    value={selectedStrategy}
                    renderValue={value => value.strategyName}
                    MenuProps={{ disablePortal: true }}
                  >
                    {allStrategyMetaData.map((value, index) => (
                      <MenuItem
                        className="showhide-select-option"
                        value={value}
                        key={index}
                      >
                        {value.strategyName}
                      </MenuItem>
                    ))}
                  </Select>
                  <Tooltip
                    title={
                      !hasPermissionToEdit ? ( //show a tooltip to user if he has no permission to create new ruleset
                        <SimpleTooltipMessage
                          className="no-permission-info-text"
                          tooltipMessage={
                            "You do not have sufficient permission to create a new strategy."
                          }
                        />
                      ) : (
                        ""
                      )
                    }
                    arrow
                    placement={"left"}
                  >
                    <div className="add">
                      <Button
                        onClick={handleOnClickForAddButton}
                        disabled={!hasPermissionToEdit} //disable if user does not have permission to edit
                      >
                        <Add color="primary" fontSize={"small"} />
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </>
          <>
            <div className="body">
              {isStrategyFetched ? (
                <div>
                  {Object.keys(currentWorkingStrategy.strategies).map(
                    (strategyName, index) => (
                      <div key={index} className="strategy">
                        <CustomCheckBox
                          stateValue={
                            currentWorkingStrategy.strategies[strategyName]
                              .enabled
                          }
                          disabled={!hasPermissionToEdit}
                          stateChangeHandler={dispatchChangeInCheckboxState}
                          index={index}
                        />
                        <div>
                          {
                            currentWorkingStrategy.strategies[strategyName]
                              .strategyDisplayName
                          }
                        </div>
                      </div>
                    )
                  )}
                  {hasPermissionToEdit && (
                    <div className="cancel-submit-buttons">
                      <Button
                        id="cancel-button"
                        onClick={handleOnClickForCancelButton}
                      >
                        {!isAddNewStrategyScreenVisible ? "Reset" : "Cancel"}
                      </Button>
                      <Button
                        id="submit-button"
                        onClick={handleOnClickForSubmitButton}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                  {notification.shouldNotify ? (
                    <div className="notification">{notification.message}</div>
                  ) : null}
                </div>
              ) : (
                <LoadingState loadingMessage={"Loading strategy data..."} />
              )}
            </div>
          </>
        </div>
      </ClickAwayListener>
      <div>
        {isConfirmDialogVisible && (
          <ConfirmDialog
            title={"Confirm"}
            question={"Do you want to exit? Any unsaved changes will be lost!"}
            confirmHandler={closeStrategyDrawer}
            cancelHandler={closeConfirmDialog}
            open={isConfirmDialogVisible}
          />
        )}
      </div>
    </div>
  );
}
