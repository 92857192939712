export const LANDING = "/";
export const AUTH = "/auth";
export const SIGN_IN = "/auth/signin";
export const SIGN_IN_NEW_PASSWORD = "/auth/signin/new-password";
export const SIGN_UP = "/auth/register";
export const HOME = "/home";
export const ACCOUNT = "/auth/account";
export const ADMIN = "/auth/admin";
export const PASSWORD_FORGOT = "/auth/forgot-password";
export const ADMIN_APPROVE_USER = "/auth/approve-user";
export const UPLOAD = "/upload";
export const REPORTS = "/reports";
export const DEADHEAD = "/deadhead";
export const SOLVER_SCENARIO = "/solver-scenario";
export const VIEW_SOLVER_SCENARIO = `${SOLVER_SCENARIO}/:scenarioId`;
export const CUMULATIVE_TIME = "/cumulative-time";