import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconButton } from "@material-ui/core";
import { ChatBubble } from "@material-ui/icons";

import { CREW_GANTT_BORDER } from "../../../constants/disruptions/color";

import { getRuleNameToDisplay } from "../../../utils/pairingUtils";
import { setSelectedViolationAction } from "../../../redux/violationDrillDownReducer";

export default function ViolationMessage({
  reduxKey,
  selectedViolation,
  displayCommentIcon,
  formatMessage,
  drawUnderline
}) {
  const dispatch = useDispatch();

  /**
   * reduxKey can be of 2 types - depending on the depth of the string
   *  1) "entityViolations/duty/352116138/MAX_DUTY_CABIN_SCHEDULED-IN_FLIGHT-FAR" or "timeViolations/duty/352114623-48507979/MIN_SIT_TIME-IN_FLIGHT-CONTRACTUAL"
   *  2) "timeViolations/crew/crewDutyViolation/15950-352119610/MAX_FDP_LENGTH-FLIGHT_OPS-FAR"
   */
  const [arrayOfReduxKeys] = React.useState(reduxKey.split("/"));

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const violatingData = useSelector(store => {
    if (
      store &&
      store.crewSchedules &&
      store.crewSchedules.processedViolations
    ) {
      const processedViolations = store.crewSchedules.processedViolations;

      if (
        Array.isArray(arrayOfReduxKeys) &&
        arrayOfReduxKeys.includes("crew")
      ) {
        return processedViolations[arrayOfReduxKeys[0]][arrayOfReduxKeys[1]][
          arrayOfReduxKeys[2]
        ][arrayOfReduxKeys[3]][arrayOfReduxKeys[4]];
      } else {
        return processedViolations[arrayOfReduxKeys[0]][arrayOfReduxKeys[1]][
          arrayOfReduxKeys[2]
        ][arrayOfReduxKeys[3]];
      }
    }
    return null;
  });

  const openCommentSection = React.useCallback(
    violationMetadata => {
      setTimeout(() => {
        dispatch(setSelectedViolationAction(violationMetadata));
      });
    },
    [dispatch]
  );

  const getMessage = React.useCallback(
    message => (formatMessage ? String(message).split("-")[0] : message),
    [formatMessage]
  );

  const CommentCount = React.useCallback(
    ({ count }) => (
      <div className="comment-bubble-with-count">
        <ChatBubble />
        <div className="comment-count">
          {/** do not show comment count in case of solver output screen */}
          {isShowingASolverRun ? null : count}
        </div>
      </div>
    ),
    [isShowingASolverRun]
  );

  const styles = {
    borderBottom: drawUnderline ? `1px solid ${CREW_GANTT_BORDER}` : "unset",
    background:
      selectedViolation.violationId === violatingData.ruleIdentity.violationId
        ? "rgba(0,0,0,0.12)"
        : "unset"
  };

  return (
    <IconButton
      onClick={() =>
        openCommentSection(
          violatingData.ruleIdentity.violationId
            ? {
                violationId: violatingData.ruleIdentity.violationId,
                violationType: violatingData.violator ? "ENTITY" : "TIME",
                reduxKey: reduxKey
              }
            : {
                violationId: "NO_ID",
                violationType: "NO_TYPE",
                reduxKey: reduxKey
              }
        )
      }
    >
      <div className="violation-message" style={styles}>
        <div>
          {getMessage(
            `${getRuleNameToDisplay(violatingData)}-${violatingData.message}`
          )}
        </div>
        {displayCommentIcon && (
          <CommentCount count={violatingData.ruleIdentity.commentCount} />
        )}
      </div>
    </IconButton>
  );
}
