import Axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { BASE_ENDPOINT, ERROR_REPORTER } from "../../constants/api";
import { getAccessTokenForUser } from "../../utils/authUtils";

import "./ErrorBoundary.scss";

/**
 * As of React version 16.10, there are no React Hooks equivalent of
 * 'componentDidCatch' lifecycle method. Hence using a class component here.
 */

// TODO: Localize text of this component

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
      userInfo: null
    };
  }
  async componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });

    const payload = {
      userInfo: { ...this.props.userInfo },
      error: error && error.stack,
      stackTrace:
        errorInfo.componentStack && errorInfo.componentStack.toString(),
      context: {
        path: window.location.href,
        time: new Date()
      }
    };

    const token = await getAccessTokenForUser();
    const url = BASE_ENDPOINT + ERROR_REPORTER;
    const headers = {
      Authorization: token
    };

    Axios.post(url, payload, {
      headers: headers
    })
      .then(() => {
        console.info("Error message logged successfully!");
      })
      .catch(err => console.error(err));
  }

  render() {
    // const { error, errorInfo } = this.state;

    // if (errorInfo) {
    //   const errorDetails =
    //     process.env.NODE_ENV === "development" ? (
    //       <details className="error-boundary-preserve-space">
    //         {error && error.toString()}
    //         <br />
    //         {errorInfo.componentStack}
    //       </details>
    //     ) : undefined;
    //   return (
    //     <div>
    //       <h2 className="error-boundary-error">
    //         An unexpected error has occurred.
    //       </h2>
    //       {errorDetails}
    //     </div>
    //   );
    // }

    return this.props.children;
  }
}

const mapStateToProps = store => ({
  userInfo: store.userInfo
});

export default connect(mapStateToProps)(ErrorBoundary);
