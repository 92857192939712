import {
  VIEWWIDTH_COVERED_BY_GANTT_TIMELINE,
  DEFAULT_NUMBER_OF_DAYS_TO_BE_SHOWN_IN_ONE_VIEW,
  DEFAULT_NUMBER_OF_DAYS,
  MILLISECONDS_IN_AN_HOUR
} from "../constants/disruptions/timeline";
import {
  NUMBER_OF_CREW_PER_PAGE,
  NUMBER_OF_PREVIOUS_DAYS_TO_FETCH
} from "../constants/disruptions/crewGanttViewer";
import { getIsoDateString, getPreviousDate } from "../utils/allGanttRowsUtils";
import { TO_DATE_OFFSET_FOR_DATE_RANGE_FILTER } from "../constants/disruptions/filter";
import { MAP_FROM_FILTER_TYPE_TO_REDUX_ATTRIBUTE } from "../constants/redux";
import { getRangePickerStartAndEnd } from "../utils/searchCriteriaReducerUtils";
import { SELECT_ALL_DESELECT_ALL_VALUES_MAP } from "../constants/disruptions/durationSelect";

const ACTIONS = {
  setAirlineSelected: "SET_AIRLINE_SELECTED",
  setDateRange: "SET_DATE_RANGE",
  setTimeWindowFromAllPairings: "SET_TIME_WINDOW_FROM_ALL_PAIRINGS",
  setStartDateAndTimeForSolver: "SET_START_DATE_AND_TIME_FOR_SOLVER",
  setEndDateAndTimeForSolver: "SET_END_DATE_AND_TIME_FOR_SOLVER",
  setShowSolverInputRows: "SET_SHOW_SOLVER_INPUT_ROWS",
  setIsShowingASolverRun: "SET_IS_SHOWING_A_SOLVER_RUN",
  setGetSchedulesParametersPage: "SET_GET_SCHEDULES_PARAMETERS_PAGE",
  setFlightOpsFilter: "SET_FLIGHT_OPS_FILTER",
  setInFlightFilter: "SET_IN_FLIGHT_FILTER",
  setFilterDataFromBackend: "SET_FILTER_DATA_FROM_BACKEND",
  setSelectedFilterFromBackend: "SET_SELECTED_FILTER_FROM_BACKEND",
  setSelectedFilter: "SET_SELECTED_FILTER",
  resetSelectedFilter: "RESET_SELECTED_FILTER",
  setScheduleDataGrid: "SET_SCHEDULE_DATA_GRID",
  setCurrentPosition: "SET_CURRENT_POSITION",
  setExcludedCrewMemberForSolver: "SET_EXCLUDED_CREW_MEMBER_FOR_SOLVER",
  setIsFilterBeingApplied: "SET_IS_FILTER_BEING_APPLIED",
  resetDateRange: "RESET_DATE_RANGE",
  resetSelectedFilterForSolverOutput: "RESET_SELECTED_FILTER_FOR_SOLVER_OUTPUT",
  restoreDateRangeOnReturnToCurrentSchedule:
    "RESTORE_DATE_RANGE_ON_RETURN_TO_CURRENT_SCHEDULE",
  setCountInfo: "SET_COUNT_INFO",
  setIncludedCrewMembersForSolver: "SET_INCLUDED_CREW_MEMBERS_FOR_SOLVER",
  setSelectAllOrDeselectAllState: "SET_SELECT_ALL_OR_DESELECT_ALL_STATE"
};

const initialState = {
  airlineSelected: "",
  dateRange: {
    startDate: getPreviousDate(NUMBER_OF_PREVIOUS_DAYS_TO_FETCH),
    rangeInDaysInSingleViewPort: DEFAULT_NUMBER_OF_DAYS_TO_BE_SHOWN_IN_ONE_VIEW,
    previousRangeInDaysInSingleViewPort:
      DEFAULT_NUMBER_OF_DAYS_TO_BE_SHOWN_IN_ONE_VIEW,
    widthOfOneDay:
      VIEWWIDTH_COVERED_BY_GANTT_TIMELINE /
      DEFAULT_NUMBER_OF_DAYS_TO_BE_SHOWN_IN_ONE_VIEW,
    endDate: new Date(),
    entireRangeInDays: DEFAULT_NUMBER_OF_DAYS
  },
  rangeSelectionForSolver: {
    startDateAndTime: new Date(
      Number(new Date()) + 8 * MILLISECONDS_IN_AN_HOUR
    ),
    endDateAndTime: new Date(Number(new Date()) + 16 * MILLISECONDS_IN_AN_HOUR)
  },
  showSolverInputRows: true,
  isShowingASolverRun: false,
  getSchedulesParameters: {
    limit: NUMBER_OF_CREW_PER_PAGE,
    page: 0,
    startTime: getIsoDateString(),
    endTime: getIsoDateString(TO_DATE_OFFSET_FOR_DATE_RANGE_FILTER),
    scheduleDataGrid: [[]],
    currentPosition: {
      x: 0,
      y: 0
    },
    yPositionMap: []
  },
  flightOpsInFlightFilter: {
    flightOps: true,
    inFlight: true
  },
  filterData: { Airline: {} },
  selectedFilter: {
    date: {
      from: null,
      to: null
    },
    rank: [],
    pairings: [],
    disruptions: [],
    airport: [],
    base: [],
    airline: [],
    crewName: "",
    flightNumber: "",
    violation: [],
    legal: [],
    showWarnings: true
  },
  selectedFilterForSolverOutput: {
    date: {
      from: new Date(),
      to: new Date()
    },
    rank: [],
    pairings: [],
    disruptions: [],
    airport: [],
    base: [],
    airline: [],
    crewName: "",
    flightNumber: ""
  },
  excludedCrewsForSolver: [],
  isFilterBeingApplied: false,
  countInfo: {},
  includedCrewsForSolver: [],
  selectAllOrDeselectAllState: SELECT_ALL_DESELECT_ALL_VALUES_MAP.DESELECT_ALL
};

export function searchCriteriaReducer(prevState = initialState, action) {
  let newState;
  switch (action.type) {
    case "SET_DATE_RANGE":
      newState = {
        ...prevState,
        dateRange: {
          ...prevState.dateRange,
          rangeInDaysInSingleViewPort: action.rangeInDaysInSingleViewPort,
          previousRangeInDaysInSingleViewPort:
            prevState.dateRange.rangeInDaysInSingleViewPort,
          widthOfOneDay:
            VIEWWIDTH_COVERED_BY_GANTT_TIMELINE /
            action.rangeInDaysInSingleViewPort
        }
      };
      break;
    case "SET_TIME_WINDOW_FROM_ALL_PAIRINGS":
      const [rangePickerStart, rangePickerEnd] = getRangePickerStartAndEnd(
        action,
        prevState
      );

      newState = {
        ...prevState,
        dateRange: {
          ...prevState.dateRange,
          startDate: action.timeWindowFromAllPairings.earliestStartDate,
          endDate: action.timeWindowFromAllPairings.latestEndDate,
          entireRangeInDays: action.timeWindowFromAllPairings.totalWindowInDays
        },
        rangeSelectionForSolver: {
          startDateAndTime: rangePickerStart,
          endDateAndTime: rangePickerEnd
        },
        selectedFilterForSolverOutput: {
          ...prevState.selectedFilterForSolverOutput,
          date: {
            from: action.timeWindowFromAllPairings.earliestStartDate
              ? action.timeWindowFromAllPairings.earliestStartDate
              : new Date(),
            to: action.timeWindowFromAllPairings.latestEndDate
              ? action.timeWindowFromAllPairings.latestEndDate
              : new Date()
          }
        }
      };
      break;
    case "SET_START_DATE_AND_TIME_FOR_SOLVER":
      newState = {
        ...prevState,
        rangeSelectionForSolver: {
          ...prevState.rangeSelectionForSolver,
          startDateAndTime: action.startDateAndTimeForSolver
        }
      };
      break;
    case "SET_END_DATE_AND_TIME_FOR_SOLVER":
      newState = {
        ...prevState,
        rangeSelectionForSolver: {
          ...prevState.rangeSelectionForSolver,
          endDateAndTime: action.endDateAndTimeForSolver
        }
      };
      break;
    case "SET_IS_SHOWING_A_SOLVER_RUN":
      newState = {
        ...prevState,
        isShowingASolverRun: action.isShowingASolverRun
      };
      break;
    case "SET_SHOW_SOLVER_INPUT_ROWS":
      newState = {
        ...prevState,
        showSolverInputRows: action.showSolverInputRows
      };
      break;
    case "SET_GET_SCHEDULES_PARAMETERS_PAGE":
      newState = {
        ...prevState,
        getSchedulesParameters: {
          ...prevState.getSchedulesParameters,
          page: action.page
        }
      };
      break;
    case "SET_FLIGHT_OPS_FILTER":
      newState = {
        ...prevState,
        flightOpsInFlightFilter: {
          ...prevState.flightOpsInFlightFilter,
          flightOps:
            prevState.flightOpsInFlightFilter.inFlight || action.flightOpsFilter
              ? action.flightOpsFilter
              : true
        }
      };
      break;
    case "SET_IN_FLIGHT_FILTER":
      newState = {
        ...prevState,
        flightOpsInFlightFilter: {
          ...prevState.flightOpsInFlightFilter,
          inFlight:
            prevState.flightOpsInFlightFilter.flightOps || action.inFlightFilter
              ? action.inFlightFilter
              : true
        }
      };
      break;
    case "SET_FILTER_DATA_FROM_BACKEND":
      newState = {
        ...prevState,
        filterData: action.filterData
      };
      break;
    case "SET_SELECTED_FILTER_FROM_BACKEND":
      newState = {
        ...prevState,
        selectedFilter: action.selectedFilter
      };
      break;
    case "SET_SELECTED_FILTER":
      newState = {
        ...prevState,
        [action.selectedFilterData.isCurrentSchedule
          ? MAP_FROM_FILTER_TYPE_TO_REDUX_ATTRIBUTE.CURRENT_SCHEDULE
          : MAP_FROM_FILTER_TYPE_TO_REDUX_ATTRIBUTE.SOLVER_OUTPUT]: {
          ...prevState[
            action.selectedFilterData.isCurrentSchedule
              ? MAP_FROM_FILTER_TYPE_TO_REDUX_ATTRIBUTE.CURRENT_SCHEDULE
              : MAP_FROM_FILTER_TYPE_TO_REDUX_ATTRIBUTE.SOLVER_OUTPUT
          ],
          [action.selectedFilterData.filterName]:
            action.selectedFilterData.selectedFilter
        }
      };
      break;

    case "RESET_SELECTED_FILTER":
      let newFilterState = {};

      if (action.resetParams.resetDateFilterOnly) {
        newFilterState = {
          ...prevState.selectedFilter,
          date: {
            from: action.resetParams.fromDate,
            to: action.resetParams.toDate
          }
        };
      } else {
        newFilterState = {
          date: {
            from: action.resetParams.fromDate,
            to: action.resetParams.toDate
          },
          rank: [],
          pairings: [],
          disruptions: [],
          airport: [],
          base: [],
          airline: [],
          crewName: "",
          flightNumber: "",
          violation: [],
          legal: [],
          showWarnings: prevState.selectedFilter.showWarnings
        };
      }

      newState = {
        ...prevState,
        selectedFilter: {
          ...newFilterState
        },
        filterData: {
          ...prevState.filterData,
          Date: {
            ...prevState.Date,
            values: {
              from: action.resetParams.fromDate,
              to: action.resetParams.toDate
            }
          }
        }
      };
      break;
    case "SET_END_TIME":
      newState = {
        ...prevState,
        getSchedulesParameters: {
          ...prevState.getSchedulesParameters,
          endTime: action.endTime
        }
      };
      break;

    case "SET_SCHEDULE_DATA_GRID":
      newState = {
        ...prevState,
        getSchedulesParameters: {
          ...prevState.getSchedulesParameters,
          scheduleDataGrid: action.scheduleDataGrid.scheduleDataGrid,
          yPositionMap: action.scheduleDataGrid.yPositionMap
        }
      };
      break;
    case "SET_CURRENT_POSITION":
      newState = {
        ...prevState,
        getSchedulesParameters: {
          ...prevState.getSchedulesParameters,
          currentPosition: action.currentPosition
        }
      };
      break;
    case "SET_EXCLUDED_CREW_MEMBER_FOR_SOLVER":
      newState = {
        ...prevState,
        excludedCrewsForSolver: action.excludedCrewMembers
      };
      break;
    case "SET_IS_FILTER_BEING_APPLIED":
      newState = {
        ...prevState,
        isFilterBeingApplied: action.isFilterBeingApplied
      };
      break;
    case "RESET_DATE_RANGE":
      newState = {
        ...prevState,
        dateRange: {
          startDate: getPreviousDate(NUMBER_OF_PREVIOUS_DAYS_TO_FETCH),
          rangeInDaysInSingleViewPort:
            DEFAULT_NUMBER_OF_DAYS_TO_BE_SHOWN_IN_ONE_VIEW,
          previousRangeInDaysInSingleViewPort:
            DEFAULT_NUMBER_OF_DAYS_TO_BE_SHOWN_IN_ONE_VIEW,
          widthOfOneDay:
            VIEWWIDTH_COVERED_BY_GANTT_TIMELINE /
            DEFAULT_NUMBER_OF_DAYS_TO_BE_SHOWN_IN_ONE_VIEW,
          endDate: new Date(),
          entireRangeInDays: DEFAULT_NUMBER_OF_DAYS
        },
        rangeSelectionForSolver: {
          startDateAndTime: new Date(
            Number(new Date()) + 8 * MILLISECONDS_IN_AN_HOUR
          ),
          endDateAndTime: new Date(
            Number(new Date()) + 16 * MILLISECONDS_IN_AN_HOUR
          )
        }
      };
      break;

    case "RESET_SELECTED_FILTER_FOR_SOLVER_OUTPUT":
      newState = {
        ...prevState,
        selectedFilterForSolverOutput: {
          date: {
            from: prevState.dateRange.startDate,
            to: prevState.dateRange.endDate
          },
          rank: [],
          pairings: [],
          disruptions: [],
          airport: [],
          base: [],
          airline: [],
          crewName: "",
          flightNumber: ""
        }
      };
      break;

    case "RESTORE_DATE_RANGE_ON_RETURN_TO_CURRENT_SCHEDULE":
      newState = {
        ...prevState,
        dateRange: { ...action.dateRangeSelection }
      };
      break;
    case "SET_COUNT_INFO":
      newState = {
        ...prevState,
        countInfo: {
          ...action.countInfo
        }
      };
      break;
    case "SET_INCLUDED_CREW_MEMBERS_FOR_SOLVER":
      newState = {
        ...prevState,
        includedCrewsForSolver: action.includedCrewsForSolver
      };
      break;

    case "SET_SELECT_ALL_OR_DESELECT_ALL_STATE":
      newState = {
        ...prevState,
        selectAllOrDeselectAllState: action.selectAllOrDeselectAllState
      };
      break;

    default:
      newState = prevState;
      break;
  }
  return newState;
}

export function setDateRangeAction(rangeInDaysInSingleViewPort) {
  return { type: ACTIONS.setDateRange, rangeInDaysInSingleViewPort };
}

export function setTimeWindowFromAllPairings(timeWindowFromAllPairings) {
  return {
    type: ACTIONS.setTimeWindowFromAllPairings,
    timeWindowFromAllPairings
  };
}

export function setStartDateForSolver(startDateAndTimeForSolver) {
  return {
    type: ACTIONS.setStartDateAndTimeForSolver,
    startDateAndTimeForSolver
  };
}

export function setEndDateForSolver(endDateAndTimeForSolver) {
  return { type: ACTIONS.setEndDateAndTimeForSolver, endDateAndTimeForSolver };
}

export function setShowSolverInputRowsAction(showSolverInputRows) {
  return { type: ACTIONS.setShowSolverInputRows, showSolverInputRows };
}

export function setIsShowingASolverRunAction(isShowingASolverRun) {
  return { type: ACTIONS.setIsShowingASolverRun, isShowingASolverRun };
}

export function setGetSchedulesParametersPageAction(page) {
  return { type: ACTIONS.setGetSchedulesParametersPage, page };
}

export function setFlightOpsFilterAction(flightOpsFilter) {
  return { type: ACTIONS.setFlightOpsFilter, flightOpsFilter };
}

export function setInFlightFilterAction(inFlightFilter) {
  return { type: ACTIONS.setInFlightFilter, inFlightFilter };
}

export function setFilterDataFromBackendAction(filterData) {
  return { type: ACTIONS.setFilterDataFromBackend, filterData };
}

export function setSelectedFilterFromBackendAction(selectedFilter) {
  return { type: ACTIONS.setSelectedFilterFromBackend, selectedFilter };
}

export function setSelectedFilterAction(selectedFilterData) {
  return { type: ACTIONS.setSelectedFilter, selectedFilterData };
}

export function resetSelectedFilterAction(resetParams) {
  return { type: ACTIONS.resetSelectedFilter, resetParams };
}

export function setScheduleDataGridAction(scheduleDataGrid) {
  return { type: ACTIONS.setScheduleDataGrid, scheduleDataGrid };
}

export function setExcludedCrewMemberForSolverAction(excludedCrewMembers) {
  return { type: ACTIONS.setExcludedCrewMemberForSolver, excludedCrewMembers };
}

export function setIsFilterBeingAppliedAction(isFilterBeingApplied) {
  return { type: ACTIONS.setIsFilterBeingApplied, isFilterBeingApplied };
}

export function resetDateRangeAction() {
  return { type: ACTIONS.resetDateRange };
}

export function resetSelectedFilterForSolverAction() {
  return { type: ACTIONS.resetSelectedFilterForSolverOutput };
}

export function restoreDateRangeOnReturnToCurrentScheduleAction(
  dateRangeSelection
) {
  return {
    type: ACTIONS.restoreDateRangeOnReturnToCurrentSchedule,
    dateRangeSelection
  };
}

export function setCountInfoAction(countInfo) {
  return {
    type: ACTIONS.setCountInfo,
    countInfo
  };
}

export function setIncludedCrewMemberForSolverAction(includedCrewsForSolver) {
  return {
    type: ACTIONS.setIncludedCrewMembersForSolver,
    includedCrewsForSolver
  };
}

export function setSelectAllOrDeselectAllStateAction(
  selectAllOrDeselectAllState
) {
  return {
    type: ACTIONS.setSelectAllOrDeselectAllState,
    selectAllOrDeselectAllState
  };
}
