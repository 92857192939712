import { GET_OPEN_FLYING_DATA_RESOURCE_NAME } from "../constants/api";
import { LOCAL_TIME_ZONE_ATTRIBUTE_KEY } from "../constants/disruptions/disruptionSummary";
import { OPEN_FLYING_DATA_STATUS } from "../constants/disruptions/openTime";
import {
  setCrewSchedulesAction,
  setOpenFlyingDataStatusAction,
  setOpenTimeAction
} from "../redux/crewSchedulesReducer";
import { setIntermediateDataForOpenFlyingAction } from "../redux/websocketIntermediateDataReducer";
import { calculatePositionAndWidthOfEntities } from "./getScheduleUtils";
import {
  findFlightsAndPairingWithOpenPositions,
  processReceivedChunk,
  takeActionForPayload
} from "./middlewareUtils";

export const getOpenFlyingData = (socket, store) => {
  const isGanttShownInUTC = store.getState().userInfo.showGanttInUTC;

  const userLocalTimeZone =
    store.getState().userInfo.userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][
      "zone"
    ];

  const dateRangeSelection = store.getState().searchCriteria.dateRange;

  socket.send(
    JSON.stringify({
      action: GET_OPEN_FLYING_DATA_RESOURCE_NAME,
      isGanttShownInUTC,
      userLocalTimeZone,
      start: dateRangeSelection.startDate,
      end: dateRangeSelection.endDate
    })
  );

  store.dispatch(
    setOpenFlyingDataStatusAction(OPEN_FLYING_DATA_STATUS.FETCHING)
  );
};

export const handlerForOpenFlyingData = (store, eventData) => {
  const fetchedOpenFlyingData = eventData.data;
  const intermediateDataForOpenFlying = {
    ...store.getState().websocketIntermediateData.intermediateDataForOpenFlying
  };

  let processedPayload = processReceivedChunk(
    eventData,
    intermediateDataForOpenFlying,
    fetchedOpenFlyingData,
    store.dispatch,
    setIntermediateDataForOpenFlyingAction
  );

  takeActionForPayload(processedPayload, processOpenFlyingDataAndUpdateStore, {
    store: store
  });
};

export const processOpenFlyingDataAndUpdateStore = ({ store, payload }) => {
  const dispatch = store.dispatch;

  const {
    pairings: fetchedPairings,
    duties: fetchedDuties,
    flights: fetchedFlights
  } = payload;

  const {
    pairings: pairingsInRedux,
    duties: dutiesInRedux,
    flights: flightsInRedux,
    crewMembers: crewMembersInRedux,
    lookUpData
  } = store.getState().crewSchedules;

  const { showGanttInUTC, userAttributes } = store.getState().userInfo;

  const dateRange = store.getState().searchCriteria.dateRange;

  const updatedSchedule = {
    scheduleFetched: {
      pairings: { ...pairingsInRedux, ...fetchedPairings },
      duties: { ...dutiesInRedux, ...fetchedDuties },
      flights: { ...flightsInRedux, ...fetchedFlights },
      crewMembers: [...crewMembersInRedux]
    }
  };

  calculatePositionAndWidthOfEntities(
    updatedSchedule,
    showGanttInUTC,
    userAttributes,
    dateRange,
    lookUpData
  );

  dispatch(setCrewSchedulesAction({ ...updatedSchedule.scheduleFetched }));
  dispatch(
    setOpenTimeAction(
      findFlightsAndPairingWithOpenPositions(
        updatedSchedule.scheduleFetched.flights,
        updatedSchedule.scheduleFetched.pairings
      )
    )
  );
  dispatch(setOpenFlyingDataStatusAction(OPEN_FLYING_DATA_STATUS.FETCHED));
};
