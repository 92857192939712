import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Accordion,
  AccordionSummary,
  Button,
  ClickAwayListener,
  IconButton
} from "@material-ui/core";
import {
  ChatBubble,
  ExpandMore as ExpandMoreIcon,
  FileCopy as CopyToClipboardIcon
} from "@material-ui/icons";

import TooltipViolationMessage from "../TooltipViolationMessage/TooltipViolationMessage";
import PairingInfo from "../pairinginfo/PairingInfo";

import {
  setIsErrorModalVisibleAction,
  setIsViolationDrilledDownDrawerVisibleAction
} from "../../../redux/showAndHideReducer";

import { getAllViolationMessageAndMuteStatus } from "../../../utils/pairingUtils";
import { getSpreadSafeArray, isNonEmptyArray } from "../../../utils/arrayUtils";
import {
  getPairingDate,
  getPairingId,
  processCommentCountData,
  getAndStorePairingInfo,
  getRelavantViolatingData
} from "./tooltipWithViolationMessageAndPairingInfoUtils";
import {
  setReduxKeysForViolatingData,
  setSelectedViolationAction
} from "../../../redux/violationDrillDownReducer";
import { getAllViolationMessages } from "../violation-drill-down-section/utils";

import "./ToolTipWithViolationMessageAndPairingInfo.scss";
import {
  getTimestampOfFirstViolationOnPairing,
  runContextualSolver
} from "../../../utils/solverUtil";
import { addOrSubtractHours } from "../../../utils/dateTimeUtils";

export default function ToolTipWithViolationMessageAndPairingInfo({
  violationMessages,
  pairingId,
  isFlightOps,
  tooltipRef,
  handlerToOpenCloseTooltip,
  crewIndexInStore,
  isOpen,
  isAClickFromMinRest,
  nextEntityStartTimestamp = null,
  setShowTooltip
}) {
  const dispatch = useDispatch();

  //---------- Redux Subsciptions
  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const crewMemberDetails = useSelector(
    store => store.crewSchedules.crewMembers[crewIndexInStore]
  );
  const pairingFromRedux = useSelector(
    store => store.crewSchedules.pairings[pairingId]
  );

  const processedViolations = useSelector(
    store => store.crewSchedules.processedViolations
  );

  const allDuties = useSelector(store => store.crewSchedules.duties);

  const allFlights = useSelector(store => store.crewSchedules.flights);

  const selectedStrategy = useSelector(
    store => store.ruleAndStrategy.selectedStrategy
  );

  const selectedRuleSet = useSelector(
    store => store.ruleAndStrategy.selectedRuleSet
  );

  const solverWindowOffsetDefaults = useSelector(
    store => store.tenantConfig.solver
  );

  const showGanttInUTC = useSelector(store => store.userInfo.showGanttInUTC);

  const userAttributes = useSelector(store => store.userInfo.userAttributes);

  //---------- React Callbacks
  /**
   * filters the violation messages
   */
  const getAllMessagesAndMuteStatusesToBeDisplayed = React.useCallback(
    () =>
      getSpreadSafeArray(
        getAllViolationMessageAndMuteStatus(violationMessages, isFlightOps)
      ),
    [violationMessages, isFlightOps]
  );

  const getPairingIdMemoised = React.useCallback(
    () => getPairingId(pairingFromRedux, pairingId),
    [pairingFromRedux, pairingId]
  );

  const getPairingDateMemoised = React.useCallback(
    () => getPairingDate(pairingFromRedux, pairingId),
    [pairingFromRedux, pairingId]
  );

  const getAndStorePairingInfoMemoised = React.useCallback(
    async () =>
      getAndStorePairingInfo(
        pairingId,
        setPairingInfoForThePairing,
        tooltipRef,
        crewMemberDetails
      ),
    [pairingId, tooltipRef, crewMemberDetails]
  );

  const processCommentCountDataMemoised = React.useCallback(
    () =>
      setCommentCountData(
        processCommentCountData(violationMessages, isFlightOps)
      ),
    [violationMessages, isFlightOps]
  );

  const handleClickOnCopyToClipboard = React.useCallback(
    event => {
      event.stopPropagation();
      navigator.clipboard.writeText(getPairingIdMemoised());
      setIsPairingIdCopied(true);
    },
    [getPairingIdMemoised]
  );

  const handleClickOnResolveButton = React.useCallback(
    event => {
      event.stopPropagation();
      handlerToOpenCloseTooltip(false);

      // 1. check if there is an internet connection
      if (!navigator.onLine) {
        dispatch(
          setIsErrorModalVisibleAction({
            isVisible: true,
            message:
              "Could not initiate a solver request, please check your internet connection and try again"
          })
        );
        return;
      }

      /**
       * if online then dispatch a solver-request
       *
       * -- solver window calculations --
       *
       * 1. When the user clicks on a violation and clicks solve,
       * the window start should be 2 hours
       * before the first violation of the pairing.
       * 2. If the user clicks on a min-rest violation,
       * the window start should be 2 hours
       * before the first pairing after the min-rest.
       *
       *  */
      let runStartTime =
        isAClickFromMinRest && nextEntityStartTimestamp
          ? nextEntityStartTimestamp
          : getTimestampOfFirstViolationOnPairing(
              pairingFromRedux,
              processedViolations,
              crewMemberDetails,
              allDuties,
              allFlights
            );

      if (isAClickFromMinRest) {
        runStartTime = nextEntityStartTimestamp
          ? nextEntityStartTimestamp
          : runStartTime;
      }

      runStartTime = addOrSubtractHours(
        new Date(runStartTime),
        solverWindowOffsetDefaults.contextualWindowStartHourOffset,
        true
      ).toISOString();
      const runEndTime = addOrSubtractHours(
        new Date(runStartTime),
        solverWindowOffsetDefaults.contextualWindowEndHourOffset
      ).toISOString();

      runContextualSolver(
        dispatch,
        pairingId,
        isFlightOps,
        runStartTime,
        runEndTime,
        selectedRuleSet,
        selectedStrategy,
        crewMemberDetails,
        showGanttInUTC,
        userAttributes
      );
    },
    [
      handlerToOpenCloseTooltip,
      dispatch,
      pairingId,
      isFlightOps,
      crewMemberDetails,
      pairingFromRedux,
      selectedRuleSet,
      selectedStrategy,
      isAClickFromMinRest,
      nextEntityStartTimestamp,
      solverWindowOffsetDefaults.contextualWindowEndHourOffset,
      solverWindowOffsetDefaults.contextualWindowStartHourOffset,
      allDuties,
      allFlights,
      processedViolations,
      showGanttInUTC,
      userAttributes
    ]
  );

  const handleClickOnViolationCommentsCount = React.useCallback(
    event => {
      event.stopPropagation();
      handlerToOpenCloseTooltip(false);
      const relavantMessages = getAllViolationMessages(
        getRelavantViolatingData(violationMessages, isFlightOps)
      );

      dispatch(
        setReduxKeysForViolatingData(
          relavantMessages.map(violation => violation.reduxKey)
        )
      );

      if (relavantMessages.length > 0) {
        /**
         * Since there is no intermediate screen for user to select the violation
         * setting the first element as the selected violation
         */
        dispatch(
          setSelectedViolationAction({
            violationType: relavantMessages[0].violationType,
            violationId: relavantMessages[0].violationId
          })
        );

        dispatch(setIsViolationDrilledDownDrawerVisibleAction(true));
      } else {
        dispatch(
          setIsErrorModalVisibleAction({
            isVisible: true,
            message: "There are no violations associated with this entity!"
          })
        );
      }
    },
    [dispatch, handlerToOpenCloseTooltip, violationMessages, isFlightOps]
  );

  /**
   * update the tooltip position on open and close of expansion panel
   */
  const handleOnChangeForAccordion = React.useCallback(
    () => tooltipRef.current.scheduleUpdate(),
    [tooltipRef]
  );

  /**
   * Clipboard.readText() is A Promise that resolves with a DOMString containing the textual contents of the clipboard.
   * Returns an empty string if the clipboard is empty
   */
  const getClipboardInfo = React.useCallback(() => {
    try {
      navigator.clipboard
        .readText()
        .then(value => setIsPairingIdCopied(getPairingId() === value)) //check if the pairingId is the one copied in the clipboard
        .catch(err => setIsPairingIdCopied(false)); //if user has not given permission to read from clipboard then set it to false
    } catch (err) {
      console.log(err);
    }
  }, []);

  //--------------- Component States

  const [pairingInfoForThePairing, setPairingInfoForThePairing] = useState({
    isFetched: false,
    pairingInfo: null
  });

  /**
   * state to track if the current pairingId is copied to the clipboard or not
   */
  const [isPairingIdCopied, setIsPairingIdCopied] = React.useState(false);

  const [messagesAndMuteStatusesToBeDisplayed] = React.useState(
    getAllMessagesAndMuteStatusesToBeDisplayed(violationMessages, isFlightOps)
  );

  const [isPairingInfoOpenedByDefault] = React.useState(
    !isNonEmptyArray(messagesAndMuteStatusesToBeDisplayed)
  );

  const [commentCountData, setCommentCountData] = React.useState({
    totalNumberOfComments: 0,
    violationToNumberOfCommentMap: {}
  });

  //---------- Side Effects

  useEffect(() => {
    getAndStorePairingInfoMemoised();

    getClipboardInfo();

    processCommentCountDataMemoised();

    return () => {
      setPairingInfoForThePairing(null);
    };
  }, [
    getAndStorePairingInfoMemoised,
    getClipboardInfo,
    processCommentCountDataMemoised
  ]);

  const PairingInfoHeader = React.useCallback(
    () => (
      <div className="pairing-info-header">
        <span className="pairing-info-header-text">Pairing Info - </span>
        <span className="pairing-info-header-pairing-id-container">
          <span className="pairing-info-header-pairing-id">
            {getPairingIdMemoised()}
          </span>
          <Button
            className="pairing-info-header-pairing-copy"
            onClick={handleClickOnCopyToClipboard}
          >
            <CopyToClipboardIcon
              style={{ color: `${isPairingIdCopied ? "green" : "initial"}` }}
            />
          </Button>
        </span>
        <span className="pairing-info-header-pairing-date">
          {getPairingDateMemoised()}
        </span>
      </div>
    ),
    [
      getPairingIdMemoised,
      getPairingDateMemoised,
      handleClickOnCopyToClipboard,
      isPairingIdCopied
    ]
  );

  const ViolationMessageHeader = React.useCallback(
    ({ commentCount, showResolveButton }) => (
      <div className="violation-message-header">
        <span className="violations-label">
          Violations
          <IconButton onClick={handleClickOnViolationCommentsCount}>
            <ChatBubble />
            <div className="comment-count">
              {isShowingASolverRun //do not show count in case of solver output screen
                ? null
                : Number.isNaN(commentCount)
                ? "-"
                : commentCount}
            </div>
          </IconButton>
        </span>
        {showResolveButton ? (
          <span className="resolve-button-container">
            <Button
              variant="outlined"
              type="primary"
              className="resolve-button"
              onClick={handleClickOnResolveButton}
            >
              Resolve
            </Button>
          </span>
        ) : null}
      </div>
    ),
    [
      handleClickOnViolationCommentsCount,
      isShowingASolverRun,
      handleClickOnResolveButton
    ]
  );

  const handleClickAway = React.useCallback(() => {
    setShowTooltip(false);
  }, [setShowTooltip]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="violation-message-pairing-info-container">
        <Accordion
          defaultExpanded={!isPairingInfoOpenedByDefault}
          onChange={handleOnChangeForAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <ViolationMessageHeader
              commentCount={commentCountData.totalNumberOfComments}
              showResolveButton={
                !isPairingInfoOpenedByDefault && !isOpen && !isShowingASolverRun
              }
            />
          </AccordionSummary>
          <TooltipViolationMessage
            messagesAndMuteStatusesToBeDisplayed={
              messagesAndMuteStatusesToBeDisplayed
            }
          />
        </Accordion>

        <Accordion
          onChange={handleOnChangeForAccordion}
          defaultExpanded={isPairingInfoOpenedByDefault}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <PairingInfoHeader />
          </AccordionSummary>
          <PairingInfo
            pairingInfo={pairingInfoForThePairing}
            pairingFromRedux={pairingFromRedux}
          />
        </Accordion>
      </div>
    </ClickAwayListener>
  );
}
