/**
 * classNames for the Select mui components in DurationSelection.js
 */
export const MATERIAL_UI_SELECT_FOR_DURATION_SELECTION = {
  ROOT: "material-ui-select-for-duration-selection-root",
  ICON: "material-ui-select-for-duration-selection-icon",
  ICON_OPEN: "material-ui-select-for-duration-selection-iconOpen",
  SELECT: "material-ui-select-for-duration-selection",
  SELECT_MENU: "material-ui-select-for-duration-selection-menu",
};


