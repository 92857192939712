const ACTIONS = {
  SET_VIOLATING_DATA: "SET_VIOLATING_DATA",
  SET_COMMENT_DATA: "SET_COMMENT_DATA",
  SET_IS_DELETING_COMMENT: "SET_IS_DELETING_COMMENT",
  SET_IS_SAVING_COMMENT: "SET_IS_SAVING_COMMENT",
  SET_REDUX_KEYS_FOR_VIOLATING_DATA: "SET_REDUX_KEYS_FOR_VIOLATING_DATA",
  SET_SELECTED_VIOLATION: "SET_SELECTED_VIOLATION"
};

const initialState = {
  violatingData: [],
  commentData: { NO_ID_NO_TYPE: [] }, //for previous solver run data, the violationId is not present so cannot map violations to comments - so created a empty fallback array
  isDeletingComment: false,
  isSavingComment: false,
  reduxKeysForViolatingData: [],
  selectedViolation: {
    violationId: "",
    violationType: ""
  }
};

export function violationDrillDownReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_VIOLATING_DATA":
      newState = {
        ...prevState,
        violatingData: action.violatingData
      };
      break;
    case "SET_COMMENT_DATA":
      newState = {
        ...prevState,
        commentData: {
          ...prevState.commentData,
          [action.commentData.violationKey]: action.commentData.comments
        }
      };
      break;
    case "SET_IS_DELETING_COMMENT":
      newState = {
        ...prevState,
        isDeletingComment: action.isDeletingComment
      };
      break;
    case "SET_IS_SAVING_COMMENT":
      newState = {
        ...prevState,
        isSavingComment: action.isSavingComment
      };
      break;
    case "SET_REDUX_KEYS_FOR_VIOLATING_DATA":
      newState = {
        ...prevState,
        reduxKeysForViolatingData: action.reduxKeysForViolatingData
      };
      break;
    case "SET_SELECTED_VIOLATION":
      newState = {
        ...prevState,
        selectedViolation: action.selectedViolation
      };
      break;

    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setViolatingDataAction(violatingData) {
  return {
    type: ACTIONS.SET_VIOLATING_DATA,
    violatingData
  };
}

export function setCommentDataAction(commentData) {
  return {
    type: ACTIONS.SET_COMMENT_DATA,
    commentData
  };
}

export function setIsDeletingCommentAction(isDeletingComment) {
  return {
    type: ACTIONS.SET_IS_DELETING_COMMENT,
    isDeletingComment
  };
}

export function setIsSavingCommentAction(isSavingComment) {
  return {
    type: ACTIONS.SET_IS_SAVING_COMMENT,
    isSavingComment
  };
}

export function setReduxKeysForViolatingData(reduxKeysForViolatingData) {
  return {
    type: ACTIONS.SET_REDUX_KEYS_FOR_VIOLATING_DATA,
    reduxKeysForViolatingData
  };
}

export function setSelectedViolationAction(selectedViolation) {
  return {
    type: ACTIONS.SET_SELECTED_VIOLATION,
    selectedViolation
  };
}
