import { isNonEmptyArray } from "./arrayUtils";
import {
  processEntityViolations,
  processTimeViolations
} from "./allGanttRowsUtils";
import {
  setOpenTimeAction,
  updateScheduleOnPartialRefreshAction
} from "../redux/crewSchedulesReducer";
import { resetPairingInfoForAllPairingsAction } from "../redux/pairingInfoReducer";
import {
  findFlightsAndPairingWithOpenPositions,
  getPairingIdsSortedByTime
} from "./middlewareUtils";
import { batch } from "react-redux";
import { isNonEmptyObject } from "./jsonUtils";
import { updateGanttOnUserAction } from "../redux/webSocketReducer";

export const handleAddedPairings = (
  dataCollectedFromAllChunks,
  crewMembersFromRedux
) => {
  let didCrewsChange = false;
  dataCollectedFromAllChunks &&
    dataCollectedFromAllChunks.addedCrewPairings &&
    Object.keys(dataCollectedFromAllChunks.addedCrewPairings).forEach(
      crewId => {
        const crewIdAsInt = parseInt(crewId);
        let specificCrewMemberIndex = crewMembersFromRedux.findIndex(
          crewMember => crewMember.crewId === crewIdAsInt
        );
        if (specificCrewMemberIndex !== -1) {
          const specificCrewMemberFromRedux =
            crewMembersFromRedux[specificCrewMemberIndex];
          dataCollectedFromAllChunks.addedCrewPairings[crewIdAsInt].forEach(
            addedPairingId => {
              if (
                specificCrewMemberFromRedux.pairings.indexOf(addedPairingId) < 0
              ) {
                specificCrewMemberFromRedux.pairings.push(addedPairingId);
                didCrewsChange = true;
              }
            }
          );
          crewMembersFromRedux[specificCrewMemberIndex] =
            specificCrewMemberFromRedux;
        }
      }
    );
  return didCrewsChange;
};

export const handleRemovedPairings = (
  dataCollectedFromAllChunks,
  crewMembersFromRedux
) => {
  let didCrewsChange = false;
  dataCollectedFromAllChunks &&
    dataCollectedFromAllChunks.removedCrewPairings &&
    Object.keys(dataCollectedFromAllChunks.removedCrewPairings).forEach(
      crewId => {
        const crewIdAsInt = parseInt(crewId);
        let specificCrewMemberIndex = crewMembersFromRedux.findIndex(
          crewMember => crewMember.crewId === crewIdAsInt
        );
        if (specificCrewMemberIndex !== -1) {
          const specificCrewMemberFromRedux =
            crewMembersFromRedux[specificCrewMemberIndex];
          dataCollectedFromAllChunks.removedCrewPairings[crewIdAsInt].forEach(
            removedPairingId => {
              if (
                specificCrewMemberFromRedux.pairings.indexOf(removedPairingId) >
                -1
              ) {
                specificCrewMemberFromRedux.pairings.splice(
                  specificCrewMemberFromRedux.pairings.indexOf(
                    removedPairingId
                  ),
                  1
                );
                didCrewsChange = true;
              }
            }
          );
          crewMembersFromRedux[specificCrewMemberIndex] =
            specificCrewMemberFromRedux;
        }
      }
    );
  return didCrewsChange;
};

export const handleViolations = (
  dataCollectedFromAllChunks,
  entityViolationsFromRedux,
  timeViolationsFromRedux,
  changedSchedule,
  ruleDisplayConfig,
  violationMetadata
) => {
  if (
    isNonEmptyArray(dataCollectedFromAllChunks.entityViolations) ||
    isNonEmptyArray(dataCollectedFromAllChunks.timeViolations) ||
    isNonEmptyArray(Object.keys(dataCollectedFromAllChunks.ruleDisplayConfig))
  ) {
    const updatedViolationMetadata = { ...violationMetadata };

    let computedRuleDisplayConfig = {};

    if (
      dataCollectedFromAllChunks.ruleDisplayConfig &&
      isNonEmptyArray(Object.keys(dataCollectedFromAllChunks.ruleDisplayConfig))
    ) {
      computedRuleDisplayConfig = {
        ...ruleDisplayConfig,
        ...dataCollectedFromAllChunks.ruleDisplayConfig
      };
    } else {
      computedRuleDisplayConfig = ruleDisplayConfig;
    }

    //handle entity violations
    clearExistingEntityViolationsOnRefreshedEntites(
      dataCollectedFromAllChunks,
      entityViolationsFromRedux
    );
    const processedEntityViolations = processEntityViolations(
      dataCollectedFromAllChunks.entityViolations,
      entityViolationsFromRedux,
      updatedViolationMetadata.entityViolation,
      computedRuleDisplayConfig
    );
    //handle time violations
    clearExistingTimeViolationsOnRefreshedEntites(
      dataCollectedFromAllChunks,
      timeViolationsFromRedux
    );
    const processedTimeViolations = processTimeViolations(
      dataCollectedFromAllChunks.timeViolations,
      timeViolationsFromRedux,
      updatedViolationMetadata.timeViolation,
      computedRuleDisplayConfig
    );

    changedSchedule.processedViolations = {
      entityViolations: processedEntityViolations,
      timeViolations: processedTimeViolations,
      ruleDisplayConfig: computedRuleDisplayConfig
    };
    changedSchedule.violationMetadata = updatedViolationMetadata;
  } else {
    changedSchedule.processedViolations = {
      entityViolations: entityViolationsFromRedux,
      timeViolations: timeViolationsFromRedux,
      ruleDisplayConfig: ruleDisplayConfig
    };
    changedSchedule.violationMetadata = violationMetadata;
  }
};

export function clearExistingEntityViolationsOnRefreshedEntites(
  allPartialRefreshData,
  existingViolations
) {
  if (isNonEmptyArray(allPartialRefreshData.flights)) {
    allPartialRefreshData.flights.forEach(flightObj => {
      existingViolations.flight[flightObj.flightId] = {};
    });
  }

  if (isNonEmptyArray(allPartialRefreshData.duties)) {
    allPartialRefreshData.duties.forEach(duty => {
      existingViolations.duty[duty.dutyId] = {};
    });
  }

  if (isNonEmptyArray(allPartialRefreshData.pairings)) {
    allPartialRefreshData.pairings.forEach(pairing => {
      existingViolations.pairing[pairing.pairingId] = {};
    });
  }

  return existingViolations;
}

export function clearExistingTimeViolationsOnRefreshedEntites(
  allPartialRefreshData,
  existingViolations
) {
  if (isNonEmptyArray(allPartialRefreshData.duties)) {
    allPartialRefreshData.duties.forEach(duty => {
      if (isNonEmptyArray(duty.flightIds)) {
        duty.flightIds.forEach(flightId => {
          existingViolations.duty[`${duty.dutyId}-${flightId}`] = {};
        });
      }
    });
  }

  if (isNonEmptyArray(allPartialRefreshData.pairings)) {
    allPartialRefreshData.pairings.forEach(pairing => {
      if (isNonEmptyArray(pairing.dutyIds)) {
        pairing.dutyIds.forEach(dutyId => {
          existingViolations.pairing[`${pairing.pairingId}-${dutyId}`] = {};
        });
      }
    });
  }

  if (isNonEmptyArray(allPartialRefreshData.changedCrewIds)) {
    allPartialRefreshData.changedCrewIds.forEach(crewId => {
      if (isNonEmptyArray(allPartialRefreshData.duties)) {
        allPartialRefreshData.duties.forEach(duty => {
          existingViolations.crew.crewDutyViolation[
            `${crewId}-${duty.dutyId}`
          ] = {};
        });
      }

      if (isNonEmptyArray(allPartialRefreshData.pairings)) {
        allPartialRefreshData.pairings.forEach(pairing => {
          existingViolations.crew.crewPairingViolation[
            `${crewId}-${pairing.pairingId}`
          ] = {};
          existingViolations.crew.restViolation[
            `${crewId}-${pairing.pairingId}`
          ] = {};
        });
      }
    });
  }

  return existingViolations;
}

export function handleNewlyAddedOpenTimes(
  newlyAddedOpenTimes,
  openTimeIdsFromRedux,
  changedSchedule
) {
  const openFlightIdsArray = [...openTimeIdsFromRedux.openFlightIds];
  changedSchedule.openTimeIds = openTimeIdsFromRedux;
  if (isNonEmptyArray(newlyAddedOpenTimes.flightIds)) {
    newlyAddedOpenTimes.flightIds.forEach(newOpenFlightId =>
      openFlightIdsArray.push([newOpenFlightId])
    );
  }

  changedSchedule.openTimeIds.openFlightIds = openFlightIdsArray;
}

export function handleResolvedOpenTimes(resolvedOpenTimes, changedSchedule) {
  const openFlightIdsArray = [...changedSchedule.openTimeIds.openFlightIds];
  const rowColPairsToBeDeleted = [];
  if (isNonEmptyArray(resolvedOpenTimes.flightIds)) {
    resolvedOpenTimes.flightIds.forEach(resolvedFlightId => {
      openFlightIdsArray.forEach((row, rowIndex) => {
        const colIndexToBeRemoved = row.findIndex(
          ele => ele === resolvedFlightId
        );
        if (colIndexToBeRemoved !== -1) {
          rowColPairsToBeDeleted.push({
            rowIndex,
            colIndex: colIndexToBeRemoved
          });
        }
      });
    });
  }
  if (isNonEmptyArray(rowColPairsToBeDeleted)) {
    rowColPairsToBeDeleted.forEach(pair =>
      openFlightIdsArray[pair.rowIndex].splice(pair.colIndex, 1)
    );
  }
  changedSchedule.openTimeIds.openFlightIds = openFlightIdsArray;
}

export const getCurrentScheduleDataFromRedux = crewSchedules => {
  return {
    crewSchedules: crewSchedules,
    crewMembersFromRedux: crewSchedules.crewMembers,
    pairingsFromRedux: crewSchedules.pairings,
    dutiesFromRedux: crewSchedules.duties,
    flightsFromRedux: crewSchedules.flights,
    entityViolationsFromRedux:
      crewSchedules.processedViolations.entityViolations,
    timeViolationsFromRedux: crewSchedules.processedViolations.timeViolations,
    ruleDisplayConfigFromRedux:
      crewSchedules.processedViolations.ruleDisplayConfig,
    violationMetadata: crewSchedules.violationMetadata
  };
};

export const handleChangeInPairingsForCrew = (
  dataCollectedFromAllChunks,
  crewMembersFromRedux,
  changedSchedule
) => {
  //handle added pairings
  handleAddedPairings(dataCollectedFromAllChunks, crewMembersFromRedux);
  //handle removed pairings
  handleRemovedPairings(dataCollectedFromAllChunks, crewMembersFromRedux);

  changedSchedule.crewMembers = crewMembersFromRedux;

  /**
   * arrange the pairing ids in crew object in ascending order
   */
  changedSchedule &&
    changedSchedule.crewMembers &&
    changedSchedule.crewMembers.forEach(crewMember => {
      if (
        isNonEmptyArray(crewMember.pairings) &&
        changedSchedule.pairings &&
        Object.keys(changedSchedule.pairings).length > 0
      )
        crewMember.pairings = getPairingIdsSortedByTime(
          crewMember.pairings,
          changedSchedule.pairings
        );
    });
};

export const handleChangeInEntityData = (
  dataCollectedFromAllChunks,
  flightsFromRedux,
  dutiesFromRedux,
  pairingsFromRedux,
  changedSchedule
) => {
  //handle flight data
  if (isNonEmptyArray(dataCollectedFromAllChunks.flights)) {
    dataCollectedFromAllChunks.flights.forEach(flight => {
      flightsFromRedux[flight.flightId] = flight;
    });
    changedSchedule.flights = flightsFromRedux;
  }

  //handle duty data
  if (isNonEmptyArray(dataCollectedFromAllChunks.duties)) {
    dataCollectedFromAllChunks.duties.forEach(duty => {
      dutiesFromRedux[duty.dutyId] = duty;
    });
    changedSchedule.duties = dutiesFromRedux;
  }
  if (isNonEmptyArray(dataCollectedFromAllChunks.pairings)) {
    //handle pairing data
    dataCollectedFromAllChunks.pairings.forEach(pairing => {
      pairingsFromRedux[pairing.pairingId] = pairing;
    });
    changedSchedule.pairings = pairingsFromRedux;
  }
};

export const isThereChangeInOpenTimeData = dataCollectedFromAllChunks =>
  (dataCollectedFromAllChunks.newlyAddedOpenTimes &&
    (isNonEmptyArray(
      dataCollectedFromAllChunks.newlyAddedOpenTimes.flightIds
    ) ||
      isNonEmptyArray(
        dataCollectedFromAllChunks.newlyAddedOpenTimes.pairingIds
      ))) ||
  (dataCollectedFromAllChunks.resolvedOpenTimes &&
    (isNonEmptyArray(dataCollectedFromAllChunks.resolvedOpenTimes.flightIds) ||
      isNonEmptyArray(
        dataCollectedFromAllChunks.resolvedOpenTimes.pairingIds
      )));

export const handleOpenTimeData = (
  dataCollectedFromAllChunks,
  flights,
  pairings,
  dispatcher
) => {
  if (isThereChangeInOpenTimeData(dataCollectedFromAllChunks)) {
    dispatcher(
      setOpenTimeAction(
        findFlightsAndPairingWithOpenPositions(flights, pairings)
      )
    );

    /**
     * commenting the below code as it may be needed if we
     * want to show new open positions in new rows
     * instead of reshulling all open positions.
     */
    // handle newly added open times using `newlyAddedOpenTimes`
    // const openTimeIdsFromRedux = crewSchedules.openTimeIds;
    // handleNewlyAddedOpenTimes(
    //   dataCollectedFromAllChunks.newlyAddedOpenTimes,
    //   openTimeIdsFromRedux,
    //   changedSchedule
    // );

    // handle resolved open times using `resolvedOpenTimes`
    // handleResolvedOpenTimes(
    //   dataCollectedFromAllChunks.resolvedOpenTimes,
    //   changedSchedule
    // );
  }
};

export const updateStoreWithNewPartialRefreshData = (
  changedSchedule,
  dataCollectedFromAllChunks,
  dispatcher
) => {
  if (isNonEmptyObject(changedSchedule)) {
    batch(() => {
      dispatcher(updateScheduleOnPartialRefreshAction(changedSchedule));
      dispatcher(resetPairingInfoForAllPairingsAction());
    });
  }
};

export const processPartialRefreshDataAndUpdateStore = ({ store, payload }) => {
  let {
    crewMembersFromRedux,
    pairingsFromRedux,
    dutiesFromRedux,
    flightsFromRedux,
    entityViolationsFromRedux,
    timeViolationsFromRedux,
    ruleDisplayConfigFromRedux,
    violationMetadata
  } = getCurrentScheduleDataFromRedux(store.getState().crewSchedules);

  let changedSchedule = {};

  /**
   * do, add added pairings, remove removed pairings to/from crew object's pairingId array
   */
  handleChangeInPairingsForCrew(payload, crewMembersFromRedux, changedSchedule);

  /**
   * handle change in flights, duties and pairing
   */
  handleChangeInEntityData(
    payload,
    flightsFromRedux,
    dutiesFromRedux,
    pairingsFromRedux,
    changedSchedule
  );

  //handle violations
  handleViolations(
    payload,
    entityViolationsFromRedux,
    timeViolationsFromRedux,
    changedSchedule,
    ruleDisplayConfigFromRedux,
    violationMetadata
  );

  handleOpenTimeData(
    payload,
    changedSchedule.flights,
    changedSchedule.pairings,
    store.dispatch
  );

  store.dispatch(
    updateGanttOnUserAction({ changedSchedule, doNotRefreshScreen: true })
  );
};
