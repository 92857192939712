import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DutyPeriod from "./DutyPeriod";

import "./PairingInfo.scss";
import { isNonEmptyArray } from "../../../utils/arrayUtils";
import { CircularProgress } from "@material-ui/core";
import {
  FLIGHT_STATUS_KEY,
  UNPLANNED_FLIGHT_DIVERSION_STATUSES
} from "../../../constants/disruptions/pairing";
import Bold from "../bold/Bold";

export default function PairingInfo({ pairingInfo, pairingFromRedux }) {
  const allDuties = useSelector(store => store.crewSchedules.duties);

  const allFlights = useSelector(store => store.crewSchedules.flights);

  const [dutyWiseDiversionInfo, setDutyWiseDiversionInfo] = useState({});

  const getTimezoneInfoForTheFirstAndLastFlightForDuty = React.useCallback(
    index => {
      const firstFlightForDuty =
        allFlights[allDuties[pairingFromRedux.dutyIds[index]].flightIds[0]];
      const lastFlightForDuty =
        allFlights[
          allDuties[pairingFromRedux.dutyIds[index]].flightIds[
            allDuties[pairingFromRedux.dutyIds[index]].flightIds.length - 1
          ]
        ];

      return {
        reportTimeTimezone:
          firstFlightForDuty && firstFlightForDuty.originTZ
            ? firstFlightForDuty.originTZ
            : 0,
        releaseTimeTimezone:
          lastFlightForDuty && lastFlightForDuty.destinationTZ
            ? lastFlightForDuty.destinationTZ
            : 0
      };
    },
    [allDuties, allFlights, pairingFromRedux.dutyIds]
  );

  useEffect(() => {
    setDutyWiseDiversionInfo(
      pairingFromRedux.dutyIds.reduce(
        (result, dutyId, dutyIndex, dutyIdsForPairing) => {
          let unplannedDiversionCount = 0;
          const dutyDetails = allDuties[dutyId];
          dutyDetails.flightIds.forEach((flightId, index) => {
            if (index === 0) return;
            const firstFlight = allFlights[dutyDetails.flightIds[index - 1]];
            const secondFlight = allFlights[flightId];
            if (
              firstFlight &&
              secondFlight &&
              UNPLANNED_FLIGHT_DIVERSION_STATUSES.includes(
                firstFlight[FLIGHT_STATUS_KEY]
              ) &&
              UNPLANNED_FLIGHT_DIVERSION_STATUSES.includes(
                secondFlight[FLIGHT_STATUS_KEY]
              )
            ) {
              unplannedDiversionCount++;
            }
          });
          result[dutyId] = unplannedDiversionCount;
          return result;
        },
        {}
      )
    );
    return () => {
      setDutyWiseDiversionInfo({});
    };
  }, [allDuties, allFlights, pairingFromRedux]);

  const Header = React.useCallback(() => {
    return (
      <div className='header'>
        <div>Report - Release</div>
        <div>
          Duty Time(Est/<Bold>Act</Bold>)
        </div>
        <div>
          FDP Time(Est/<Bold>Act</Bold>)
        </div>
        <div># landing</div>
      </div>
    );
  }, []);

  return (
    <div className='pairing-info-container'>
      <Header />
      {pairingInfo?.isFetched ? (
        isNonEmptyArray(pairingInfo.pairingInfo) ? (
          pairingInfo.pairingInfo
            .sort(
              (ele1, ele2) =>
                Number(new Date(ele1.reportTime)) -
                Number(new Date(ele2.reportTime))
            )
            .map((dutyInfo, index) => {
              return {
                ...dutyInfo,
                ...getTimezoneInfoForTheFirstAndLastFlightForDuty(index)
              };
            })
            .map((dutyInfo, index) => (
              <DutyPeriod
                key={index}
                dutyInfo={dutyInfo}
                unplannedDiversionCount={dutyWiseDiversionInfo[dutyInfo.dutyId]}
              />
            ))
        ) : (
          <div className='no-pairing-info'>
            No operating duties in the pairing.
          </div>
        )
      ) : (
        <div className='pairing-info-loading'>
          <CircularProgress />
          <div className='no-pairing-info'>Loading...</div>
        </div>
      )}
    </div>
  );
}
