import React from "react";

/**
 * Created this component to cater to needs where a single line text has to be shown to user
 */
export default function SimpleTooltipMessage({ className, tooltipMessage }) {
  return (
    <div className={className ? className : "simple-tooltip-message"}>
      {tooltipMessage}
    </div>
  );
}
