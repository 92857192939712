import React from "react";
import { Tooltip } from "@material-ui/core";
import { CODE_FOR_OPERATIONAL_DUTY } from "../../../constants/disruptions/crewGanttViewer";
import { CODES_TO_ICON_MAP } from "../../../constants/disruptions/referenceIcons";
import {
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_TO_BE_SHOWN,
  MINIMUM_VIEW_WIDTH_FOR_FULL_DUTY_CODE_TO_BE_SHOWN,
  MINIMUM_VIEW_WIDTH_FOR_ICON_AND_ITS_DESC_TO_BE_SHOWN
} from "../../../constants/disruptions/waitingTime";
import { isInArray } from "../../../utils/arrayUtils";
import SimpleTooltipMessage from "../simpletooltipmessages/SimpleTooltipMessage";

export default function DutyCode({
  dutyCode,
  crewBase,
  dutyLength,
  hasFlights
}) {
  /**
   * replace true with the config to replace or not replace flight number
   */
  const shouldIconReplaceDutyCode = React.useCallback(
    dutyCode => true && isInArray(dutyCode, Object.keys(CODES_TO_ICON_MAP)),
    []
  );

  const [replaceDutyCode, setReplaceDutyCode] = React.useState(false);

  React.useEffect(
    () => setReplaceDutyCode(shouldIconReplaceDutyCode(dutyCode)),
    [dutyCode, shouldIconReplaceDutyCode]
  );

  const willCrewBaseFit = React.useCallback(
    dutyLength =>
      dutyLength > MINIMUM_VIEW_WIDTH_FOR_FULL_DUTY_CODE_TO_BE_SHOWN,
    []
  );

  const willIconFit = React.useCallback(
    dutyLength => dutyLength > MINIMUM_VIEW_WIDTH_FOR_FLIGHT_ICON_TO_BE_SHOWN,
    []
  );

  const willIconDescFit = React.useCallback(
    dutyLength =>
      dutyLength > MINIMUM_VIEW_WIDTH_FOR_ICON_AND_ITS_DESC_TO_BE_SHOWN,
    []
  );

  const IconForDutyType = React.useCallback(
    ({ dutyCode, crewBase }) => (
      <Tooltip
        title={
          <SimpleTooltipMessage
            className={"simple-tooltip-message"}
            tooltipMessage={`${dutyCode}:${crewBase}`}
          />
        }
      >
        {CODES_TO_ICON_MAP[dutyCode]}
      </Tooltip>
    ),
    []
  );

  return (
    <>
      {dutyCode !== CODE_FOR_OPERATIONAL_DUTY && !hasFlights ? (
        <div className={"reserve-duty"}>
          <span>
            {replaceDutyCode && willIconFit(dutyLength) && (
              <IconForDutyType dutyCode={dutyCode} crewBase={crewBase} />
            )}
            {dutyCode && willIconDescFit(dutyLength) && dutyCode}
            {crewBase && willCrewBaseFit(dutyLength) ? ` :${crewBase}` : ""}
          </span>
        </div>
      ) : null}
    </>
  );
}
