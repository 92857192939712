import React from "react";
import { setSelectedFilterAction } from "../../../redux/searchCriteriaReducer";
import {
  getSpreadSafeArray,
  isInArray,
  isNonEmptyArray
} from "../../../utils/arrayUtils";
import Arrow from "../arrow/Arrow";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Checkbox } from "@material-ui/core";
import { SORT_TYPE } from "../../../constants/disruptions/filter";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AutoCompleteComponent({
  filter,
  values,
  className,
  freeSolo,
  disabled,
  filterName,
  optionDisplayConfig
}) {
  const dispatch = useDispatch();

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const getAllOptionsAfterApplyingConfigParams = React.useCallback(
    allOptions => {
      if (!optionDisplayConfig) return allOptions;

      switch (optionDisplayConfig.sortType) {
        case SORT_TYPE.ALPHABETIC:
          return allOptions.sort();
        case SORT_TYPE.NONE:
          return allOptions;
        default:
          return allOptions;
      }
    },
    [optionDisplayConfig]
  );

  /**
   * Returns the options for the selected component
   * options -> exhasutive list of options
   * state.inputValue -> contains the user entered text
   * values -> user selected values
   */
  const filterOption = React.useCallback(
    (options, state, values) => {
      const allOptions = getAllOptionsAfterApplyingConfigParams(options);

      /**
       * add the user selected options to the top 50 options that has to be displayed
       */
      let topFiftyOptions = [
        ...getAllOptionsAfterApplyingConfigParams(getSpreadSafeArray(values))
      ];

      /**
       * freeSolo means that the user can give his own input so in that case display the user types input first
       */
      if (freeSolo && String(state.inputValue).length > 0) {
        topFiftyOptions = [state.inputValue, ...topFiftyOptions]; //display the inputValue at the begining of all the options
      }
      /**
       * loop through all the options
       */
      for (
        let loopVariable = 0;
        loopVariable < allOptions.length;
        loopVariable++
      ) {
        /**
         * if no user input then push the options after checking if they are present or not in the user selected array
         */
        if (state.inputValue.length <= 0) {
          if (!isInArray(allOptions[loopVariable], topFiftyOptions))
            topFiftyOptions.push(allOptions[loopVariable]);
        } else {
          /**
           * if user did enter a text then apply starts with operation for each option against the input and
           */
          if (
            allOptions[loopVariable].startsWith(
              state.inputValue.toUpperCase()
            ) &&
            !isInArray(allOptions[loopVariable], topFiftyOptions)
          )
            topFiftyOptions.push(allOptions[loopVariable]);
        }

        /**
         * limiting the length of the options to max of 50
         */
        if (
          topFiftyOptions.length > 50 ||
          allOptions.length - 1 === loopVariable
        ) {
          return topFiftyOptions;
        }
      }
    },
    [freeSolo, getAllOptionsAfterApplyingConfigParams]
  );

  return filter ? (
    <Autocomplete
      renderInput={params => (
        <TextField
          {...params}
          label={filterName}
          variant="standard"
          className={[
            className,
            isNonEmptyArray(values) ? "selected-filter" : "noop"
          ].join(" ")}
        />
      )}
      renderOption={(option, { selected }) => {
        return option !== "Fetching..." && option !== "No options" ? ( //if option is fetching do not show checkboxes
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              color="primary"
              checked={selected}
              className={className}
            />
            {option}
          </React.Fragment>
        ) : (
          <div className="auto-complete-loading-placeholder">{option}</div>
        );
      }}
      renderTags={() => {}}
      options={
        filter.values.length > 0
          ? filter.values.map(options => options.name)
          : ["No options"]
      }
      size="small"
      popupIcon={<Arrow />}
      multiple
      onChange={(event, value) => {
        dispatch(
          setSelectedFilterAction({
            isCurrentSchedule: isShowingASolverRun ? false : true,
            filterName: filter.filterName.toLowerCase(),
            selectedFilter: value
          })
        );
      }}
      value={values}
      filterOptions={(options, state) => filterOption(options, state, values)}
      disableCloseOnSelect
      freeSolo={freeSolo ? true : false}
      disabled={disabled ? true : false}
    />
  ) : null;
}
