import { useCallback, useEffect, useRef } from "react";

/**
 *  A react hook that invokes a callback when user scrolls to the bottom
 *
 * @param onBottom Required callback that will be invoked when scrolled to bottom
 * @param offset Offset from bottom of page in pixels. E.g. 300 will trigger onBottom 300px from the bottom of the page
 * @return React.MutableRefObject Optionally you can use this to pass to a element to use that as the scroll container
 */
function useBottomScrollListener(onBottom: () => void, offset: number = 0) {
  const containerRef = useRef(null);

  const handleOnScroll = useCallback(() => {
    if (containerRef.current != null) {
      const scrollNode = containerRef.current;
      const scrollContainerBottomPosition = Math.round(
        scrollNode.scrollTop + scrollNode.clientHeight
      );
      const scrollPosition = Math.round(scrollNode.scrollHeight - offset);

      if (scrollPosition <= scrollContainerBottomPosition) {
        onBottom();
      }
    } else {
      const scrollNode = document.scrollingElement || document.documentElement;
      const scrollContainerBottomPosition = Math.round(
        scrollNode.scrollTop + window.innerHeight
      );
      const scrollPosition = Math.round(scrollNode.scrollHeight - offset);

      if (scrollPosition <= scrollContainerBottomPosition) {
        onBottom();
      }
    }
  }, [offset, onBottom]);
  useEffect(() => {
    const ref = containerRef.current;
    if (ref != null) {
      ref.addEventListener("scroll", handleOnScroll);
    } else {
      window.addEventListener("scroll", handleOnScroll);
    }

    return () => {
      if (ref != null) {
        ref.removeEventListener("scroll", handleOnScroll);
      } else {
        window.removeEventListener("scroll", handleOnScroll);
      }
    };
  }, [handleOnScroll]);

  return containerRef;
}

export default useBottomScrollListener;
