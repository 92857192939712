import React from "react";
import { useSelector } from "react-redux";

import { SITE_ORANGE_COLOR } from "../../../constants/disruptions/color";
import { isInArray } from "../../../utils/arrayUtils";
import { shouldShowFlightDetails } from "../../../utils/pairingUtils";

export default function AirportInfo({ dateRange, flightData, flight, isRTG }) {
  const selectedAirportFilter = useSelector(
    store => store.searchCriteria.selectedFilter.airport
  );

  const selectedAirportFilterForSolverOutput = useSelector(
    store => store.searchCriteria.selectedFilterForSolverOutput.airport
  );

  const selectedBaseFilter = useSelector(
    store => store.searchCriteria.selectedFilter.base
  );

  const selectedBaseFilterForSolverOutput = useSelector(
    store => store.searchCriteria.selectedFilterForSolverOutput.base
  );

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  /**
   * check if the given airport is present in any of the selected filter
   */
  const getBackgroundColor = React.useCallback(
    airport =>
      `${
        isInArray(
          airport,
          isShowingASolverRun
            ? [
                ...selectedAirportFilterForSolverOutput,
                ...selectedBaseFilterForSolverOutput
              ]
            : [...selectedAirportFilter, ...selectedBaseFilter]
        )
          ? SITE_ORANGE_COLOR
          : "initial"
      }`,
    [
      selectedAirportFilter,
      selectedAirportFilterForSolverOutput,
      selectedBaseFilter,
      selectedBaseFilterForSolverOutput,
      isShowingASolverRun
    ]
  );

  const shouldShowFlightDetailsMemoised = React.useCallback(() => {
    return shouldShowFlightDetails(
      dateRange.rangeInDaysInSingleViewPort,
      flightData
    );
  }, [flightData, dateRange.rangeInDaysInSingleViewPort]);

  return (
    <>
      {shouldShowFlightDetailsMemoised() ? (
        <span className="source-destination-info">
          <span style={{ background: getBackgroundColor(flight.origin) }}>
            {flight.origin}
          </span>
          <span style={{ background: getBackgroundColor(flight.destination) }}>
            {flight.destination}
          </span>
        </span>
      ) : null}
    </>
  );
}
