import React from "react";

import "./AllFiltersV2.scss";

import {
  performApiCallToGetAllMetaData,
  processFilter
} from "../../../../utils/disruptionSummaryUtils";
import {
  BASE_ENDPOINT,
  FILTERS,
  LIST_RULE_SETS,
  LIST_SOLVER_SCENARIOS,
  LIST_STRATEGIES
} from "../../../../constants/api";
import {
  setAllRuleSetMetaDataAction,
  setAllStrategyMetaDataAction,
  setSelectedRuleSetAction,
  setSelectedStrategyAction
} from "../../../../redux/ruleAndStrategyManagerReducer";
import { useDispatch } from "react-redux";
import {
  setFilterDataFromBackendAction,
  setSelectedFilterFromBackendAction
} from "../../../../redux/searchCriteriaReducer";
import { logErrorMessageForMonitoring } from "../../../../utils/errorUtils";
import { getAccessTokenForUser } from "../../../../utils/authUtils";
import Axios from "axios";
import { setAllScenarioMetadataAction } from "../../../../redux/solverDataReducer";
import { FILTER_DISPLAY_CONFIG } from "../../../../constants/disruptions/filter";
import FilterMapper from "./FilterMapper";
import FilterClearApply from "./FilterClearApply";
import DrawerContainer from "./DrawerContainer";

import "../RulesAndStrategyManager/RulesAndStrategyManager.scss";

export default function AllFiltersV2() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    performApiCallToGetAllMetaData(LIST_RULE_SETS).then(value => {
      dispatch(setAllRuleSetMetaDataAction(value));

      if (value !== null)
        value.forEach(ruleSet => {
          if (ruleSet.ruleSetName === "Default RuleSet") {
            dispatch(setSelectedRuleSetAction(ruleSet));
          }
        });
    });

    performApiCallToGetAllMetaData(LIST_STRATEGIES).then(value => {
      dispatch(setAllStrategyMetaDataAction(value));

      if (value !== null)
        value.forEach(strategy => {
          if (strategy.strategyName === "Default Strategy") {
            dispatch(setSelectedStrategyAction(strategy));
          }
        });
    });

    performApiCallToGetAllMetaData(FILTERS)
      .then(value => {
        /**
         * process the received filter data into the form that can be consumed by the UI
         */
        const processedFilterData = processFilter(
          value.filters,
          value.selectedFilter
        );

        dispatch(
          setSelectedFilterFromBackendAction(processedFilterData.selectedFilter)
        );
        dispatch(setFilterDataFromBackendAction(processedFilterData.filter));
      })
      .catch(reason =>
        logErrorMessageForMonitoring(
          "Failed to fetch Filter response... ",
          reason
        )
      );
  }, [dispatch]);

  React.useEffect(() => {
    const fetchAllScenariosForUser = async () => {
      const token = await getAccessTokenForUser();
      const url = BASE_ENDPOINT + LIST_SOLVER_SCENARIOS;
      const headers = {
        Authorization: token
      };
      const listScenarioResponse = await Axios.get(url, {
        headers: headers
      });

      dispatch(setAllScenarioMetadataAction(listScenarioResponse.data));
    };
    fetchAllScenariosForUser();
  }, [dispatch]);

  return (
    <div className="filter-v2-container">
      <div className="filter-v2-filter-section">
        {FILTER_DISPLAY_CONFIG.map((filter, index) => (
          <FilterMapper filter={filter} key={`${filter.name}-${index}`} />
        ))}
      </div>
      <>
        <FilterClearApply />
      </>
      <>
        <DrawerContainer />
      </>
    </div>
  );
}
