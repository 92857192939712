import React from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomCheckbox from "../../../../../partials/checkbox/CustomCheckBox";

import { isInArray } from "../../../../../../utils/arrayUtils";
import { includeExcludeCrewsForSolver } from "../../../../../../utils/solverUtil";
import { convertToPascalCase } from "../../../../../../utils/stringUtils";

import { SELECT_ALL_DESELECT_ALL_VALUES_MAP } from "../../../../../../constants/disruptions/durationSelect";

import "./CrewsToBeResolved.scss";

export default function CrewsToBeResolved({ noCrewsSelected, crewsToBeShown }) {
  const dispatch = useDispatch();

  const includedCrewsForSolver = useSelector(
    store => store.searchCriteria.includedCrewsForSolver
  );

  const selectAllOrDeselectAllState = useSelector(
    store => store.searchCriteria.selectAllOrDeselectAllState
  );

  const handleStateChangeForCrewCheckBox = React.useCallback(
    (isChecked, crewId) => {
      includeExcludeCrewsForSolver(
        isChecked,
        crewId,
        includedCrewsForSolver,
        dispatch
      );
    },
    [dispatch, includedCrewsForSolver]
  );
  return (
    <div className="solver-crew-info">
      {noCrewsSelected ? (
        <div className="crew-row no-bottom-border">No crew selected.</div>
      ) : (
        <>
          {selectAllOrDeselectAllState ===
            SELECT_ALL_DESELECT_ALL_VALUES_MAP.SELECT_ALL && (
            <div className="crew-row no-bottom-border">
              NOTE: All crews selected as part of the filter combination will be
              sent for solve. You might be seeing only a subset of the selected
              crew here.
            </div>
          )}
          {crewsToBeShown.map(crew => (
            <div className="crew-row">
              <CustomCheckbox
                stateValue={isInArray(
                  crew.crewExternalId,
                  includedCrewsForSolver
                )}
                stateChangeHandler={handleStateChangeForCrewCheckBox}
                index={crew.crewExternalId}
              />
              {convertToPascalCase(`${crew.firstname} ${crew.lastname}`)}
            </div>
          ))}
        </>
      )}
    </div>
  );
}
