import React from "react";
import { useSelector } from "react-redux";
import { MILLISECONDS_IN_A_MINUTE } from "../../../constants/disruptions/timeline";
import { getTwoDigitStringFromNumber } from "../../../utils/timelineUtils";

export default function DutyPeriod({ dutyInfo, unplannedDiversionCount }) {
  const showGanttInUTC = useSelector(store => store.userInfo.showGanttInUTC);

  /**
   * set the report time and release time according to the state of showGanttInUTC:
   * false -> add/sub the required offset to the prop values
   * true -> keep the prop values as it is
   */
  const getReportTimeAndReleaseTime = React.useCallback(() => {
    let reportTime, releaseTime;
    if (!showGanttInUTC) {
      reportTime = Number(
        Number(new Date(dutyInfo.reportTime)) +
          Number(dutyInfo.reportTimeTimezone * MILLISECONDS_IN_A_MINUTE)
      );
      releaseTime = Number(
        Number(new Date(dutyInfo.releaseTime)) +
          Number(dutyInfo.releaseTimeTimezone * MILLISECONDS_IN_A_MINUTE)
      );
    } else {
      reportTime = Number(new Date(dutyInfo.reportTime));
      releaseTime = Number(new Date(dutyInfo.releaseTime));
    }

    return { releaseTime: releaseTime, reportTime: reportTime };
  }, [
    showGanttInUTC,
    dutyInfo.reportTime,
    dutyInfo.reportTimeTimezone,
    dutyInfo.releaseTime,
    dutyInfo.releaseTimeTimezone
  ]);

  /**
   * state variable to hold the derived values of report and release time
   */
  const [reportTimeAndReleaseTime] = React.useState(
    getReportTimeAndReleaseTime()
  );

  const scheduledDutyTime = `${getTwoDigitStringFromNumber(
    dutyInfo.scheduledDutyTime
      ? dutyInfo.scheduledDutyTime.hours
      : dutyInfo.dutyTime.hours
  )}:${getTwoDigitStringFromNumber(
    dutyInfo.scheduledDutyTime
      ? dutyInfo.scheduledDutyTime.minutes
      : dutyInfo.dutyTime.minutes
  )}`;

  const actualDutyTime = `${
    !(
      dutyInfo.actualDutyTime ||
      dutyInfo.dutyTime || //added this condition to handle scenarios where actualDutyTime is null and dutyTime is undefined, encountered this case in the solver output screen
      (dutyInfo.actualDutyTime && dutyInfo.dutyTime)
    )
      ? "NA"
      : `${getTwoDigitStringFromNumber(
          dutyInfo.actualDutyTime
            ? dutyInfo.actualDutyTime.hours
            : dutyInfo.dutyTime.hours
        )}:${getTwoDigitStringFromNumber(
          dutyInfo.actualDutyTime
            ? dutyInfo.actualDutyTime.minutes
            : dutyInfo.dutyTime.minutes
        )}`
  }`;

  const scheduledFdpTime = `${getTwoDigitStringFromNumber(
    dutyInfo.scheduledFdpTime
      ? dutyInfo.scheduledFdpTime.hours
      : dutyInfo.fdpTime.hours
  )}:${getTwoDigitStringFromNumber(
    dutyInfo.scheduledFdpTime
      ? dutyInfo.scheduledFdpTime.minutes
      : dutyInfo.fdpTime.minutes
  )}`;

  const actualFdpTime = `${
    dutyInfo.actualFdpTime === null //dutyInfo.actualFdpTime is null incase of Cancelled flights, showing "NA" instead
      ? "NA"
      : `${getTwoDigitStringFromNumber(
          dutyInfo.actualFdpTime
            ? dutyInfo.actualFdpTime.hours
            : dutyInfo.fdpTime.hours
        )}:${getTwoDigitStringFromNumber(
          dutyInfo.actualFdpTime
            ? dutyInfo.actualFdpTime.minutes
            : dutyInfo.fdpTime.minutes
        )}`
  }`;

  return (
    <div className="duty-period-container">
      <div className="duty-period-unexpanded">
        <div className="duty-report-release-block block">
          <span>
            {new Date(reportTimeAndReleaseTime.reportTime).getUTCMonth() + 1}/
            {/**getUTCMonth() returns months in the range {0-11}**/}
            {new Date(reportTimeAndReleaseTime.reportTime).getUTCDate()}&nbsp;
            {getTwoDigitStringFromNumber(
              new Date(reportTimeAndReleaseTime.reportTime).getUTCHours()
            )}
            :
            {getTwoDigitStringFromNumber(
              new Date(reportTimeAndReleaseTime.reportTime).getUTCMinutes()
            )}
            &nbsp;
          </span>
          <span>-&nbsp;</span>
          <span>
            {new Date(reportTimeAndReleaseTime.releaseTime).getUTCMonth() + 1}/
            {new Date(reportTimeAndReleaseTime.releaseTime).getUTCDate()}&nbsp;
            {getTwoDigitStringFromNumber(
              new Date(reportTimeAndReleaseTime.releaseTime).getUTCHours()
            )}
            :
            {getTwoDigitStringFromNumber(
              new Date(reportTimeAndReleaseTime.releaseTime).getUTCMinutes()
            )}
          </span>
        </div>
        <div className="block">
          <div>
            <span>
              {scheduledDutyTime}
              &nbsp;
            </span>
            <span>-&nbsp;</span>
            <span className={`font-size-15 bold`}>{actualDutyTime}</span>
          </div>
        </div>
        <div className="block">
          <div>
            <span>
              {scheduledFdpTime}
              &nbsp;
            </span>
            <span>-&nbsp;</span>
            <span className={`font-size-15 bold`}>{actualFdpTime}</span>
          </div>
        </div>
        <div className="block">
          <span>
            {getTwoDigitStringFromNumber(dutyInfo.numberOfLandings)}
            {unplannedDiversionCount && unplannedDiversionCount > 0
              ? `/+${unplannedDiversionCount}`
              : null}
          </span>
        </div>
      </div>
    </div>
  );
}
