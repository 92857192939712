import { VIOLATING_DEADHEAD_FLIGHT } from "../constants/disruptions/color";
import {
  CREW_TYPE,
  LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
  LOCAL_TIME_ZONE_NAME_KEY
} from "../constants/disruptions/disruptionSummary";
import { RULE_TYPE } from "../constants/disruptions/rules";
import { MILLISECONDS_IN_A_MINUTE } from "../constants/disruptions/timeline";
import {
  MINIMUM_WIDTH_FOR_RTG_FLIGHT,
  WIDTH_FOR_PPP_FLIGHT
} from "../constants/disruptions/waitingTime";
import { isNonEmptyArray } from "./arrayUtils";
import {
  findColorForViolation,
  findUtcArrivalTimeOfFlight,
  findUtcDepartureTimeOfFlight
} from "./pairingUtils";
import { getWidthForEntity } from "./timelineUtils";
import { getTimezoneOffsetForGivenDateTimeAndUserSelectedZone } from "./dateTimeUtils";

/**
 * TODO: Move all helper function from within Flight component to here
 */

/**
 * returns the background color for the cancelled deadhead flight
 * @param {Object} ruleDisplayConfigForCancelledFlight | config object that contains color info for cancelled flight
 * @param {Boolean} isFlightOps | true/false depending upon whether the crew isFlightOps or no
 */
export function getColorForCancelledDeadhead(
  ruleDisplayConfigForCancelledFlight,
  isFlightOps
) {
  const DEFAULT_COLOR = "#d0021b";
  return ruleDisplayConfigForCancelledFlight
    ? isFlightOps
      ? ruleDisplayConfigForCancelledFlight[CREW_TYPE.FLIGHT_OPS]
        ? ruleDisplayConfigForCancelledFlight[CREW_TYPE.FLIGHT_OPS][
            RULE_TYPE.GENERAL
          ]["color"]
        : DEFAULT_COLOR
      : ruleDisplayConfigForCancelledFlight[CREW_TYPE.IN_FLIGHT]
      ? ruleDisplayConfigForCancelledFlight[CREW_TYPE.IN_FLIGHT][
          RULE_TYPE.GENERAL
        ]["color"]
      : DEFAULT_COLOR
    : DEFAULT_COLOR; //default color for a cancelled deadhead, acts as a fallback color if no rule display config is present
}

/**
 * returns the linear gradient used for deadhead with background as the provided color
 * @param {*} color
 */
export function getBackgroundForViolatingDeadHeads(color) {
  return VIOLATING_DEADHEAD_FLIGHT.replaceAll(
    ":replacementColor",
    String(color)
  );
}

/**
 * returns the width for a flight block
 *   - returns 0.6 for a RTG block
 *   - returns 2.1 for a PPP block
 *   - returns the calculated width for the remaining flight blocks
 * @param {*} startTime
 * @param {*} endTime
 * @param {*} isRTG
 */
export function getWidthForFlightBlock(
  startTime,
  endTime,
  widthOfOneDay,
  isRTG,
  isPPP
) {
  if (isPPP) return WIDTH_FOR_PPP_FLIGHT;

  let widthOfFlight = getWidthForEntity(endTime, startTime, widthOfOneDay);

  if (isRTG) {
    return widthOfFlight < MINIMUM_WIDTH_FOR_RTG_FLIGHT
      ? MINIMUM_WIDTH_FOR_RTG_FLIGHT
      : widthOfFlight;
  }

  return widthOfFlight;
}

/**
 * returns the start time of a flight
 * @param {*} flight
 * @param {*} showGanttInUTC
 * @param {*} userAttributes
 */
export function getStartTimeOfFlight(flight, showGanttInUTC, userAttributes) {
  const utcStartTime = findUtcDepartureTimeOfFlight(flight);

  let localOffset = getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
    utcStartTime,
    userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][LOCAL_TIME_ZONE_NAME_KEY]
  );

  return showGanttInUTC
    ? Number(new Date(utcStartTime))
    : Number(
        Number(new Date(utcStartTime)) +
          Number(localOffset * MILLISECONDS_IN_A_MINUTE)
      );
}

/**
 * returns the end time of a flight
 * @param {*} flight
 * @param {*} showGanttInUTC
 * @param {*} userAttributes
 */
export function getEndTimeOfFlight(flight, showGanttInUTC, userAttributes) {
  const utcEndTime = findUtcArrivalTimeOfFlight(flight);

  let localOffset = getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
    utcEndTime,
    userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][LOCAL_TIME_ZONE_NAME_KEY]
  );

  return showGanttInUTC
    ? Number(new Date(utcEndTime))
    : Number(
        Number(new Date(utcEndTime)) +
          Number(localOffset * MILLISECONDS_IN_A_MINUTE)
      );
}

/**
 * returns the color of violation for the flight block
 * @param {*} violatingData
 * @param {*} ruleDisplayConfig
 * @param {*} isFlightOps
 */
export function getColorForFlight(
  violatingData,
  ruleDisplayConfig,
  isFlightOps
) {
  if (violatingData && isNonEmptyArray(Object.keys(violatingData))) {
    for (let violationKey of Object.keys(violatingData)) {
      const colorForTheBlock = findColorForViolation(
        violatingData[violationKey],
        ruleDisplayConfig,
        isFlightOps
      );
      if (colorForTheBlock) {
        return colorForTheBlock;
      }
    }
  }
  return null;
}

/**
 * returns the airports local time
 * @param {*} timeInUTC
 * @param {*} offset
 */
export function getAirportLocalTime(timeInUTC, offset) {
  return new Date(
    Number(timeInUTC) + Number(offset * MILLISECONDS_IN_A_MINUTE)
  );
}
