import React from "react";
import { useSelector } from "react-redux";

import DurationSelection from "./DurationSelection";
import Timeline from "./Timeline";

import "./SelectionTimelineContainer.scss";

export default function SelectionTimelineContainer(props) {
  const isRangePickerOverlayVisible = useSelector(
    (store) => store.showAndHide.isRangePickerOverlayVisible
  );
  return (
    <div className="selection-timeline-main">
      {/**
       * This component is divided into 2 parts:
       * - a. DurationSelection - The left fixed section that has info about
       *        visible duration of the timeline as well option for all crew selection.
       * - b. Timeline - The right section having the timeline divided in days and hours
       *        and a draggable section to denote solver's time scope.
       */}
      <div
        className="selection-timeline-container"
        // TODO: onScroll handler may need to be added for left scroll of timeline
        ref={props.forwardRef}
        style={{
          height: isRangePickerOverlayVisible ? "90vh" : "10vh",
        }} /** make timeline container 90vh when the range picker is mouseOver-ed upon */
      >
        <DurationSelection />
        <Timeline />
      </div>
    </div>
  );
}
