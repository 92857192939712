import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Axios from "axios";
import * as serviceWorker from "./serviceWorker";
import App from "./components/App.js";
import store from "./redux/store";
import PageNotFound from "./components/misc/PageNotFound";
import { BASE_ENDPOINT, TENANT_URI } from "./constants/api";

import GanttViewer from "./components/GanttViewer/GanttViewer";
import { GANTT_VIEWER } from "./constants/gantt-viewer";
import {
  logErrorMessageForMonitoring,
  initializeErrorMonitoringPlugin
} from "./utils/errorUtils";

import "./sass/index.scss";
import { configureAmplify } from "./utils/amplifyUtils";
import { updateReduxWithTenantConfigDetails } from "./utils/indexUtils";

let isErrorLoggingEnabled = false;
try {
  initializeErrorMonitoringPlugin();
  isErrorLoggingEnabled = true;
} catch (error) {
  console.log("Proactive error logging disabled.");
}

/**
 * Calling the tenant config API to fetch the tenant specific configurations
 */
Axios.get(BASE_ENDPOINT + TENANT_URI)
  .then(response => {
    if (response && response.data && response.data.cognito) {
      configureAmplify(response.data);

      updateReduxWithTenantConfigDetails(store, response);

      if (
        typeof process.env.REACT_APP_VIEW === "string" &&
        process.env.REACT_APP_VIEW === GANTT_VIEWER
      ) {
        ReactDOM.render(
          <Provider store={store}>
            <GanttViewer />
          </Provider>,
          document.getElementById("root")
        );
      } else {
        ReactDOM.render(
          <Provider store={store}>
            <App />
          </Provider>,
          document.getElementById("root")
        );
      }
    }
  })
  .catch(err => {
    isErrorLoggingEnabled &&
      logErrorMessageForMonitoring(
        `Tenant config fetch failed with error message: ${
          err.message ? err.message : err
        }`
      );
    console.log(
      "error in fetching tenant config - ",
      err.message ? err.message : err
    );
    ReactDOM.render(
      <PageNotFound>
        <div>
          Tenant config for your airlines not found. Please contact site
          administrator.
        </div>
      </PageNotFound>,
      document.getElementById("root")
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
