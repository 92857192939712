import React from "react";

/**
 * @param {Object}
 *    item-1 | hour | String | hour to be displayed
 *    item-2 | width | String | width each hour should take
 */
export default function Hour({ hour, width }) {
  return (
    <div className="hour">
      <div style={{ width: width + "vw" }}>{hour}</div>
    </div>
  );
}
