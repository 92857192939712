import React from "react";
import Axios from "axios";
import hash from "object-hash";
import { Add, Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  MenuItem,
  Button,
  TextField,
  ClickAwayListener,
  Tab,
  Tabs,
  AppBar,
  Tooltip
} from "@material-ui/core";

import Rules from "./Rules";
import Arrow from "../../../partials/arrow/Arrow";
import SimpleTooltipMessage from "../../../partials/simpletooltipmessages/SimpleTooltipMessage";
import LoadingState from "../../../partials/LoadingState/LoadingState";
import ConfirmDialog from "../../../partials/ConfirmDialog/ConfirmDialog";

import {
  setSelectedRuleSetAction,
  setFetchedRuleSetDataToStoreAction,
  resetRuleSetAction,
  setAllRuleSetMetaDataAction
} from "../../../../redux/ruleAndStrategyManagerReducer";
import { setIsRuleSetDrawerVisibleAction } from "../../../../redux/showAndHideReducer";

import { isInArray } from "../../../../utils/arrayUtils";
import { getAccessTokenForUser } from "../../../../utils/authUtils";
import {
  flattenRuleSet,
  processRuleSet,
  performApiCallToGetAllMetaData,
  getAllNamesFromMetaData
} from "../../../../utils/disruptionSummaryUtils";

import { LIST_RULE_SETS, BASE_ENDPOINT } from "../../../../constants/api";
import { USER_ROLES } from "../../../../constants/auth/signUp";
import {
  ERROR_MESSAGES,
  DEFAULT_RULE_SET,
  CREW_TYPE,
  LABEL_FOR_NEW_RULE_SET_TEXT_FIELD
} from "../../../../constants/disruptions/disruptionSummary";
import { getClickPath } from "../../../../utils/pairingUtils";
import TabPanel from "../../../partials/tabPanel/TabPanel";

export default function RulesManager() {
  const dispatch = useDispatch();

  /**
   * redux subscriptions
   */
  const allRuleSetMetaData = useSelector(
    store => store.ruleAndStrategy.allRuleSetMetaData
  );

  const selectedRuleSet = useSelector(
    store => store.ruleAndStrategy.selectedRuleSet
  );
  const currentWorkingRuleSet = useSelector(
    store => store.ruleAndStrategy.currentWorkingRuleSet
  );

  const fetchedRuleSet = useSelector(
    store => store.ruleAndStrategy.fetchedRuleSet
  );

  const isRuleManagerDrawerVisible = useSelector(
    store => store.showAndHide.isRuleManagerDrawerVisible
  );

  const userGroups = useSelector(store => store.userInfo.userGroups);

  /**
   * component states
   */
  const [newRuleSetName, setNewRuleSetName] = React.useState({
    name: "",
    errorText: "",
    error: false
  });

  const [isAddNewRuleScreenVisible, setIsAddNewRuleScreenVisible] =
    React.useState(false);

  const [isRuleSetFetched, setIsRuleSetFetched] = React.useState(false);

  const [isConfirmDialogVisible, setIsConfirmDialogVisible] =
    React.useState(false);

  const [notification, setNotification] = React.useState({
    shouldNotify: false,
    message: ""
  });

  //if user has "Rules" group in his usergroups then give him permission to edit rule values
  const [hasPermissionToEdit] = React.useState(
    isInArray(USER_ROLES.Rules, userGroups)
  );

  /**
   * react callbacks
   */
  const fetchRuleSetDataForId = React.useCallback(
    async id => {
      setIsRuleSetFetched(false);

      const token = await getAccessTokenForUser();
      const url = BASE_ENDPOINT + LIST_RULE_SETS + `/${id}`;
      const headers = {
        Authorization: token
      };
      const response = await Axios.get(url, {
        headers: headers
      });

      if (response.data !== undefined) {
        let ruleSetData = processRuleSet(response.data);
        dispatch(
          setFetchedRuleSetDataToStoreAction({
            current: ruleSetData,
            fetched: response.data
          })
        );
        setIsRuleSetFetched(true);
      }
    },
    [dispatch]
  );

  const isDirty = React.useCallback(() => {
    let isThereChange = false;

    /**
     * prevents the isDirty when user closes the rule manager drawer before the ruleSet data is fetched
     */
    if (Object.keys(currentWorkingRuleSet).length === 0) return isThereChange;

    const currentRuleSet = [
      ...flattenRuleSet(currentWorkingRuleSet.contractual.flightOpsRules),
      ...flattenRuleSet(currentWorkingRuleSet.contractual.inFlightRules),
      ...flattenRuleSet(currentWorkingRuleSet.far.flightOpsRules),
      ...flattenRuleSet(currentWorkingRuleSet.far.inFlightRules)
    ];

    if (
      hash(currentRuleSet, { unorderedArrays: true }) !==
      hash(fetchedRuleSet.rules, { unorderedArrays: true })
    )
      isThereChange = true;
    return isThereChange;
  }, [currentWorkingRuleSet, fetchedRuleSet.rules]);

  const closeRuleSetDrawer = React.useCallback(() => {
    dispatch(setIsRuleSetDrawerVisibleAction(false));
  }, [dispatch]);

  const closeConfirmDialog = React.useCallback(() => {
    setIsConfirmDialogVisible(false);
  }, []);

  const getDefaultRuleSet = React.useCallback(() => {
    let defaultRuleSet;
    allRuleSetMetaData.forEach(ruleSet => {
      if (ruleSet.ruleSetName === "Default RuleSet") {
        defaultRuleSet = ruleSet;
      }
    });
    return defaultRuleSet;
  }, [allRuleSetMetaData]);

  const publishRuleSetData = React.useCallback(async (newRuleSet, id) => {
    const token = await getAccessTokenForUser();
    const headers = {
      Authorization: token,
      "Content-Type": "Application/json"
    };

    if (id) {
      let url = BASE_ENDPOINT + LIST_RULE_SETS + `/${id}`;
      return new Promise((resolve, reject) => {
        Axios.patch(url, newRuleSet, { headers: headers })
          .then(value => resolve(value))
          .catch(reason => reject(reason));
      });
    } else {
      let url = BASE_ENDPOINT + LIST_RULE_SETS;
      return new Promise((resolve, reject) => {
        Axios.post(url, newRuleSet, { headers: headers })
          .then(value => resolve(value))
          .catch(reason => reject(reason));
      });
    }
  }, []);

  const handleOnClickForSubmitButton = React.useCallback(
    event => {
      if (isAddNewRuleScreenVisible) {
        if (newRuleSetName.name.length === 0) {
          setNewRuleSetName({
            name: "",
            error: true,
            errorText: ERROR_MESSAGES.ZERO_LENGTH
          });
        } else if (
          isInArray(
            newRuleSetName.name,
            getAllNamesFromMetaData(allRuleSetMetaData, "ruleSetName")
          )
        ) {
          setNewRuleSetName({
            ...newRuleSetName,
            error: true,
            errorText: ERROR_MESSAGES.ALREADY_IN_USE
          });
        } else {
          let newRuleSet = {
            ruleSetName: newRuleSetName.name,
            rules: [
              ...flattenRuleSet(
                currentWorkingRuleSet.contractual.flightOpsRules
              ),
              ...flattenRuleSet(
                currentWorkingRuleSet.contractual.inFlightRules
              ),
              ...flattenRuleSet(currentWorkingRuleSet.far.flightOpsRules),
              ...flattenRuleSet(currentWorkingRuleSet.far.inFlightRules)
            ]
          };

          setIsRuleSetFetched(false);

          publishRuleSetData(newRuleSet)
            .then(() => {
              performApiCallToGetAllMetaData(LIST_RULE_SETS).then(value => {
                dispatch(setAllRuleSetMetaDataAction(value));

                if (value !== null) {
                  let currentRuleSet;
                  value.forEach(ruleSet => {
                    if (ruleSet.ruleSetName === newRuleSetName.name)
                      currentRuleSet = ruleSet;
                  });
                  dispatch(setSelectedRuleSetAction(currentRuleSet));
                  setIsAddNewRuleScreenVisible(false);
                }
              });
            })
            .catch(reason => {
              setIsRuleSetFetched(true);
              setNotification({
                shouldNotify: true,
                message: "RuleSet was not added."
              });
            });
        }
      } else {
        let newRuleSet = {
          id: selectedRuleSet.id,
          ruleSetName: currentWorkingRuleSet.name,
          rules: [
            ...flattenRuleSet(currentWorkingRuleSet.contractual.flightOpsRules),
            ...flattenRuleSet(currentWorkingRuleSet.contractual.inFlightRules),
            ...flattenRuleSet(currentWorkingRuleSet.far.flightOpsRules),
            ...flattenRuleSet(currentWorkingRuleSet.far.inFlightRules)
          ]
        };

        publishRuleSetData(newRuleSet, selectedRuleSet.id)
          .then(value => {
            setNotification({
              shouldNotify: true,
              message: "RuleSet successfully updated."
            });

            performApiCallToGetAllMetaData(LIST_RULE_SETS).then(value => {
              dispatch(setAllRuleSetMetaDataAction(value));

              if (value !== null) {
                let currentRuleSet;
                value.forEach(ruleSet => {
                  if (ruleSet.ruleSetName === currentWorkingRuleSet.name)
                    currentRuleSet = ruleSet;
                });

                dispatch(setSelectedRuleSetAction(currentRuleSet));
                setIsAddNewRuleScreenVisible(false);
              }
            });

            setTimeout(() => {
              setNotification({
                shouldNotify: false,
                message: ""
              });
            }, 5000);
          })
          .catch(reason => {
            setIsRuleSetFetched(true);
            setNotification({
              shouldNotify: true,
              message: "RuleSet was not updated."
            });
          });
      }
    },
    [
      allRuleSetMetaData,
      currentWorkingRuleSet,
      dispatch,
      isAddNewRuleScreenVisible,
      newRuleSetName,
      publishRuleSetData,
      selectedRuleSet.id
    ]
  );

  const handleOnClickForCancelButton = React.useCallback(() => {
    if (isAddNewRuleScreenVisible) setIsAddNewRuleScreenVisible(false);
    dispatch(resetRuleSetAction());
    setNotification({ shouldNotify: false, message: "" });
  }, [dispatch, isAddNewRuleScreenVisible]);

  const handleOnInputForNewRuleSetNameTextField = React.useCallback(event => {
    setNewRuleSetName({
      error: false,
      name: event.target.value,
      errorText: ""
    });
    setNotification({ shouldNotify: false, message: "" });
  }, []);

  const handleOnChangeForRuleSetSelect = React.useCallback(
    event => {
      dispatch(setSelectedRuleSetAction(event.target.value));
    },
    [dispatch]
  );

  const handleOnClickAwayEvent = React.useCallback(
    event => {
      //checking if the event was caused by the sketch-picker or the cancel button

      let isTheClickAwayCausedBySketchPicker = false;
      let path = getClickPath(event);
      path &&
        path.forEach(path => {
          if (
            path.className === "sketch-picker " ||
            path.id === "cancel-button"
          ) {
            isTheClickAwayCausedBySketchPicker = true;
          }
        });

      if (!isTheClickAwayCausedBySketchPicker) {
        if (isDirty()) setIsConfirmDialogVisible(true);
        else dispatch(setIsRuleSetDrawerVisibleAction(false));
      }
    },
    [isDirty, dispatch]
  );

  const handleOnClickForAddButton = React.useCallback(() => {
    setIsAddNewRuleScreenVisible(true);
    setNotification({ shouldNotify: false, message: "" });
    dispatch(setSelectedRuleSetAction(getDefaultRuleSet()));
  }, [dispatch, getDefaultRuleSet]);

  const handleOnClickForCloseButton = React.useCallback(() => {
    dispatch(setIsRuleSetDrawerVisibleAction(false));
  }, [dispatch]);

  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

  const handleTabChange = React.useCallback((event, newValue) => {
    setCurrentTabIndex(newValue);
  }, []);

  /**
   * side effects
   */
  React.useEffect(() => {
    if (selectedRuleSet.id) {
      fetchRuleSetDataForId(selectedRuleSet.id);
    }
    setNotification({ shouldNotify: false, message: "" });
  }, [selectedRuleSet, isRuleManagerDrawerVisible, fetchRuleSetDataForId]);

  return (
    <div>
      <ClickAwayListener onClickAway={handleOnClickAwayEvent}>
        <div className="rules-manager-container">
          <>
            <div className="header">
              <div className="header-text">Rules Manager</div>
              <div className="close">
                <Button onClick={handleOnClickForCloseButton}>
                  <Close color="primary" fontSize={"small"} />
                </Button>
              </div>
            </div>
          </>
          <>
            <div className="text-field-select-menu-container">
              {isAddNewRuleScreenVisible ? (
                <div>
                  <div className="field">
                    <TextField
                      name="rule-name"
                      id="rule-name"
                      variant="outlined"
                      fullWidth
                      label={LABEL_FOR_NEW_RULE_SET_TEXT_FIELD}
                      required
                      onInput={handleOnInputForNewRuleSetNameTextField}
                      error={newRuleSetName.error}
                      helperText={newRuleSetName.errorText}
                      placeholder={DEFAULT_RULE_SET}
                      focused={true}
                    />
                  </div>
                </div>
              ) : (
                <div className="select-menu">
                  <Select
                    id="showHide"
                    onChange={handleOnChangeForRuleSetSelect}
                    IconComponent={props => <Arrow iconClass={props} />}
                    disableUnderline={true}
                    value={selectedRuleSet}
                    renderValue={value => value.ruleSetName}
                    MenuProps={{ disablePortal: true }}
                  >
                    {allRuleSetMetaData.map(value => (
                      <MenuItem
                        className="showhide-select-option"
                        value={value}
                        key={value.id}
                      >
                        {value.ruleSetName}
                      </MenuItem>
                    ))}
                  </Select>
                  <Tooltip
                    title={
                      !hasPermissionToEdit ? ( //show a tooltip to user if he has no permission to create new ruleset
                        <SimpleTooltipMessage
                          className="no-permission-info-text"
                          tooltipMessage={
                            "You do not have sufficient permission to create a new ruleset."
                          }
                        />
                      ) : (
                        ""
                      )
                    }
                    arrow
                    placement={"left"}
                  >
                    <div className="add">
                      <Button
                        onClick={handleOnClickForAddButton}
                        disabled={!hasPermissionToEdit} //disabling the add button if user does not have the required Rules permission
                      >
                        <Add color="primary" fontSize={"small"} />
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </>
          <>
            <div className="body">
              {isRuleSetFetched ? (
                <div>
                  <div className="tab-section">
                    <AppBar position="static">
                      <Tabs
                        value={currentTabIndex}
                        onChange={handleTabChange}
                        aria-label="simple tabs example"
                      >
                        <Tab label="CONTRACTUAL" />
                        <Tab label="FAR" />
                      </Tabs>
                      <TabPanel value={currentTabIndex} index={0}>
                        <div className="tab-rules">
                          <Rules
                            headerName={"FlightOps"}
                            ruleSet={
                              currentWorkingRuleSet.contractual.flightOpsRules
                            }
                            type={CREW_TYPE.FLIGHT_OPS}
                            isContractual
                            hasPermissionToEdit={hasPermissionToEdit}
                          />
                          <Rules
                            headerName={"InFlight"}
                            ruleSet={
                              currentWorkingRuleSet.contractual.inFlightRules
                            }
                            type={CREW_TYPE.IN_FLIGHT}
                            isContractual
                            hasPermissionToEdit={hasPermissionToEdit}
                          />
                        </div>
                      </TabPanel>

                      <TabPanel value={currentTabIndex} index={1}>
                        <div className="tab-rules">
                          <Rules
                            headerName={"FlightOps"}
                            ruleSet={currentWorkingRuleSet.far.flightOpsRules}
                            type={CREW_TYPE.FLIGHT_OPS}
                            isContractual={false}
                            hasPermissionToEdit={hasPermissionToEdit}
                          />
                          <Rules
                            headerName={"InFlight"}
                            ruleSet={currentWorkingRuleSet.far.inFlightRules}
                            type={CREW_TYPE.IN_FLIGHT}
                            isContractual={false}
                            hasPermissionToEdit={hasPermissionToEdit}
                          />
                        </div>
                      </TabPanel>
                    </AppBar>
                  </div>
                  {hasPermissionToEdit && (
                    <div className="cancel-submit-buttons">
                      <Button
                        id="cancel-button"
                        onClick={handleOnClickForCancelButton}
                      >
                        {!isAddNewRuleScreenVisible ? "Reset" : "Cancel"}
                      </Button>
                      <Button
                        id="submit-button"
                        onClick={handleOnClickForSubmitButton}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                  {notification.shouldNotify ? (
                    <div className="notification">{notification.message}</div>
                  ) : null}
                </div>
              ) : (
                <LoadingState loadingMessage={"Loading rule set data..."} />
              )}
            </div>
          </>
        </div>
      </ClickAwayListener>
      <div>
        {isConfirmDialogVisible && (
          <ConfirmDialog
            title={"Confirm"}
            question={"Do you want to exit? Any unsaved changes will be lost!"}
            confirmHandler={closeRuleSetDrawer}
            cancelHandler={closeConfirmDialog}
            open={isConfirmDialogVisible}
          />
        )}
      </div>
    </div>
  );
}
