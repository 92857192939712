import React from "react";
import MuiDrawer from "@material-ui/core/Drawer";

export default function Drawer({ open, ComponentToBeDisplayed, id }) {
  return (
    <div>
      <MuiDrawer open={open} anchor="right" id={id}>
        <div className="drawer-conatiner">
          <ComponentToBeDisplayed />
        </div>
      </MuiDrawer>
    </div>
  );
}
