import React from "react";
import { Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RuleTable from "./RuleTable";

export default function Rules({
  headerName,
  ruleSet,
  type,
  isContractual,
  hasPermissionToEdit
}) {
  return (
    <div className="rules-container">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="header">{headerName}</div>
        </AccordionSummary>
        <div>
          <RuleTable
            ruleSet={ruleSet}
            type={type}
            isContractual={isContractual}
            hasPermissionToEdit={hasPermissionToEdit}
          />
        </div>
      </Accordion>
    </div>
  );
}
