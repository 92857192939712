export const HOURS = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00"
];

export const GRANULARITY_OF_DAY_INFO = {
  LONG_DAY_AND_FULL_DATE: "LONG_DAY_AND_FULL_DATE",
  SHORT_DAY_AND_FULL_DATE: "SHORT_DAY_AND_FULL_DATE",
  NO_DAY_AND_PARTIAL_DATE: "NO_DAY_AND_PARTIAL_DATE"
};

export const VIEWWIDTH_COVERED_BY_GANTT_TIMELINE = 84;
export const DEFAULT_NUMBER_OF_DAYS_TO_BE_SHOWN_IN_ONE_VIEW = 1;
export const MILLISECONDS_IN_A_DAY = 86400000;
export const DEFAULT_NUMBER_OF_DAYS = 1;

export const DAY_DISPLAY = {
  DAY_LONG: "long",
  DAY_SHORT: "short"
};
export const MILLISECONDS_IN_AN_HOUR = 3600000;
export const MILLISECONDS_IN_A_MINUTE = 60000;
export const MINUTES_IN_AN_HOUR = 60;
export const HOURS_IN_A_DAY = 24;

/**
 * the lower bound for the slider
 */
export const SLIDER_MIN_RANGE = 0;

export const DAY_TO_NAME_MAPPING = {
  0: { short: "Sun", long: "Sunday" },
  1: { short: "Mon", long: "Monday" },
  2: { short: "Tue", long: "Tuesday" },
  3: { short: "Wed", long: "Wednesday" },
  4: { short: "Thu", long: "Thursday" },
  5: { short: "Fri", long: "Friday" },
  6: { short: "Sat", long: "Saturday" }
};
