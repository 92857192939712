import React from "react";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { Button, TextField } from "@material-ui/core";
import { TimelineDot as CommentMarker } from "@material-ui/lab";

import { setIsSavingCommentAction } from "../../../redux/violationDrillDownReducer";

import { BASE_ENDPOINT, GET_VIOLATION_COMMENT } from "../../../constants/api";

import { getAccessTokenForUser } from "../../../utils/authUtils";

export default function AddCommentSection({ userName, selectedViolation }) {
  const dispatch = useDispatch();

  const isSaving = useSelector(
    state => state.violationDrilledDownData.isSavingComment
  );

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const [newComment, setNewComment] = React.useState("");

  const handleChangeOnAddCommentTextField = React.useCallback(
    event => setNewComment(event.target.value),
    []
  );

  const isValidComment = React.useCallback(
    comment => String(comment).length > 0,
    []
  );

  const handleClickOnSaveButton = React.useCallback(async () => {
    if (isValidComment(newComment)) {
      dispatch(setIsSavingCommentAction(true));
      setNewComment("");

      const token = await getAccessTokenForUser();
      const url = BASE_ENDPOINT + GET_VIOLATION_COMMENT;
      const headers = {
        Authorization: token
      };

      const commentToBePosted = {
        comment: String(newComment).trim(),
        commentBy: userName,
        commentTime: new Date().toISOString()
      };

      const body = {
        violationMetaData: {
          violationId: selectedViolation.violationId,
          violationType: selectedViolation.violationType
        },
        newComment: commentToBePosted
      };

      Axios.post(url, body, {
        headers: headers
      })
        .then(response => {
          console.log("ADDED COMMENT:", response.data);
        })
        .catch(err => console.error(err));
    }
  }, [
    isValidComment,
    newComment,
    dispatch,
    userName,
    selectedViolation.violationId,
    selectedViolation.violationType
  ]);

  return (
    <div className="add-comment-section">
      <div className="user-icon">
        <CommentMarker variant={"outlined"} />
      </div>
      <div className="text-field-section">
        <TextField
          key={"add-comment-textfield"}
          variant={"outlined"}
          label={
            isShowingASolverRun
              ? "No commenting allowed in solver output section..."
              : "Add comment here..."
          }
          multiline
          value={newComment}
          onChange={handleChangeOnAddCommentTextField}
          disabled={
            isShowingASolverRun || selectedViolation.violationId === "NO_ID" //if there is no violationId for a violation i.e previous solver run then disable commenting as comments cannot be mapped
          }
        />
        <div
          className={`comment-button ${
            newComment.length > 0 ? "" : "outlined-button"
          }`}
          onClick={() => handleClickOnSaveButton()}
        >
          <Button
            id={"save-button"}
            variant={newComment.length > 0 ? "text" : "outlined"}
            disabled={
              isShowingASolverRun || selectedViolation.violationId === "NO_ID" //if there is no violationId for a violation i.e previous solver run then disable commenting as comments cannot be mapped
            }
          >{`${isSaving ? "Saving..." : "Save"}`}</Button>
        </div>
      </div>
    </div>
  );
}
