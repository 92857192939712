import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { Drawer, Button, MenuItem, ClickAwayListener } from "@material-ui/core";
import SignOutButton from "../../../auth/SignOut/Signout";
import { MAP_FROM_DRAWER_TYPE_TO_DRAWER_NAME } from "../../../../constants/disruptions/disruptionSummary";
import {
  setIsRuleSetDrawerVisibleAction,
  setIsStrategyDrawerVisibleAction,
  setIsHamburgerMenuVisibleAction,
  setIsUserProfileDrawerVisibleAction
} from "../../../../redux/showAndHideReducer";
import UploadLink from "../../../upload/UploadNavItem";
import { useDispatch, useSelector } from "react-redux";
import ReportsRedirect from "../../../reports/ReportsRedirect";

export default function HamburgerMenu() {
  const isHamburgerMenuVisible = useSelector(
    store => store.showAndHide.isHamburgerMenuVisible
  );
  const dispatch = useDispatch();
  return (
    <div className="hamburger-menu-container">
      <div>
        <Button onClick={() => dispatch(setIsHamburgerMenuVisibleAction(true))}>
          <MenuIcon />
        </Button>
      </div>

      <Drawer open={isHamburgerMenuVisible} anchor="right">
        <ClickAwayListener
          onClickAway={() => dispatch(setIsHamburgerMenuVisibleAction(false))}
        >
          <div className="right-side-menu">
            <div className="right-side-menu-body">
              <MenuItem
                onClick={() => {
                  dispatch(setIsHamburgerMenuVisibleAction(false));
                  dispatch(setIsRuleSetDrawerVisibleAction(true));
                }}
              >
                {MAP_FROM_DRAWER_TYPE_TO_DRAWER_NAME.RULE_MANAGER}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch(setIsHamburgerMenuVisibleAction(false));
                  dispatch(setIsStrategyDrawerVisibleAction(true));
                }}
              >
                {MAP_FROM_DRAWER_TYPE_TO_DRAWER_NAME.STRATEGY_MANAGER}
              </MenuItem>

              <ReportsRedirect />

              <MenuItem>
                <UploadLink />
              </MenuItem>
              {/* TODO: commenting as CRWEB-267, uncomment when ready to implement actual functionality */}
              {/* <MenuItem>Profile</MenuItem> */}
              <MenuItem
                onClick={() => {
                  dispatch(setIsUserProfileDrawerVisibleAction(true));
                }}
              >
                {MAP_FROM_DRAWER_TYPE_TO_DRAWER_NAME.USER_PROFILE}
              </MenuItem>
              <MenuItem>
                <SignOutButton />
              </MenuItem>
            </div>
          </div>
        </ClickAwayListener>
      </Drawer>
    </div>
  );
}
