import {
  processRuleSet,
  processStrategy,
} from "../utils/disruptionSummaryUtils";

const ACTIONS = {
  setAllRuleSetMetaData: "SET_ALL_RULE_SET_META_DATA",
  setAllStrategyMetaData: "SET_ALL_STRATEGY_META_DATA",
  setFetchedRuleSetDataToStore: "SET_FETCHED_RULE_SET_DATA_TO_STORE",
  setFetchedStrategyDataToStore: "SET_FETCHED_STRATEGY_DATA_TO_STORE",
  setSelectedRuleSet: "SET_SELECTED_RULE_SET",
  setSelectedStrategy: "SET_SELECTED_STRATEGY",
  setRuleSeverity: "SET_RULE_SEVERITY",
  setRuleColor: "SET_RULE_COLOR",
  setRuleSetValue: "SET_RULE_SET_VALUE",

  setIsStrategySelected: "SET_IS_STRATEGY_SELECTED",

  resetRuleSet: "RESET_RULE_SET",
  resetStrategy: "RESET_STRATEGY",
};

const initialState = {
  allRuleSetMetaData: [{ id: 1, ruleSetName: "Default RuleSet" }],
  selectedRuleSet: { id: 1, ruleSetName: "Default RuleSet" },
  fetchedRuleSet: {},
  currentWorkingRuleSet: {},

  allStrategyMetaData: [{ id: 1, strategyName: "Default Strategy" }],
  selectedStrategy: { id: 1, strategyName: "Default Strategy" },
  fetchedStrategy: {},
  currentWorkingStrategy: {},
};

export function ruleAndStrategyManagerReducer(
  prevState = initialState,
  action
) {
  let newState;
  switch (action.type) {
    case "SET_ALL_RULE_SET_META_DATA":
      newState = {
        ...prevState,
        allRuleSetMetaData: [...action.allRuleSetMetaData],
      };
      break;
    case "SET_SELECTED_RULE_SET":
      newState = {
        ...prevState,
        selectedRuleSet: action.selectedRuleSet,
      };
      break;
    case "SET_FETCHED_RULE_SET_DATA_TO_STORE":
      newState = {
        ...prevState,
        fetchedRuleSet: action.ruleSetData.fetched,
        currentWorkingRuleSet: action.ruleSetData.current,
      };
      break;
    case "SET_RULE_SET_VALUE":
      newState = {
        ...prevState,
        currentWorkingRuleSet: {
          ...prevState.currentWorkingRuleSet,
          [action.ruleSetValue.isContractual ? "contractual" : "far"]: {
            ...prevState.currentWorkingRuleSet[
              action.ruleSetValue.isContractual ? "contractual" : "far"
            ],
            [action.ruleSetValue.type]: {
              ...prevState.currentWorkingRuleSet[
                action.ruleSetValue.isContractual ? "contractual" : "far"
              ][action.ruleSetValue.type],
              [action.ruleSetValue.ruleName]: {
                ...prevState.currentWorkingRuleSet[
                  action.ruleSetValue.isContractual ? "contractual" : "far"
                ][action.ruleSetValue.type][action.ruleSetValue.ruleName],
                ruleValue: {
                  ...prevState.currentWorkingRuleSet[
                    action.ruleSetValue.isContractual ? "contractual" : "far"
                  ][action.ruleSetValue.type][action.ruleSetValue.ruleName][
                    "ruleValue"
                  ],
                  [action.ruleSetValue.key]: action.ruleSetValue.value,
                },
              },
            },
          },
        },
      };
      break;

    case "SET_RULE_SEVERITY":
      newState = {
        ...prevState,
        currentWorkingRuleSet: {
          ...prevState.currentWorkingRuleSet,
          [action.ruleSeverity.isContractual ? "contractual" : "far"]: {
            ...prevState.currentWorkingRuleSet[
              action.ruleSeverity.isContractual ? "contractual" : "far"
            ],
            [action.ruleSeverity.type]: {
              ...prevState.currentWorkingRuleSet[
                action.ruleSeverity.isContractual ? "contractual" : "far"
              ][action.ruleSeverity.type],
              [action.ruleSeverity.ruleName]: {
                ...prevState.currentWorkingRuleSet[
                  action.ruleSeverity.isContractual ? "contractual" : "far"
                ][action.ruleSeverity.type][action.ruleSeverity.ruleName],
                ruleSeverity: action.ruleSeverity.value,
              },
            },
          },
        },
      };
      break;

    case "SET_RULE_COLOR":
      newState = {
        ...prevState,
        currentWorkingRuleSet: {
          ...prevState.currentWorkingRuleSet,
          [action.ruleColor.isContractual ? "contractual" : "far"]: {
            ...prevState.currentWorkingRuleSet[
              action.ruleColor.isContractual ? "contractual" : "far"
            ],
            [action.ruleColor.type]: {
              ...prevState.currentWorkingRuleSet[
                action.ruleColor.isContractual ? "contractual" : "far"
              ][action.ruleColor.type],
              [action.ruleColor.ruleName]: {
                ...prevState.currentWorkingRuleSet[
                  action.ruleColor.isContractual ? "contractual" : "far"
                ][action.ruleColor.type][action.ruleColor.ruleName],
                ruleConfig: {
                  ...prevState.currentWorkingRuleSet[
                    action.ruleColor.isContractual ? "contractual" : "far"
                  ][action.ruleColor.type][action.ruleColor.ruleName][
                    "ruleConfig"
                  ],
                  color: action.ruleColor.value,
                },
              },
            },
          },
        },
      };
      break;

    case "RESET_RULE_SET":
      newState = {
        ...prevState,
        currentWorkingRuleSet: processRuleSet(prevState.fetchedRuleSet),
      };
      break;

    case "SET_SELECTED_STRATEGY":
      newState = {
        ...prevState,
        selectedStrategy: action.selectedStrategy,
      };
      break;

    case "SET_IS_STRATEGY_SELECTED":
      newState = {
        ...prevState,
        currentWorkingStrategy: {
          ...prevState.currentWorkingStrategy,
          strategies: {
            ...prevState.currentWorkingStrategy.strategies,
            [action.isSelected.name]: {
              ...prevState.currentWorkingStrategy.strategies[
                action.isSelected.name
              ],
              enabled: action.isSelected.isSelected,
            },
          },
        },
      };
      break;

    case "SET_FETCHED_STRATEGY_DATA_TO_STORE":
      newState = {
        ...prevState,
        fetchedStrategy: action.strategyData.fetched,
        currentWorkingStrategy: action.strategyData.current,
      };
      break;

    case "SET_ALL_STRATEGY_META_DATA":
      newState = {
        ...prevState,
        allStrategyMetaData: [...action.allStrategyMetaData],
      };
      break;

    case "RESET_STRATEGY":
      newState = {
        ...prevState,
        currentWorkingStrategy: processStrategy(prevState.fetchedStrategy),
      };
      break;

    default:
      newState = prevState;
      break;
  }
  return newState;
}

export function setSelectedRuleSetAction(selectedRuleSet) {
  return {
    type: ACTIONS.setSelectedRuleSet,
    selectedRuleSet,
  };
}

export function setRuleSetValueAction(ruleSetValue) {
  return {
    type: ACTIONS.setRuleSetValue,
    ruleSetValue,
  };
}

export function setSelectedStrategyAction(selectedStrategy) {
  return {
    type: ACTIONS.setSelectedStrategy,
    selectedStrategy,
  };
}

export function setIsStrategySelectedAction(isSelected) {
  return {
    type: ACTIONS.setIsStrategySelected,
    isSelected,
  };
}

export function setAllRuleSetMetaDataAction(allRuleSetMetaData) {
  return {
    type: ACTIONS.setAllRuleSetMetaData,
    allRuleSetMetaData,
  };
}

export function setFetchedRuleSetDataToStoreAction(ruleSetData) {
  return {
    type: ACTIONS.setFetchedRuleSetDataToStore,
    ruleSetData,
  };
}

export function setRuleSeverityAction(ruleSeverity) {
  return {
    type: ACTIONS.setRuleSeverity,
    ruleSeverity,
  };
}

export function setRuleColorAction(ruleColor) {
  return {
    type: ACTIONS.setRuleColor,
    ruleColor,
  };
}

export function resetRuleSetAction() {
  return {
    type: ACTIONS.resetRuleSet,
  };
}

export function setFetchedStrategyDataToStoreAction(strategyData) {
  return {
    type: ACTIONS.setFetchedStrategyDataToStore,
    strategyData,
  };
}

export function setAllStrategyMetaDataAction(allStrategyMetaData) {
  return {
    type: ACTIONS.setAllStrategyMetaData,
    allStrategyMetaData,
  };
}

export function resetStrategyAction() {
  return {
    type: ACTIONS.resetStrategy,
  };
}
