export const CREW_ROWS_LOADING_MESSAGE = `Loading crew member details...`;
export const GANTT_ROWS_LOADING_MESSAGE = `Loading schedules for crew members...`;
export const OPEN_FLIGHT_LEFT_SECTION_LOADING_MESSAGE = `Loading open flights, pairings...`;
export const OPEN_FLIGHT_GANTT_SECTION_LOADING_MESSAGE = `Loading open flights and pairings...`;
export const LOADING_MORE_DATA_MESSAGE = `Loading more crew data...`;

export const CREW_GANTT_LOADING_HEIGHT = "50vh";
export const OPEN_FLIGHTS_LOADING_HEIGHT = "35vh";
export const LOADING_MORE_DATA_HEIGHT = "50vh";
export const FULL_SCREEN_LOADING_HEIGHT = "50vh";

export const CODE_FOR_OPERATIONAL_DUTY = "OPR";
export const CODE_FOR_RESERVE_DUTY = "RSV";
export const ENTITY_TYPE = {
  FLIGHT: "FLIGHT",
  DUTY: "DUTY",
  PAIRING: "PAIRING",
  CREW: "CREW"
};

export const WAIT_TIME_KEY = {
  SIT: "dutyId",
  LAYOVER: "pairingId"
};

export const CANCELLED_FLIGHT = "CANCELLED";

export const PAIRING_TYPE = {
  SHELL: "SHELL"
};

export const CODE_FOR_SHELL_DUTY = "shell";

export const NUMBER_OF_CREW_PER_PAGE = 50;

export const NUMBER_OF_PREVIOUS_DAYS_TO_FETCH = 3;

export const NUMBER_OF_DAYS_PER_FETCH = 10 + NUMBER_OF_PREVIOUS_DAYS_TO_FETCH;

export const NUMBER_OF_FORWARD_DAYS_TO_FETCH = 10; //number of days to fetch after the toDate of the selected date range
