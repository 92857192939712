import React from "react";
import { useSelector } from "react-redux";
import {
  getleftMarginForEntity,
  getWidthForEntity
} from "../../../utils/timelineUtils";

export default function DequalificationBackground({ dequalificationInfo }) {
  const dateRangeSelection = useSelector(
    store => store.searchCriteria.dateRange
  );

  const leftMarginForDequalificationBackground = React.useCallback(
    () =>
      getleftMarginForEntity(
        Number(dequalificationInfo.dateOfFirstDequalification),
        dateRangeSelection.widthOfOneDay,
        dateRangeSelection.startDate
      ),
    [
      dequalificationInfo.dateOfFirstDequalification,
      dateRangeSelection.widthOfOneDay,
      dateRangeSelection.startDate
    ]
  );

  const widthOfDequalificationBackground = React.useCallback(
    () =>
      getWidthForEntity(
        dateRangeSelection.endDate,
        dequalificationInfo.dateOfFirstDequalification,
        dateRangeSelection.widthOfOneDay
      ),
    [
      dateRangeSelection.endDate,
      dequalificationInfo.dateOfFirstDequalification,
      dateRangeSelection.widthOfOneDay
    ]
  );

  return (
    <div
      className="dequalification-background-container"
      style={{
        marginLeft: leftMarginForDequalificationBackground() + "vw",
        width: widthOfDequalificationBackground() + "vw"
      }}
    />
  );
}
