import React, { useState } from "react";
import { CallSplit as DiversionIcon } from "@material-ui/icons";
import {
  findColorForViolation,
  handleOnClick,
  handleClickAway,
  getCommentMarkerDataUtil,
  getAllViolationMessageAndMuteStatus
} from "../../../../../utils/pairingUtils";
import { useSelector } from "react-redux";
import {
  WAIT_TIME_TYPES,
  MINIMUM_VIEW_WIDTH_FOR_TEXT_TO_BE_SHOWN,
  MINIMUM_VIEW_WIDTH_FOR_ICON_TO_BE_SHOWN
} from "../../../../../constants/disruptions/waitingTime";

import {
  SIT_TIME_BACKGROUND,
  LAYOVER_BACKGROUND,
  DUTY_BORDER,
  LAYOVER_BORDER,
  WARNING_BORDER
} from "../../../../../constants/disruptions/color";
import { Tooltip, ClickAwayListener } from "@material-ui/core";
import { isNonEmptyArray } from "../../../../../utils/arrayUtils";
import ToolTipWithViolationMessageAndPairingInfo from "../../../../partials/tooltip/ToolTipWithViolationMessageAndPairingInfo";
import { DIAGNOSTIC_LEVEL } from "../../../../../constants/disruptions/rules";
import ViolationCommentMarker from "../../../../partials/violation-comment-marker/ViolationCommentMarker";
import { getTwoDigitStringFromNumber } from "../../../../../utils/timelineUtils";

export default function WaitingTime({
  width,
  type,
  leftPosition,
  className,
  solvedRow,
  minutes,
  hours,
  isPairingViolating,
  pairingViolatingColor,
  pairingViolatingData,
  timeViolationData,
  isFlightOps,
  parentRef,
  isChildrenHavingTooltipOpen,
  isParentTooltipOpen,
  handlerForOpeningAndClosingParentTooltip,
  pairingId,
  hideCommentMarker,
  crewIndexInStore,
  isOpen,
  nextEntityStartTimestamp = null,
  isUnplannedDiversion = false
}) {
  const ruleDisplayConfig = useSelector(
    store => store.crewSchedules.processedViolations.ruleDisplayConfig
  );
  const dateRange = useSelector(store => store.searchCriteria.dateRange);

  const [entityViolationColor, setEntityViolationColor] = useState(null);
  const [warningBorderToBeShown, setWarningBorderToBeShown] = useState(false);
  const [commentMarkerData, setCommmentMarkerData] = useState({
    showMarker: false,
    violatingData: []
  });

  const getCommentMarkerData = React.useCallback(
    () => getCommentMarkerDataUtil(timeViolationData, isFlightOps),
    [timeViolationData, isFlightOps]
  );

  React.useEffect(() => {
    if (timeViolationData && isNonEmptyArray(timeViolationData)) {
      for (let violation of timeViolationData) {
        const colorForTheBlock = findColorForViolation(
          violation,
          ruleDisplayConfig,
          isFlightOps
        );
        if (colorForTheBlock) {
          setEntityViolationColor(colorForTheBlock);
        }

        if (
          colorForTheBlock && //require because warning border should only be shown only if crew category and violation category matches, here color would be non null in that case
          violation.ruleIdentity &&
          violation.ruleIdentity.diagnosticLevel === DIAGNOSTIC_LEVEL.WARNING
        ) {
          setWarningBorderToBeShown(true);
        }
      }
    }
  }, [timeViolationData, ruleDisplayConfig, isFlightOps]);

  React.useEffect(() => {
    setCommmentMarkerData(getCommentMarkerData());
  }, [timeViolationData, getCommentMarkerData]);

  const [showToolTip, setShowTooltip] = useState(false);

  const createTooltipForWaitingTime = React.useCallback(() => {
    return timeViolationData ? (
      <ToolTipWithViolationMessageAndPairingInfo
        violationMessages={timeViolationData}
        pairingId={pairingId}
        isFlightOps={isFlightOps}
        tooltipRef={tooltipRef}
        handlerToOpenCloseTooltip={setShowTooltip}
        crewIndexInStore={crewIndexInStore}
        isOpen={isOpen}
        isAClickFromMinRest={type === WAIT_TIME_TYPES.REST}
        nextEntityStartTimestamp={nextEntityStartTimestamp}
      />
    ) : (
      ""
    );
  }, [
    timeViolationData,
    pairingId,
    isFlightOps,
    crewIndexInStore,
    isOpen,
    type,
    nextEntityStartTimestamp
  ]);

  const handleClickAwayForWaitingTime = React.useCallback(
    event => {
      handleClickAway(
        event,
        isChildrenHavingTooltipOpen,
        showToolTip,
        setShowTooltip
      );
    },
    [showToolTip, isChildrenHavingTooltipOpen]
  );

  const handleOnClickForWaitingTime = React.useCallback(
    event => {
      if (
        timeViolationData &&
        isNonEmptyArray(
          getAllViolationMessageAndMuteStatus(timeViolationData, isFlightOps)
        )
      ) {
        event.stopPropagation();

        if (isParentTooltipOpen) {
          handlerForOpeningAndClosingParentTooltip(false);
        }
        handleOnClick(
          event,
          isChildrenHavingTooltipOpen,
          showToolTip,
          setShowTooltip
        );
      }
    },
    [
      showToolTip,
      timeViolationData,
      isChildrenHavingTooltipOpen,
      handlerForOpeningAndClosingParentTooltip,
      isParentTooltipOpen,
      isFlightOps
    ]
  );

  /**
   * this state is used to re-draw the parent ref after every change of the expansion panel inside the tooltip
   */
  const [
    stateToHandleDynamicPositioningOfTooltip,
    setStateToHandleDynamicPositioningOfTooltip
  ] = React.useState(false);

  /**
   * ref of the tooltip to be passed to the tooltip's inner component on which the scheduleUpdate() will be called
   */
  const tooltipRef = React.useRef(null);

  /**
   * ref to hold the timeout object
   */
  const isUpdateStateEnqued = React.useRef(null);

  return (
    <ClickAwayListener onClickAway={handleClickAwayForWaitingTime}>
      <Tooltip
        title={createTooltipForWaitingTime()}
        open={showToolTip}
        arrow
        interactive
        PopperProps={{
          popperOptions: {
            onCreate: data => {
              /**
               * on tooltip creation assign the tooltip instance to the tooltipRef
               **/
              tooltipRef.current = data.instance;
            },
            onUpdate: data => {
              /**
               * on every update check if there is a updateState already under progress in timeout if so then clear it
               */
              if (isUpdateStateEnqued.current) {
                clearTimeout(isUpdateStateEnqued.current);
              }

              /**
               * create a new timeout which handles the change in the state
               */
              isUpdateStateEnqued.current = setTimeout(() => {
                setStateToHandleDynamicPositioningOfTooltip(
                  !stateToHandleDynamicPositioningOfTooltip
                );
              }, 250);
            }
          }
        }}
      >
        <div
          onClick={handleOnClickForWaitingTime}
          className={`time ${className} ${
            solvedRow && className === "layover-main"
              ? "solver-border"
              : "no-solver-border"
          } ${
            isNonEmptyArray(timeViolationData) &&
            timeViolationData[0].ruleIdentity &&
            timeViolationData[0].ruleIdentity.ruleName
              ? "entity-violation"
              : null
          }`}
          style={{
            width:
              type === WAIT_TIME_TYPES.LAYOVER /**to accomodate duty borders */
                ? width - 0.2 + "vw"
                : width <
                  0.1 /**to handle case where the rest blocks are too thin to be visible on the screen. Ref: REPDESK-44 */
                ? "0.15vw" /**add a fixed with to the rest block*/
                : width + "vw",
            left:
              type === WAIT_TIME_TYPES.LAYOVER /**to accomodate duty borders */
                ? leftPosition + 0.2 + "vw"
                : width <
                  0.1 /**to handle case where the rest blocks are too thin to be visible on the screen. Ref: REPDESK-44 */
                ? dateRange.rangeInDaysInSingleViewPort <
                  2 /** pull back the rest block a little back to make it visible(will overlap the previous LCR block right border)  */
                  ? leftPosition - 0.01 + "vw"
                  : leftPosition - 0.05 + "vw"
                : leftPosition + "vw",
            background: entityViolationColor
              ? entityViolationColor
              : className === "sit-time-main"
              ? SIT_TIME_BACKGROUND
              : LAYOVER_BACKGROUND,
            borderStyle: `solid`,
            borderWidth: `${
              warningBorderToBeShown
                ? "2px 2px"
                : isPairingViolating && className === "layover-main"
                ? "2px 0px"
                : "0px"
            }`,
            borderColor: `${
              warningBorderToBeShown
                ? WARNING_BORDER
                : isPairingViolating
                ? pairingViolatingColor
                : className === "sit-time-main"
                ? DUTY_BORDER
                : LAYOVER_BORDER
            }`,
            zIndex: `${
              width < 1 ? 1 : "initial"
            }` /**increase the z-index to make it visible over duty border */
          }}
        >
          {isUnplannedDiversion ? (
            <div className="unplanned-diversion-sit-time">
              <span>
                {width < MINIMUM_VIEW_WIDTH_FOR_ICON_TO_BE_SHOWN ? (
                  ""
                ) : (
                  <DiversionIcon />
                )}
              </span>
              <span className="time-info">
                {width < MINIMUM_VIEW_WIDTH_FOR_TEXT_TO_BE_SHOWN
                  ? ""
                  : `${getTwoDigitStringFromNumber(
                      hours
                    )}:${getTwoDigitStringFromNumber(minutes)}`}
              </span>
            </div>
          ) : (
            <div>
              {width < MINIMUM_VIEW_WIDTH_FOR_TEXT_TO_BE_SHOWN
                ? ""
                : `${getTwoDigitStringFromNumber(
                    hours
                  )}:${getTwoDigitStringFromNumber(minutes)}`}
            </div>
          )}
          {!hideCommentMarker && commentMarkerData.showMarker && (
            <ViolationCommentMarker
              currentViolatingData={commentMarkerData.violatingData}
              className={`violation-comment-marker-wait-time${
                warningBorderToBeShown ? "-warning" : ""
              }`}
            />
          )}
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
}
