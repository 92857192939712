import React, { useState } from "react";
import { useSelector } from "react-redux";

import Duty from "./Duty";
import {
  getWidthForEntity,
  getleftMarginForEntity,
  getNumberOfHoursBetweenDates,
  getNumberOfMinutesBetweenDates
} from "../../../../../utils/timelineUtils";
import WaitingTime from "./WaitingTime";

import "./Pairing.scss";
import { isEmptyArray, isNonEmptyArray } from "../../../../../utils/arrayUtils";
import useStatus from "../../../../customHooks/useStatus";
import {
  CODE_FOR_OPERATIONAL_DUTY,
  CODE_FOR_RESERVE_DUTY,
  PAIRING_TYPE
} from "../../../../../constants/disruptions/crewGanttViewer";
import {
  findColorForViolation,
  handleOnClick,
  getStartAndEndOfDuty,
  getClickPath,
  getCommentMarkerDataUtil,
  getAllViolationMessageAndMuteStatus,
  checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity,
  getTimeAfterApplyingOffsetsBasedOnDstInfo
} from "../../../../../utils/pairingUtils";
import { WAIT_TIME_TYPES } from "../../../../../constants/disruptions/waitingTime";
import { Tooltip, ClickAwayListener } from "@material-ui/core";
import ToolTipWithViolationMessageAndPairingInfo from "../../../../partials/tooltip/ToolTipWithViolationMessageAndPairingInfo";
import { MILLISECONDS_IN_A_MINUTE } from "../../../../../constants/disruptions/timeline";
import {
  LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
  LOCAL_TIME_ZONE_NAME_KEY
} from "../../../../../constants/disruptions/disruptionSummary";
import { getPreviousTimeViolation } from "../../../../../utils/violationUtils";
import { getTimezoneOffsetForGivenDateTimeAndUserSelectedZone } from "../../../../../utils/dateTimeUtils";
import { DST_CHANGE_TYPES } from "../../../../../constants/daylightSavings";

const initialState = {
  pairingStartTimeInEpochMillis: null,
  pairingEndTimeInEpochMillis: null,
  pairingLengthInViewWidthUnits: 0,
  leftMarginForPairingInViewWidthUnits: 0,
  allLayoverInfo: [],
  allViolationData: [],
  entityViolationColor: null
};

const pairingReducer = (prevState, action) => {
  switch (action.type) {
    case "INITIALIZE_PAIRING":
      return {
        ...prevState,
        ...action.pairingData
      };
    case "UPDATE_START_AND_END":
      return {
        ...prevState,
        ...action.pairingStartAndEndData
      };
    default:
      return prevState;
  }
};

export default function Pairing(props) {
  const {
    pairing,
    violatingData,
    pairingId,
    isOpen,
    type,
    zIndex,
    nextPairingData,
    solvedRow,
    crewBase,
    crewId,
    isFlightOps,
    previousPairingRestViolation,
    crewIndexInStore,
    openFlyingType
  } = props;

  const dateRange = useSelector(store => store.searchCriteria.dateRange);

  const duties = useSelector(store => store.crewSchedules.duties);

  const [pairingData, componentLevelDispatcher] = React.useReducer(
    pairingReducer,
    initialState
  );
  const { Status, setStatus } = useStatus("hasOperationalDuties");

  const processedViolations = useSelector(
    store => store.crewSchedules.processedViolations
  );

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const parentRef = React.useRef();

  const showGanttInUTC = useSelector(store => store.userInfo.showGanttInUTC);

  const userAttributes = useSelector(store => store.userInfo.userAttributes);

  React.useEffect(() => {
    if (parentRef && parentRef.current) {
      parentRef.current.zIndexForPairing =
        type === PAIRING_TYPE.SHELL
          ? -1
          : //check for resevve pairing
          pairing &&
            pairing.dutyIds &&
            pairing.dutyIds.length === 1 &&
            duties[pairing.dutyIds[0]] && //added this extra check to prevent UI from breaking sometimes on horizontal fetch in the new non-disruptive (transparent loader) method, Why?
            duties[pairing.dutyIds[0]].dutyCode !== CODE_FOR_OPERATIONAL_DUTY
          ? 0
          : 1;
    }
  }, [pairing, duties, type]);

  const isChildrenHavingTooltipOpen = React.useRef();

  const getCommentMarkerData = React.useCallback(
    violatingData => getCommentMarkerDataUtil(violatingData, isFlightOps),
    [isFlightOps]
  );

  React.useEffect(() => {
    if (!pairing) return;
    const { utcStartTime } = pairing;
    let pairingStateData = {};

    if (type === PAIRING_TYPE.SHELL) setStatus("shell");
    pairingStateData.allLayoverInfo = [];
    let countOfReserveDuties = 0;

    const dstRelatedInfo = {
      typeOfDSTChange: DST_CHANGE_TYPES.NO_CHANGE,
      dstOffset: 0
    };

    if (Array.isArray(pairing.dutyIds) && pairing.dutyIds.length > 1) {
      pairing.dutyIds.forEach((dutyId, index) => {
        if (
          duties[dutyId] &&
          duties[dutyId].dutyCode &&
          duties[dutyId].dutyCode !== CODE_FOR_OPERATIONAL_DUTY &&
          isEmptyArray(duties[dutyId].flightIds)
        ) {
          countOfReserveDuties++;
        }
        if (
          index !== 0 &&
          duties[dutyId] &&
          duties[pairing.dutyIds[index - 1]] &&
          new Date(getStartAndEndOfDuty(duties[dutyId]).utcStartTime) >
            new Date(
              getStartAndEndOfDuty(
                duties[pairing.dutyIds[index - 1]]
              ).utcEndTime
            )
        ) {
          const layoverStart = new Date(
            getStartAndEndOfDuty(duties[pairing.dutyIds[index - 1]]).utcEndTime
          );
          const layoverEnd = new Date(
            getStartAndEndOfDuty(duties[dutyId]).utcStartTime
          );

          checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity(
            layoverStart,
            layoverEnd,
            userAttributes,
            dstRelatedInfo
          );

          let layOverViolations =
            processedViolations.timeViolations.pairing[
              `${pairing.id ? pairing.id : pairing.pairingId}-${
                pairing.dutyIds[index - 1]
              }`
            ];
          if (
            layOverViolations &&
            isNonEmptyArray(Object.keys(layOverViolations))
          ) {
            layOverViolations = Object.values(layOverViolations);
          }
          pairingStateData.allLayoverInfo.push({
            width: getWidthForEntity(
              getTimeAfterApplyingOffsetsBasedOnDstInfo(
                layoverEnd,
                dstRelatedInfo,
                DST_CHANGE_TYPES.DST_CHANGE_POSITIVE
              ),
              Number(layoverStart),
              dateRange.widthOfOneDay
            ), //add the dst offset to the end time when there is a positive change, eg: -300 -> -240; in this case the width to be drawn will be more than the computed value
            leftPosition: getleftMarginForEntity(
              getTimeAfterApplyingOffsetsBasedOnDstInfo(
                layoverStart,
                dstRelatedInfo,
                DST_CHANGE_TYPES.DST_CHANGE_NEGATIVE
              ), //add the dst offset to the start time when there is a negative change, eg: -240 -> -300; in this case the left position from which entity will be drawn will be less than the prev computed value
              dateRange.widthOfOneDay,
              new Date(utcStartTime)
            ),
            hours: getNumberOfHoursBetweenDates(layoverStart, layoverEnd),
            minutes: getNumberOfMinutesBetweenDates(layoverStart, layoverEnd),
            startTime: layoverStart,
            layoverViolation: layOverViolations
          });
        }
      });
    }

    // merge crew specific pairing violations to its entity violations
    pairingStateData.allViolationData = violatingData
      ? Object.values(violatingData)
      : [];
    let crewSpecificPairingViolationsDerived =
      processedViolations.timeViolations.crew.crewPairingViolation[
        `${crewId}-${pairing.id ? pairing.id : pairing.pairingId}`
      ];
    if (
      crewSpecificPairingViolationsDerived &&
      isNonEmptyArray(Object.keys(crewSpecificPairingViolationsDerived))
    ) {
      crewSpecificPairingViolationsDerived = Object.values(
        crewSpecificPairingViolationsDerived
      );
      if (isNonEmptyArray(pairingStateData.allViolationData)) {
        pairingStateData.allViolationData = [
          ...pairingStateData.allViolationData,
          ...crewSpecificPairingViolationsDerived
        ];
      } else {
        pairingStateData.allViolationData = [
          ...crewSpecificPairingViolationsDerived
        ];
      }
    }

    if (isNonEmptyArray(pairingStateData.allViolationData)) {
      for (let violation of pairingStateData.allViolationData) {
        const colorForTheBlock = findColorForViolation(
          violation,
          processedViolations.ruleDisplayConfig,
          isFlightOps
        );
        if (colorForTheBlock) {
          pairingStateData.entityViolationColor = colorForTheBlock;
          break;
        }
      }

      pairingStateData.commentMarkerData = getCommentMarkerData(
        pairingStateData.allViolationData
      );
    }

    if (
      countOfReserveDuties !== 0 &&
      countOfReserveDuties === pairing.dutyIds.length
    ) {
      setStatus("entirePairingReserve");
    }
    componentLevelDispatcher({
      type: "INITIALIZE_PAIRING",
      pairingData: pairingStateData
    });
  }, [
    pairing,
    dateRange.widthOfOneDay,
    duties,
    setStatus,
    solvedRow,
    type,
    processedViolations.timeViolations.pairing,
    violatingData,
    processedViolations.ruleDisplayConfig,
    processedViolations.timeViolations.crew.crewPairingViolation,
    crewId,
    isFlightOps,
    getCommentMarkerData,
    userAttributes
  ]);

  React.useEffect(() => {
    if (!pairing) return;
    const { utcStartTime, utcEndTime } = pairing;

    let pairingStartAndEndData = {};

    if (!showGanttInUTC) {
      let localOffsetStart =
        getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
          utcStartTime,
          userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][
            LOCAL_TIME_ZONE_NAME_KEY
          ]
        );

      let localOffsetEnd = getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
        utcEndTime,
        userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][LOCAL_TIME_ZONE_NAME_KEY]
      );

      pairingStartAndEndData.pairingStartTimeInEpochMillis = Number(
        Number(new Date(utcStartTime)) +
          Number(localOffsetStart * MILLISECONDS_IN_A_MINUTE)
      );
      pairingStartAndEndData.pairingEndTimeInEpochMillis = Number(
        Number(new Date(utcEndTime)) +
          Number(localOffsetEnd * MILLISECONDS_IN_A_MINUTE)
      );
    } else {
      pairingStartAndEndData.pairingStartTimeInEpochMillis = Number(
        new Date(utcStartTime)
      );
      pairingStartAndEndData.pairingEndTimeInEpochMillis = Number(
        new Date(utcEndTime)
      );
    }

    pairingStartAndEndData.pairingLengthInViewWidthUnits = getWidthForEntity(
      pairingStartAndEndData.pairingEndTimeInEpochMillis,
      pairingStartAndEndData.pairingStartTimeInEpochMillis,
      dateRange.widthOfOneDay
    );
    pairingStartAndEndData.leftMarginForPairingInViewWidthUnits =
      getleftMarginForEntity(
        pairingStartAndEndData.pairingStartTimeInEpochMillis,
        dateRange.widthOfOneDay,
        dateRange.startDate
      );

    componentLevelDispatcher({
      type: "UPDATE_START_AND_END",
      pairingStartAndEndData: pairingStartAndEndData
    });
  }, [
    pairing,
    showGanttInUTC,
    userAttributes,
    dateRange.startDate,
    dateRange.widthOfOneDay
  ]);

  const [showToolTip, setShowTooltip] = useState(false);

  const createTooltipForPairing = React.useCallback(() => {
    return !solvedRow ? (
      <ToolTipWithViolationMessageAndPairingInfo
        violationMessages={pairingData.allViolationData}
        pairingId={pairingId}
        isFlightOps={isFlightOps}
        tooltipRef={tooltipRef}
        handlerToOpenCloseTooltip={setShowTooltip}
        crewIndexInStore={crewIndexInStore}
        isOpen={isOpen}
      />
    ) : (
      ""
    );
  }, [
    pairingData.allViolationData,
    pairingId,
    isFlightOps,
    solvedRow,
    crewIndexInStore,
    isOpen
  ]);

  const handleClickAwayForPairing = React.useCallback(event => {
    let isTheClickAwayCausedByClickInsideToolTip = false;
    let path = getClickPath(event);
    path &&
      path.forEach((path, index) => {
        if (path.className === "violation-message-pairing-info-container") {
          isTheClickAwayCausedByClickInsideToolTip = true;
        }
      });

    if (!isTheClickAwayCausedByClickInsideToolTip) {
      setShowTooltip(false);
      if (parentRef.current)
        parentRef.current.style.zIndex = parentRef.current.zIndexForPairing;
    }
  }, []);

  const handleOnClickOnPairing = React.useCallback(
    event => {
      handleOnClick(event, null, showToolTip, setShowTooltip, parentRef);
    },
    [showToolTip]
  );

  /**
   * this state is used to re-draw the parent ref after every change of the expansion panel inside the tooltip
   */
  const [
    stateToHandleDynamicPositioningOfTooltip,
    setStateToHandleDynamicPositioningOfTooltip
  ] = React.useState(false);

  /**
   * ref of the tooltip to be passed to the tooltip's inner component on which the scheduleUpdate() will be called
   */
  const tooltipRef = React.useRef(null);

  /**
   * ref to hold the timeout object
   */
  const isUpdateStateEnqued = React.useRef(null);

  const getPreviousLayoverViolations = React.useCallback(
    indexOfDuty =>
      getPreviousTimeViolation(
        pairingData.allLayoverInfo[indexOfDuty - 1],
        "layoverViolation"
      ),
    [pairingData.allLayoverInfo]
  );

  if (!pairing) return null;

  return (
    <ClickAwayListener onClickAway={handleClickAwayForPairing}>
      <Tooltip
        title={createTooltipForPairing()}
        open={showToolTip}
        arrow
        interactive
        id={String(pairingId)}
        PopperProps={{
          popperOptions: {
            onCreate: data => {
              /**
               * on tooltip creation assign the tooltip instance to the tooltipRef
               **/
              tooltipRef.current = data.instance;
            },
            onUpdate: data => {
              /**
               * on every update check if there is a updateState already under progress in timeout if so then clear it
               */
              if (isUpdateStateEnqued.current) {
                clearTimeout(isUpdateStateEnqued.current);
              }

              /**
               * create a new timeout which handles the change in the state
               */
              isUpdateStateEnqued.current = setTimeout(() => {
                setStateToHandleDynamicPositioningOfTooltip(
                  !stateToHandleDynamicPositioningOfTooltip
                );
              }, 250);
            }
          },
          modifiers: {
            /**
             * since the pairing has no height there has to be an offset added to the tooltip so that it is placed properly
             */
            offset: {
              enabled: true,
              fn: data => {
                //if the placement is bottom then set the top offset
                if (data.placement === "bottom") {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      top: 40,
                      left: 0
                    }
                  };
                } else {
                  //if not set back the offsets to 0
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      top: 0,
                      left: 0
                    }
                  };
                }
              }
            }
          }
        }}
      >
        <div
          className="pairing-main"
          onClick={handleOnClickOnPairing}
          id={pairing ? (pairing.id ? pairing.id : pairing.pairingId) : -1}
          style={{
            width: pairingData.pairingLengthInViewWidthUnits + "vw",
            marginLeft: pairingData.leftMarginForPairingInViewWidthUnits + "vw",
            zIndex: zIndex
              ? zIndex
              : parentRef && parentRef.current
              ? parentRef.current.zIndexForPairing
              : 0
          }}
          ref={parentRef}
        >
          <Status
            entirePairingReserve={
              <Duty
                duty={{
                  utcStartTime: pairing.utcStartTime,
                  utcEndTime: pairing.utcEndTime,
                  dutyCode: CODE_FOR_RESERVE_DUTY
                }}
                pairingStartTime={pairingData.pairingStartTimeInEpochMillis}
                crewBase={crewBase}
                crewId={crewId}
                isFlightOps={isFlightOps}
                pairingId={pairingId}
                commentMarkerDataForPairing={
                  pairingData.commentMarkerData &&
                  pairingData.commentMarkerData.showMarker
                    ? pairingData.commentMarkerData.violatingData
                    : []
                }
                crewIndexInStore={crewIndexInStore}
              />
            }
            hasOperationalDuties={
              <>
                {isNonEmptyArray(pairing.dutyIds) &&
                  pairing.dutyIds
                    .map(dutyId => parseInt(dutyId, 10))
                    .map((dutyId, index) => {
                      return (
                        <Duty
                          key={dutyId}
                          duty={duties[dutyId]}
                          dutyId={dutyId}
                          pairingOriginTZ={pairing.originTZ}
                          pairingDestinationTZ={pairing.destinationTZ}
                          pairingStartTime={
                            pairingData.pairingStartTimeInEpochMillis
                          }
                          solvedRow={solvedRow}
                          isFirstDutyInPairing={
                            pairing.dutyIds.indexOf(dutyId) === 0
                          }
                          isLastDutyInPairing={
                            pairing.dutyIds.indexOf(dutyId) ===
                            pairing.dutyIds.length -
                              1 /** TODO: solve https://skyschedule.atlassian.net/browse/CRWEB-137 for finding correct last duty */
                          }
                          violatingData={
                            processedViolations.entityViolations.duty[dutyId]
                          }
                          isPairingViolating={
                            pairingData.allViolationData &&
                            getAllViolationMessageAndMuteStatus(
                              pairingData.allViolationData,
                              isFlightOps
                            ).length > 0 //filter allViolatingData based on isFlightOps and then decide if violating or not
                              ? true
                              : false
                          }
                          pairingViolatingData={
                            pairingData.allViolationData &&
                            getAllViolationMessageAndMuteStatus(
                              pairingData.allViolationData,
                              isFlightOps
                            ).length > 0 //filter allViolatingData based on isFlightOps and then decide if violating or not
                              ? getAllViolationMessageAndMuteStatus(
                                  pairingData.allViolationData,
                                  isFlightOps
                                )[0]
                              : null
                          }
                          pairingViolatingColor={
                            pairingData.entityViolationColor
                          }
                          isOpen={isOpen}
                          crewBase={crewBase}
                          crewId={crewId}
                          isFlightOps={isFlightOps}
                          parentRef={parentRef}
                          isChildrenHavingTooltipOpen={
                            isChildrenHavingTooltipOpen
                          }
                          isParentTooltipOpen={showToolTip}
                          handlerForOpeningAndClosingParentTooltip={
                            setShowTooltip
                          }
                          pairingId={pairingId}
                          pairingOpenPositions={isOpen && pairing.openPositions}
                          /**
                           * check if there is a previousPairingRestViolation and the duty is the first duty
                           * then pass the violation to the duty
                           */
                          previousPairingRestViolation={
                            previousPairingRestViolation &&
                            pairing.dutyIds.indexOf(dutyId) === 0
                              ? previousPairingRestViolation
                              : null
                          }
                          previousLayoverViolation={getPreviousLayoverViolations(
                            index
                          )}
                          commentMarkerDataForPairing={
                            index === 0 &&
                            pairingData.commentMarkerData &&
                            pairingData.commentMarkerData.showMarker
                              ? pairingData.commentMarkerData.violatingData
                              : []
                          }
                          crewIndexInStore={crewIndexInStore}
                          openFlyingType={openFlyingType}
                        />
                      );
                    })}
                {isNonEmptyArray(pairingData.allLayoverInfo) &&
                  pairingData.allLayoverInfo.map((layover, index) => (
                    <WaitingTime
                      key={index}
                      width={layover.width}
                      leftPosition={layover.leftPosition}
                      hours={layover.hours}
                      minutes={layover.minutes}
                      className="layover-main"
                      solvedRow={solvedRow}
                      isPairingViolating={
                        pairingData.allViolationData &&
                        getAllViolationMessageAndMuteStatus(
                          pairingData.allViolationData,
                          isFlightOps
                        ).length > 0 //filter allViolatingData based on isFlightOps and then decide if violating or not
                          ? true
                          : false
                      }
                      pairingViolatingData={
                        pairingData.allViolationData &&
                        getAllViolationMessageAndMuteStatus(
                          pairingData.allViolationData,
                          isFlightOps
                        ).length > 0 //filter allViolatingData based on isFlightOps and then decide if violating or not
                          ? getAllViolationMessageAndMuteStatus(
                              pairingData.allViolationData,
                              isFlightOps
                            )[0]
                          : null
                      }
                      pairingViolatingColor={pairingData.entityViolationColor}
                      parentId={pairingId}
                      startTime={layover.startTime}
                      type={WAIT_TIME_TYPES.LAYOVER}
                      timeViolationData={layover.layoverViolation}
                      isFlightOps={isFlightOps}
                      parentRef={parentRef}
                      isChildrenHavingTooltipOpen={isChildrenHavingTooltipOpen}
                      pairingId={pairingId}
                      crewIndexInStore={crewIndexInStore}
                      isOpen={isOpen}
                    />
                  ))}
                {!isShowingASolverRun && nextPairingData && (
                  <WaitingTime
                    width={getWidthForEntity(
                      Number(
                        new Date(nextPairingData.nextPairing.utcStartTime)
                      ),
                      Number(new Date(pairing.utcEndTime)),
                      dateRange.widthOfOneDay
                    )}
                    leftPosition={pairingData.pairingLengthInViewWidthUnits}
                    type={WAIT_TIME_TYPES.REST}
                    hours={getNumberOfHoursBetweenDates(
                      new Date(pairing.utcEndTime),
                      new Date(nextPairingData.nextPairing.utcStartTime)
                    )}
                    minutes={getNumberOfMinutesBetweenDates(
                      new Date(pairing.utcEndTime),
                      new Date(nextPairingData.nextPairing.utcStartTime)
                    )}
                    className="rest-main"
                    timeViolationData={
                      nextPairingData.crewSpecificRestViolatingData
                    }
                    isFlightOps={isFlightOps}
                    parentRef={parentRef}
                    isChildrenHavingTooltipOpen={isChildrenHavingTooltipOpen}
                    isParentTooltipOpen={showToolTip}
                    handlerForOpeningAndClosingParentTooltip={setShowTooltip}
                    pairingId={pairingId}
                    crewIndexInStore={crewIndexInStore}
                    isOpen={isOpen}
                    nextEntityStartTimestamp={
                      nextPairingData.nextPairing.utcStartTime
                    }
                  />
                )}
              </>
            }
            shell={
              <Duty
                duty={{
                  utcStartTime: pairing.utcStartTime,
                  utcEndTime: pairing.utcEndTime,
                  dutyCode: ""
                }}
                pairingStartTime={pairingData.pairingStartTimeInEpochMillis}
                pairingId={pairingId}
                crewIndexInStore={crewIndexInStore}
              />
            }
          />
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
}

/**
 * The code below is used to memoize redux subscription using reselect - commented for now as part of  CRWEB-617
 */
// // creating a memoized redux subscription only for flights associated with this duty
// const dutiesForPairing = useMemo(getDutiesForPairingSelector, []);
// const duties = useSelector(state => dutiesForPairing(state, pairing.dutyIds));

// const getDutiesForPairingSelector = () =>
//   createSelector(
//     state => state.crewSchedules.duties,
//     (_, dutyIds) => dutyIds,
//     (duties, dutyIds) =>
//       Object.fromEntries(
//         Object.entries(duties).filter(
//           ([key, value]) => dutyIds.indexOf(parseInt(key)) > -1
//         )
//       )
//   );
