import React from "react";
import { useSelector } from "react-redux";

import CrewRow from "./CrewRow";
import useStatus from "../../../customHooks/useStatus";
import LoadingState from "../../../partials/LoadingState/LoadingState";
import {
  CREW_ROWS_LOADING_MESSAGE,
  CREW_GANTT_LOADING_HEIGHT,
  LOADING_MORE_DATA_MESSAGE,
  LOADING_MORE_DATA_HEIGHT
} from "../../../../constants/disruptions/crewGanttViewer";

import "./CrewGanttContainer.scss";
import OpenTimeExpansionPanel from "./OpenTime/OpenTimeExpansionPanel";
import OpenTimeLoadingState from "./OpenTime/OpenTimeLoadingState";
import { shouldIncludeCrewMember } from "../../../../utils/allGanttRowsUtils";

export default function AllCrewRows(props) {
  const crewSchedules = useSelector(store => store.crewSchedules);
  const { Status, setStatus } = useStatus("loading");
  const flightOpsInFlightFilter = useSelector(
    store => store.searchCriteria.flightOpsInFlightFilter
  );

  React.useEffect(() => {
    crewSchedules.isCrewSchedulesNotDefined &&
    crewSchedules.isOpenTimeNotDefined
      ? setStatus("loading")
      : !crewSchedules.isCrewSchedulesNotDefined &&
        !crewSchedules.isOpenTimeNotDefined
      ? setStatus("success")
      : setStatus("schedulesLoaded");
  }, [
    crewSchedules.isCrewSchedulesNotDefined,
    crewSchedules.isOpenTimeNotDefined,
    setStatus
  ]);

  const CrewSchedulesLoaded = () => {
    return (
      <div className="crew-rows">
        {Array.isArray(crewSchedules.crewMembers) &&
          crewSchedules.crewMembers.map((crewMember, index) =>
            shouldIncludeCrewMember(crewMember, flightOpsInFlightFilter) ? (
              <CrewRow
                key={index}
                crewMember={crewMember}
                rowNumber={crewMember.id}
              />
            ) : null
          )}
      </div>
    );
  };

  return (
    <div className="all-disp-left-panel" ref={props.forwardRef}>
      <div className="open-flights">
        <Status
          loading={<OpenTimeLoadingState />}
          schedulesLoaded={<OpenTimeExpansionPanel type="crew" />}
          success={<OpenTimeExpansionPanel type="crew" />}
        />
      </div>
      <Status
        loading={
          <LoadingState
            loadingMessage={CREW_ROWS_LOADING_MESSAGE}
            height={CREW_GANTT_LOADING_HEIGHT}
          />
        }
        schedulesLoaded={<CrewSchedulesLoaded />}
        success={<CrewSchedulesLoaded />}
      />
      {crewSchedules.loadingOnScroll ? (
        <LoadingState
          loadingMessage={LOADING_MORE_DATA_MESSAGE}
          height={LOADING_MORE_DATA_HEIGHT}
        />
      ) : null}
    </div>
  );
}
