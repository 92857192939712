import React from "react";

import { Skeleton, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { InputLabel, Tooltip, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFilterAction } from "../../../../redux/searchCriteriaReducer";
import {
  DASHBOARD_FILTERS_FOR_WHICH_WARNING_VALUE_SHOULD_BE_HIDDEN,
  SUMMARY_DASHBOARD
} from "../../../../constants/disruptions/disruptionSummary";

export default function DashboardSummary() {
  const dispatch = useDispatch();

  const filterData = useSelector(store => store.searchCriteria.filterData);

  const selectedFilter = useSelector(
    store => store.searchCriteria.selectedFilter
  );
  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const countInfo = useSelector(store => store.searchCriteria.countInfo);

  const handleViolationFilters = (event, newViolationFilters) => {
    dispatch(
      setSelectedFilterAction({
        isCurrentSchedule: isShowingASolverRun ? false : true,
        filterName: "violation",
        selectedFilter: newViolationFilters
      })
    );
  };

  const handleLegalFilters = (event, newLegalFilters) => {
    dispatch(
      setSelectedFilterAction({
        isCurrentSchedule: isShowingASolverRun ? false : true,
        filterName: "legal",
        selectedFilter: newLegalFilters
      })
    );
  };

  const useStyles = makeStyles(theme => ({
    tooltip: {
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11
    }
  }));

  function CustomTooltip(props) {
    const classes = useStyles();
    return <Tooltip classes={classes} {...props} />;
  }

  const Loading = React.useCallback(
    () => <Skeleton variant="rect" height={30} animation={"pulse"} />,
    []
  );

  const CountInfo = React.useCallback(
    ({ countDataKey, index, hideWarningCount }) => {
      const countForGivenDashboard = countInfo[countDataKey];
      if (
        !countForGivenDashboard ||
        !Object.keys(countForGivenDashboard).length > 0
      )
        return null;

      const countForCategory = countForGivenDashboard[index];
      const violationCount =
        countForCategory &&
        countForCategory[SUMMARY_DASHBOARD.VIOLATIONS.LEVELS.VIOLATIONS]
          ? `${
              countForCategory[SUMMARY_DASHBOARD.VIOLATIONS.LEVELS.VIOLATIONS]
            }`
          : `0`;
      const warningCount =
        countForCategory &&
        countForCategory[SUMMARY_DASHBOARD.VIOLATIONS.LEVELS.WARNINGS]
          ? `${countForCategory[SUMMARY_DASHBOARD.VIOLATIONS.LEVELS.WARNINGS]}`
          : `0`;
      return hideWarningCount ? (
        <span>{violationCount}</span>
      ) : (
        <span>
          {violationCount}/{warningCount}
        </span>
      );
    },
    [countInfo]
  );

  const isFilterDataFetched = React.useCallback(
    filterKey => filterData && filterData[filterKey],
    [filterData]
  );

  const handleChangeOfWarningSwitch = React.useCallback(
    event =>
      dispatch(
        setSelectedFilterAction({
          isCurrentSchedule: isShowingASolverRun ? false : true,
          filterName: "showWarnings",
          selectedFilter: event.target.checked
        })
      ),
    [dispatch, isShowingASolverRun]
  );

  return (
    <div aria-label="Toolbar with button groups" className="dashboard-summary">
      <div className="button-group-label">
        <InputLabel margin="dense" shrink={true}>
          Violations / Warnings
        </InputLabel>
        <ToggleButtonGroup
          aria-label="Violations"
          size="small"
          value={selectedFilter["violation"] ? selectedFilter["violation"] : []}
          onChange={handleViolationFilters}
        >
          {isFilterDataFetched("Violation") ? (
            filterData["Violation"].values.map(violation => {
              const hideWarningCount =
                DASHBOARD_FILTERS_FOR_WHICH_WARNING_VALUE_SHOULD_BE_HIDDEN.includes(
                  violation.code
                );

              return (
                <ToggleButton
                  value={violation.id}
                  key={`violation-${violation.id}`}
                  disabled={isShowingASolverRun}
                >
                  <CustomTooltip title={violation.name}>
                    <span>
                      <span className="text-abbreviated">
                        {violation.code}
                        <br />
                        <CountInfo
                          countDataKey={"violation"}
                          index={violation.id}
                          hideWarningCount={hideWarningCount}
                        />
                      </span>
                      <span className="text-full">
                        {violation.name}
                        <br />
                        <CountInfo
                          countDataKey={"violation"}
                          index={violation.id}
                          hideWarningCount={hideWarningCount}
                        />
                      </span>
                    </span>
                  </CustomTooltip>
                </ToggleButton>
              );
            })
          ) : (
            <Loading />
          )}
        </ToggleButtonGroup>
      </div>
      <div className="button-group-label">
        <InputLabel margin="dense" shrink={true}>
          Legal
        </InputLabel>
        <ToggleButtonGroup
          aria-label="Legal"
          size="small"
          value={selectedFilter["legal"] ? selectedFilter["legal"] : []}
          onChange={handleLegalFilters}
        >
          {isFilterDataFetched("Legal") ? (
            filterData["Legal"].values.map(legal => (
              <ToggleButton
                value={legal.id}
                key={`${legal.id}-legal`}
                disabled={isShowingASolverRun}
              >
                <CustomTooltip title={legal.name}>
                  <span>
                    <span className="text-abbreviated">
                      {legal.code}
                      <br />
                      <CountInfo countDataKey={"legal"} index={legal.id} />
                    </span>
                    <span className="text-full">
                      {legal.name}
                      <br />
                      <CountInfo countDataKey={"legal"} index={legal.id} />
                    </span>
                  </span>
                </CustomTooltip>
              </ToggleButton>
            ))
          ) : (
            <Loading />
          )}
        </ToggleButtonGroup>
      </div>
      <div className="button-group-label">
        <InputLabel margin="dense" shrink={true}>
          Show warnings
        </InputLabel>
        <Switch
          checked={selectedFilter["showWarnings"]}
          onChange={handleChangeOfWarningSwitch}
          color="secondary"
        />
      </div>
    </div>
  );
}
