import Amplify, { Storage } from "aws-amplify";

export function configureAmplify(tenantConfig) {
  Amplify.configure({
    Auth: {
      mandatorySignId: true,
      region: tenantConfig.cognito.region,
      userPoolId: tenantConfig.cognito.userPoolId,
      userPoolWebClientId: tenantConfig.cognito.clientId,
      identityPoolId: tenantConfig.cognito.identityPoolId,
      authenticationFlowType: "USER_PASSWORD_AUTH"
    },
    // Configure storage for the tenant
    Storage: {
      bucket: tenantConfig.s3.bucket,
      region: tenantConfig.s3.region,
      identityPoolId: tenantConfig.cognito.identityPoolId,
      level: "protected"
    }
  });
}

export function setS3Level(level) {
  // TODO: Check if we can just pass level and retain previous config
  Storage.configure({
    ...Storage._config,
    level: level
  });
}
