import React from "react";
import {
  NotificationsOffRounded as MutedIcon,
  NotificationsActiveRounded as ActiveIcon
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { CREW_GANTT_BORDER } from "../../../constants/disruptions/color";
import { isNonEmptyArray } from "../../../utils/arrayUtils";
import { setViolationMetadataForAViolationAction } from "../../../redux/crewSchedulesReducer";
import { IconButton } from "@material-ui/core";

export default function TooltipViolationMessage({
  messagesAndMuteStatusesToBeDisplayed
}) {
  const dispatch = useDispatch();
  const violationMetadata = useSelector(
    state => state.crewSchedules.violationMetadata
  );

  const computeMuteStatusFromViolationMetadata = React.useCallback(
    messageAndMuteStatus =>
      violationMetadata[
        messageAndMuteStatus.violationType === "ENTITY"
          ? "entityViolation"
          : "timeViolation"
      ][messageAndMuteStatus.violationId].changedMuteStatus,
    [violationMetadata]
  );

  const handleClickOnViolationMuteIcon = React.useCallback(
    (messageAndMuteStatus, event) => {
      event.stopPropagation();
      dispatch(
        setViolationMetadataForAViolationAction({
          violationType: messageAndMuteStatus.violationType,
          violationId: messageAndMuteStatus.violationId,
          muteStatus:
            !computeMuteStatusFromViolationMetadata(messageAndMuteStatus)
        })
      );
    },
    [dispatch, computeMuteStatusFromViolationMetadata]
  );

  return (
    <div className="tooltip-violation-container">
      {isNonEmptyArray(messagesAndMuteStatusesToBeDisplayed) ? (
        messagesAndMuteStatusesToBeDisplayed.map(
          (messageAndMuteStatus, index) => (
            <div
              key={index}
              className="tooltip-violation-message"
              style={{
                borderBottom:
                  index === messagesAndMuteStatusesToBeDisplayed.length - 1
                    ? "unset"
                    : `1px solid ${CREW_GANTT_BORDER}`
              }}
            >
              <span
                title={
                  computeMuteStatusFromViolationMetadata(messageAndMuteStatus)
                    ? "Click to unmute violation"
                    : "Click to mute violation"
                }
                onClick={handleClickOnViolationMuteIcon.bind(
                  this,
                  messageAndMuteStatus
                )}
              >
                <IconButton>
                  {computeMuteStatusFromViolationMetadata(
                    messageAndMuteStatus
                  ) ? (
                    <MutedIcon />
                  ) : (
                    <ActiveIcon />
                  )}
                </IconButton>
              </span>
              {messageAndMuteStatus.message}
            </div>
          )
        )
      ) : (
        <div
          className="tooltip-violation-message"
          style={{
            borderBottom: "unset"
          }}
        >
          No Violations
        </div>
      )}
      {/* TODO: commenting as CRWEB-267, uncomment when ready to implement actual functionality */}
      {/* <button className="tooltip-resolve-button" value={"Resolve"}>
        Resolve
      </button> */}
    </div>
  );
}
