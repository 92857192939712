import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { setIsOpenTimesVisible } from "../../../../../redux/showAndHideReducer";
import OpenTimeGantt from "./OpenTimeGantt";
import OpenTimeCrew from "./OpenTimeCrew";

/**
 * TODO: Handle case when there are no entities in the OpenTime section
 */
export default function OpenTimeExpansionPanel({ type }) {
  const isOpenTimesVisible = useSelector(
    store => store.showAndHide.isOpenTimesVisible
  );

  const dispatcher = useDispatch();
  return (
    <>
      <div>
        <Accordion
          expanded={isOpenTimesVisible}
          onChange={(event, expanded) =>
            dispatcher(setIsOpenTimesVisible(expanded))
          }
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Open Time
          </AccordionSummary>
          {/**
           * render open time only if the expansion panel is expanded
           */}
          {isOpenTimesVisible ? (
            type === "crew" ? (
              <OpenTimeCrew />
            ) : (
              <OpenTimeGantt />
            )
          ) : null}
        </Accordion>
      </div>
    </>
  );
}
