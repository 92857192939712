import { batch } from "react-redux";

import { setLocaleAction } from "../redux/i18nReducer";
import { setDefaultTimeZoneAction, setSolverWindowDefaultsAction } from "../redux/tenantConfigReducer";
import { setThemeAction } from "../redux/themeReducer";
import { setUIConfigAction } from "../redux/uiConfigReducer";

import {
  DEFAULT_LOCALE,
  DEFAULT_THEME,
  DEFAULT_UI_CONFIG
} from "../constants/defaultConfigs";
import { DEFAULT_TIMEZONE, SOLVER } from "../constants/disruptions/disruptionSummary";


export const updateReduxWithTenantConfigDetails = (store, response) => {
  batch(() => {
    store.dispatch(setThemeAction(response.data.defaultTheme || DEFAULT_THEME));
    store.dispatch(
      setLocaleAction(response.data.defaultLanguage || DEFAULT_LOCALE)
    );
    /**
     * dispatch tenant specific default timezone to redux store, if not available then set it to the -05:00
     */
    store.dispatch(
      setDefaultTimeZoneAction(
        response.data.defaultTimeZone || DEFAULT_TIMEZONE
      )
    );

    /**
     * dispatch tenant specific uiConfig to redux store
     */
    store.dispatch(
      setUIConfigAction(response.data.uiConfig || DEFAULT_UI_CONFIG)
    );

    /**
     * dispatch tenant specific solver configurations to redux
     */
    store.dispatch(
      setSolverWindowDefaultsAction(
        response.data.solver || {
          contextualWindowStartHourOffset:
            SOLVER.DEFAULT_CONTEXTUAL_WINDOW_START_HOUR_OFFSET,
          contextualWindowEndHourOffset:
            SOLVER.DEFAULT_CONTEXTUAL_WINDOW_END_HOUR_OFFSET
        }
      )
    );
  });
};
