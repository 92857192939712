import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Select,
  MenuItem,
  ClickAwayListener
} from "@material-ui/core";
import Calendar from "react-calendar";

import Arrow from "../../../../partials/arrow/Arrow";
import MenuItemWithEllipsis from "../../../../partials/MenuItemWithEllipsis/MenuItemWithEllipsis";
import CustomCheckbox from "../../../../partials/checkbox/CustomCheckBox";

import {
  setSelectedRuleSetAction,
  setSelectedStrategyAction
} from "../../../../../redux/ruleAndStrategyManagerReducer";
import {
  setIsRuleSetDrawerVisibleAction,
  setIsStrategyDrawerVisibleAction
} from "../../../../../redux/showAndHideReducer";

export default function SolverParameters({
  componentLevelDispatcher,
  solverRunData
}) {
  const dispatch = useDispatch();

  const selectedStrategy = useSelector(
    store => store.ruleAndStrategy.selectedStrategy
  );

  const selectedRuleSet = useSelector(
    store => store.ruleAndStrategy.selectedRuleSet
  );

  const allStrategyMetaData = useSelector(
    store => store.ruleAndStrategy.allStrategyMetaData
  );

  const allRuleSetMetaData = useSelector(
    store => store.ruleAndStrategy.allRuleSetMetaData
  );

  const handleClickOnEllipsisStrategy = React.useCallback(
    index => {
      dispatch(setSelectedStrategyAction(allStrategyMetaData[index]));
      dispatch(setIsStrategyDrawerVisibleAction(true));
    },
    [allStrategyMetaData, dispatch]
  );

  const handleClickOnEllipsisRuleSet = React.useCallback(
    index => {
      dispatch(setSelectedRuleSetAction(allRuleSetMetaData[index]));
      dispatch(setIsRuleSetDrawerVisibleAction(true));
    },
    [allRuleSetMetaData, dispatch]
  );

  const handleChangeOnFixOpenFlightsCheckbox = React.useCallback(
    isChecked => {
      componentLevelDispatcher({
        type: "UPDATE_DATA",
        field: "fixOpenFlights",
        value: isChecked
      });
    },
    [componentLevelDispatcher]
  );

  const handleClickAwayForFromCalendar = React.useCallback(
    event => {
      componentLevelDispatcher({
        type: "UPDATE_DATA",
        field: "showFromCalendar",
        value: false
      });
    },
    [componentLevelDispatcher]
  );

  const handleClickAwayForToCalendar = React.useCallback(() => {
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showToCalendar",
      value: false
    });
  }, [componentLevelDispatcher]);

  const fromDateChangeHandler = React.useCallback(
    date => {
      componentLevelDispatcher({
        type: "UPDATE_DATA",
        field: "fromDate",
        value: date
      });
      componentLevelDispatcher({
        type: "UPDATE_DATA",
        field: "showFromCalendar",
        value: false
      });
    },
    [componentLevelDispatcher]
  );

  const toDateChangeHandler = React.useCallback(
    date => {
      componentLevelDispatcher({
        type: "UPDATE_DATA",
        field: "toDate",
        value: date
      });
      componentLevelDispatcher({
        type: "UPDATE_DATA",
        field: "showToCalendar",
        value: false
      });
    },
    [componentLevelDispatcher]
  );

  const showAndHideFromCalendar = React.useCallback(() => {
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showFromCalendar",
      value: !solverRunData.showFromCalendar
    });
  }, [solverRunData.showFromCalendar, componentLevelDispatcher]);

  const showAndHideToCalendar = React.useCallback(() => {
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showToCalendar",
      value: !solverRunData.showToCalendar
    });
  }, [solverRunData.showToCalendar, componentLevelDispatcher]);

  return (
    <div className="run-solver-dialog-options">
      <div className="option scenario-name">
        <div className="select-option-heading scenario-name">Scenario Name</div>
        <div id="rule-set-select">
          <TextField
            name="scenario-name"
            id="scenario-name"
            variant="outlined"
            fullWidth
            value={solverRunData.scenarioName}
            onChange={event =>
              componentLevelDispatcher({
                type: "UPDATE_DATA",
                field: "scenarioName",
                value: event.target.value
              })
            }
          />
        </div>
      </div>
      <div className="option select-rule-set">
        <div className="select-option-heading">Rule Set</div>
        <div id="rule-set-select">
          <Select
            value={selectedRuleSet}
            IconComponent={props => <Arrow iconClass={props} />}
            disableUnderline
            onChange={event => {
              dispatch(setSelectedRuleSetAction(event.target.value));
            }}
            renderValue={value => value.ruleSetName}
            MenuProps={{ disablePortal: true }}
          >
            {allRuleSetMetaData.map((value, index) => (
              <MenuItem
                className="showhide-select-option"
                value={value}
                key={index}
              >
                <MenuItemWithEllipsis
                  itemName={value.ruleSetName}
                  index={index}
                  handlerForClickOnEllipsis={handleClickOnEllipsisRuleSet}
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="option select-rule-set">
        <div className="select-option-heading">Strategy</div>
        <div id="rule-set-select">
          <Select
            id="showHide"
            onChange={event => {
              dispatch(setSelectedStrategyAction(event.target.value));
            }}
            IconComponent={props => <Arrow iconClass={props} />}
            disableUnderline={true}
            value={selectedStrategy}
            renderValue={value => value.strategyName}
            MenuProps={{ disablePortal: true }}
          >
            {allStrategyMetaData.map((value, index) => (
              <MenuItem
                className="showhide-select-option"
                value={value}
                key={index}
              >
                <MenuItemWithEllipsis
                  itemName={value.strategyName}
                  index={index}
                  handlerForClickOnEllipsis={handleClickOnEllipsisStrategy}
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="option select-time-frame">
        <div className="select-option-heading">Time Frame</div>
        <div className="to-from-heading">
          <span>From</span>
          <span>To</span>
        </div>
        <div className="to-from">
          <div className="from-date">
            <span className="heading">Date</span>
            <span className="heading">Hours</span>
            <span className="heading">Minutes</span>
          </div>
          <div className="from-date">
            <span className="heading">Date</span>
            <span className="heading">Hours</span>
            <span className="heading">Minutes</span>
          </div>
        </div>
        <div className="to-from">
          <div className="from-date">
            <div className="date-div">
              <span onClick={showAndHideFromCalendar}>
                {solverRunData.fromDate.getMonth() + 1}/
                {solverRunData.fromDate.getDate()}/
                {solverRunData.fromDate.getFullYear()}
              </span>
              {solverRunData.showFromCalendar ? (
                <ClickAwayListener onClickAway={handleClickAwayForFromCalendar}>
                  <div className="calendar-div">
                    <Calendar
                      onChange={fromDateChangeHandler}
                      value={solverRunData.fromDate}
                    />
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
            <Select
              id="showHide"
              onChange={event => {
                componentLevelDispatcher({
                  type: "UPDATE_DATA",
                  field: "fromHours",
                  value: event.target.value
                });
              }}
              IconComponent={props => <Arrow iconClass={props} />}
              disableUnderline={true}
              value={solverRunData.fromHours}
            >
              {[...Array(24).keys()].map((value, index) => (
                <MenuItem
                  className="showhide-select-option"
                  value={value}
                  key={index}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
            <Select
              id="showHide"
              onChange={event => {
                componentLevelDispatcher({
                  type: "UPDATE_DATA",
                  field: "fromMinutes",
                  value: event.target.value
                });
              }}
              IconComponent={props => <Arrow iconClass={props} />}
              disableUnderline={true}
              value={solverRunData.fromMinutes}
            >
              {[...Array(60).keys()].map((value, index) => (
                <MenuItem
                  className="showhide-select-option"
                  value={value}
                  key={index}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="from-date">
            <div className="date-div">
              <span onClick={showAndHideToCalendar}>
                {solverRunData.toDate.getMonth() + 1}/
                {solverRunData.toDate.getDate()}/
                {solverRunData.toDate.getFullYear()}
              </span>
              {solverRunData.showToCalendar ? (
                <ClickAwayListener onClickAway={handleClickAwayForToCalendar}>
                  <div className="calendar-div">
                    <Calendar
                      onChange={toDateChangeHandler}
                      value={solverRunData.toDate}
                    />
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
            <Select
              id="showHide"
              onChange={event => {
                componentLevelDispatcher({
                  type: "UPDATE_DATA",
                  field: "toHours",
                  value: event.target.value
                });
              }}
              IconComponent={props => <Arrow iconClass={props} />}
              disableUnderline={true}
              value={solverRunData.toHours}
            >
              {[...Array(24).keys()].map((value, index) => (
                <MenuItem
                  className="showhide-select-option"
                  value={value}
                  key={index}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
            <Select
              id="showHide"
              onChange={event => {
                componentLevelDispatcher({
                  type: "UPDATE_DATA",
                  field: "toMinutes",
                  value: event.target.value
                });
              }}
              IconComponent={props => <Arrow iconClass={props} />}
              disableUnderline={true}
              value={solverRunData.toMinutes}
            >
              {[...Array(60).keys()].map((value, index) => (
                <MenuItem
                  className="showhide-select-option"
                  value={value}
                  key={index}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="fix-open-flights-checkbox option">
        <CustomCheckbox
          stateValue={solverRunData.fixOpenFlights}
          stateChangeHandler={handleChangeOnFixOpenFlightsCheckbox}
        />
        <div>Fix Open Flights</div>
      </div>
    </div>
  );
}
