import React from "react";

import Axios from "axios";
import { toast } from "react-toastify";
import { BASE_ENDPOINT, SHARE_SCENARIO } from "../constants/api";
import { getAccessTokenForUser } from "./authUtils";

import {
  NO_INFO_ON_SOLVER_STATUS_USER_INFO_TEXT,
  SCENARIO_FAILED_USER_INFO_TEXT,
  SCENARIO_STILL_UNDER_PROGRESS_USER_INFO_TEXT
} from "../constants/runSolver";

import {
  CheckCircle,
  Error,
  FiberManualRecord as InProgress
} from "@material-ui/icons";

export const shareScenario = async (
  scenarioId,
  shareUrl,
  setIsShareUrlCopied
) => {
  const token = await getAccessTokenForUser();
  const url = BASE_ENDPOINT + SHARE_SCENARIO;
  const headers = {
    Authorization: token
  };

  const body = {
    requestType: "SHARE_SOLVER_SCENARIO",
    scenarioId
  };

  Axios.post(url, body, {
    headers: headers
  })
    .then(response => {
      if (response.data.data === 200) {
        toast(
          "Scenario shared successfully! Share link has been copied to your clipboard."
        );
        navigator.clipboard.writeText(shareUrl);
        setIsShareUrlCopied(true);
      } else {
        toast("Failed to share scenario! Please try again.");
      }
    })
    .catch(err => console.log(err));
};

export const getSolverStatusTextAndIcon = selectedScenarioData => {
  const statusTextTemplate = completed => `${completed}% Solution generated`;

  const ErrorStatusIcon = (
    <Error fontSize={"small"} className="solver-run-error-icon" />
  );
  const CompletedStatusIcon = (
    <CheckCircle fontSize={"small"} className="solver-run-completed-icon" />
  );
  const InProgressStatusIcon = (
    <InProgress className="solver-run-in-progress-icon" />
  );

  /**
   * For predated run the solver solution can vary between completed and in progress
   */
  if (selectedScenarioData.isPredatedRun) {
    if (selectedScenarioData.status === "COMPLETED") {
      return [statusTextTemplate(100), CompletedStatusIcon];
    } else {
      return [
        SCENARIO_STILL_UNDER_PROGRESS_USER_INFO_TEXT,
        InProgressStatusIcon
      ];
    }
  } else {
    //else i.e for live runs; rely on the percentage completed variable to determine the status of the run
    if (selectedScenarioData.error) {
      return [SCENARIO_FAILED_USER_INFO_TEXT, <ErrorStatusIcon />];
    } else if (selectedScenarioData.percentageCompleted) {
      if (selectedScenarioData.percentageCompleted < 100) {
        return [
          statusTextTemplate(selectedScenarioData.percentageCompleted),
          InProgressStatusIcon
        ];
      } else {
        return [statusTextTemplate(100), CompletedStatusIcon];
      }
    } else {
      return [NO_INFO_ON_SOLVER_STATUS_USER_INFO_TEXT, InProgressStatusIcon];
    }
  }
};
