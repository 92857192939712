import React from "react";
import { FILTER_TYPES } from "../../../../constants/disruptions/filter";
import AirlineAutoCompleteComponent from "../../../partials/autocomplete/AirlineAutoCompleteComponent";
import AutoCompleteContainer from "../../../partials/autocomplete/AutoCompleteContainer";
import DateRangeFilter from "../../../partials/DateRange/DateRangeFilter";
import TextFieldFilter from "../../../partials/TextFieldFilter/TextFieldFilter";

export default function FilterMapper({ filter }) {
  const filterMapper = React.useCallback(() => {
    switch (filter.type) {
      case FILTER_TYPES.AIRLINE_AUTOCOMPLETE:
        return <AirlineAutoCompleteComponent />;
      case FILTER_TYPES.AUTOCOMPLETE:
        return (
          <AutoCompleteContainer
            filterName={filter.filterName}
            filterKey={filter.filterKey}
            filterLabel={filter.filterLabel}
            className={filter.className}
            optionDisplayConfig={filter.optionDisplayConfig}
          />
        );
      case FILTER_TYPES.TEXTFIELD:
        return (
          <TextFieldFilter
            filterName={filter.filterName}
            filterKey={filter.filterKey}
            filterLabel={filter.filterLabel}
          />
        );
      case FILTER_TYPES.DATE_RANGE:
        return <DateRangeFilter />;
      default:
        return null;
    }
  }, [filter]);

  return <>{filterMapper()}</>;
}
