export const SIZE_OF_CIRCULAR_PROGRESS_SHOWN_IN_SCENARIO_DROPDOWN = 12;
export const THICKNESS_OF_CIRCULAR_PROGRESS_SHOWN_IN_SCENARIO_DROPDOWN = 4;

export const CLICK_EVENT_CONSTANTS = {
  INPUT_TAGNAME_IN_EVENT: "INPUT",
  CHECKBOX_EVENT_TYPE: "checkbox"
};

export const NUMBER_OF_CHARACTER_TO_SHOW_IN_CREW_ROW = 16;
export const NUMBER_OF_CHARACTER_TO_SHOW_FOR_EQUIPMENT = 15;
