import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip, ClickAwayListener } from "@material-ui/core";

import Flight from "./Flight";
import {
  getWidthForEntity,
  getleftMarginForEntity,
  getNumberOfMinutesBetweenDates,
  getNumberOfHoursBetweenDates
} from "../../../../../utils/timelineUtils";
import WaitingTime from "./WaitingTime";
import {
  getSpreadSafeArray,
  isNonEmptyArray
} from "../../../../../utils/arrayUtils";

import {
  findLatestFlightForDuty,
  findEarliestFlightForDuty,
  findColorForViolation,
  findUtcDepartureTimeOfFlight,
  findUtcArrivalTimeOfFlight,
  handleOnClick,
  handleClickAway,
  getStartAndEndOfDuty,
  getAllApplicableViolations,
  getCommentMarkerDataUtil,
  checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity,
  getTimeAfterApplyingOffsetsBasedOnDstInfo
} from "../../../../../utils/pairingUtils";
import {
  WARNING,
  VIOLATION,
  DUTY_BORDER,
  BORDER_COLOR_AFTER_SOLVER_RESPONSE
} from "../../../../../constants/disruptions/color";
import { WAIT_TIME_TYPES } from "../../../../../constants/disruptions/waitingTime";
import {
  DUTY_REPORT_TIME_RULENAMES,
  DUTY_RELEASE_TIME_RULENAMES,
  DIAGNOSTIC_LEVEL
} from "../../../../../constants/disruptions/rules";
import ToolTipWithViolationMessageAndPairingInfo from "../../../../partials/tooltip/ToolTipWithViolationMessageAndPairingInfo";

import "./Pairing.scss";
import { MILLISECONDS_IN_A_MINUTE } from "../../../../../constants/disruptions/timeline";
import {
  LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
  LOCAL_TIME_ZONE_NAME_KEY
} from "../../../../../constants/disruptions/disruptionSummary";
import { getPreviousTimeViolation } from "../../../../../utils/violationUtils";
import DutyCode from "../../../../partials/duty/DutyCode";
import { hasAnySitReportOrReleaseViolation, shouldShowTimeInfoOnDutyBlock } from "../../../../../utils/dutyUtils";
import ViolationCommentMarker from "../../../../partials/violation-comment-marker/ViolationCommentMarker";
import { getRelavantViolatingData } from "../../../../partials/tooltip/tooltipWithViolationMessageAndPairingInfoUtils";
import {
  FLIGHT_STATUS_KEY,
  UNPLANNED_FLIGHT_DIVERSION_STATUSES
} from "../../../../../constants/disruptions/pairing";
import { getTimezoneOffsetForGivenDateTimeAndUserSelectedZone } from "../../../../../utils/dateTimeUtils";
import { DST_CHANGE_TYPES } from "../../../../../constants/daylightSavings";
import TimeInfo, { timeInfoTextColorEnum } from "../../../../partials/time-info/TimeInfo";

const initialState = {
  dutyStartTimeInEpochMillis: null,
  dutyEndTimeInEpochMillis: null,
  dutyLengthInViewWidthUnits: 0,
  leftPositionForDutyInViewWidthUnits: 0,
  allSitTimeInfo: [],
  showToolTip: false,
  allViolationData: [],
  entityViolationColor: null,
  showWarningBorder: false
};

const dutyReducer = (prevState, action) => {
  switch (action.type) {
    case "INITIALIZE_DUTY":
      return {
        ...prevState,
        ...action.dutyData
      };
    case "UPDATE_START_AND_END":
      return {
        ...prevState,
        ...action.dutyStartAndEndData
      };
    default:
      return prevState;
  }
};

export default function Duty(props) {
  const dateRange = useSelector(store => store.searchCriteria.dateRange);

  const {
    duty,
    pairingStartTime,
    violatingData,
    isPairingViolating,
    dutyId,
    pairingViolatingData,
    isOpen,
    crewBase,
    pairingViolatingColor,
    solvedRow,
    isLastDutyInPairing,
    isFirstDutyInPairing,
    crewId,
    isFlightOps,
    parentRef,
    isChildrenHavingTooltipOpen,
    pairingId,
    pairingOpenPositions,
    previousPairingRestViolation,
    previousLayoverViolation,
    commentMarkerDataForPairing,
    crewIndexInStore,
    openFlyingType,
    pairingOriginTZ,
    pairingDestinationTZ,
  } = props;

  const flights = useSelector(store => store.crewSchedules.flights);

  const processedViolations = useSelector(
    store => store.crewSchedules.processedViolations
  );
  const [dutyData, componentLevelDispatcher] = React.useReducer(
    dutyReducer,
    initialState
  );

  const showGanttInUTC = useSelector(store => store.userInfo.showGanttInUTC);

  const userAttributes = useSelector(store => store.userInfo.userAttributes);

  const isChildrenHavingTooltipOpenForDuty = React.useRef(false);

  const getAllSitTimeProperties = React.useCallback(
    (
      sitTimeStart,
      sitTimeEnd,
      utcStartTime,
      entityViolations,
      isUnplannedDiversion = false,
      dstRelatedInfo
    ) => {
      return {
        width: getWidthForEntity(
          getTimeAfterApplyingOffsetsBasedOnDstInfo(
            sitTimeEnd,
            dstRelatedInfo,
            DST_CHANGE_TYPES.DST_CHANGE_POSITIVE
          ),
          Number(sitTimeStart),
          dateRange.widthOfOneDay
        ),
        leftPosition: getleftMarginForEntity(
          getTimeAfterApplyingOffsetsBasedOnDstInfo(
            sitTimeStart,
            dstRelatedInfo,
            DST_CHANGE_TYPES.DST_CHANGE_NEGATIVE
          ),
          dateRange.widthOfOneDay,
          new Date(utcStartTime)
        ),
        hours: getNumberOfHoursBetweenDates(sitTimeStart, sitTimeEnd),
        minutes: getNumberOfMinutesBetweenDates(sitTimeStart, sitTimeEnd),
        startTime: sitTimeStart,
        entityViolation: isNonEmptyArray(entityViolations)
          ? entityViolations
          : null,
        isUnplannedDiversion
      };
    },
    [dateRange.widthOfOneDay]
  );

  const getCommentMarkerData = React.useCallback(
    _violatingData => getCommentMarkerDataUtil(_violatingData, isFlightOps),
    [isFlightOps]
  );

  React.useEffect(() => {
    const { utcStartTime, utcEndTime } = getStartAndEndOfDuty(duty);
    let dutyStateData = {};

    dutyStateData.utcStartTime = utcStartTime;
    dutyStateData.utcEndTime = utcEndTime;
    dutyStateData.allSitTimeInfo = [];
    dutyStateData.releaseAndReportTimeViolations = [];
    dutyStateData.allViolationData = violatingData
      ? Object.values(violatingData)
      : [];

    if (Array.isArray(duty.flightIds) && duty.flightIds.length > 0) {
      // add duty report and release time violations based sit time blocks
      if (violatingData && isNonEmptyArray(Object.keys(violatingData))) {
        const allEntityViolations = Object.values(violatingData);
        const dutyReportTimeViolation = allEntityViolations.filter(
          entity =>
            DUTY_REPORT_TIME_RULENAMES.indexOf(entity.ruleIdentity.ruleName) >
            -1
        );

        const earliestFlightForDuty = findEarliestFlightForDuty(
          flights,
          duty.flightIds
        );
        if (dutyReportTimeViolation.length > 0 && earliestFlightForDuty) {
          dutyStateData.hasReleaseTimeViolation = true;

          const dstRelatedInfo = {
            typeOfDSTChange: DST_CHANGE_TYPES.NO_CHANGE,
            dstOffset: 0
          };

          const sitStart = new Date(utcStartTime);
          const sitEnd = new Date(
            findUtcDepartureTimeOfFlight(earliestFlightForDuty)
          );

          checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity(
            sitStart,
            sitEnd,
            userAttributes,
            dstRelatedInfo
          );

          dutyStateData.releaseAndReportTimeViolations.push({
            ...getAllSitTimeProperties(
              sitStart,
              sitEnd,
              utcStartTime,
              dutyReportTimeViolation,
              false,
              dstRelatedInfo
            ),
            isReportTimeViolation: true
          });
        }

        const dutyReleaseTimeViolation = allEntityViolations.filter(
          entity =>
            DUTY_RELEASE_TIME_RULENAMES.indexOf(entity.ruleIdentity.ruleName) >
            -1
        );
        const latestFlightForDuty = findLatestFlightForDuty(
          flights,
          duty.flightIds
        );
        if (dutyReleaseTimeViolation.length > 0 && latestFlightForDuty) {
          const dstRelatedInfo = {
            typeOfDSTChange: DST_CHANGE_TYPES.NO_CHANGE,
            dstOffset: 0
          };

          const sitStart = new Date(
            findUtcArrivalTimeOfFlight(latestFlightForDuty)
          );
          const sitEnd = new Date(utcEndTime);

          checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity(
            sitStart,
            sitEnd,
            userAttributes,
            dstRelatedInfo
          );

          dutyStateData.releaseAndReportTimeViolations.push(
            getAllSitTimeProperties(
              new Date(findUtcArrivalTimeOfFlight(latestFlightForDuty)),
              new Date(utcEndTime),
              utcStartTime,
              dutyReleaseTimeViolation,
              false,
              dstRelatedInfo
            )
          );
        }
      }

      const dstRelatedInfo = {
        typeOfDSTChange: DST_CHANGE_TYPES.NO_CHANGE,
        dstOffset: 0
      };

      duty.flightIds.forEach((flightId, index) => {
        if (index === 0) return;
        const firstFlight = flights[duty.flightIds[index - 1]];
        const secondFlight = flights[flightId];
        if (firstFlight && secondFlight) {
          let sitTimeViolations =
            processedViolations.timeViolations.duty[
              `${duty.id ? duty.id : duty.dutyId}-${duty.flightIds[index - 1]}`
            ];
          if (
            sitTimeViolations &&
            isNonEmptyArray(Object.keys(sitTimeViolations))
          ) {
            sitTimeViolations = Object.values(sitTimeViolations);
            dutyStateData.allViolationData = [
              ...sitTimeViolations,
              ...dutyStateData.allViolationData
            ];
          }

          const isUnplannedDiversion =
            UNPLANNED_FLIGHT_DIVERSION_STATUSES.includes(
              firstFlight[FLIGHT_STATUS_KEY]
            ) &&
            UNPLANNED_FLIGHT_DIVERSION_STATUSES.includes(
              secondFlight[FLIGHT_STATUS_KEY]
            );

          const sitStart = new Date(findUtcArrivalTimeOfFlight(firstFlight));
          const sitEnd = new Date(findUtcDepartureTimeOfFlight(secondFlight));

          checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity(
            sitStart,
            sitEnd,
            userAttributes,
            dstRelatedInfo
          );

          dutyStateData.allSitTimeInfo.push(
            getAllSitTimeProperties(
              sitStart,
              sitEnd,
              utcStartTime,
              sitTimeViolations,
              isUnplannedDiversion,
              dstRelatedInfo
            )
          );
        }
      });
    }

    // merge crew specific pairing violations to its entity violations
    let crewSpecificDutyViolationsDerived =
      processedViolations.timeViolations.crew.crewDutyViolation[
        `${crewId}-${duty.id ? duty.id : duty.dutyId}`
      ];
    if (
      crewSpecificDutyViolationsDerived &&
      isNonEmptyArray(Object.keys(crewSpecificDutyViolationsDerived))
    ) {
      crewSpecificDutyViolationsDerived = Object.values(
        crewSpecificDutyViolationsDerived
      );
      if (isNonEmptyArray(dutyStateData.allViolationData)) {
        dutyStateData.allViolationData = [
          ...dutyStateData.allViolationData,
          ...crewSpecificDutyViolationsDerived
        ];
      } else {
        dutyStateData.allViolationData = [...crewSpecificDutyViolationsDerived];
      }
    }

    //merge allViolationData with previous pairing rest violation data if any
    if (previousPairingRestViolation) {
      if (isNonEmptyArray(dutyStateData.allViolationData)) {
        dutyStateData.allViolationData = [
          ...dutyStateData.allViolationData,
          ...previousPairingRestViolation
        ];
      } else {
        dutyStateData.allViolationData = [...previousPairingRestViolation];
      }
    }

    /**
     * if there are any layover violations which were too small to be shown, then show it as part of this duty
     */
    if (previousLayoverViolation) {
      if (isNonEmptyArray(dutyStateData.allViolationData)) {
        dutyStateData.allViolationData = [
          ...dutyStateData.allViolationData,
          ...previousLayoverViolation
        ];
      } else {
        dutyStateData.allViolationData = [...previousLayoverViolation];
      }
    }

    if (isNonEmptyArray(dutyStateData.allViolationData)) {
      for (let violation of dutyStateData.allViolationData) {
        const colorForTheBlock = findColorForViolation(
          violation,
          processedViolations.ruleDisplayConfig,
          isFlightOps
        );
        if (colorForTheBlock) {
          dutyStateData.entityViolationColor = colorForTheBlock;

          if (
            violation.ruleIdentity &&
            violation.ruleIdentity.diagnosticLevel === DIAGNOSTIC_LEVEL.WARNING
          ) {
            dutyStateData.showWarningBorder = true;
          }
        }
      }

      dutyStateData.commentMarkerData = getCommentMarkerData(
        dutyStateData.allViolationData
      );
    }

    componentLevelDispatcher({
      type: "INITIALIZE_DUTY",
      dutyData: dutyStateData
    });
  }, [
    duty,
    pairingStartTime,
    flights,
    getAllSitTimeProperties,
    violatingData,
    processedViolations.ruleDisplayConfig,
    processedViolations.timeViolations.crew.crewDutyViolation,
    crewId,
    processedViolations.timeViolations.duty,
    isFlightOps,
    previousPairingRestViolation,
    previousLayoverViolation,
    getCommentMarkerData,
    userAttributes
  ]);

  React.useEffect(() => {
    const { utcStartTime, utcEndTime } = getStartAndEndOfDuty(duty);
    let dutyStartAndEndData = {};
    if (!showGanttInUTC) {
      let localOffsetStart =
        getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
          utcStartTime,
          userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][
            LOCAL_TIME_ZONE_NAME_KEY
          ]
        );

      let localOffsetEnd = getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
        utcEndTime,
        userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][LOCAL_TIME_ZONE_NAME_KEY]
      );

      dutyStartAndEndData.dutyStartTimeInEpochMillis = Number(
        Number(new Date(utcStartTime)) +
          Number(localOffsetStart * MILLISECONDS_IN_A_MINUTE)
      );
      dutyStartAndEndData.dutyEndimeInEpochMillis = Number(
        Number(new Date(utcEndTime)) +
          Number(localOffsetEnd * MILLISECONDS_IN_A_MINUTE)
      );
    } else {
      dutyStartAndEndData.dutyStartTimeInEpochMillis = Number(
        new Date(utcStartTime)
      );
      dutyStartAndEndData.dutyEndimeInEpochMillis = Number(
        new Date(utcEndTime)
      );
    }

    dutyStartAndEndData.dutyLengthInViewWidthUnits = getWidthForEntity(
      dutyStartAndEndData.dutyEndimeInEpochMillis,
      dutyStartAndEndData.dutyStartTimeInEpochMillis,
      dateRange.widthOfOneDay
    );
    dutyStartAndEndData.leftPositionForDutyInViewWidthUnits =
      getleftMarginForEntity(
        dutyStartAndEndData.dutyStartTimeInEpochMillis,
        dateRange.widthOfOneDay,
        new Date(pairingStartTime)
      );
    componentLevelDispatcher({
      type: "UPDATE_START_AND_END",
      dutyStartAndEndData: dutyStartAndEndData
    });
  }, [
    showGanttInUTC,
    userAttributes,
    dateRange.widthOfOneDay,
    duty,
    pairingStartTime
  ]);

  /**
   * compute additional classes for duty box based on props
   */
  const computeClassesForDuty = React.useCallback(() => {
    let classesForDuty = ["duty-main"];
    solvedRow && classesForDuty.push("solver-border");
    solvedRow &&
      isFirstDutyInPairing &&
      classesForDuty.push("solver-border-left");
    solvedRow &&
      isLastDutyInPairing &&
      classesForDuty.push("solver-border-right");
    return classesForDuty.join(" ");
  }, [solvedRow, isFirstDutyInPairing, isLastDutyInPairing]);
  const [showToolTip, setShowTooltip] = useState(false);

  const createTooltipForDuty = React.useCallback(() => {
    return (
      <ToolTipWithViolationMessageAndPairingInfo
        violationMessages={dutyData.allViolationData}
        pairingId={pairingId}
        isFlightOps={isFlightOps}
        tooltipRef={tooltipRef}
        handlerToOpenCloseTooltip={setShowTooltip}
        crewIndexInStore={crewIndexInStore}
        isOpen={isOpen}
      />
    );
  }, [
    dutyData.allViolationData,
    pairingId,
    isFlightOps,
    crewIndexInStore,
    isOpen
  ]);

  const computeBorderColorForDuty = React.useCallback(() => {
    return `${
      !solvedRow
        ? !dutyData.entityViolationColor
          ? isPairingViolating
            ? pairingViolatingColor
            : isNonEmptyArray(
                getAllApplicableViolations(
                  dutyData.allViolationData,
                  isFlightOps
                )
              )
            ? getAllApplicableViolations(
                dutyData.allViolationData,
                isFlightOps
              )[0].isOverridable
              ? WARNING
              : VIOLATION
            : DUTY_BORDER
          : dutyData.entityViolationColor
        : BORDER_COLOR_AFTER_SOLVER_RESPONSE
    }`;
  }, [
    isPairingViolating,
    solvedRow,
    dutyData.allViolationData,
    dutyData.entityViolationColor,
    pairingViolatingColor,
    isFlightOps
  ]);

  const handleClickAwayForDuty = React.useCallback(
    event => {
      handleClickAway(
        event,
        isChildrenHavingTooltipOpen,
        showToolTip,
        setShowTooltip
      );
    },
    [isChildrenHavingTooltipOpen, showToolTip]
  );

  const handleOnClickOnDuty = React.useCallback(
    event => {
      //if violating do not propogate click up the heirarchy
      if (
        isNonEmptyArray(
          getAllApplicableViolations(dutyData.allViolationData, isFlightOps)
        )
      ) {
        event.stopPropagation();

        handleOnClick(
          event,
          isChildrenHavingTooltipOpen,
          showToolTip,
          setShowTooltip
        );
      }
    },
    [
      dutyData.allViolationData,
      showToolTip,
      isChildrenHavingTooltipOpen,
      isFlightOps
    ]
  );

  /**
   * this state is used to re-draw the parent ref after every change of the expansion panel inside the tooltip
   */
  const [
    stateToHandleDynamicPositioningOfTooltip,
    setStateToHandleDynamicPositioningOfTooltip
  ] = React.useState(false);
  /**
   * ref of the tooltip to be passed to the tooltip's inner component on which the scheduleUpdate() will be called
   */
  const tooltipRef = React.useRef(null);

  /**
   * ref to hold the timeout object
   */
  const isUpdateStateEnqued = React.useRef(null);

  const getPreviousSitTimeViolations = React.useCallback(
    indexOfFlight =>
      getPreviousTimeViolation(
        dutyData.allSitTimeInfo[indexOfFlight - 1],
        "entityViolation"
      ),
    [dutyData.allSitTimeInfo]
  );

  const hasAnySitReportOrReleaseViolationMemoised = React.useCallback(
    (sitTimeInfo, releaseAndReportTimeViolations) =>
      hasAnySitReportOrReleaseViolation(
        sitTimeInfo,
        releaseAndReportTimeViolations
      ),
    []
  );

  const shouldShowTimeInfoOnDutyBlockMemoised = React.useCallback(
    () =>
      shouldShowTimeInfoOnDutyBlock(
        duty.dutyCode,
        duty.flightIds,
        dutyData.dutyLengthInViewWidthUnits
      ),
    [duty, dutyData]
  )

  return (
    <ClickAwayListener onClickAway={handleClickAwayForDuty}>
      <Tooltip
        title={createTooltipForDuty()}
        open={showToolTip}
        arrow
        interactive
        id={String(dutyId)}
        PopperProps={{
          popperOptions: {
            onCreate: (data) => {
              /**
               * on tooltip creation assign the tooltip instance to the tooltipRef
               **/
              tooltipRef.current = data.instance
            },
            onUpdate: (data) => {
              /**
               * on every update check if there is a updateState already under progress in timeout if so then clear it
               */
              if (isUpdateStateEnqued.current) {
                clearTimeout(isUpdateStateEnqued.current)
              }

              /**
               * create a new timeout which handles the change in the state
               */
              isUpdateStateEnqued.current = setTimeout(() => {
                setStateToHandleDynamicPositioningOfTooltip(
                  !stateToHandleDynamicPositioningOfTooltip
                )
              }, 250)
            },
          },
        }}
      >
        <span
          onClick={handleOnClickOnDuty}
          id={duty.id ? duty.id : duty.dutyId}
          className={computeClassesForDuty()}
          style={{
            width:
              dutyData.dutyLengthInViewWidthUnits +
              0.2 /** extra 0.2 viewwidth to accomodate the borders */ +
              'vw',
            left: dutyData.leftPositionForDutyInViewWidthUnits + 'vw',
            borderColor: computeBorderColorForDuty(),
            zIndex: 1,
          }}
        >
          {dutyData.showWarningBorder && (
            <div className="duty-warning-border" />
          )}
          {((dutyData.commentMarkerData &&
            dutyData.commentMarkerData.showMarker) ||
            (commentMarkerDataForPairing &&
              commentMarkerDataForPairing.length > 0)) && (
            <ViolationCommentMarker
              parentViolatingData={commentMarkerDataForPairing}
              currentViolatingData={
                dutyData.commentMarkerData &&
                dutyData.commentMarkerData.showMarker &&
                dutyData.commentMarkerData.violatingData
              }
            />
          )}
          {isNonEmptyArray(duty.flightIds) &&
            duty.flightIds
              .map((flightId) => parseInt(flightId, 10))
              .map((flightId, index) => {
                return duty.flightIds.indexOf(flightId) > -1 &&
                  flights[flightId] ? (
                  <Flight
                    key={flightId}
                    flight={flights[flightId]}
                    flightId={flightId}
                    dutyStartTime={dutyData.dutyStartTimeInEpochMillis}
                    violatingData={
                      processedViolations.entityViolations.flight[flightId]
                    }
                    isPairingViolating={isPairingViolating}
                    isOpen={isOpen}
                    dutyId={dutyId}
                    isChildrenHavingTooltipOpen={
                      isChildrenHavingTooltipOpenForDuty
                    }
                    isParentTooltipOpen={showToolTip}
                    handlerForOpeningAndClosingParentTooltip={setShowTooltip}
                    pairingId={pairingId}
                    isFlightOps={isFlightOps}
                    pairingOpenPositions={pairingOpenPositions}
                    previousSitTimeViolation={getPreviousSitTimeViolations(
                      index
                    )}
                    crewIndexInStore={crewIndexInStore}
                    openFlyingType={openFlyingType}
                  />
                ) : null
              })}
          {hasAnySitReportOrReleaseViolationMemoised(
            dutyData.allSitTimeInfo,
            dutyData.releaseAndReportTimeViolations
          ) &&
            [
              ...getSpreadSafeArray(dutyData.allSitTimeInfo),
              ...getSpreadSafeArray(dutyData.releaseAndReportTimeViolations),
            ].map((sitTime, index) => (
              <WaitingTime
                key={index}
                width={sitTime.width}
                leftPosition={sitTime.leftPosition}
                hours={sitTime.hours}
                minutes={sitTime.minutes}
                className="sit-time-main"
                parentId={dutyId}
                startTime={sitTime.startTime}
                type={WAIT_TIME_TYPES.SIT}
                isPairingViolating={isPairingViolating}
                pairingViolatingData={pairingViolatingData}
                timeViolationData={
                  sitTime.entityViolation ? sitTime.entityViolation : null
                }
                isFlightOps={isFlightOps}
                parentRef={parentRef}
                isChildrenHavingTooltipOpen={isChildrenHavingTooltipOpenForDuty}
                isParentTooltipOpen={showToolTip}
                handlerForOpeningAndClosingParentTooltip={setShowTooltip}
                pairingId={pairingId}
                hideCommentMarker={
                  dateRange.rangeInDaysInSingleViewPort > 1 || //do not show sit time comment count icon if less than 2 days as it may bleed
                  sitTime.width < 0.1 //sit time violations < 0.1vw are shown as part of next flight
                    ? true
                    : isNonEmptyArray(
                        getRelavantViolatingData(dutyData.allViolationData)
                      ) && sitTime.isReportTimeViolation
                } //if a duty has violations and the sit block has a report time violation then do not show the marker as the duty aggregates it anyways
                crewIndexInStore={crewIndexInStore}
                isOpen={isOpen}
                isUnplannedDiversion={sitTime.isUnplannedDiversion}
              />
            ))}
          <>
            <DutyCode
              crewBase={crewBase}
              dutyCode={duty.dutyCode}
              dutyLength={
                dutyData.dutyLengthInViewWidthUnits
                  ? dutyData.dutyLengthInViewWidthUnits
                  : 0
              }
              hasFlights={isNonEmptyArray(duty.flightIds)}
            />
          </>
          {/* If it is a reserve block, then show start and end time on the block */}
          <>
            {shouldShowTimeInfoOnDutyBlockMemoised() && (
              <TimeInfo
                startTime={dutyData.utcStartTime}
                endTime={dutyData.utcEndTime}
                originTZ={pairingOriginTZ}
                destinationTZ={pairingDestinationTZ}
                textColor={timeInfoTextColorEnum.reserveDutyTextColor}
              />
            )}
          </>
        </span>
      </Tooltip>
    </ClickAwayListener>
  )
}

/**
 * The code below is used to memoize redux subscription using reselect - commented for now as part of  CRWEB-617
 */
// creating a memoized redux subscription only for flights associated with this duty
// const flightsForDuty = useMemo(getFlightsForDutySelector, []);
// const flights = useSelector(state => flightsForDuty(state, duty.flightIds));

// const getFlightsForDutySelector = () =>
//   createSelector(
//     state => state.crewSchedules.flights,
//     (_, flightIds) => flightIds,
//     (flights, flightIds) =>
//       Object.fromEntries(
//         Object.entries(flights).filter(
//           ([key, value]) => flightIds && flightIds.indexOf(parseInt(key)) > -1
//         )
//       )
//   );
