import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import wsMiddleware from "../websocket/middleware";
import axiosMiddleware from "../axios/middleware";

import { loginReducer } from "./loginReducer";
import { themeReducer } from "./themeReducer";
import { i18nReducer } from "./i18nReducer";
import { searchCriteriaReducer } from "./searchCriteriaReducer";
import { crewSchedulesReducer } from "./crewSchedulesReducer";
import { webSocketReducer } from "./webSocketReducer";
import { showAndHideReducer } from "./showAndHideReducer";
import { solverDataReducer } from "./solverDataReducer";
import { ruleAndStrategyManagerReducer } from "./ruleAndStrategyManagerReducer";
import { visibleDateRangeReducer } from "./visibleDateRangeReducer";
import { pairingInfoReducer } from "./pairingInfoReducer";
import { userInfoReducer } from "./userInfoReducer";
import { tenantConfigReducer } from "./tenantConfigReducer";
import { websocketIntermediateDataReducer } from "./websocketIntermediateDataReducer";
import { violationDrillDownReducer } from "./violationDrillDownReducer";
import { deadheadReportReducer } from "./deadheadReportReducer";
import { uiConfigReducer } from "./uiConfigReducer";
import { cumulativeTimeReportReducer } from './cumulativeTimeReportReducer'

const middlewares = [wsMiddleware, axiosMiddleware];

const reduxStore = createStore(
  combineReducers({
    auth: loginReducer,
    theme: themeReducer,
    i18n: i18nReducer,
    searchCriteria: searchCriteriaReducer,
    crewSchedules: crewSchedulesReducer,
    webSocket: webSocketReducer,
    showAndHide: showAndHideReducer,
    solverData: solverDataReducer,
    ruleAndStrategy: ruleAndStrategyManagerReducer,
    visibleDateRange: visibleDateRangeReducer,
    pairingInfo: pairingInfoReducer,
    userInfo: userInfoReducer,
    tenantConfig: tenantConfigReducer,
    websocketIntermediateData: websocketIntermediateDataReducer,
    violationDrilledDownData: violationDrillDownReducer,
    deadheadReport: deadheadReportReducer,
    uiConfig: uiConfigReducer,
    cumulativeTimeReport: cumulativeTimeReportReducer,
  }),
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default reduxStore;

/**
 * If enabling of redux-devtools-extension in production is not
 * desired, use ‘redux-devtools-extension/developmentOnly’ instead of
 * ‘redux-devtools-extension’.
 */
