import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getAirportLocalTime } from "../../../utils/flightUtils";
import { getTwoDigitStringFromNumber } from "../../../utils/timelineUtils";

export const timeInfoTextColorEnum = {
  reserveDutyTextColor: "reserve-duty-text-color"
};

function TimeBlock({ timeObject }) {
  const showGanttInUTC = useSelector(store => store.userInfo.showGanttInUTC);
  return (
    <span>
      {getTwoDigitStringFromNumber(
        showGanttInUTC
          ? timeObject.utc.getUTCHours()
          : timeObject.local.getUTCHours()
      )}
      {getTwoDigitStringFromNumber(
        showGanttInUTC
          ? timeObject.utc.getUTCMinutes()
          : timeObject.local.getUTCMinutes()
      )}
    </span>
  );
}

/**
 * Component to display start and end time blocks on an entity
 * (on extreme left and extreme right corners)
 */
function TimeInfo({ startTime, endTime, originTZ, destinationTZ, textColor }) {
  /**
   * returns the time with the offset added to it
   */
  const airportLocalTime = React.useCallback(
    (timeInUTC, offset) => getAirportLocalTime(timeInUTC, offset),
    []
  );

  const timeInfoData = {
    startTime: {},
    endTime: {}
  };

  timeInfoData.startTime.utc = new Date(startTime);
  timeInfoData.endTime.utc = new Date(endTime);

  timeInfoData.startTime.local = airportLocalTime(
    timeInfoData.startTime.utc,
    originTZ
  );
  timeInfoData.endTime.local = airportLocalTime(
    timeInfoData.endTime.utc,
    destinationTZ
  );

  return (
    <span className={`time-info-main ${textColor}`}>
      {
        <>
          {Object.values(timeInfoData).map((time, index) => {
            return <TimeBlock timeObject={time} key={index} />;
          })}
        </>
      }
    </span>
  );
}

TimeInfo.propTypes = {
  textColor: PropTypes.oneOf(Object.values(timeInfoTextColorEnum))
};

export default TimeInfo;
