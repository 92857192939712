import { getAccessTokenForUser } from "./authUtils";
import { BASE_ENDPOINT } from "../constants/api";
import Axios from "axios";
import { getTwoDigitStringFromNumber } from "./timelineUtils";
import { isNonEmptyArray } from "./arrayUtils";
import { FILTER_DISPLAY_CONFIG } from "../constants/disruptions/filter";
import { MILLISECONDS_IN_A_MINUTE } from "../constants/disruptions/timeline";
import { getTimezoneOffsetForGivenDateTimeAndUserSelectedZone } from "./dateTimeUtils";

export function processRuleSet(ruleSetData) {
  let returnObject = {
    name: "",
    far: { flightOpsRules: {}, inFlightRules: {} },
    contractual: { flightOpsRules: {}, inFlightRules: {} }
  };

  returnObject.name = ruleSetData.ruleSetName;

  ruleSetData.rules.forEach(rule => {
    rule.ruleType === "FAR"
      ? rule.ruleCategory === "FLIGHT_OPS"
        ? (returnObject.far.flightOpsRules[rule.ruleDisplayName] = rule)
        : (returnObject.far.inFlightRules[rule.ruleDisplayName] = rule)
      : rule.ruleCategory === "FLIGHT_OPS"
        ? (returnObject.contractual.flightOpsRules[rule.ruleDisplayName] = rule)
        : (returnObject.contractual.inFlightRules[rule.ruleDisplayName] = rule);
  });

  return returnObject;
}

export function flattenRuleSet(rules) {
  let returnArray = [];

  Object.keys(rules).forEach(rule => {
    returnArray.push(rules[rule]);
  });

  return returnArray;
}

export async function performApiCallToGetAllMetaData(path) {
  const token = await getAccessTokenForUser();
  const url = BASE_ENDPOINT + path;
  const headers = {
    Authorization: token
  };

  return new Promise((resolve, reject) => {
    Axios.get(url, {
      headers: headers
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(reason => {
        reject(reason);
      });
  });
}

export function processStrategy(strategyData) {
  let returnObject = {
    name: "",
    strategies: {}
  };

  returnObject.name = strategyData.strategyName;

  strategyData.strategies.forEach(strategy => {
    returnObject.strategies[strategy.strategyDisplayName] = strategy;
  });

  return returnObject;
}

export function flattenStrategy(strategy) {
  let returnArray = [];

  Object.keys(strategy).forEach(key =>
    returnArray.push({
      strategyDisplayName: strategy[key].strategyDisplayName,
      strategyName: strategy[key].strategyName,
      enabled: strategy[key].enabled
    })
  );

  return returnArray;
}

export function getAllNamesFromMetaData(metaData, key) {
  let returnArray = [];

  metaData.map(item => returnArray.push(item[key]));
  return returnArray;
}

/**
 * processes the received filter data to help in UI rendering
 * @param {*} filterData
 * @param {*} selectedFilterData
 */
export function processFilter(filterData, selectedFilterData) {
  const processedFilterData = {};
  const processedSelectedFilterData = {
    showWarnings: true,
    ...selectedFilterData
  };

  /**
   * creating a map from the recieved filterData
   */
  filterData.forEach(filter => {
    if (filter.filterName === "Pairing") {
      filter.values = separatePairingIds(filter);
    }

    if (filter.filterName === "Date") {
      filter.values = selectedFilterData.date; //set the values property of date filter data to the received date value to check for if any changes during filter application
    }

    if (filter.filterName === "Airport") {
      filter.values.push({ name: "*" });

      //the filters are stored as an array of origin and destination, so converting it into single array to be consumed by the AutoComplete component, wrt CRWEB-682
      if (
        processedSelectedFilterData.airport &&
        isNonEmptyArray(processedSelectedFilterData.airport.origin)
      ) {
        processedSelectedFilterData.airport = [
          ...processedSelectedFilterData.airport.origin
        ];
      } else {
        processedSelectedFilterData.airport = [];
      }
    }

    if (
      filter.filterName === "Airline" &&
      processedSelectedFilterData.airline
    ) {
      processedSelectedFilterData.airline = getSelectedAirlineFilterValues(
        filter.values,
        selectedFilterData.airline
      );
    }

    processedFilterData[filter.filterName] = filter;

    /**
     * new users do not have a completely formed selectedFilterData object (contains only date property)
     * but UI requires it to atleast have a property for each filter initialized to empty array
     * so setting missing property as empty array
     */
    if (String(filter.filterName).toLowerCase() !== "date") {
      const filterKey = getKeyForFilter(filter.filterName);

      processedSelectedFilterData[filterKey] = processedSelectedFilterData[
        filterKey
      ]
        ? processedSelectedFilterData[filterKey]
        : [];
    } else {
      /**
       * dstOffsetDifference is used to account for the difference in offset hours between the offset of current time and offset of user selected time
       * in case of regions where user is affected by Daylight savings react-calendar uses the offset for the current time rather than the selected time 
       * therefore we are finding the difference in offsets and adjusting the same 
       */
      const dstOffsetDifference = new Date().getTimezoneOffset() * -1 - getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(selectedFilterData.date.from, Intl.DateTimeFormat().resolvedOptions().timeZone)
      const filterKey = getKeyForFilter(filter.filterName);

      const from = new Date(
        Number(new Date(selectedFilterData.date.from)) +
        (new Date().getTimezoneOffset() + dstOffsetDifference) * MILLISECONDS_IN_A_MINUTE
      );

      const to = new Date(
        Number(new Date(selectedFilterData.date.to)) +
        (new Date().getTimezoneOffset() + dstOffsetDifference) * MILLISECONDS_IN_A_MINUTE
      );

      processedSelectedFilterData[filterKey] = processedSelectedFilterData[
        filterKey
      ]
        ? { from: from, to: to }
        : { from: new Date(), to: new Date() };
    }
  });

  return {
    filter: processedFilterData,
    selectedFilter: processedSelectedFilterData
  };
}

export function separatePairingIds(pairingValues) {
  let values = [];
  pairingValues.values.forEach(pairingId => {
    pairingId.id && values.push({ name: String(pairingId.id) });
    pairingId.tenantPairingId &&
      values.push({ name: String(pairingId.tenantPairingId) });
  });

  return values;
}

/**
 * returns,
 * nextMonth true -> start of next month,
 * nextMonth false -> start of current month
 * @param {boolean} nextMonth
 */
export function getStartOfMonth(nextMonth) {
  return new Date(
    new Date().getFullYear(),
    nextMonth ? new Date().getMonth() + 1 : new Date().getMonth(),
    1,
    0,
    0,
    0,
    0
  );
}

export function getTimeStringForSolverResponse(dateTimeData, hours, minutes) {
  return `${dateTimeData.getFullYear()}-${getTwoDigitStringFromNumber(
    dateTimeData.getMonth() + 1
  )}-${getTwoDigitStringFromNumber(
    dateTimeData.getDate()
  )}T${getTwoDigitStringFromNumber(hours)}:${getTwoDigitStringFromNumber(
    minutes
  )}`;
}

/**
 * returns the value of the airline in the form AirlineAutoComplete can consume i.e converts ["UA"] -> [{"name": "United Airlines","code": "UA"}]
 * @param {*} allAirlineValues
 * @param {*} selectedAirlineValues
 */
export function getSelectedAirlineFilterValues(
  allAirlineValues,
  selectedAirlineValues
) {
  let selectedAirlineFilter = [];

  for (
    let loopVariable = 0;
    loopVariable < selectedAirlineValues.length;
    loopVariable++
  ) {
    allAirlineValues.forEach(value => {
      if (value.code === selectedAirlineValues[loopVariable]) {
        selectedAirlineFilter.push(value);
      }
    });
  }

  return selectedAirlineFilter;
}

/**
 *  loops through the filter display config and gets the filterKey corresponding to the filterName
 * @param {*} filterName
 * @returns
 */
const getKeyForFilter = filterName => {
  for (let i = 0; i < FILTER_DISPLAY_CONFIG.length; i++)
    if (FILTER_DISPLAY_CONFIG[i].filterName === filterName)
      return FILTER_DISPLAY_CONFIG[i].filterKey;

  return filterName;
};
