import {
  LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
  LOCAL_TIME_ZONE_OFFSET_IN_MINUTE_KEY
} from "../constants/disruptions/disruptionSummary";
import { MILLISECONDS_IN_A_MINUTE } from "../constants/disruptions/timeline";

export const getRangePickerStartAndEnd = (action, prevState) => {
  const { startDateAndTime, endDateAndTime } =
    prevState.rangeSelectionForSolver;

  const { isFirstFetch, isShowingASolverRun } =
    action.timeWindowFromAllPairings;

  /**
   * do not change the position of the range picker if its not the initial load or is showing a solver
   */
  if (!isFirstFetch || isShowingASolverRun)
    return [startDateAndTime, endDateAndTime];

  const {
    uiConfig: {
      rangePicker: { range, startOffset }
    },
    userInfo: { showGanttInUTC, userAttributes }
  } = action.timeWindowFromAllPairings;

  const rangePickerStart =
    Number(new Date()) +
    getOffsetOfRangePicker(startOffset, showGanttInUTC, userAttributes);

  const rangePickerEnd = rangePickerStart + range;

  return [rangePickerStart, rangePickerEnd];
};

/**
 *  returns the offset for the range picker after applying the necessary offsets
 * @param {*} offset
 * @param {*} showGanttInUTC
 * @param {*} userAttributes
 * @returns
 */
export const getOffsetOfRangePicker = (
  offset,
  showGanttInUTC,
  userAttributes
) =>
  showGanttInUTC
    ? offset
    : getUserTimeZoneOffsetInMillisecondsFromUserAttributes(userAttributes) +
      offset;

/**
 * returns the offset of the user selected timzone in milliseconds
 * @param {*} userAttributes
 * @returns
 */
export const getUserTimeZoneOffsetInMillisecondsFromUserAttributes =
  userAttributes =>
    userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][
      LOCAL_TIME_ZONE_OFFSET_IN_MINUTE_KEY
    ] * MILLISECONDS_IN_A_MINUTE;
