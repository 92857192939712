import React from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { Button, Dialog, Tab, Tabs } from "@material-ui/core";

import TabPanel from "../../../../partials/tabPanel/TabPanel";
import SolverParameters from "./SolverParameters";
import CrewsToBeResolved from "./crews-to-be-resolved/CrewsToBeResolved";

import {
  setEndDateForSolver,
  setIncludedCrewMemberForSolverAction,
  setStartDateForSolver
} from "../../../../../redux/searchCriteriaReducer";
import { setIsErrorModalVisibleAction } from "../../../../../redux/showAndHideReducer";
import { solverRequestAction } from "../../../../../redux/webSocketReducer";
import { setIncludedOpenFlyingRowsAction } from "../../../../../redux/solverDataReducer";

import {
  LIMIT_ON_THE_RUN_SOLVER_WINDOW,
  LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
  LOCAL_TIME_ZONE_OFFSET_IN_UTC_KEY
} from "../../../../../constants/disruptions/disruptionSummary";
import { MILLISECONDS_IN_A_DAY } from "../../../../../constants/disruptions/timeline";
import {
  OPEN_PAIRING,
  OPEN_SEGMENT
} from "../../../../../constants/disruptions/openTime";

import { isEmptyArray } from "../../../../../utils/arrayUtils";
import { getCurrentTimestamp } from "../../../../../utils/dateTimeUtils";
import {
  CHARS_TO_BE_REPLACED_IN_SCENARIO_NAME,
  fillOpenFlyingData
} from "../../../../../utils/solverUtil";
import { getTwoDigitStringFromNumber } from "../../../../../utils/timelineUtils";

import "./RunSolverDialogV2.scss";

export default function RunSolverDialogV2(props) {
  const dispatch = useDispatch();

  const { handlerForShowDialog } = props;

  const rangeSelectionForSolver = useSelector(
    store => store.searchCriteria.rangeSelectionForSolver
  );
  const [solverRunData, componentLevelDispatcher] = React.useReducer(
    runSolverDialogReducer,
    initialState
  );

  const selectedStrategy = useSelector(
    store => store.ruleAndStrategy.selectedStrategy
  );

  const selectedRuleSet = useSelector(
    store => store.ruleAndStrategy.selectedRuleSet
  );

  const excludedCrewsForSolver = useSelector(
    store => store.searchCriteria.excludedCrewsForSolver
  );

  const includedCrewsForSolver = useSelector(
    store => store.searchCriteria.includedCrewsForSolver
  );

  const crewsToBeShown = useSelector(store =>
    store.crewSchedules.crewMembers.filter(crew =>
      includedCrewsForSolver.includes(crew.crewExternalId)
    )
  );

  const noCrewsSelected = isEmptyArray(crewsToBeShown);

  const showGanttInUTC = useSelector(store => store.userInfo.showGanttInUTC);

  const userAttributes = useSelector(store => store.userInfo.userAttributes);

  const solverRequestData = useSelector(store => store.solverData.requestData);

  const openTimeData = useSelector(store => store.crewSchedules.openTimeIds);

  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

  const handleTabChange = React.useCallback((event, newValue) => {
    setCurrentTabIndex(newValue);
  }, []);

  /**
   * side effects
   */
  React.useEffect(() => {
    const fromDateFromSolver = new Date(
      rangeSelectionForSolver.startDateAndTime
    );
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "fromDate",
      value: new Date(
        fromDateFromSolver.getUTCFullYear(),
        fromDateFromSolver.getUTCMonth(),
        fromDateFromSolver.getUTCDate()
      )
    });
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "fromHours",
      value: fromDateFromSolver.getUTCHours()
    });
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "fromMinutes",
      value: fromDateFromSolver.getUTCMinutes()
    });

    const toDateFromSolver = new Date(rangeSelectionForSolver.endDateAndTime);
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "toDate",
      value: new Date(
        toDateFromSolver.getUTCFullYear(),
        toDateFromSolver.getUTCMonth(),
        toDateFromSolver.getUTCDate()
      )
    });
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "toHours",
      value: toDateFromSolver.getUTCHours()
    });
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "toMinutes",
      value: toDateFromSolver.getUTCMinutes()
    });

    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "scenarioName",
      value: `${userAttributes.given_name}_${
        userAttributes.family_name
      }_${getCurrentTimestamp()}`
    });
  }, [
    rangeSelectionForSolver,
    props.showDialog,
    userAttributes.given_name,
    userAttributes.family_name
  ]);

  const getDateStringForSolverRun = React.useCallback(
    (
      solverRunDate,
      solverRunHours,
      solverRunMinutes,
      shouldConsiderLocalTimezone = true
    ) =>
      `${solverRunDate.getFullYear()}-${getTwoDigitStringFromNumber(
        solverRunDate.getMonth() + 1
      )}-${getTwoDigitStringFromNumber(
        solverRunDate.getDate()
      )}T${getTwoDigitStringFromNumber(
        solverRunHours
      )}:${getTwoDigitStringFromNumber(solverRunMinutes)}:00${
        //if gantt is shown in UTC append +00:00 else append the corresponding local timezone offset
        showGanttInUTC || !shouldConsiderLocalTimezone
          ? "+00:00"
          : userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][
              LOCAL_TIME_ZONE_OFFSET_IN_UTC_KEY
            ]
      }`,
    [showGanttInUTC, userAttributes]
  );

  const isThereAnyErrors = React.useCallback(() => {
    // 1. check if there is an internet connection
    if (!navigator.onLine) {
      return {
        isThereAnError: true,
        errorMessage:
          "Could not initiate a solver request, please check your internet connection and try again"
      };
    }

    //2. check if the time window(to-from) with in the accepted limit
    if (
      (new Date(
        getDateStringForSolverRun(
          solverRunData.toDate,
          solverRunData.toHours,
          solverRunData.toMinutes
        )
      ) -
        new Date(
          getDateStringForSolverRun(
            solverRunData.fromDate,
            solverRunData.fromHours,
            solverRunData.fromMinutes
          )
        )) /
        MILLISECONDS_IN_A_DAY >
      LIMIT_ON_THE_RUN_SOLVER_WINDOW
    ) {
      return {
        isThereAnError: true,
        errorMessage: `The solver window is currently limited to ${LIMIT_ON_THE_RUN_SOLVER_WINDOW} days. Please reduce your time window and try again.`
      };
    }

    // 3. check if the to date is greater than from date
    if (
      new Date(
        getDateStringForSolverRun(
          solverRunData.toDate,
          solverRunData.toHours,
          solverRunData.toMinutes
        )
      ) <
      new Date(
        getDateStringForSolverRun(
          solverRunData.fromDate,
          solverRunData.fromHours,
          solverRunData.fromMinutes
        )
      )
    ) {
      return {
        isThereAnError: true,
        errorMessage: `You chose a to-date less than from-date...`
      };
    }

    const includedOpenFlyingEntites = fillOpenFlyingData(
      solverRequestData,
      openTimeData
    );

    //4. Check if user has not selected any crew or open time rows to solve
    if (
      noCrewsSelected &&
      includedOpenFlyingEntites.includedOpenSegments.length === 0 &&
      includedOpenFlyingEntites.includedOpenPairings.length === 0
    ) {
      return {
        isThereAnError: true,
        errorMessage: `Please select atleast 1 crew row/ open time row for the solver to solve...`
      };
    }

    return {
      isThereAnError: false,
      errorMessage: ""
    };
  }, [
    getDateStringForSolverRun,
    solverRunData.toDate,
    solverRunData.toHours,
    solverRunData.toMinutes,
    solverRunData.fromDate,
    solverRunData.fromHours,
    solverRunData.fromMinutes,
    solverRequestData,
    openTimeData,
    noCrewsSelected
  ]);

  const runSolverHandler = React.useCallback(() => {
    /**
     * check if there are any errors
     */
    const errorObject = isThereAnyErrors();

    /**
     * if no error then dispatch  solver-request action
     */
    if (!errorObject.isThereAnError) {
      batch(() => {
        dispatch(
          solverRequestAction({
            scenarioName: solverRunData.scenarioName.replace(
              CHARS_TO_BE_REPLACED_IN_SCENARIO_NAME,
              "_"
            ),
            startDate: getDateStringForSolverRun(
              solverRunData.fromDate,
              solverRunData.fromHours,
              solverRunData.fromMinutes
            ),
            endDate: getDateStringForSolverRun(
              solverRunData.toDate,
              solverRunData.toHours,
              solverRunData.toMinutes
            ),
            ruleSetId: selectedRuleSet.id,
            strategyId: selectedStrategy.id,
            excludedCrewIds: excludedCrewsForSolver,
            includedCrewIds: includedCrewsForSolver,
            fixOpenFlights: solverRunData.fixOpenFlights
          })
        );
        dispatch(setIncludedCrewMemberForSolverAction([]));

        batch(() => {
          dispatch(
            setIncludedOpenFlyingRowsAction({
              type: OPEN_SEGMENT,
              includedRows: []
            })
          );
          dispatch(
            setIncludedOpenFlyingRowsAction({
              type: OPEN_PAIRING,
              includedRows: []
            })
          );
        });

        //update redux store with updated solver range
        dispatch(
          setStartDateForSolver(
            Number(
              new Date(
                getDateStringForSolverRun(
                  solverRunData.fromDate,
                  solverRunData.fromHours,
                  solverRunData.fromMinutes,
                  false
                )
              )
            )
          )
        );

        dispatch(
          setEndDateForSolver(
            Number(
              new Date(
                getDateStringForSolverRun(
                  solverRunData.toDate,
                  solverRunData.toHours,
                  solverRunData.toMinutes,
                  false
                )
              )
            )
          )
        );
      });

      handlerForShowDialog(false);
    } else {
      dispatch(
        setIsErrorModalVisibleAction({
          isVisible: true,
          message: errorObject.errorMessage
        })
      );
    }
  }, [
    handlerForShowDialog,
    dispatch,
    solverRunData.scenarioName,
    solverRunData.fromDate,
    solverRunData.fromHours,
    solverRunData.fromMinutes,
    solverRunData.toDate,
    solverRunData.toHours,
    solverRunData.toMinutes,
    selectedRuleSet.id,
    selectedStrategy.id,
    excludedCrewsForSolver,
    solverRunData.fixOpenFlights,
    isThereAnyErrors,
    getDateStringForSolverRun,
    includedCrewsForSolver
  ]);

  return (
    <div className='run-solver-dialog-v2'>
      <Dialog
        id='run-solver-dialog'
        open={props.showDialog}
        onClose={(event, reason) => handlerForShowDialog(false)}
      >
        <>
          <div className='run-solver-dialog-header'>
            <div className='title'>Run Solver</div>
          </div>
        </>
        <>
          <div className='run-solver-dialog-body'>
            <Tabs
              value={currentTabIndex}
              onChange={handleTabChange}
              aria-label='simple tabs example'
            >
              <Tab label='Solver Parameters' />
              <Tab label='Crews To Be Solved' />
            </Tabs>
            <TabPanel value={currentTabIndex} index={0}>
              {solverRunData && (
                <SolverParameters
                  componentLevelDispatcher={componentLevelDispatcher}
                  solverRunData={solverRunData}
                />
              )}
            </TabPanel>
            <TabPanel value={currentTabIndex} index={1}>
              <CrewsToBeResolved
                noCrewsSelected={noCrewsSelected}
                crewsToBeShown={crewsToBeShown}
              />
            </TabPanel>
          </div>
        </>
        <>
          <div className='run-solver-dialog-footer'>
            <Button
              id='run-solver-dialog-cancel-button'
              onClick={() => props.handlerForShowDialog(false)}
            >
              Cancel
            </Button>
            <Button
              id='run-solver-dialog-solve-button'
              onClick={runSolverHandler}
            >
              Run Solver
            </Button>
          </div>
        </>
      </Dialog>
    </div>
  );
}

const initialState = {
  fromDate: new Date(),
  fromHours: 1,
  fromMinutes: 1,
  showFromCalendar: false,
  toDate: new Date(),
  toHours: 1,
  toMinutes: 1,
  showToCalendar: false,
  scenarioName: "",
  fixOpenFlights: true
};

const runSolverDialogReducer = (prevState, action) => {
  switch (action.type) {
    case "UPDATE_DATA":
      return {
        ...prevState,
        [action.field]: action.value
      };
    default:
      return prevState;
  }
};
