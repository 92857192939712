import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip, ClickAwayListener } from "@material-ui/core";

import ToolTipWithViolationMessageAndPairingInfo from "../../../../partials/tooltip/ToolTipWithViolationMessageAndPairingInfo";
import FlightNumber from "../../../../partials/flight/FlightNumber";
import AirportInfo from "../../../../partials/flight/AirportInfo";

import {
  STANDARD_FLIGHT,
  DEADHEAD_FLIGHT,
  RTG_FLIGHT
} from "../../../../../constants/disruptions/color";
import { CREW_POSITION } from "../../../../../constants/disruptions/pairing";

import { getleftMarginForEntity } from "../../../../../utils/timelineUtils";
import {
  findUtcDepartureTimeOfFlight,
  findUtcArrivalTimeOfFlight,
  handleClickAway,
  handleOnClick,
  getCommentMarkerDataUtil,
  shouldShowFlightDetails
} from "../../../../../utils/pairingUtils";
import { isNonEmptyArray } from "../../../../../utils/arrayUtils";
import {
  getAirportLocalTime,
  getColorForCancelledDeadhead,
  getColorForFlight,
  getEndTimeOfFlight,
  getStartTimeOfFlight,
  getWidthForFlightBlock,
  getBackgroundForViolatingDeadHeads
} from "../../../../../utils/flightUtils";
import ViolationCommentMarker from "../../../../partials/violation-comment-marker/ViolationCommentMarker";
import { OPEN_SEGMENT } from "../../../../../constants/disruptions/openTime";

import "./Pairing.scss";
import TimeInfo from "../../../../partials/time-info/TimeInfo";

// component level state
const initialState = {
  flightStartTimeInEpochMillis: undefined,
  flightEndTimeInEpochMillis: undefined,
  flightLengthInViewWidthUnits: 0,
  leftMarginForFlightInViewWidthUnits: 0,
  flightDepUTCHours: 0,
  flightDepUTCMinutes: 0,
  flightArrUTCHours: 0,
  flightArrUTCMinutes: 0,
  flightDepLocalHours: 0,
  flightDepLocalMinutes: 0,
  flightArrLocalHours: 0,
  flightArrLocalMinutes: 0,
  entityViolationColor: null
};

const flightReducer = (prevState, action) => {
  switch (action.type) {
    case "INITIALIZE_FLIGHT":
      return {
        ...prevState,
        ...action.flightData
      };
    case "UPDATE_START_AND_END":
      return {
        ...prevState,
        ...action.flightStartAndEndData
      };
    default:
      return prevState;
  }
};

export default function Flight(props) {
  const dateRange = useSelector(store => store.searchCriteria.dateRange);

  const ruleDisplayConfig = useSelector(
    store => store.crewSchedules.processedViolations.ruleDisplayConfig
  );

  const {
    flight,
    dutyStartTime,
    violatingData,
    isOpen,
    pairingId,
    isFlightOps,
    crewIndexInStore,
    openFlyingType
  } = props;
  const [flightData, componentLevelDispatcher] = React.useReducer(
    flightReducer,
    initialState
  );

  const showGanttInUTC = useSelector(store => store.userInfo.showGanttInUTC);

  const userAttributes = useSelector(store => store.userInfo.userAttributes);

  const backgroundForViolatingDeadHead = React.useCallback(
    color => getBackgroundForViolatingDeadHeads(color),
    []
  );

  const colorForCancelledDeadHead = React.useCallback(
    () =>
      backgroundForViolatingDeadHead(
        getColorForCancelledDeadhead(
          ruleDisplayConfig["CANCELLED_FLIGHT"],
          isFlightOps
        )
      ),
    [ruleDisplayConfig, isFlightOps, backgroundForViolatingDeadHead]
  );

  /**
   * RTG flights have the same origin and destination and flight status as '*-return'
   */
  const checkIfRTG = React.useCallback(
    (status, origin, destination) =>
      origin === destination && String(status).includes("-return"),
    []
  );

  /**
   * PPP flights have the same start and end time
   */
  const checkIfPPP = React.useCallback(
    (startTime, endTime) => startTime === endTime,
    []
  );

  const widthOfFlightBlock = React.useCallback(
    (startTime, endTime, widthOfOneDay, isRTG, isPPP) =>
      getWidthForFlightBlock(startTime, endTime, widthOfOneDay, isRTG, isPPP),
    []
  );

  const startTimeOfFlight = React.useCallback(
    (flight, showGanttInUTC, userAttributes) =>
      getStartTimeOfFlight(flight, showGanttInUTC, userAttributes),
    []
  );

  const endTimeOfFlight = React.useCallback(
    (flight, showGanttInUTC, userAttributes) =>
      getEndTimeOfFlight(flight, showGanttInUTC, userAttributes),
    []
  );

  const colorOfFlight = React.useCallback(
    (violatingData, ruleDisplayConfig, isFlightOps) =>
      getColorForFlight(violatingData, ruleDisplayConfig, isFlightOps),
    []
  );

  /**
   * returns the time with the offset added to it
   */
  const airportLocalTime = React.useCallback(
    (timeInUTC, offset) => getAirportLocalTime(timeInUTC, offset),
    []
  );

  const getCommentMarkerData = React.useCallback(
    violatingData => getCommentMarkerDataUtil(violatingData, isFlightOps),
    [isFlightOps]
  );

  React.useEffect(() => {
    let flightStateData = {};

    /**
     * flight departure and arrival in UTC - used when the gantt is shown in UTC time
     */
    let flightDepartureTime = new Date(findUtcDepartureTimeOfFlight(flight));
    let flightArrivalTime = new Date(findUtcArrivalTimeOfFlight(flight));

    flightStateData.flightDepUTCHours = flightDepartureTime.getUTCHours();
    flightStateData.flightDepUTCMinutes = flightDepartureTime.getUTCMinutes();
    flightStateData.flightArrUTCHours = flightArrivalTime.getUTCHours();
    flightStateData.flightArrUTCMinutes = flightArrivalTime.getUTCMinutes();

    /**
     * flight departure and arrival in their respective airport local time - used when the gantt is shown in local times
     */
    let airportLocalDepartureTimes = airportLocalTime(
      flightDepartureTime,
      flight.originTZ
    );
    let airportLocalArrivalTimes = airportLocalTime(
      flightArrivalTime,
      flight.destinationTZ
    );

    flightStateData.flightDepLocalHours =
      airportLocalDepartureTimes.getUTCHours();
    flightStateData.flightDepLocalMinutes =
      airportLocalDepartureTimes.getUTCMinutes();
    flightStateData.flightArrLocalHours =
      airportLocalArrivalTimes.getUTCHours();
    flightStateData.flightArrLocalMinutes =
      airportLocalArrivalTimes.getUTCMinutes();

    let combinedViolations =
      violatingData && isNonEmptyArray(Object.keys(violatingData))
        ? { ...violatingData }
        : {};

    //process violation from previous sit time block here
    if (props.previousSitTimeViolation) {
      props.previousSitTimeViolation.forEach(violation => {
        combinedViolations = {
          ...combinedViolations,
          [`${violation.ruleIdentity.ruleName}-${violation.ruleIdentity.ruleCategory}-${violation.ruleIdentity.ruleType}`]:
            violation
        };
      });
    }

    flightStateData.entityViolationColor = colorOfFlight(
      combinedViolations,
      ruleDisplayConfig,
      isFlightOps
    );

    flightStateData.allViolationData = combinedViolations
      ? Object.values(combinedViolations)
      : [];

    flightStateData.commentMarkerData = getCommentMarkerData(
      flightStateData.allViolationData
    );

    componentLevelDispatcher({
      type: "INITIALIZE_FLIGHT",
      flightData: flightStateData
    });
  }, [
    flight,
    violatingData,
    ruleDisplayConfig,
    isFlightOps,
    props.previousSitTimeViolation,
    airportLocalTime,
    colorOfFlight,
    getCommentMarkerData,
    userAttributes
  ]);

  React.useEffect(() => {
    let flightStateData = {};

    flightStateData.flightStartTimeInEpochMillis = startTimeOfFlight(
      flight,
      showGanttInUTC,
      userAttributes
    );

    flightStateData.flightEndTimeInEpochMillis = endTimeOfFlight(
      flight,
      showGanttInUTC,
      userAttributes
    );

    flightStateData.isRTG = checkIfRTG(
      flight.flightStatus,
      flight.origin,
      flight.destination
    );

    flightStateData.isPPP = checkIfPPP(
      flightStateData.flightEndimeInEpochMillis,
      flightStateData.flightStartTimeInEpochMillis
    );

    flightStateData.flightLengthInViewWidthUnits = widthOfFlightBlock(
      flightStateData.flightStartTimeInEpochMillis,
      flightStateData.flightEndTimeInEpochMillis,
      dateRange.widthOfOneDay,
      flightStateData.isRTG,
      flightStateData.isPPP
    );

    flightStateData.leftMarginForFlightInViewWidthUnits =
      getleftMarginForEntity(
        flightStateData.flightStartTimeInEpochMillis,
        dateRange.widthOfOneDay,
        new Date(dutyStartTime)
      );

    componentLevelDispatcher({
      type: "UPDATE_START_AND_END",
      flightStartAndEndData: flightStateData
    });
  }, [
    showGanttInUTC,
    userAttributes,
    flight,
    dateRange.widthOfOneDay,
    dutyStartTime,
    checkIfRTG,
    checkIfPPP,
    endTimeOfFlight,
    startTimeOfFlight,
    widthOfFlightBlock
  ]);

  const [showToolTip, setShowTooltip] = useState(false);

  const createTooltipForFlight = React.useCallback(() => {
    return (
      <ToolTipWithViolationMessageAndPairingInfo
        violationMessages={flightData.allViolationData}
        pairingId={pairingId}
        isFlightOps={isFlightOps}
        tooltipRef={tooltipRef}
        handlerToOpenCloseTooltip={setShowTooltip}
        crewIndexInStore={crewIndexInStore}
        isOpen={isOpen}
      />
    );
  }, [
    pairingId,
    isFlightOps,
    flightData.allViolationData,
    crewIndexInStore,
    isOpen
  ]);

  const getBackgroundColorForFlight = React.useCallback(() => {
    return `${
      props.previousSitTimeViolation && flight.flightType === "DHD"
        ? backgroundForViolatingDeadHead(flightData.entityViolationColor)
        : flightData.isRTG
        ? RTG_FLIGHT
        : flight.flightType === "DHD"
        ? flight.flightStatus === "cancelled"
          ? colorForCancelledDeadHead()
          : DEADHEAD_FLIGHT
        : !isOpen
        ? flightData.entityViolationColor
          ? flightData.entityViolationColor
          : STANDARD_FLIGHT
        : STANDARD_FLIGHT
    }`;
  }, [
    flight.flightType,
    isOpen,
    flightData.entityViolationColor,
    flight.flightStatus,
    colorForCancelledDeadHead,
    backgroundForViolatingDeadHead,
    props.previousSitTimeViolation,
    flightData.isRTG
  ]);

  const handleClickAwayForFlight = React.useCallback(
    event => {
      handleClickAway(
        event,
        props.isChildrenHavingTooltipOpen,
        showToolTip,
        setShowTooltip
      );
    },
    [props.isChildrenHavingTooltipOpen, showToolTip]
  );

  const handleOnClickForFlight = React.useCallback(
    event => {
      if (isNonEmptyArray(flightData.allViolationData)) {
        event.stopPropagation();

        if (props.isParentTooltipOpen) {
          props.handlerForOpeningAndClosingParentTooltip(false);
        }

        handleOnClick(
          event,
          props.isChildrenHavingTooltipOpen,
          showToolTip,
          setShowTooltip
        );
      }
    },
    [props, showToolTip, flightData.allViolationData]
  );

  /**
   * this state is used to re-draw the parent ref after every change of the expansion panel inside the tooltip
   */
  const [
    stateToHandleDynamicPositioningOfTooltip,
    setStateToHandleDynamicPositioningOfTooltip
  ] = React.useState(false);

  /**
   * ref of the tooltip to be passed to the tooltip's inner component on which the scheduleUpdate() will be called
   */
  const tooltipRef = React.useRef(null);

  /**
   * ref to hold the timeout object
   */
  const isUpdateStateEnqued = React.useRef(null);

  /**
   * returns true if the flight is missing a crew for the given poistion
   */
  const isMissingPosition = React.useCallback(
    position =>
      isOpen
        ? openFlyingType === OPEN_SEGMENT
          ? flight.openPositions && flight.openPositions[position] > 0
          : props.pairingOpenPositions &&
            props.pairingOpenPositions[position] > 0
        : false,
    [isOpen, flight.openPositions, props.pairingOpenPositions, openFlyingType]
  );

  const shouldShowFlightDetailsMemoised = React.useCallback(() => {
    return shouldShowFlightDetails(
      dateRange.rangeInDaysInSingleViewPort,
      flightData
    );
  }, [flightData, dateRange.rangeInDaysInSingleViewPort]);

  return (
    <ClickAwayListener onClickAway={handleClickAwayForFlight}>
      <Tooltip
        title={createTooltipForFlight()}
        open={showToolTip}
        arrow
        interactive
        PopperProps={{
          popperOptions: {
            onCreate: data => {
              /**
               * on tooltip creation assign the tooltip instance to the tooltipRef
               **/
              tooltipRef.current = data.instance;
            },
            onUpdate: data => {
              /**
               * on every update check if there is a updateState already under progress in timeout if so then clear it
               */
              if (isUpdateStateEnqued.current) {
                clearTimeout(isUpdateStateEnqued.current);
              }

              /**
               * create a new timeout which handles the change in the state
               */
              isUpdateStateEnqued.current = setTimeout(() => {
                setStateToHandleDynamicPositioningOfTooltip(
                  !stateToHandleDynamicPositioningOfTooltip
                );
              }, 250);
            }
          }
        }}
      >
        <span
          className={`flight-main ${
            flightData.isPPP
              ? "flight-with-same-arrival-and-departure-times"
              : ""
          }`}
          id={flight.id}
          onClick={handleOnClickForFlight}
          style={{
            width: flightData.flightLengthInViewWidthUnits + "vw",
            left: flightData.leftMarginForFlightInViewWidthUnits + "vw",
            background: getBackgroundColorForFlight(),
            zIndex: props.isOpen ? 2 : flightData.isRTG ? 1 : 0
          }}
        >
          <>
            <AirportInfo
              flightData={flightData}
              flight={flight}
              dateRange={dateRange}
              isRTG={flightData.isRTG}
            />
          </>
          <>
            <FlightNumber
              flightData={flightData}
              flight={flight}
              dateRange={dateRange}
              isRTG={flightData.isRTG}
            />
          </>
          {shouldShowFlightDetailsMemoised() && (
            <TimeInfo
              startTime={findUtcDepartureTimeOfFlight(flight)}
              endTime={findUtcArrivalTimeOfFlight(flight)}
              originTZ={flight.originTZ}
              destinationTZ={flight.destinationTZ}
            />
          )}
          {isMissingPosition(CREW_POSITION.CA) && (
            <div className='triangle-top-left'></div>
          )}
          {isMissingPosition(CREW_POSITION.FO) && (
            <div className='triangle-top-right'></div>
          )}
          {isMissingPosition(CREW_POSITION.FA) && (
            <div className='triangle-bottom-right'></div>
          )}
          {flightData.commentMarkerData &&
            flightData.commentMarkerData.showMarker && (
              <ViolationCommentMarker
                currentViolatingData={
                  flightData.commentMarkerData.violatingData
                }
                className={`violation-comment-marker-flight`}
              />
            )}
        </span>
      </Tooltip>
    </ClickAwayListener>
  );
}
