import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener, Tooltip, Divider, Button } from "@material-ui/core";
import { Error } from "@material-ui/icons";

import { DEFAULT_ROW_HEIGHT } from "../../../../constants/disruptions/ganttCrewCommon";

import { getTwoDigitStringFromNumber } from "../../../../utils/timelineUtils";
import { convertToPascalCase } from "../../../../utils/stringUtils";
import { isInArray, isNonEmptyArray } from "../../../../utils/arrayUtils";

import "./CrewGanttContainer.scss";
import CustomCheckBox from "../../../partials/checkbox/CustomCheckBox";
import {
  CLICK_EVENT_CONSTANTS,
  NUMBER_OF_CHARACTER_TO_SHOW_FOR_EQUIPMENT,
  NUMBER_OF_CHARACTER_TO_SHOW_IN_CREW_ROW
} from "../../../../constants/display";
import { includeExcludeCrewsForSolver } from "../../../../utils/solverUtil";
import { OPEN_SEGMENT } from "../../../../constants/disruptions/openTime";
import { setIncludedOpenFlyingRowsAction } from "../../../../redux/solverDataReducer";
import SimpleTooltipMessage from "../../../partials/simpletooltipmessages/SimpleTooltipMessage";
import { FileCopy as CopyToClipboardIcon } from '@material-ui/icons'

function CrewRow(props) {
  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );
  const showSolverInputRows = useSelector(
    store => store.searchCriteria.showSolverInputRows
  );

  const includedCrewsForSolver = useSelector(
    store => store.searchCriteria.includedCrewsForSolver
  );

  const solverRequestData = useSelector(store => store.solverData.requestData);
  const { includedOpenSegmentRows, includedOpenPairingRows } =
    solverRequestData;

  /**
   * if an open time row then check if the row is selected by the user
   */
  const isThisRowSelectedForOpenTimeSolve = props.isOpen
    ? props.type === OPEN_SEGMENT
      ? isInArray(props.openFlyingRowNumber, includedOpenSegmentRows)
      : isInArray(props.openFlyingRowNumber, includedOpenPairingRows)
    : false;

  const dispatch = useDispatch();

  const [showTooltip, setShowTooltip] = React.useState(false);
  const [isEmployeeIdCopied, setIsEmployeeIdCopied] = React.useState(false);

  const crewName =
    props.crewMember && props.crewMember.firstname && props.crewMember.lastname
      ? convertToPascalCase(
          `${props.crewMember.firstname} ${props.crewMember.lastname}`
        )
      : `${props.rowNumber}`;

  /**
   * returns date in the format mm/dd/yyyy
   */
  const getDateString = React.useCallback(
    date =>
      `${getTwoDigitStringFromNumber(
        date.getUTCMonth() + 1 //adding 1 as the getUTCMonth() method returns number from {0_>Jan,11->Dec}
      )}/${getTwoDigitStringFromNumber(
        date.getUTCDate()
      )}/${getTwoDigitStringFromNumber(date.getUTCFullYear())}`,
    []
  );

  const handleStateChangeForCrewCheckBox = React.useCallback(
    (isChecked, crewId) => {
      includeExcludeCrewsForSolver(
        isChecked,
        crewId,
        includedCrewsForSolver,
        dispatch
      );
    },
    [dispatch, includedCrewsForSolver]
  );

  const handleStateChangeForCrewCheckBoxInOpenTime = React.useCallback(
    (isChecked, index, additionalParamsForHandler) => {
      let includedRows = [];

      const {
        includedOpenPairingRows,
        includedOpenSegmentRows,
        type,
        dispatcher
      } = additionalParamsForHandler;

      if (isChecked) {
        if (type === OPEN_SEGMENT) {
          includedRows = [...includedOpenSegmentRows, index];
        } else {
          includedRows = [...includedOpenPairingRows, index];
        }
      } else {
        if (type === OPEN_SEGMENT) {
          includedRows = includedOpenSegmentRows.filter(
            rowIndex => rowIndex !== index
          );
        } else {
          includedRows = includedOpenPairingRows.filter(
            rowIndex => rowIndex !== index
          );
        }
      }

      dispatcher(
        setIncludedOpenFlyingRowsAction({
          type,
          includedRows
        })
      );
    },
    []
  );

  const handleClickOnCrewRow = React.useCallback(event => {
    //check if the click occured outside the checkbox
    if (
      event.target.type === CLICK_EVENT_CONSTANTS.CHECKBOX_EVENT_TYPE &&
      event.target.tagName === CLICK_EVENT_CONSTANTS.INPUT_TAGNAME_IN_EVENT
    )
      return;
    setShowTooltip(true);
  }, []);

  const handleOnClickEmployeeIdCopyToClipboard = React.useCallback(
    (event) => {
      event.stopPropagation()
      navigator.clipboard.writeText(
        props.crewMember ? props.crewMember.crewExternalId : null
      )
      setIsEmployeeIdCopied(true)
    },
    [props.crewMember]
  )

  /**
   * Clipboard.readText() is A Promise that resolves with a DOMString containing the textual contents of the clipboard.
   * Returns an empty string if the clipboard is empty
   */
   const getClipboardInfo = React.useCallback(() => {
    try {
      navigator.clipboard
        .readText()
        .then((value) =>
          setIsEmployeeIdCopied(props.crewMember.crewExternalId === value)
        ) //check if the crewId is the one copied in the clipboard
        .catch((err) => setIsEmployeeIdCopied(false)) //if user has not given permission to read from clipboard then set it to false
    } catch (err) {
      console.log(err)
    }
  }, [props.crewMember])

  React.useEffect(() => {
    getClipboardInfo()
  }, [showTooltip, getClipboardInfo])

  /**
   * creates a tooltip component for the crew
   */
  const createTooltipForCrew = React.useCallback(
    () => (
      <div className="crew-info-tooltip">
        <div className="employee-id crew-info-tooltip-row">
          <span className="bold">
            Employee ID <span>- </span>
          </span>
          <span>
            {props.crewMember ? props.crewMember.crewExternalId : null}
          </span>
          <Button
            className="crew-info-employee-id-copy"
            onClick={handleOnClickEmployeeIdCopyToClipboard}
          >
            <CopyToClipboardIcon
              style={{ color: `${isEmployeeIdCopied ? 'green' : 'initial'}` }}
            />
          </Button>
        </div>
        <div className="employee-base crew-info-tooltip-row">
          <span className="bold">
            Crew Base <span>- </span>
          </span>
          <span>{props.crewMember ? props.crewMember.base : null}</span>
        </div>
        <div className="employee-qualification crew-info-tooltip-row">
          <div className="bold">Qualifications</div>
          <div className="employee-qualification-header">
            <div>Equipment</div>
            <div>Rank</div>
            <div>Expires on</div>
          </div>
          <div className="employee-qualification-body">
            {props.crewMember &&
            isNonEmptyArray(props.crewMember.qualifications) ? (
              props.crewMember.qualifications.map((qualification, index) => {
                let expiryDate = new Date(qualification.utcExpiryDateTime)

                return (
                  <div key={index} className="employee-qualification-body-row">
                    {qualification.equipment ? (
                      qualification.equipment.length <
                      NUMBER_OF_CHARACTER_TO_SHOW_FOR_EQUIPMENT ? (
                        <div>{qualification.equipment}</div>
                      ) : (
                        <ToolTipContainerForOverFlowingRows
                          tooltipMessage={qualification.equipment}
                        >
                          <div>
                            {qualification.equipment.substring(
                              0,
                              NUMBER_OF_CHARACTER_TO_SHOW_FOR_EQUIPMENT - 1
                            )}
                          </div>
                        </ToolTipContainerForOverFlowingRows>
                      )
                    ) : null}

                    <div>{qualification.rank}</div>
                    <div>{getDateString(expiryDate)}</div>
                    <div>
                      {/**
                       * denote an error icon on each de-qualified equipment for the crew
                       */}
                      {props.crewMember &&
                        props.crewMember.dequalificationInfo &&
                        props.crewMember.dequalificationInfo
                          .isCrewDequalified &&
                        isInArray(
                          index,
                          props.crewMember.dequalificationInfo.indices
                        ) && <Error />}
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="no-qualification-info">No qualification info</div>
            )}
          </div>
        </div>
      </div>
    ),
    [getDateString, props.crewMember, isEmployeeIdCopied, handleOnClickEmployeeIdCopyToClipboard]
  )

  const ToolTipContainerForOverFlowingRows = ({ children, tooltipMessage }) => (
    <Tooltip
      title={<SimpleTooltipMessage tooltipMessage={tooltipMessage} />}
      PopperProps={{
        modifiers: {
          offset: {
            enabled: true,
            fn: data => {
              //if the placement is bottom then set the top offset
              if (data.placement === "bottom") {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    top: -20, // adding this offset so that the tooltip is placed properly under the name
                    left: 0
                  }
                };
              } else {
                //if not set back the offsets to 0
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    top: 0,
                    left: 0
                  }
                };
              }
            }
          }
        }
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      {(props.crewMember &&
        ((isShowingASolverRun && props.crewMember.solvedPairings) ||
          !isShowingASolverRun)) ||
      !props.crewMember ? (
        <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
          <Tooltip
            title={!props.isOpen ? createTooltipForCrew() : ""} //do not show crew info tooltip for openflight rows
            placement={"right"}
            arrow
            interactive
            open={showTooltip}
          >
            <div
              key={props.rowNumber}
              className="left-side-row"
              style={{
                height:
                  !props.isOpen && isShowingASolverRun && showSolverInputRows
                    ? DEFAULT_ROW_HEIGHT * 2 + "px"
                    : DEFAULT_ROW_HEIGHT + "px"
              }}
              onClick={handleClickOnCrewRow}
            >
              {props.crewMember &&
              props.crewMember.crewExternalId !== undefined ? (
                <div className="crew-info">
                  {!isShowingASolverRun ? (
                    <span className="crew-checkbox">
                      <CustomCheckBox
                        stateValue={isInArray(
                          props.crewMember.crewExternalId,
                          includedCrewsForSolver
                        )}
                        stateChangeHandler={handleStateChangeForCrewCheckBox}
                        index={props.crewMember.crewExternalId}
                      />
                    </span>
                  ) : null}
                  <span>
                    {/**
                     * show a red marker if a crew is dequalified
                     */}
                    {props.crewMember.dequalificationInfo &&
                      props.crewMember.dequalificationInfo
                        .isCrewDequalified && (
                        <Divider orientation="vertical" />
                      )}
                  </span>
                  <span className="crew-rank">
                    {props.crewMember.rank ? `${props.crewMember.rank}` : " "}
                  </span>
                  {crewName.length < NUMBER_OF_CHARACTER_TO_SHOW_IN_CREW_ROW ? (
                    <span className="crew-name">{crewName}</span>
                  ) : (
                    <ToolTipContainerForOverFlowingRows
                      tooltipMessage={crewName}
                    >
                      <span className="crew-name">
                        {crewName.substring(
                          0,
                          NUMBER_OF_CHARACTER_TO_SHOW_IN_CREW_ROW - 1
                        ) + "..."}
                      </span>
                    </ToolTipContainerForOverFlowingRows>
                  )}
                </div>
              ) : (
                <div className="crew-info">
                  <span className="crew-checkbox">
                    {!isShowingASolverRun && (
                      <CustomCheckBox
                        stateValue={isThisRowSelectedForOpenTimeSolve}
                        stateChangeHandler={
                          handleStateChangeForCrewCheckBoxInOpenTime
                        }
                        dispatcher={dispatch}
                        index={props.openFlyingRowNumber}
                        additionalParamsForHandler={{
                          type: props.type,
                          includedOpenPairingRows: includedOpenPairingRows,
                          includedOpenSegmentRows: includedOpenSegmentRows
                        }}
                      />
                    )}
                  </span>
                  <span className="crew-name">{`Open Time`}</span>
                </div>
              )}
            </div>
          </Tooltip>
        </ClickAwayListener>
      ) : null}
    </>
  );
}

export default React.memo(CrewRow);

/**
 * The code below is used to exclude crews from running a solver run
 */
// const excludedCrewsForSolver = useSelector(
//   store => store.searchCriteria.excludedCrewsForSolver
// );

// const dispatch = useDispatch();

// const handleStateChangeForCrewCheckBox = React.useCallback(
//   (isChecked, crewId) => {
//     if (!isChecked) {
//       dispatch(
//         setExcludedCrewMemberForSolverAction([
//           ...excludedCrewsForSolver,
//           crewId
//         ])
//       );
//     } else {
//       const newExcludedCrewRows = excludedCrewsForSolver.filter(
//         crewMemberId => crewMemberId !== crewId
//       );
//       dispatch(setExcludedCrewMemberForSolverAction(newExcludedCrewRows));
//     }
//   },
//   [dispatch, excludedCrewsForSolver]
// );
