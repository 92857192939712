import { Checkbox, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNonEmptyArray } from "../../../utils/arrayUtils";
import Arrow from "../arrow/Arrow";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { setSelectedFilterAction } from "../../../redux/searchCriteriaReducer";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AirlineAutoCompleteComponent() {
  const dispatch = useDispatch();

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const filterData = useSelector(store => store.searchCriteria.filterData);

  const selectedAirlineFilter = useSelector(
    store => store.searchCriteria.selectedFilter.airline
  );

  const selectedAirlineFilterOnSolverOutputScreen = useSelector(
    store => store.searchCriteria.selectedFilterForSolverOutput.airline
  );

  const getAirlineFilterValues = React.useCallback(() => {
    if (filterData["Airline"].values) return [...filterData["Airline"].values];
    else return [{ name: "Fetching..." }];
  }, [filterData]);

  const handleChangeForAirlineFilter = React.useCallback(
    values => {
      dispatch(
        setSelectedFilterAction({
          isCurrentSchedule: isShowingASolverRun ? false : true,
          filterName: "airline",
          selectedFilter: values
        })
      );
    },
    [dispatch, isShowingASolverRun]
  );

  const getAirlineFilterSelectedValue = React.useCallback(
    () =>
      isShowingASolverRun
        ? selectedAirlineFilterOnSolverOutputScreen
        : selectedAirlineFilter,
    [
      selectedAirlineFilter,
      selectedAirlineFilterOnSolverOutputScreen,
      isShowingASolverRun
    ]
  );

  return (
    <div className="auto-complete airline-auto-complete">
      <Autocomplete
        className="airline-auto-complete"
        renderInput={params => (
          <TextField
            {...params}
            label={"Airline"}
            variant="standard"
            className={[
              isNonEmptyArray(getAirlineFilterSelectedValue())
                ? "selected-filter"
                : "noop"
            ].join(" ")}
          />
        )}
        renderOption={(option, { selected }) => {
          return option.name !== "Fetching..." &&
            option.name !== "No options" ? ( //if option is fetching do not show checkboxes
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                color="primary"
                checked={selected}
                className="airline-auto-complete-checkbox"
              />
              {option.name}
            </React.Fragment>
          ) : (
            <div className="auto-complete-loading-placeholder">
              {option.name}
            </div>
          );
        }}
        renderTags={() => {}}
        options={
          getAirlineFilterValues().length > 0
            ? getAirlineFilterValues().map(option => option)
            : [{ name: "No options" }]
        }
        size="small"
        popupIcon={<Arrow />}
        multiple
        onChange={(event, value) => handleChangeForAirlineFilter(value)}
        value={getAirlineFilterSelectedValue()}
        disableCloseOnSelect
        getOptionLabel={option => option.name}
      />
    </div>
  );
}
