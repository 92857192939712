import Axios from 'axios'
import {
  BASE_ENDPOINT,
  DEADHEAD_REPORT,
  CUMULATIVE_TIME_REPORT,
} from '../constants/api'
import { LOCAL_TIME_ZONE_ATTRIBUTE_KEY } from '../constants/disruptions/disruptionSummary'
import {
  setCanFetchMoreDeadheadsAction,
  setDeadheadDataAction,
  setIsLoadingAction,
} from '../redux/deadheadReportReducer'
import {
  setIsDataFetchedAction,
  setIsLoadingValueAction,
  setCumulativeTimeDataAction,
  setParentTableVisibleColumnCount,
} from '../redux/cumulativeTimeReportReducer'
import { setIsErrorModalVisibleAction } from '../redux/showAndHideReducer'
import { isNonEmptyArray } from './arrayUtils'
import { getAccessTokenForUser } from './authUtils'
import { processDateFilter } from './middlewareUtils'
import { getMinutesInHoursAndMinutesString } from './dateTimeUtils'

export const fetchDeadheadData = async (store) => {
  const dispatch = store.dispatch
  const searchData = store.getState().deadheadReport.searchData
  const filterOptions = store.getState().deadheadReport.filterOptions
  const offset = store.getState().deadheadReport.deadheadData.offset

  const processedSearchData = processSearchData(searchData)
  const userInfo = store.getState().userInfo

  const token = await getAccessTokenForUser()
  const url = BASE_ENDPOINT + DEADHEAD_REPORT
  const headers = {
    Authorization: token,
  }

  if (isAValidRequest(processedSearchData)) {
    const validRequestObject = getValidRequestObject(
      processedSearchData,
      filterOptions
    )

    const requestObject = {
      requestType: 'GET_REPORTS',
      requestBody: {
        ...validRequestObject,
        offset: offset * 200,
        isInUTC: userInfo.showGanttInUTC,
        timeZone:
          userInfo.userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY]['zone'],
      },
    }

    try {
      const deadheadSearchResponse = await Axios.post(url, requestObject, {
        headers: headers,
      })

      const { flightCombinations, flights } = deadheadSearchResponse.data

      if (flightCombinations && flightCombinations.length > 0) {
        dispatch(
          setDeadheadDataAction({
            flightCombinations: flightCombinations,
            flights: flights,
            offset: offset + 1,
          })
        )

        if (flightCombinations.length < 200) {
          dispatch(setCanFetchMoreDeadheadsAction(false))
        } else {
          dispatch(setCanFetchMoreDeadheadsAction(true))
        }
      } else {
        dispatch(
          setIsErrorModalVisibleAction({
            isVisible: true,
            message: 'No data received.',
          })
        )
        dispatch(setCanFetchMoreDeadheadsAction(false))
        dispatch(setIsLoadingAction(false))
      }
    } catch (err) {
      dispatch(
        setIsErrorModalVisibleAction({
          isVisible: true,
          message: 'Error occurred while fetching data please try again.',
        })
      )
      dispatch(setIsLoadingAction(false))
    }
  } else {
    dispatch(
      setIsErrorModalVisibleAction({
        isVisible: true,
        message: 'Please fill in all the fields.',
      })
    )
    dispatch(setIsLoadingAction(false))
  }
}

const isAValidRequest = (searchData) =>
  searchData.date &&
  searchData.origin &&
  searchData.destination &&
  searchData.hopCount &&
  searchData.minSitTime

const getValidRequestObject = (searchData, filterOptions) => {
  const validRequestObject = {
    startTime: null,
    endTime: null,
    origin: null,
    destination: null,
    hopCount: null,
    minSitTime: null,
    airline: [],
  }

  /**
   *  to be in sync with the gantt airline filter behaviour
   *  if user selects no airline then all airlines should be selected
   */
  if (searchData.airline && searchData.airline.length === 0) {
    const airlineFilterOptions = filterOptions.airline

    if (isNonEmptyArray(airlineFilterOptions)) {
      airlineFilterOptions.forEach((options) => {
        validRequestObject.airline.push(options.label)
      })
    }
  } else {
    validRequestObject.airline = searchData.airline.map((item) => item.label)
  }

  validRequestObject.startTime = searchData.date.start
  validRequestObject.endTime = searchData.date.end
  validRequestObject.origin = searchData.origin.name
  validRequestObject.destination = searchData.destination.name
  validRequestObject.hopCount = searchData.hopCount.value
  validRequestObject.minSitTime = searchData.minSitTime.value

  return validRequestObject
}

const processSearchData = (searchData) => {
  const toReturn = {
    ...searchData,
  }

  if (searchData['date']) {
    const processedDate = processDateFilter({
      from: searchData['date'].start,
      to: searchData['date'].end,
    })
    toReturn['date'] = {
      start: processedDate.from,
      end: processedDate.to,
    }
  }

  return toReturn
}

export const fetchCumulativeTimeData = async (store) => {
  const dispatch = store.dispatch
  const searchData = store.getState().cumulativeTimeReport.searchData
  dispatch(setIsDataFetchedAction(false))
  dispatch(setIsLoadingValueAction(true))

  const token = await getAccessTokenForUser()
  const url = BASE_ENDPOINT + CUMULATIVE_TIME_REPORT
  const headers = {
    Authorization: token,
  }

  const requestObj = {
    crewId: searchData.crewId,
    isSummary: false,
  }

  // no need to pass start and end date if not selected by user
  // backend handles it
  if (searchData && !(searchData.date.start instanceof Date)) {
    const processedSearchData = processSearchData(searchData)
    requestObj.startTime = processedSearchData.date.start
    requestObj.endTime = processedSearchData.date.end
  }

  if (searchData && searchData.crewId) {
    try {
      const cumulativeTimeSearchResponse = await Axios.post(url, requestObj, {
        headers: headers,
      })
      if (cumulativeTimeSearchResponse?.data?.data?.length > 0) {
        processCumulativeTimeResponse(cumulativeTimeSearchResponse.data, store)
        dispatch(setCumulativeTimeDataAction(cumulativeTimeSearchResponse.data))
        dispatch(setIsDataFetchedAction(true))
      } else {
        dispatch(
          setIsErrorModalVisibleAction({
            isVisible: true,
            message: 'No data received.',
          })
        )
      }
      dispatch(setIsLoadingValueAction(false))
    } catch (err) {
      dispatch(
        setIsErrorModalVisibleAction({
          isVisible: true,
          message:
            'Error occurred while fetching cumulative time data. Please try again.',
        })
      )
      dispatch(setIsLoadingValueAction(false))
    }
  } else {
    dispatch(
      setIsErrorModalVisibleAction({
        isVisible: true,
        message: 'Please fill in all the fields.',
      })
    )
    dispatch(setIsLoadingValueAction(false))
  }
}

const processCumulativeTimeResponse = (cumulativeTimeResponse, store) => {
  const dispatch = store.dispatch
  let parentTableVisibleColumnsCount = Object.keys(cumulativeTimeResponse.data[0]).length
  const columnsWithDurationType = []

  if (cumulativeTimeResponse?.metadata?.columns) {
    cumulativeTimeResponse.metadata.columns.forEach((column, index) => {
      // {for react-csv} need label attribute to add header in CSV
        column.label = column.name
        if(column.type === 'duration') {
          columnsWithDurationType.push(column.key)
        }
    })
    cumulativeTimeResponse.data.forEach((rowData) => {

      // columns with type as duration should be formatted as hh:mm
      columnsWithDurationType.forEach((column) => {
        rowData[column] = getMinutesInHoursAndMinutesString(rowData[column])
      })
    })
  }
  dispatch(setParentTableVisibleColumnCount(parentTableVisibleColumnsCount))
}
