import { Divider } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

/**
 * Draws the day demarcation line
 */
export default function DayDemarcation() {
  const dateRangeSelection = useSelector(
    store => store.searchCriteria.dateRange
  );

  const widthOfOneDay = dateRangeSelection.widthOfOneDay;
  const numberOfDays = dateRangeSelection.entireRangeInDays;

  return (
    <div className="day-demarcation-container">
      {[...Array(numberOfDays)].map((value, index) => (
        <div
          className="day-demarcation"
          style={{ width: widthOfOneDay + "vw" }}
          key={`day-demarcation-${value}-${index}`}
        >
          <Divider key={index} orientation={"vertical"} />
        </div>
      ))}
    </div>
  );
}
