import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./Arrow.scss";

export default function Arrow({ iconClass, isInverted }) {
  return (
    <div
      className={`arrow-container ${iconClass && iconClass.className} ${
        isInverted && "inverted-expand-more"
      }`}
    >
      <ExpandMoreIcon fontSize="small" />
    </div>
  );
}
