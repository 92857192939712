import {
  MILLISECONDS_IN_AN_HOUR,
  MINUTES_IN_AN_HOUR,
} from '../constants/disruptions/timeline'
import { getTwoDigitStringFromNumber } from './timelineUtils'

export const padZero = (number) => {
  return ('0' + number).slice(-2)
}

export const getCurrentTimestamp = () => {
  const currentDate = new Date()
  const currentMonth =
    currentDate.getMonth() + 1 /** as months are indexed from 0 */
  const currentDayOfMonth = currentDate.getDate()
  const currentHour = currentDate.getHours()
  const currentMinute = currentDate.getMinutes()

  return `${padZero(currentMonth)}${padZero(currentDayOfMonth)}${padZero(
    currentHour
  )}${padZero(currentMinute)}`
}

export const addOrSubtractHours = function (
  date,
  hours,
  isSubtraction = false
) {
  date.setTime(
    date.getTime() + hours * 60 * 60 * 1000 * (isSubtraction ? -1 : 1)
  )
  return date
}

/**
 * Does the following things
 * convert recieved time string to Date obj
 * get the current timezone offset
 * convert the datetime to the new/required timezone
 * get the difference between the current datetime and the converted datetime
 * add said difference to the current timezone offset
 * @param {*} dateTime
 * @param {*} userSelectedZone
 * @returns
 */
export const getTimezoneOffsetForGivenDateTimeAndUserSelectedZone = (
  dateTime,
  userSelectedZone
) => {
  const dateTimeInUtc = new Date(dateTime)

  const utcOffsetInHoursForCurrentTimezone =
    (dateTimeInUtc.getTimezoneOffset() / 60) * -1

  const utcTimeConvertedToLocalTimezone = dateTimeInUtc.toLocaleString(
    'en-US',
    {
      timeZone: userSelectedZone,
    }
  )

  const dateTimeInUserSelectedZone = new Date(utcTimeConvertedToLocalTimezone)

  const differenceInHours =
    (dateTimeInUserSelectedZone.getTime() - dateTimeInUtc.getTime()) / //getTime() -  returns number of milliseconds since January 1, 1970 00:00:00. - eg: 1646218293740
    MILLISECONDS_IN_AN_HOUR

  const offsetHours = utcOffsetInHoursForCurrentTimezone + differenceInHours

  return MINUTES_IN_AN_HOUR * offsetHours //converting to minutes as the existing code uses offsets in minutes
}

export const getTimeStringForDateObject = (dateTimeData) => {
  return `${dateTimeData.getFullYear()}-${getTwoDigitStringFromNumber(
    dateTimeData.getMonth() + 1
  )}-${getTwoDigitStringFromNumber(
    dateTimeData.getDate()
  )}T${getTwoDigitStringFromNumber(
    dateTimeData.getHours()
  )}:${getTwoDigitStringFromNumber(dateTimeData.getMinutes())}:00.000Z`
}

export const getTimeStringForString = (dateTimeData) =>
  dateTimeData.concat(':00.000Z')

export const getDateObjectForOneMonthAgo = (dateNow) => {
  return new Date(
    dateNow.getFullYear(),
    dateNow.getMonth() - 1,
    dateNow.getDate(),
    dateNow.getHours(),
    dateNow.getMinutes(),
    dateNow.getSeconds(),
    dateNow.getMilliseconds()
  )
}

// function to convert minutes to hh:mm format
export const getMinutesInHoursAndMinutesString = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `${addLeadingZero(hours)}:${addLeadingZero(remainingMinutes)}`
}

// function to add a leading zero to the number if it is less than 10
export const addLeadingZero = (number) => {
  return number < 10 ? ('0' + number) : number;
}
