const ACTIONS = {
  setIsRangePickerOverlayVisible: "SET_IS_RANGE_PICKER_OVERLAY_VISIBLE",
  setIsOpenTimesVisible: "SET_IS_OPEN_TIMES_VISIBLE",
  setIsRuleSetDrawerVisible: "SET_IS_RULE_SET_DRAWER_VISIBLE",
  setIsStrategyDrawerVisible: "SET_IS_STRATEGY_DRAWER_VISIBLE",
  setIsHamburgerMenuVisible: "SET_IS_HAMBURGER_MENU_VISIBLE",
  setIsFilterDropDownVisible: "SET_IS_FILTER_DROP_DOWN_VISIBLE",
  setIsErrorModalVisible: "SET_IS_ERROR_MODAL_VISIBLE",
  setIsDateTimeDialogVisible: "SET_IS_DATE_TIME_DIALOG_VISIBLE",
  setIsUserProfileDrawerVisible: "SET_IS_USER_PROFILE_DRAWER_VISIBLE",
  setIsViolationDrilledDownDrawerVisible:
    "SET_IS_VIOLATION_DRILLED_DOWN_DRAWER_VISIBLE"
};

const initialState = {
  isRangePickerOverlayVisible: false,
  isOpenTimesVisible: false,
  isRuleManagerDrawerVisible: false,
  isStrategyManagerDrawerVisible: false,
  isHamburgerMenuVisible: false,
  isFilterDropDownVisible: false,
  isErrorModalVisible: { isVisible: false, message: "" },
  isDateTimeDialogVisible: false,
  isUserProfileDrawerVisible: false,
  isViolationDrilledDownDrawerVisible: false
};

export function showAndHideReducer(prevState = initialState, action) {
  let newState;
  switch (action.type) {
    case "SET_IS_RANGE_PICKER_OVERLAY_VISIBLE":
      newState = {
        ...prevState,
        isRangePickerOverlayVisible: action.isRangePickerOverlayVisible
      };
      break;
    case "SET_IS_OPEN_TIMES_VISIBLE":
      newState = {
        ...prevState,
        isOpenTimesVisible: action.isOpenTimesVisible
      };
      break;
    case "SET_IS_RULE_SET_DRAWER_VISIBLE":
      newState = {
        ...prevState,
        isRuleManagerDrawerVisible: action.isOpen,
        isStrategyManagerDrawerVisible: action.isOpen
          ? false
          : prevState.isStrategyManagerDrawerVisible
      };

      break;

    case "SET_IS_STRATEGY_DRAWER_VISIBLE":
      newState = {
        ...prevState,
        isStrategyManagerDrawerVisible: action.isOpen,
        isRuleManagerDrawerVisible: action.isOpen
          ? false
          : prevState.isRuleManagerDrawerVisible
      };
      break;

    case "SET_IS_HAMBURGER_MENU_VISIBLE":
      newState = {
        ...prevState,
        isHamburgerMenuVisible: action.isHamburgerMenuVisible
      };
      break;

    case "SET_IS_FILTER_DROP_DOWN_VISIBLE":
      newState = {
        ...prevState,
        isFilterDropDownVisible: action.isFilterDropDownVisible
      };
      break;
    case "SET_IS_ERROR_MODAL_VISIBLE":
      newState = {
        ...prevState,
        isErrorModalVisible: action.isErrorModalVisible
      };
      break;
    case "SET_IS_DATE_TIME_DIALOG_VISIBLE":
      newState = {
        ...prevState,
        isDateTimeDialogVisible: action.isDateTimeDialogVisible
      };
      break;
    case "SET_IS_USER_PROFILE_DRAWER_VISIBLE":
      newState = {
        ...prevState,
        isUserProfileDrawerVisible: action.isUserProfileDrawerVisible
      };
      break;
    case "SET_IS_VIOLATION_DRILLED_DOWN_DRAWER_VISIBLE":
      newState = {
        ...prevState,
        isViolationDrilledDownDrawerVisible:
          action.isViolationDrilledDownDrawerVisible
      };
      break;

    default:
      newState = prevState;
      break;
  }
  return newState;
}

export function setIsRangePickerOverlayVisible(isRangePickerOverlayVisible) {
  return {
    type: ACTIONS.setIsRangePickerOverlayVisible,
    isRangePickerOverlayVisible
  };
}

export function setIsOpenTimesVisible(isOpenTimesVisible) {
  return { type: ACTIONS.setIsOpenTimesVisible, isOpenTimesVisible };
}

export function setIsRuleSetDrawerVisibleAction(isOpen) {
  return {
    type: ACTIONS.setIsRuleSetDrawerVisible,
    isOpen
  };
}

export function setIsStrategyDrawerVisibleAction(isOpen) {
  return {
    type: ACTIONS.setIsStrategyDrawerVisible,
    isOpen
  };
}

export function setIsHamburgerMenuVisibleAction(isHamburgerMenuVisible) {
  return {
    type: ACTIONS.setIsHamburgerMenuVisible,
    isHamburgerMenuVisible
  };
}

export function setIsFilterDropDownVisibleAction(isFilterDropDownVisible) {
  return {
    type: ACTIONS.setIsFilterDropDownVisible,
    isFilterDropDownVisible
  };
}

export function setIsErrorModalVisibleAction(isErrorModalVisible) {
  return {
    type: ACTIONS.setIsErrorModalVisible,
    isErrorModalVisible
  };
}

export function setIsDateTimeDialogVisibleAction(isDateTimeDialogVisible) {
  return {
    type: ACTIONS.setIsDateTimeDialogVisible,
    isDateTimeDialogVisible
  };
}

export function setIsUserProfileDrawerVisibleAction(
  isUserProfileDrawerVisible
) {
  return {
    type: ACTIONS.setIsUserProfileDrawerVisible,
    isUserProfileDrawerVisible
  };
}

export function setIsViolationDrilledDownDrawerVisibleAction(
  isViolationDrilledDownDrawerVisible
) {
  return {
    type: ACTIONS.setIsViolationDrilledDownDrawerVisible,
    isViolationDrilledDownDrawerVisible
  };
}
