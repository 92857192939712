import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

import PublicRoute from "./auth/PublicRoute";
import PrivateRoute from "./auth/PrivateRoute";
import { checkWhetherLoggedIn } from "../utils/authUtils";
import { loginAction } from "../redux/loginReducer";

import * as ROUTES from "../constants/routes";
import messages_en from "../translations/en.json";
import messages_es from "../translations/es.json";
import { flattenMessages } from "../utils/jsonUtils";

import "./App.scss";

// aggregating the translations
const messages = {
  es: messages_es,
  en: messages_en
};

const AdminPage = React.lazy(() => import("./auth/Admin"));
const HomePage = React.lazy(() => import("./HomePage/HomePage"));
const LandingPage = React.lazy(() => import("./LandingPage/LandingPage"));
const AuthLayout = React.lazy(() => import("./auth/AuthLayout"));
const PageNotFound = React.lazy(() => import("./misc/PageNotFound"));
const UploadPage = React.lazy(() => import("./upload/UploadContainer"));
const ReportRouter = React.lazy(() => import("./reports/ReportsRouter"));
const SolverScenarioViewPage = React.lazy(() => import("./solver-scenario/SolverScenarioViewPage"));

/** The Suspense and Lazy load APIs in React help
 * automatically load the bundle containing the components
 * in dynamic imports above (with React.lazy)
 * when this component is first rendered.
 * Reference: https://reactjs.org/docs/code-splitting.html
 */
const App = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.i18n.locale);

  const [isLoggedInStateChecked, setIsLoggedInStateChecked] =
    React.useState(false);

  React.useEffect(() => {
    checkWhetherLoggedIn()
      .then(() => dispatch(loginAction()))
      .catch(err => console.log(err.message))
      .finally(() => setIsLoggedInStateChecked(true));
  }, [dispatch]);

  return (
    <IntlProvider locale={locale} messages={flattenMessages(messages[locale])}>
      <AppRoutes isLoggedInStateChecked={isLoggedInStateChecked} />
    </IntlProvider>
  );
};

export const AppRoutes = ({ isLoggedInStateChecked }) => {
  return (
    <>
      {isLoggedInStateChecked ? (
        <React.Suspense fallback={<div className="app-loader">Loading...</div>}>
          <Router>
            <Switch>
              <PublicRoute path={ROUTES.LANDING} exact>
                <LandingPage />
              </PublicRoute>

              <PublicRoute path={ROUTES.AUTH}>
                <AuthLayout />
              </PublicRoute>

              <PrivateRoute path={ROUTES.HOME}>
                <HomePage />
              </PrivateRoute>

              <PrivateRoute path={ROUTES.ADMIN}>
                <AdminPage />
              </PrivateRoute>

              <PrivateRoute path={ROUTES.UPLOAD}>
                <UploadPage />
              </PrivateRoute>

              <PrivateRoute path={ROUTES.REPORTS} component={ReportRouter} />

              <PrivateRoute path={ROUTES.VIEW_SOLVER_SCENARIO} component={SolverScenarioViewPage} />

              <PublicRoute path="*">
                <PageNotFound />
              </PublicRoute>
            </Switch>
          </Router>
        </React.Suspense>
      ) : (
        <div className="app-loader">Loading...</div>
      )}
    </>
  );
};

export default App;
