import React from "react";
import { Link } from "react-router-dom";
import { DEADHEAD, REPORTS } from "../../../constants/routes";

export default function DeadheadReportRedirect() {
  return (
    <Link
      to={`${REPORTS}${DEADHEAD}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Deadhead Candidate Report
    </Link>
  );
}
