import { batch } from "react-redux";
import { getCommentDateTime } from "../components/partials/violation-drill-down-section/utils";
import {
  updateRuleIdentityForCrewTimeViolationAction,
  updateRuleIdentityForViolationAction
} from "../redux/crewSchedulesReducer";
import {
  setCommentDataAction,
  setIsDeletingCommentAction,
  setIsSavingCommentAction
} from "../redux/violationDrillDownReducer";
import { getSpreadSafeArray } from "./arrayUtils";

export const processCommentRefreshDataAndUpdateStore = (
  commentRefreshData,
  violationMetadata,
  commentData,
  dispatch
) => {
  const uiAction = commentRefreshData.action;
  const affectedViolationMetadata =
    commentRefreshData.affectedViolationMetadata;

  const reduxKeyOfAffectedViolation = doesScheduleContainAffectedViolation(
    affectedViolationMetadata,
    violationMetadata
  );

  if (reduxKeyOfAffectedViolation) {
    /**
     * update count data
     */
    if (reduxKeyOfAffectedViolation.split("/")[1] !== "crew") {
      dispatch(
        updateRuleIdentityForViolationAction({
          reduxKey: reduxKeyOfAffectedViolation,
          key: "commentCount",
          value: uiAction === "ADD" ? 1 : -1
        })
      );
    } else {
      dispatch(
        updateRuleIdentityForCrewTimeViolationAction({
          reduxKey: reduxKeyOfAffectedViolation,
          key: "commentCount",
          value: uiAction === "ADD" ? 1 : -1
        })
      );
    }

    /**
     * update the comment data
     * we are caching the comment data for violations that have been already opened once
     * updating the cached comment data here
     */
    const violationKey =
      affectedViolationMetadata.violationId +
      "_" +
      affectedViolationMetadata.violationType;
    const affectedCommentData = commentData[violationKey];

    /**
     * is comment data cached?
     */
    if (Array.isArray(affectedCommentData)) {
      //then update

      /**
       * if the action is ADD then append the new comment in redux
       */
      if (uiAction === "ADD") {
        const newComment = processNewlyAddedComment(
          commentRefreshData.affectedCommentData.newComment
        );

        batch(() => {
          dispatch(
            setCommentDataAction({
              violationKey: violationKey,
              comments: getSpreadSafeArray([
                ...affectedCommentData,
                ...newComment
              ])
            })
          );
          dispatch(setIsSavingCommentAction(false));
        });
      } else {
        const deletedCommentId =
          commentRefreshData.affectedCommentData.affectedCommentId;

        const commentDataWithAffectedCommentRemoved =
          affectedCommentData.filter(
            comment => String(comment.commentId) !== String(deletedCommentId)
          );

        batch(() => {
          dispatch(
            setCommentDataAction({
              violationKey: violationKey,
              comments: commentDataWithAffectedCommentRemoved
            })
          );

          dispatch(setIsDeletingCommentAction(false));
        });
      }
    }
  }
};

const doesScheduleContainAffectedViolation = (
  affectedViolationMetadata,
  scheduleViolationMetadata
) => {
  const affectedViolationId = affectedViolationMetadata.violationId;

  if (affectedViolationMetadata.violationType === "ENTITY") {
    if (scheduleViolationMetadata.entityViolation[affectedViolationId])
      return scheduleViolationMetadata.entityViolation[affectedViolationId][
        "reduxKey"
      ];
  } else {
    if (scheduleViolationMetadata.timeViolation[affectedViolationId])
      return scheduleViolationMetadata.timeViolation[affectedViolationId][
        "reduxKey"
      ];
  }

  return null;
};

export const getViolatingDataAfterUpdate = (
  violatingData,
  changedViolationMetadata,
  countDiff
) => {
  const returnArray = [];

  getSpreadSafeArray(violatingData).forEach(violation => {
    const violationType = violation.violator ? "ENTITY" : "TIME";
    const violationId = violation.ruleIdentity.violationId;

    if (
      violationType === changedViolationMetadata.violationType &&
      String(violationId) === String(changedViolationMetadata.violationId)
    ) {
      const changedViolation = {
        ...violation,
        ruleIdentity: {
          ...violation.ruleIdentity,
          commentCount:
            Number(violation.ruleIdentity.commentCount) + Number(countDiff)
        }
      };

      returnArray.push(changedViolation);
    } else {
      returnArray.push(violation);
    }
  });

  return returnArray;
};

const processNewlyAddedComment = newlyAddedComment => {
  const processedCommentData = [];

  newlyAddedComment.forEach(comment => {
    const commentObj = {
      commentId: comment.commentId,
      comment: comment.comment,
      commentBy: comment.commentBy,
      commentDateTime: getCommentDateTime(comment.commentDateTime)
    };
    processedCommentData.push(commentObj);
  });

  return processedCommentData;
};
