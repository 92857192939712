import React from "react";
import {
  BeachAccess,
  LocalHospital,
  School,
  EmojiTransportation
} from "@material-ui/icons";

export const CODES_TO_ICON_MAP = {
  /**
   * VACATION CODES
   */
  VAC: <BeachAccess />,
  ABS: <BeachAccess />,
  OFF: <BeachAccess />,
  V24: <BeachAccess />,
  V28: <BeachAccess />,
  /**
   * SICK CODES
   */
  SIC: <LocalHospital />,
  /**
   * TRAINING CODES
   */
  TRN: <School />,
  SIM: <School />,
  /**
   * TRANSPORT CODES
   */
  UBER: <EmojiTransportation />,
  TAXI: <EmojiTransportation />,
  LIMO: <EmojiTransportation />
};
