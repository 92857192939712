import { CODE_FOR_OPERATIONAL_DUTY } from "../constants/disruptions/crewGanttViewer";
import { MINIMUM_VIEW_WIDTH_FOR_TIME_INFO_ON_RESERVE_BLOCK_TO_BE_SHOWN } from "../constants/disruptions/waitingTime";
import { isNonEmptyArray, isEmptyArray } from "./arrayUtils";

export const hasAnySitReportOrReleaseViolation = (
  sitTimeViolations,
  releaseAndReportViolations
) =>
  isNonEmptyArray(sitTimeViolations) ||
  isNonEmptyArray(releaseAndReportViolations);

export const shouldShowTimeInfoOnDutyBlock = (
  dutyCode,
  flightIds,
  dutyLengthInViewWidthUnits
) => {
  return (
    dutyCode !== CODE_FOR_OPERATIONAL_DUTY &&
    isEmptyArray(flightIds) &&
    dutyLengthInViewWidthUnits >=
      MINIMUM_VIEW_WIDTH_FOR_TIME_INFO_ON_RESERVE_BLOCK_TO_BE_SHOWN
  )
}
