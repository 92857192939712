import React from "react";

import { useDispatch } from "react-redux";
import { setRuleSetValueAction } from "../../../../redux/ruleAndStrategyManagerReducer";
import ColorPicker from "./ColorPicker";
import { getTwoDigitStringFromNumber } from "../../../../utils/timelineUtils";
import {
  CREW_TYPE,
  TIME_METRIC_MAP,
  RULE_TYPE_TO_REDUX_PROPERTY_MAP
} from "../../../../constants/disruptions/disruptionSummary";
import { ClickAwayListener } from "@material-ui/core";
import CustomModal from "../../../partials/modal/CustomModal";
import { isInArray } from "../../../../utils/arrayUtils";
import {
  TABLE_A_RULES,
  TABLE_B_RULES
} from "../../../../constants/disruptions/rules";
import { TABLE_A_IMAGE, TABLE_B_IMAGE } from "../../../../constants/resources";

export default function RuleTable({
  ruleSet,
  type,
  isContractual,
  hasPermissionToEdit
}) {
  const dispatch = useDispatch();

  const [isTableAorTableBImageShown, setIsTableAorTableBImageShown] =
    React.useState(false);

  const [imageToShow, setImageToShow] = React.useState(null);

  const getTimeMetric = React.useCallback((rule, rules) => {
    return `${TIME_METRIC_MAP[rules[rule].ruleConfig.unit] || ""}`;
  }, []);

  const handleClickAwayFromImageContainer = React.useCallback(() => {
    setIsTableAorTableBImageShown(false);
    setImageToShow("");
  }, []);

  const handleClickOnImageContainer = React.useCallback(showTableB => {
    setIsTableAorTableBImageShown(true);
    setImageToShow(showTableB ? TABLE_B_IMAGE : TABLE_A_IMAGE);
  }, []);

  const getChangedMinutes = React.useCallback((hours, minutes) => {
    return Number(hours) * 60 + Number(minutes);
  }, []);

  /**
   * onKeyDown handler is chosen to get control over what character is typed
   * this function always appends the newly entered key(number here) to the units position
   */
  const handleOnKeyDownForMinuteTextField = React.useCallback(
    (event, minuteValue, hourValue, keyForValue, rule, rules) => {
      let currentValue = Number(minuteValue);

      let changedMin = null;

      /**
       * if the value entered is a number then
       */
      if (Number(event.key) >= 0 && Number(event.key) <= 9) {
        //convert the newly entered value into String
        let recentlyEnteredNumber = String(event.key);

        //extract the digit in the units place
        //minuteValue is a string of the format mm eg: "45" which on spreading will give an array containing ["4","5"] so units place will be at index 1
        let digitInUnitsPlace = String([...minuteValue][1]);

        //newly formed number is a String concatenation of recentlyEnteredNumber and digitInUnitsPlace
        let newFormedNumber = Number(
          String(digitInUnitsPlace) + String(recentlyEnteredNumber)
        );

        /**
         * if: newFormdedNumber is in range [0-60] then calculate changedMin with minute field as newFormedNumber
         * else: calculate changedMin with minute field as 0
         */
        if (newFormedNumber <= 60)
          changedMin = getChangedMinutes(hourValue, newFormedNumber);
        else changedMin = getChangedMinutes(hourValue, 0);
      } else if (event.key === "ArrowUp") {
        //increase the previous value by 1
        changedMin = getChangedMinutes(hourValue, currentValue + 1);
      } else if (event.key === "ArrowDown") {
        //decrease the previous value by 1
        changedMin = getChangedMinutes(hourValue, currentValue - 1);
      } else if (event.key === "Backspace" || event.key === "Delete") {
        //extract the digit in ten's place
        //minuteValue is a string of the format mm eg: "45" which on spreading will give an array containing ["4","5"] so tens Place will be at index 0
        let digitInTensPlace = String([...minuteValue][0]);

        //newly formed number is a String concatenation of 0 and digitInTensPlace
        let newFormedNumber = Number(String(0) + String(digitInTensPlace));

        changedMin = getChangedMinutes(hourValue, newFormedNumber);
      } else {
        //for any other key press retain the same value as before
        changedMin = getChangedMinutes(hourValue, minuteValue);
      }

      dispatch(
        setRuleSetValueAction({
          value: changedMin,
          key: keyForValue,
          ruleName: rules[rule].ruleDisplayName,
          type:
            type === CREW_TYPE.FLIGHT_OPS
              ? RULE_TYPE_TO_REDUX_PROPERTY_MAP.FLIGHT_OPS_RULES
              : RULE_TYPE_TO_REDUX_PROPERTY_MAP.IN_FLIGHT_RULES,
          isContractual: isContractual
        })
      );
    },
    [dispatch, getChangedMinutes, isContractual, type]
  );

  const TableAorTableBImage = React.useCallback(
    ({ image }) => {
      return (
        <div className={"table-a-table-b-rule-image"}>
          <ClickAwayListener onClickAway={handleClickAwayFromImageContainer}>
            <img
              src={image}
              alt={image === TABLE_A_IMAGE ? "TABLE-A" : "TABLE-B"}
            ></img>
          </ClickAwayListener>
        </div>
      );
    },
    [handleClickAwayFromImageContainer]
  );

  const getValueInHours = React.useCallback((value, metric) => {
    if (metric === "mins") {
      let hours = value / 60;
      let remainingHours = Math.floor(hours);
      let minutes = (hours - remainingHours) * 60;
      let remainingMinutes = Math.round(minutes);
      return `${getTwoDigitStringFromNumber(
        remainingHours
      )}:${getTwoDigitStringFromNumber(remainingMinutes)}`;
    } else return "";
  }, []);

  const HoursAndMinutesTextField = React.useCallback(
    ({ rule, value, keyForValue, className, rules, timeMetric, editable }) => {
      let hourAndMinuteArray = getValueInHours(value, timeMetric).split(":");

      return (
        <div className="value-container">
          {timeMetric === "" ? (
            <div className="bordered centered">
              <input
                type="number"
                className={`${className} single-value`}
                value={getTwoDigitStringFromNumber(Number(value))}
                onChange={event =>
                  dispatch(
                    setRuleSetValueAction({
                      value: Number(event.target.value),
                      key: keyForValue,
                      ruleName: rules[rule].ruleDisplayName,
                      type:
                        type === CREW_TYPE.FLIGHT_OPS
                          ? RULE_TYPE_TO_REDUX_PROPERTY_MAP.FLIGHT_OPS_RULES
                          : RULE_TYPE_TO_REDUX_PROPERTY_MAP.IN_FLIGHT_RULES,
                      isContractual: isContractual
                    })
                  )
                }
                disabled={!editable}
              />
              {timeMetric || ""}
            </div>
          ) : (
            <span className="bordered">
              <input
                type="number"
                className={`${className} text-align-right`}
                value={hourAndMinuteArray[0]}
                onChange={event => {
                  let changedMin = getChangedMinutes(
                    event.target.value,
                    hourAndMinuteArray[1]
                  );

                  dispatch(
                    setRuleSetValueAction({
                      value: changedMin,
                      key: keyForValue,
                      ruleName: rules[rule].ruleDisplayName,
                      type:
                        type === CREW_TYPE.FLIGHT_OPS
                          ? RULE_TYPE_TO_REDUX_PROPERTY_MAP.FLIGHT_OPS_RULES
                          : RULE_TYPE_TO_REDUX_PROPERTY_MAP.IN_FLIGHT_RULES,
                      isContractual: isContractual
                    })
                  );
                }}
                disabled={!editable}
                min={0}
              />
              <div className="centered time-seperator">:</div>
              <input
                type="number"
                className={`${className} text-align-left`}
                value={hourAndMinuteArray[1]}
                disabled={!editable}
                onKeyDown={event =>
                  handleOnKeyDownForMinuteTextField(
                    event,
                    hourAndMinuteArray[1],
                    hourAndMinuteArray[0],
                    keyForValue,
                    rule,
                    rules
                  )
                }
              />
            </span>
          )}
        </div>
      );
    },
    [
      dispatch,
      isContractual,
      type,
      getValueInHours,
      getChangedMinutes,
      handleOnKeyDownForMinuteTextField
    ]
  );

  return (
    <div className="rule-table-container">
      <div className="table-header">
        {/* TODO: commenting as CRWEB-267, uncomment when ready to implement actual functionality */}
        {/* <div>Severity</div> */}
        <div className="rule-name">Rule Name</div>
        <div>Value</div>
        <div>Buffer</div>
        {/** Commenting as part of CRWEB-870, to simplify UI */}
        {/* <div>
          <div className="irop-value-header">IROP Value</div>
          <div>(only for Solver)</div>
        </div> */}
        <div>Color</div>
      </div>
      <div className="table-body">
        {[...Object.keys(ruleSet)].sort().map((rule, index) => (
          <div key={index} className="table-row-container">
            {/* TODO: commenting as CRWEB-267, uncomment when ready to implement actual functionality */}
            {/* <div>
              <CustomToggle
                toggleState={ruleSet[rule].ruleSeverity}
                ruleName={ruleSet[rule].ruleDisplayName}
                type={type}
                isContractual={isContractual}
                hasPermissionToEdit={hasPermissionToEdit}
              />
            </div> */}
            <div className="rule-name">{ruleSet[rule].ruleDisplayName}</div>
            <div className="value-container">
              {isInArray(ruleSet[rule].ruleName, [
                ...TABLE_A_RULES,
                ...TABLE_B_RULES
              ]) ? ( //true if ruleName is either a tableA or tableB rule
                <div
                  onClick={() => {
                    handleClickOnImageContainer(
                      isInArray(ruleSet[rule].ruleName, TABLE_B_RULES) //true if ruleName is a tableB rule
                    );
                  }}
                >
                  <div className="click-here-for-values-label">
                    Click here for values
                  </div>
                </div>
              ) : (
                <div>
                  {getTimeMetric(rule, ruleSet) !== ""
                    ? getValueInHours(
                        ruleSet[rule].ruleValue.value,
                        getTimeMetric(rule, ruleSet)
                      )
                    : typeof ruleSet[rule].ruleValue.value === "object"
                    ? "Multi valued"
                    : getTwoDigitStringFromNumber(
                        ruleSet[rule].ruleValue.value
                      )}
                </div>
              )}
            </div>
            <HoursAndMinutesTextField
              rule={rule}
              value={ruleSet[rule].ruleValue.warningValue}
              keyForValue="warningValue"
              className="warning-text-box"
              rules={ruleSet}
              timeMetric={getTimeMetric(rule, ruleSet)}
              editable={
                hasPermissionToEdit && ruleSet[rule].ruleConfig.evaluateWarning
              }
            />
            {/** Commenting as part of CRWEB-870, to simplify UI */}
            {/* <HoursAndMinutesTextField
              rule={rule}
              value={ruleSet[rule].ruleValue.iropsValue}
              keyForValue="iropsValue"
              className="irops-text-box"
              rules={ruleSet}
              timeMetric={getTimeMetric(rule, ruleSet)}
              editable={
                hasPermissionToEdit 
              }
            /> */}
            <div className="color-picker-container centered">
              <ColorPicker
                color={ruleSet[rule].ruleConfig.color}
                ruleName={ruleSet[rule].ruleDisplayName}
                type={type}
                isContractual={isContractual}
                dispatch={dispatch}
                hasPermissionToEdit={hasPermissionToEdit}
              />
            </div>
          </div>
        ))}
        {isTableAorTableBImageShown && (
          <CustomModal
            className="table-a-table-b-image"
            ComponentToBeRendered={<TableAorTableBImage image={imageToShow} />}
          />
        )}
      </div>
    </div>
  );
}
