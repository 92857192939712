import { batch } from "react-redux";
import {
  setCachedSolverOutputAction,
  setSelectedScenarioAction,
  setSelectedScenarioSolverOutputAction
} from "../redux/solverDataReducer";
import { isNonEmptyArray } from "./arrayUtils";
import { calculatePositionAndWidthOfEntities } from "./getScheduleUtils";
import { makeSolverOutputAsCrewScheduleAndCopyOriginalSchedule } from "./solverUtil";
import { getTimeWindowFromAllPairings } from "./timelineUtils";

const processPairings = solution => {
  if (isNonEmptyArray(solution.pairings)) {
    let crewPairingsFromSolverOutput = {};
    solution.pairings.forEach(
      pairing =>
        (crewPairingsFromSolverOutput[
          pairing.pairingId ? pairing.pairingId : pairing.id
        ] = pairing)
    );
    solution.pairings = crewPairingsFromSolverOutput;
  }
};

const processDuties = solution => {
  if (isNonEmptyArray(solution.duties)) {
    let crewDutiesFromSolverOutput = {};
    solution.duties.forEach(
      duty =>
        (crewDutiesFromSolverOutput[duty.dutyId ? duty.dutyId : duty.id] = duty)
    );
    solution.duties = crewDutiesFromSolverOutput;
  }
};

const processFlights = solution => {
  if (isNonEmptyArray(solution.flights)) {
    let crewFlightsFromSolverOutput = {};
    solution.flights.forEach(flight => {
      if (
        solution.openFlights &&
        solution.openFlights[flight.id.toString()] &&
        solution.openFlights[flight.id.toString()].openPositions
      ) {
        flight.openPositions =
          solution.openFlights[flight.id.toString()].openPositions;
      }
      crewFlightsFromSolverOutput[
        flight.flightId ? flight.flightId : flight.id
      ] = flight;
    });
    solution.flights = crewFlightsFromSolverOutput;
  }
};

const processDeadheads = solution => {
  if (isNonEmptyArray(solution.deadheads)) {
    let deadheadsFromSolverOutput = { ...solution.flights };
    solution.deadheads.forEach(flight => {
      if (
        solution.openFlights &&
        solution.openFlights[flight.id.toString()] &&
        solution.openFlights[flight.id.toString()].openPositions
      ) {
        flight.openPositions =
          solution.openFlights[flight.id.toString()].openPositions;
      }
      deadheadsFromSolverOutput[flight.flightId ? flight.flightId : flight.id] =
        flight;
    });
    solution.flights = deadheadsFromSolverOutput;
  }
};

const processCrewAssignments = (solution, lookUpData) => {
  if (isNonEmptyArray(solution.crewAssignments)) {
    // find crew from crew schedules
    solution.crewAssignments.forEach(crewAssignment => {
      let indexOfCrewFromSchedules = -1;
      for (let i = 0; i < solution.crewMembers.length; i++) {
        if (solution.crewMembers[i].id === crewAssignment.crewId) {
          indexOfCrewFromSchedules = i;
          break;
        }
      }

      if (indexOfCrewFromSchedules !== -1) {
        solution.crewMembers[indexOfCrewFromSchedules].solvedPairings = [];
        solution.crewMembers[indexOfCrewFromSchedules].pairings = [];

        const crewMember = solution.crewMembers[indexOfCrewFromSchedules];

        lookUpData.crewMembers[crewMember.id] = {
          ...lookUpData.crewMembers[crewMember.id],
          indexOfCrewInStore: indexOfCrewFromSchedules
        };

        // removed pairings are original
        if (isNonEmptyArray(crewAssignment.removedPairingIds)) {
          solution.crewMembers[indexOfCrewFromSchedules].pairings = [
            ...crewAssignment.removedPairingIds
          ];
        }
        // added pairings are created by solver
        if (isNonEmptyArray(crewAssignment.addedPairingIds)) {
          solution.crewMembers[indexOfCrewFromSchedules].solvedPairings = [
            ...crewAssignment.addedPairingIds
          ];
        }
      }
    });
  }
};

const updateScenarioToStore = (
  selectedScenarioId,
  payload,
  solution,
  store,
  dispatcher,
  middlewareFlags,
  userAttributes
) => {
  const preDatedScenario = payload.metadata.isPreDatedRun;

  batch(() => {
    //keep track of the selectedScenario.id so that we may not have to fetch this data again
    dispatcher(
      setCachedSolverOutputAction({
        scenarioId: payload.metadata.scenarioId,
        solution: { ...payload, solution: solution }
      })
    );

    if (preDatedScenario) {
      dispatcher(
        setSelectedScenarioSolverOutputAction({
          ...payload,
          solution: solution
        })
      );

      dispatcher(
        setSelectedScenarioAction({
          id: payload.metadata.id,
          scenarioName: payload.metadata.scenarioName,
          start: payload.metadata.start,
          status: payload.metadata.completionStatus,
          isPredatedRun: true
        })
      );
    }
  });

  /**
   *
   * if user selected scenario is for which the data is received then update the gantt else do not
   *  if predated(chosen from Scenario drop down) show the selected scenario
   */
  if (preDatedScenario || selectedScenarioId === payload.metadata.scenarioId)
    makeSolverOutputAsCrewScheduleAndCopyOriginalSchedule(
      { ...store.getState().crewSchedules },
      store.getState().searchCriteria.dateRange,
      solution,
      middlewareFlags.isFirstFetch,
      dispatcher,
      { setIsLoadingSolverOutputData: true },
      userAttributes
    );
};

export const processSolverOutputAndUpdateStore = ({
  payload,
  dispatcher,
  store,
  selectedScenarioId,
  middlewareFlags,
  showGanttInUTC,
  userAttributes,
  dateRange
}) => {
  let solution = payload.solution;

  if (solution) {
    const lookUpData = {
      flights: {},
      duties: {},
      pairings: {},
      crewMembers: {}
    };

    processPairings(solution);

    processDuties(solution);

    processFlights(solution);

    processDeadheads(solution);

    processCrewAssignments(solution, lookUpData);

    const intermediateData = {
      scheduleFetched: {
        crewMembers: solution.crewMembers,
        pairings: solution.pairings,
        duties: solution.duties,
        flights: solution.flights
      }
    };

    if (isNonEmptyArray(Object.keys(solution.pairings))) {
      const updatedTimeWindow = getTimeWindowFromAllPairings(
        solution.pairings,
        { previousDaysToShow: 1 }
      );
      calculatePositionAndWidthOfEntities(
        intermediateData,
        showGanttInUTC,
        userAttributes,
        { ...dateRange, ...updatedTimeWindow },
        lookUpData
      );
    }

    solution = {
      ...payload.solution,
      ...intermediateData.scheduleFetched
    };

    updateScenarioToStore(
      selectedScenarioId,
      payload,
      solution,
      store,
      dispatcher,
      middlewareFlags,
      userAttributes
    );
  }
};
