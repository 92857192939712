import { useState } from "react";

/**
 * A react hook that essentially creates a finite state machine(FSM),
 * wherein the component will load based on the status of the FSM.
 * A common and widely re-occuring example -
 * If there is an 3rd party or internal API call that is fetching data, generally there can be 3 finite states to a UI component
 * - loading
 * - success
 * - error
 * Now to avoid writing conditional code for this for every such API call,
 * we can use this hook with predetermined component for each state and switch between those component based on the status of the API call.
 *
 * Another example - refer AllCrewRows and AllGanttRows component.
 *
 * @param initialState Required callback that will be invoked when scrolled to bottom
 * @return Object containing a react Component decided based on the status of the FSM and a setter to set the state
 */
const useStatus = initialState => {
  const [status, setStatus] = useState(initialState);

  const Status = props => {
    return props[status] || null;
  };

  return { Status, setStatus };
};

export default useStatus;
