import {
  MILLISECONDS_IN_A_DAY,
  HOURS,
  GRANULARITY_OF_DAY_INFO,
  MILLISECONDS_IN_A_MINUTE,
  MILLISECONDS_IN_AN_HOUR
} from "../constants/disruptions/timeline";

/**
 * computes the earliestStart, latestEnd of all pairings and the number of days between them.
 * @param {Object} pairings contains all the pairings recieved from API
 */
export const getTimeWindowFromAllPairings = (
  pairings,
  { previousDaysToShow }
) => {
  //this reference will be modified and returned
  const timeWindowFromAllPairings = {
    totalWindowInDays: 0,
    earliestStartDate: undefined,
    latestEndDate: undefined,
    earliestStartDateString: "",
    latestEndDateString: ""
  };

  let earliestStartDate;
  let latestEndDate;
  let earliestStartDateString;

  //get all the keys for the pairings object
  let allPairingIds = Object.keys(pairings);

  //map each key and compute the earliestStart and latestEnd
  allPairingIds.forEach(pairingId => {
    let pairing = pairings[pairingId];
    let pairingStart = new Date(pairing.utcStartTime);
    let pairingEnd = new Date(pairing.utcEndTime);

    //update earliestStartDate
    if (earliestStartDate === undefined || earliestStartDate > pairingStart) {
      earliestStartDate = pairingStart;
      earliestStartDateString = pairing.utcStartTime;
    }

    //update latestStartDate
    if (latestEndDate === undefined || latestEndDate < pairingEnd) {
      latestEndDate = pairingEnd;
    }
  });

  if (previousDaysToShow) {
    earliestStartDate = new Date(
      Number(new Date(earliestStartDate)) -
        previousDaysToShow * MILLISECONDS_IN_A_DAY
    );
    earliestStartDateString = earliestStartDate.toISOString();
  }

  // making earliest start date as start of day for the
  timeWindowFromAllPairings.totalWindowInDays = getDifferenceInDays(
    earliestStartDate,
    latestEndDate,
    { shouldRoundOff: true, getAbsoluteValue: true }
  );

  /**
   * making earliest date string to start from 00:00 as the timeline starts from 00:00 for a day.
   * We could have done setHours on the date but since the date gets converted to local date
   * as soon as we initialize date string with new Date and there is not option to set timezone offset on native Date objects,
   * converting date string directly to start of day with UTC timezone.
   * TODO: refactor this if we use any sophisticated 3rd party datetime library like moment.js or luxon.
   */
  timeWindowFromAllPairings.earliestStartDate = new Date(
    earliestStartDateString.split("T")[0].concat("T00:00:00+00:00")
  );
  timeWindowFromAllPairings.latestEndDate = latestEndDate;

  return timeWindowFromAllPairings;
};

/**
 * returns the width required for an entity based on its start and end time, in view width units
 * @param {number} endTime
 * @param {number} startTime
 * @param {number} widthOfOneDay
 */
export function getWidthForEntity(endTime, startTime, widthOfOneDay) {
  return ((endTime - startTime) * widthOfOneDay) / MILLISECONDS_IN_A_DAY;
}

/**
 * returns the left margin wrt earliest point on the series, in view width units
 * @param {number} startTime
 * @param {number} widthOfOneDay
 * @param {Date} startDateOfEntireGanttSeries
 */
export function getleftMarginForEntity(
  startTime,
  widthOfOneDay,
  startDateOfEntireGanttSeries
) {
  const margin =
    ((startTime - Number(startDateOfEntireGanttSeries)) * widthOfOneDay) /
    MILLISECONDS_IN_A_DAY;

  return margin;
}

/**
 * returns the number of days between 2 dates
 * @param {Date} startDate
 * @param {Date} endDate
 */
export function getDifferenceInDays(
  startDate,
  endDate,
  { shouldRoundOff, getAbsoluteValue }
) {
  const value = (endDate - startDate) / MILLISECONDS_IN_A_DAY;

  if (shouldRoundOff) {
    if (getAbsoluteValue)
      return Math.abs(
        Math.round((endDate - startDate) / MILLISECONDS_IN_A_DAY)
      );
    else return Math.round((endDate - startDate) / MILLISECONDS_IN_A_DAY);
  } else {
    if (getAbsoluteValue) return Math.abs(value);
    else return value;
  }
}

/**
 * returns an array containing hours with respect to the range selection
 * @param {Number} rangeSelection users selection from the drop down box
 */
export function getHoursArray(rangeSelection) {
  let step;
  if (rangeSelection < 1) {
    step = 1;
  } else if (rangeSelection === 1) {
    step = 2;
  } else if (rangeSelection === 2) {
    step = 3;
  } else if (rangeSelection < 5) {
    step = 6;
  } else {
    step = 12;
  }

  return HOURS.filter((hour, index) => index % step === 0);
}

/**
 * returns an array containing all the dates from startDate to startDate + entireRangeInDays
 * @param {Number} entireRangeInDays | total window between earliestStart and latestEnd
 * @param {Number} startDate | earliestStart date
 */
export function getDaysArray(entireRangeInDays, startDate) {
  return [...Array(entireRangeInDays).keys()].map(
    dayNumber =>
      new Date(Number(new Date(startDate)) + dayNumber * MILLISECONDS_IN_A_DAY)
  );
}

/**
 * returns the granularity in which the days info must be displayed
 * @param {number} rangeSelection the range for which the info has to be displayed
 */
export function getGranularity(rangeSelection) {
  //if less than five days display full day name and full date
  if (rangeSelection < 5) {
    return GRANULARITY_OF_DAY_INFO.LONG_DAY_AND_FULL_DATE;
  }
  //if less than 8 display partial day name and full date
  else if (rangeSelection < 8) {
    return GRANULARITY_OF_DAY_INFO.SHORT_DAY_AND_FULL_DATE;
  }
  //else display only partial date
  else {
    return GRANULARITY_OF_DAY_INFO.NO_DAY_AND_PARTIAL_DATE;
  }
}

/**
 * returns the name of the day either in SHORT OR LONG format
 * @param {Date} date
 * @param {String} locale
 * @param {String} weekday
 */
export function getDayName(date, locale, weekday) {
  return date.toLocaleDateString(locale, { weekday: weekday });
}

/**
 * returns true or false denoting whether the hours should be shown or timeline or not
 * @param {number} rangeSelection
 */
export function showHours(rangeSelection) {
  //hours starts to overlap after the 22nd day
  return rangeSelection <= 22;
}

/**
 * returns (minimum)two digit string for a number - used for hour, minute, second or day representations
 * @param {number} inputAsNumber
 */
export function getTwoDigitStringFromNumber(inputAsNumber) {
  return inputAsNumber
    ? inputAsNumber < 10
      ? "0" + inputAsNumber
      : "" + inputAsNumber
    : "00";
}

export function getNumberOfHoursBetweenDates(startDate, endDate) {
  return Math.floor(
    (Number(endDate) - Number(startDate)) / MILLISECONDS_IN_AN_HOUR
  );
}

export function getNumberOfMinutesBetweenDates(startDate, endDate) {
  return Math.floor(
    ((Number(endDate) - Number(startDate)) / MILLISECONDS_IN_A_MINUTE) % 60
  );
}
