import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Table,
  Paper,
  TableRow
} from "@material-ui/core";
import {
  SOLVER_INPUT_KPI_METADATA,
  SOLVER_SOLUTION_KPI_METADATA
} from "../../../../constants/runSolver";
import { isNonEmptyArray } from "../../../../utils/arrayUtils";

export default function SolverResultsPublishDialog(props) {
  /**
   * redux selectors
   */
  const selectedScenarioSolverOutput = useSelector(store => {
    const selectedScenarioSolverOutput =
      store.solverData.selectedScenarioSolverOutput;

    if (
      selectedScenarioSolverOutput &&
      selectedScenarioSolverOutput.inputKPIs &&
      selectedScenarioSolverOutput.solutionKPIs
    ) {
      return {
        //input
        numDisruptedCrewsInInput:
          selectedScenarioSolverOutput.inputKPIs.numDisruptedCrew,
        numCrewsInInput: selectedScenarioSolverOutput.inputKPIs.numCrew,
        numOpenFlightsInInput:
          selectedScenarioSolverOutput.inputKPIs.numOpenFlights,
        numOpenPairingsInInput:
          selectedScenarioSolverOutput.inputKPIs.numOpenPairings,
        numReserveCrewsInInput:
          selectedScenarioSolverOutput.inputKPIs.numReserveCrew,
        numDeadheadsInInput:
          selectedScenarioSolverOutput.inputKPIs.numDeadheads,

        //output
        numDisruptedCrewsInOutput:
          selectedScenarioSolverOutput.solutionKPIs.numDisruptedCrew,
        numUnrecoverableCrews:
          selectedScenarioSolverOutput.solutionKPIs.numUnrecoverableCrew,
        numOpenFlightsInOutput:
          selectedScenarioSolverOutput.solutionKPIs.numOpenFlights,
        numOpenPairingsInOutput:
          selectedScenarioSolverOutput.solutionKPIs.numOpenPairings,
        numDeadheadsUsed:
          selectedScenarioSolverOutput.solutionKPIs.numDeadheadsUsed,
        numReservesUsed:
          selectedScenarioSolverOutput.solutionKPIs.numReservesUsed
      };
    } else {
      return {};
    }
  });
  const selectedScenario = useSelector(
    store => store.solverData.selectedScenario
  );

  return (
    <div className="run-solver-dialogue-container">
      <Dialog
        id="run-solver-dialogue"
        open={props.showDialog}
        onClose={(event, reason) => {
          props.handlerForShowDialog(false);
        }}
      >
        <div className="publish-solver-dialog-conatiner">
          <div className="publish-heading">
            {props.hasPublishPermission ? "Publish Scenario" : "View KPIs"}
          </div>
          <div className="publish-info-main">
            <div className="details">
              <div className="scenario-name">
                {selectedScenario.scenarioName}
              </div>
              <div className="input-output-heading">Input</div>
              <div className="scenario-input-kpis">
                <TableContainer component={Paper}>
                  <Table
                    size={"small"}
                    aria-label="solver-input-kpis"
                    className="solver-input-kpi-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>KPI</TableCell>
                        <TableCell align="right">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {SOLVER_INPUT_KPI_METADATA.map(({ displayName, key }) => {
                        return (
                          <TableRow key={key}>
                            <TableCell component="th" scope="row">
                              {displayName}
                            </TableCell>
                            <TableCell align="right">
                              {selectedScenarioSolverOutput &&
                              selectedScenarioSolverOutput[key] === undefined
                                ? "N/A"
                                : selectedScenarioSolverOutput[key]}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="input-output-heading">Output Summary</div>
              <div className="scenario-output-kpis">
                {SOLVER_SOLUTION_KPI_METADATA.map(
                  ({ displayText, propertiesRequired }, index) => {
                    const argsForDisplayTextFunction = {};

                    if (isNonEmptyArray(propertiesRequired)) {
                      propertiesRequired.forEach(
                        key =>
                          (argsForDisplayTextFunction[key] =
                            selectedScenarioSolverOutput &&
                            selectedScenarioSolverOutput[key] === undefined
                              ? "N/A"
                              : selectedScenarioSolverOutput[key])
                      );

                      return (
                        <div key={index} className="metric-container">
                          <li className="metric-value">{`${displayText({
                            ...argsForDisplayTextFunction
                          })}`}</li>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  }
                )}
              </div>
            </div>
          </div>
          <div className="publish-action-buttons">
            <Button
              id={
                props.hasPublishPermission ? "cancel-button" : "publish-button" //if user does not have Publish permission then set id as "publish-button" to take up its styling
              }
              onClick={() => props.handlerForShowDialog(false)}
            >
              Cancel
            </Button>
            {props.hasPublishPermission && ( //only show Publish button if user has Publish permission
              <Button
                id="publish-button"
                onClick={() => props.handlerForShowDialog(false)}
              >
                Publish
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
