class WebWorkerService {
  constructor() {
    this.scheduleProcessor = new Worker("./js/scheduleResponseProcessor.js");
    this.violationProcessor = new Worker("./js/violationResponseProcessor.js");
    this.context = {};

    this.scheduleProcessor.onmessage = message => {
      const { scheduleFetchedAfterProcessing } = message.data;

      if (this.context.invokeLegalityProcessor === true) {
        const {
          processedEntityViolation,
          processedTimeViolation,
          ruleDisplayConfig,
          updatedViolationMetadata,
          userAttributes,
          dateRange,
          postProcessingFunction,
          postProcessingFunctionParameters
        } = this.context;

        this.invokeLegalityProcessor({
          eventType: "LEGALITY_DATA",
          eventData: {
            scheduleData: scheduleFetchedAfterProcessing,
            processedEntityViolation,
            processedTimeViolation,
            ruleDisplayConfig,
            updatedViolationMetadata,
            userAttributes,
            dateRange
          },
          context: {
            postProcessingFunction,
            postProcessingFunctionParameters,
            processedEntityViolation,
            processedTimeViolation,
            ruleDisplayConfig,
            updatedViolationMetadata
          }
        });
      } else {
        this.context.postProcessingFunction({
          ...this.context.postProcessingFunctionParameters,
          scheduleFetchedAfterProcessing
        });

        this.context = {};
      }
    };

    this.violationProcessor.onmessage = message => {
      const { scheduleData } = message.data;

      const {
        processedEntityViolation,
        processedTimeViolation,
        ruleDisplayConfig,
        updatedViolationMetadata
      } = this.context;

      this.context.postProcessingFunction({
        ...this.context.postProcessingFunctionParameters,
        scheduleData,
        processedEntityViolation,
        processedTimeViolation,
        ruleDisplayConfig,
        updatedViolationMetadata
      });

      this.context = {};
    };
  }

  invokeScheduleProcessor(message) {
    const { eventType, eventData, context } = message;

    this.context = context;

    this.scheduleProcessor.postMessage({
      eventType,
      eventData
    });
  }

  invokeLegalityProcessor(message) {
    const { eventType, eventData, context } = message;

    this.context = { ...context, ...eventData };

    this.violationProcessor.postMessage({
      eventType,
      eventData
    });
  }

  invokeScheduleProcessorAndLegalityProcessor(message) {
    const { eventData, context } = message;

    const {
      scheduleData,
      processedEntityViolation,
      processedTimeViolation,
      ruleDisplayConfig,
      updatedViolationMetadata,
      userAttributes,
      dateRange,
      showGanttInUTC,
      lookUpData
    } = eventData;

    this.invokeScheduleProcessor({
      eventType: "GET_SCHEDULES",
      eventData: {
        scheduleFetchedAfterProcessing: scheduleData,
        showGanttInUTC,
        userAttributes,
        dateRange,
        lookUpData
      },
      context: {
        invokeLegalityProcessor: true,
        processedEntityViolation,
        processedTimeViolation,
        ruleDisplayConfig,
        updatedViolationMetadata,
        userAttributes,
        dateRange,
        ...context
      }
    });
  }
}

export const WebWorkerServiceSingleton = (function () {
  var instance;

  function createInstance() {
    return new WebWorkerService();
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    }
  };
})();
