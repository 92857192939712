import {
  HOP_COUNT_FILTER_OPTIONS,
  MIN_SIT_TIME_FILTER_OPTIONS
} from "../constants/deadheadReport";

const ACTIONS = {
  setFilterOptions: "SET_FILTER_OPTIONS",
  setSearchData: "SET_SEARCH_DATA",
  setDeadheadData: "SET_DEADHEAD_DATA",
  clearDeadheadData: "CLEAR_DEADHEAD_DATA",
  setCanFetchMoreDeadheads: "SET_CAN_FETCH_MORE_DEADHEADS",
  setIsLoading: "SET_IS_LOADING"
};

/**
 * initial state of the reducer
 */
const initialState = {
  filterOptions: {
    airport: [],
    airline: [],
    hopCount: HOP_COUNT_FILTER_OPTIONS,
    minSitTime: MIN_SIT_TIME_FILTER_OPTIONS
  },
  searchData: {
    date: {
      start: new Date(),
      end: new Date()
    },
    origin: null,
    hopCount: null,
    airline: [],
    destination: null,
    minSitTime: null
  },
  deadheadData: {
    flightCombinations: [],
    flights: {},
    offset: 0
  },
  canFetchMoreDeadheads: true,
  isLoading: false
};
/**
 * VisibleDateRange reducer will handle actions like setting of VisibleDateRange
 * @param {Object} prevState
 * @param {String} action
 */
export function deadheadReportReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_FILTER_OPTIONS":
      newState = {
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          ...action.filterOptions
        }
      };
      break;
    case "SET_SEARCH_DATA":
      newState = {
        ...prevState,
        searchData: {
          ...prevState.searchData,
          [action.searchData.key]: action.searchData.value
        }
      };
      break;
    case "SET_DEADHEAD_DATA":
      newState = {
        ...prevState,
        deadheadData: {
          flightCombinations: [
            ...prevState.deadheadData.flightCombinations,
            ...action.deadheadData.flightCombinations
          ],
          flights: {
            ...prevState.deadheadData.flights,
            ...action.deadheadData.flights
          },
          offset: action.deadheadData.offset
        },
        isLoading: false
      };
      break;
    case "CLEAR_DEADHEAD_DATA":
      newState = {
        ...prevState,
        deadheadData: {
          flightCombinations: [],
          flights: {},
          offset: 0
        },
        isLoading: true
      };
      break;
    case "SET_CAN_FETCH_MORE_DEADHEADS":
      newState = {
        ...prevState,
        canFetchMoreDeadheads: action.canFetchMoreDeadheads,
        isLoading: false
      };
      break;
    case "SET_IS_LOADING":
      newState = {
        ...prevState,
        isLoading: action.isLoading
      };
      break;

    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setFilterOptionsAction(filterOptions) {
  return {
    type: ACTIONS.setFilterOptions,
    filterOptions
  };
}

export function setSearchDataAction(searchData) {
  return {
    type: ACTIONS.setSearchData,
    searchData
  };
}

export function setDeadheadDataAction(deadheadData) {
  return {
    type: ACTIONS.setDeadheadData,
    deadheadData
  };
}

export function clearDeadheadDataAction() {
  return {
    type: ACTIONS.clearDeadheadData
  };
}

export function setCanFetchMoreDeadheadsAction(canFetchMoreDeadheads) {
  return {
    type: ACTIONS.setCanFetchMoreDeadheads,
    canFetchMoreDeadheads
  };
}

export function setIsLoadingAction(isLoading) {
  return {
    type: ACTIONS.setIsLoading,
    isLoading
  };
}
