import React from "react";
import { useSelector } from "react-redux";
import AutoCompleteComponent from "./AutoCompleteComponent";

/**
 * An intermediate container component to keep the FilterMapper component clean
 */
export default function AutoCompleteContainer({
  filterName,
  filterKey,
  filterLabel,
  className,
  optionDisplayConfig
}) {
  const filterData = useSelector(store => store.searchCriteria.filterData);

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const selectedFilter = useSelector(
    store => store.searchCriteria.selectedFilter
  );

  const selectedFilterForSolverOutput = useSelector(
    store => store.searchCriteria.selectedFilterForSolverOutput
  );

  const getValues = React.useCallback(() => {
    if (isShowingASolverRun) {
      return selectedFilterForSolverOutput[filterKey]
        ? selectedFilterForSolverOutput[filterKey]
        : [];
    } else {
      return selectedFilter[filterKey] ? selectedFilter[filterKey] : [];
    }
  }, [
    filterKey,
    isShowingASolverRun,
    selectedFilter,
    selectedFilterForSolverOutput
  ]);

  const getFilter = React.useCallback(
    () =>
      filterData[filterName]
        ? filterData[filterName]
        : {
            filterName: filterName,
            values: [{ name: "Fetching..." }]
          },
    [filterData, filterName]
  );

  const getFilterName = React.useCallback(
    () => (filterLabel ? filterLabel : filterName),
    [filterLabel, filterName]
  );

  const getClassName = React.useCallback(
    () => (className ? className : ""),
    [className]
  );

  return (
    <div className={`auto-complete ${getClassName()}`}>
      <AutoCompleteComponent
        filter={getFilter()}
        values={getValues()}
        className={className}
        freeSolo={optionDisplayConfig.isFreeFlow}
        filterName={getFilterName()}
        optionDisplayConfig={optionDisplayConfig}
      />
    </div>
  );
}
