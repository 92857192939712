import React from "react";
import { Flight as FlightIcon } from "@material-ui/icons";

import "./LoadingState.scss";

export default function LoadingState(props) {
  return (
    <div className="loading-main" style={{ height: props.height }}>
      <div className="card-skeleton">
        <FlightIcon />
        {props.loadingMessage ? (
          <div className="loading-message">{props.loadingMessage}</div>
        ) : null}
      </div>
    </div>
  );
}
