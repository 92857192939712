import {
  setCopyOfScheduleWhileShowingSolverRunAction,
  setCrewSchedulesAction,
  setOpenTimeAction,
  setProcessedViolationsAction,
  setViolationMetadataAction
} from "../redux/crewSchedulesReducer";
import { getTimeWindowFromAllPairings } from "./timelineUtils";
import {
  resetSelectedFilterForSolverAction,
  setEndDateForSolver,
  setIncludedCrewMemberForSolverAction,
  setIsShowingASolverRunAction,
  setStartDateForSolver,
  setTimeWindowFromAllPairings
} from "../redux/searchCriteriaReducer";
import { findFlightsAndPairingWithOpenPositions } from "./middlewareUtils";
import { isInArray, isNonEmptyArray } from "./arrayUtils";
import {
  processEntityViolations,
  processTimeViolations
} from "./allGanttRowsUtils";
import { batch } from "react-redux";
import {
  setIsLoadingSolverOutputDataAction,
  setSelectedScenarioAction,
  setSelectedScenarioSolverOutputAction
} from "../redux/solverDataReducer";
import { solverRequestAction } from "../redux/webSocketReducer";
import { getCurrentTimestamp } from "./dateTimeUtils";
import {
  LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
  LOCAL_TIME_ZONE_OFFSET_IN_UTC_KEY
} from "../constants/disruptions/disruptionSummary";
import { OPEN_SEGMENT } from "../constants/disruptions/openTime";
import { processScheduleDataAfterViolationResponse } from "./legalityDataUtils";

export const CHARS_TO_BE_REPLACED_IN_SCENARIO_NAME = /[^a-z0-9+]+/gi;

export const makeSolverOutputAsCrewScheduleAndCopyOriginalSchedule = (
  crewSchedules,
  dateRangeSelection,
  solution,
  firstFetch,
  dispatch,
  { setIsLoadingSolverOutputData },
  userAttributes
) => {
  if (
    !isNonEmptyArray(
      Object.keys(crewSchedules.copyOfScheduleWhileShowingSolverRun)
    )
  ) {
    dispatch(
      setCopyOfScheduleWhileShowingSolverRunAction({
        pairings: crewSchedules.pairings,
        crewMembers: crewSchedules.crewMembers,
        duties: crewSchedules.duties,
        flights: crewSchedules.flights,
        openTimeIds: crewSchedules.openTimeIds,
        processedViolations: crewSchedules.processedViolations,
        dateRangeSelection: dateRangeSelection,
        violationMetadata: crewSchedules.violationMetadata
      })
    );
  }

  const violationMetadata = {
    timeViolation: {},
    entityViolation: {}
  };

  /**
   * process the entity violation associated with the current solver scenario
   */
  const processedEntityViolation = processEntityViolations(
    solution.entityViolations,
    { pairing: {}, duty: {}, flight: {} },
    violationMetadata.entityViolation,
    solution.ruleDisplayConfig
  );

  /**
   * process the time violation associated with the current solver scenario
   */
  const processedTimeViolation = processTimeViolations(
    solution.timeViolations,
    {
      pairing: {},
      duty: {},
      crew: {
        crewDutyViolation: {},
        crewPairingViolation: {},
        restViolation: {}
      }
    },
    violationMetadata.timeViolation,
    solution.ruleDisplayConfig
  );

  const intermediateData = {
    crewMembers: solution.crewMembers,
    pairings: solution.pairings,
    duties: solution.duties,
    flights: solution.flights
  };

  processScheduleDataAfterViolationResponse(
    intermediateData,
    { processedEntityViolation, processedTimeViolation },
    solution.ruleDisplayConfig,
    violationMetadata,
    {},
    userAttributes,
    dateRangeSelection
  );

  solution.crewMembers = intermediateData.crewMembers;
  solution.pairings = {
    ...solution.pairings,
    ...intermediateData.pairings
  };
  solution.duties = {
    ...solution.duties,
    ...intermediateData.duties
  };
  solution.flights = {
    ...solution.flights,
    ...intermediateData.flights
  };

  if (isNonEmptyArray(Object.keys(solution.pairings))) {
    let timeWindowFromAllPairings = getTimeWindowFromAllPairings(
      solution.pairings,
      { previousDaysToShow: 1 }
    );

    /**
     * if in case, start of earliest pairing and end of latest pairing
     * don't span across 24 hours, we'll get 0 days as the total window.
     * So, making it 1 day to show timeline at least as big as 1 day in any case.
     * else, add one more day to the total window to account the hours of the end date as well
     *  */
    if (timeWindowFromAllPairings.totalWindowInDays === 0) {
      timeWindowFromAllPairings.totalWindowInDays = 1;
    } else {
      timeWindowFromAllPairings.totalWindowInDays += 2;
    }

    //adding a first fetch flag to update the date range picker accordingly
    timeWindowFromAllPairings.isFirstFetch = firstFetch;

    batch(() => {
      //update the time window for new data
      dispatch(
        setTimeWindowFromAllPairings({
          ...timeWindowFromAllPairings,
          isShowingASolverRun: true
        })
      );

      dispatch(
        setCrewSchedulesAction({
          pairings: solution.pairings,
          crewMembers: solution.crewMembers,
          duties: solution.duties,
          flights: solution.flights,
          shouldDuplicate: true
        })
      );

      dispatch(
        setOpenTimeAction(
          findFlightsAndPairingWithOpenPositions(
            solution.flights,
            solution.pairings
          )
        )
      );

      /**
       * set the redux state with the violations associated with the current solver scenario
       */
      dispatch(
        setProcessedViolationsAction({
          entityViolations: processedEntityViolation,
          timeViolations: processedTimeViolation,
          ruleDisplayConfig: solution.ruleDisplayConfig
        })
      );
      dispatch(setViolationMetadataAction(violationMetadata));
    });
  } else {
    batch(() => {
      dispatch(
        setProcessedViolationsAction({
          entityViolations: processedEntityViolation,
          timeViolations: processedTimeViolation,
          ruleDisplayConfig: solution.ruleDisplayConfig
        })
      );

      dispatch(
        setCrewSchedulesAction({
          pairings: solution.pairings,
          crewMembers: solution.crewMembers,
          duties: solution.duties,
          flights: solution.flights,
          shouldDuplicate: true
        })
      );

      dispatch(
        setOpenTimeAction(
          findFlightsAndPairingWithOpenPositions(
            solution.flights,
            solution.pairings
          )
        )
      );
    });
  }

  if (setIsLoadingSolverOutputData) {
    batch(() => {
      dispatch(setIsLoadingSolverOutputDataAction(false));
      dispatch(setIsShowingASolverRunAction(true));
    });
  }
};

export const handleClickOnRunSolverProgressToast = (
  selectedScenarioId,
  cachedSolverOutput,
  crewSchedules,
  dateRangeSelection,
  dispatch,
  selectedScenarioName,
  userAttributes
) => {
  /**
   * if the  selectedScenarioId is present in the redux then go ahead and show it
   */
  if (isInArray(String(selectedScenarioId), Object.keys(cachedSolverOutput))) {
    dispatch(setIsLoadingSolverOutputDataAction(true));
    batch(() => {
      dispatch(resetSelectedFilterForSolverAction()); //remove existing locally applied scenario filter
      dispatch(setIsShowingASolverRunAction(true));
      makeSolverOutputAsCrewScheduleAndCopyOriginalSchedule(
        crewSchedules,
        dateRangeSelection,
        cachedSolverOutput[selectedScenarioId].solution,
        false,
        dispatch,
        { setIsLoadingSolverOutputData: false },
        userAttributes
      );
      dispatch(
        setSelectedScenarioSolverOutputAction({
          ...cachedSolverOutput[selectedScenarioId]
        })
      );

      dispatch(
        setSelectedScenarioAction({
          id: selectedScenarioId,
          scenarioName: selectedScenarioName,
          start: cachedSolverOutput[selectedScenarioId].metadata.start
        })
      );
    });
    setTimeout(() => dispatch(setIsLoadingSolverOutputDataAction(false)));
  }
};

export const runContextualSolver = (
  dispatch,
  pairingId,
  isFlightOps,
  runStartTime,
  runEndTime,
  selectedRuleSet,
  selectedStrategy,
  crewMemberDetails,
  showGanttInUTC,
  userAttributes
) => {
  const firstName = userAttributes.given_name ? userAttributes.given_name : "";
  const lastName = userAttributes.family_name ? userAttributes.family_name : "";
  let scenarioName = `${firstName}_${lastName}_for_${
    crewMemberDetails.firstname
  } ${crewMemberDetails.lastname}_${getCurrentTimestamp()}`;
  scenarioName = scenarioName.replace(
    CHARS_TO_BE_REPLACED_IN_SCENARIO_NAME,
    "_"
  );
  /**
   * solver request params config derived from https://skyschedule.atlassian.net/browse/CRWEB-795
   */
  dispatch(
    solverRequestAction({
      scenarioName: scenarioName,
      startDate: runStartTime,
      endDate: runEndTime,
      ruleSetId: selectedRuleSet.id,
      strategyId: selectedStrategy.id,
      excludedCrewIds: [],
      includedCrewIds: [crewMemberDetails.crewExternalId],
      fixOpenFlights: false,
      isContextualSolve: true
    })
  );

  let timeZoneOffsetInMillis = 0;
  if (!showGanttInUTC) {
    const timeZoneOffsetString =
      userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][
        LOCAL_TIME_ZONE_OFFSET_IN_UTC_KEY
      ];
    timeZoneOffsetInMillis =
      (timeZoneOffsetString.slice(0, 1) === "-" ? -1 : 1) *
      (Number(timeZoneOffsetString.slice(1, 3)) * 60 +
        Number(timeZoneOffsetString.slice(4, 6))) *
      60 *
      1000;
  }
  //update redux store with updated solver range
  dispatch(
    setStartDateForSolver(
      Number(new Date(runStartTime)) + timeZoneOffsetInMillis
    )
  );

  dispatch(
    setEndDateForSolver(Number(new Date(runEndTime)) + timeZoneOffsetInMillis)
  );
};

export const getTimestampOfFirstViolationOnPairing = (
  pairing,
  processedViolations,
  crewMemberDetails,
  allDuties,
  allFlights
) => {
  let result = pairing ? pairing.utcStartTime : new Date().toISOString();
  if (processedViolations.entityViolations.pairing[pairing.pairingId]) {
    result = pairing ? pairing.utcStartTime : new Date();
  } else {
    isNonEmptyArray(pairing.dutyIds) &&
      pairing.dutyIds.every(dutyId => {
        if (
          processedViolations.entityViolations.duty[dutyId] ||
          processedViolations.timeViolations.pairing[
            `${pairing.pairingId}-${dutyId}`
          ] ||
          processedViolations.timeViolations.crew.crewDutyViolation[
            `${
              crewMemberDetails.id
                ? crewMemberDetails.id
                : crewMemberDetails.crewId
            }-${dutyId}`
          ]
        ) {
          result = allDuties[dutyId].utcStartTime;
          return false;
        }
        const flightIdsForDuty = allDuties[dutyId].flightIds;
        isNonEmptyArray(flightIdsForDuty) &&
          flightIdsForDuty.every(flightId => {
            if (
              processedViolations.entityViolations.flight[flightId] ||
              processedViolations.timeViolations.duty[`${dutyId}-${flightId}`]
            ) {
              result = allFlights[flightId].utcActualDepDateTime;
              return false;
            }
            return true;
          });
        return true;
      });
  }
  return result;
};

export const includeExcludeCrewsForSolver = (
  isChecked,
  crewId,
  includedCrewsForSolver,
  dispatch
) => {
  if (isChecked) {
    dispatch(
      setIncludedCrewMemberForSolverAction([...includedCrewsForSolver, crewId])
    );
  } else {
    const newIncludedCrewRows = includedCrewsForSolver.filter(
      crewMemberId => crewMemberId !== crewId
    );
    dispatch(setIncludedCrewMemberForSolverAction(newIncludedCrewRows));
  }
};

export const getReduxStoreKeyForOpenFlying = type =>
  type === OPEN_SEGMENT ? "includedOpenSegmentRows" : "includedOpenPairingRows";

export const fillOpenFlyingData = (requestData, openFlyingEntityData) => {
  const openFlyingData = {
    includedOpenSegments: [],
    includedOpenPairings: []
  };

  const { includedOpenSegmentRows, includedOpenPairingRows } = requestData;
  const { openFlightIds, openPairingIds } = openFlyingEntityData;

  /**
   * fill in the flightIds of selected rows
   */
  if (isNonEmptyArray(includedOpenSegmentRows)) {
    includedOpenSegmentRows.forEach(rowIndex => {
      if (openFlightIds[rowIndex])
        openFlyingData.includedOpenSegments = [
          ...openFlyingData.includedOpenSegments,
          ...openFlightIds[rowIndex]
        ];
    });
  }

  if (isNonEmptyArray(includedOpenPairingRows)) {
    includedOpenPairingRows.forEach(rowIndex => {
      const openPairingIndex = rowIndex - openFlightIds.length;
      if (openPairingIds[openPairingIndex])
        openFlyingData.includedOpenPairings = [
          ...openFlyingData.includedOpenPairings,
          ...openPairingIds[openPairingIndex]
        ];
    });
  }
  return openFlyingData;
};
