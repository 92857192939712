import React, { useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import RunSolverDialogV2 from "./run-solver-dialog/RunSolverDialogV2";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import SolverResultsPublishDialog from "./SolverResultsPublishDialog";
import SimpleTooltipMessage from "../../../partials/simpletooltipmessages/SimpleTooltipMessage";

import { setSelectedScenarioAction } from "../../../../redux/solverDataReducer";
import { solverOutputRequestAction } from "../../../../redux/webSocketReducer";

import { DEFAULT_SCENARIO_ID } from "../../../../constants/disruptions/filter";
import { USER_ROLES } from "../../../../constants/auth/signUp";

import { isInArray } from "../../../../utils/arrayUtils";

export default function RunSolver() {
  /**
   * redux selectors
   */
  const selectedScenario = useSelector(
    store => store.solverData.selectedScenario
  );

  const userGroups = useSelector(store => store.userInfo.userGroups);

  /**
   * component states
   */
  const [isRunSolverDialogShown, setIsRunSolverDialogShown] = useState(false);

  const [
    isSolverResultsPublishDialogShown,
    setIsSolverResultsPublishDialogShown
  ] = useState(false);

  //true if user has "Publish" permission
  const [hasPublishPermission, setHasPublishPermission] = React.useState(
    isInArray(USER_ROLES.Publish, userGroups)
  );

  //if true has "Simulate" permission
  const [hasSimulatePermission, setHasSimulatePermission] = React.useState(
    isInArray(USER_ROLES.Simulate, userGroups)
  );

  /**
   * react callbacks
   */
  const createTooltip = React.useCallback(
    () => (
      <SimpleTooltipMessage
        className="no-permission-info-text"
        tooltipMessage={
          "You don't have sufficient permission to perform a solver run."
        }
      />
    ),
    []
  );

  /**
   * side effects
   */

  //reset permission states if there is a change in the user groups array
  React.useEffect(() => {
    setHasPublishPermission(isInArray(USER_ROLES.Publish, userGroups));
    setHasSimulatePermission(isInArray(USER_ROLES.Simulate, userGroups));
  }, [userGroups]);

  return (
    <div className="run-solver-with-logout">
      <span className="run-solver-button-container">
        {selectedScenario.id === DEFAULT_SCENARIO_ID ? (
          <Tooltip title={!hasSimulatePermission ? createTooltip() : ""} arrow>
            <div>
              <Button
                id="run-solver-button"
                onClick={() => {
                  setIsRunSolverDialogShown(true);
                }}
                variant="contained"
                disabled={!hasSimulatePermission} //disable run solver button if user does not have simulate permission
              >
                Run Solver
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button
            id="run-solver-button"
            onClick={() => {
              setIsSolverResultsPublishDialogShown(true);
            }}
            variant="contained"
          >
            {/**user without permission can only view KPIs and not publish it  */}
            {hasPublishPermission ? "Publish" : "View KPIs"}{" "}
          </Button>
        )}
      </span>
      <HamburgerMenu />
      <RunSolverDialogV2
        showDialog={isRunSolverDialogShown}
        handlerForShowDialog={setIsRunSolverDialogShown}
      />
      <SolverResultsPublishDialog
        showDialog={isSolverResultsPublishDialogShown}
        handlerForShowDialog={setIsSolverResultsPublishDialogShown}
        hasPublishPermission={hasPublishPermission}
      />
    </div>
  );
}

export const ShowScenarioCompletedMessage = props => {
  const dispatch = useDispatch();

  const handleClickOnToast = () => {
    /**
     * do not perform a scenario change if the request failed
     */
    if (!props.didRequestFail) {
      dispatch(
        setSelectedScenarioAction({
          id: props.scenarioId,
          scenarioName: props.scenarioName
        })
      );
      dispatch(
        solverOutputRequestAction({
          id: props.scenarioId,
          scenarioName: props.scenarioName
        })
      );
    }
  };

  return (
    <div className="scenario-complete-message" onClick={handleClickOnToast}>
      {`Scenario ${props.scenarioName} ${
        !props.didRequestFail ? "completed." : "failed."
      }`}
    </div>
  );
};
