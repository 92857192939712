import React from "react";

import ViolationMessage from "./ViolationMessage";

import { isNonEmptyArray } from "../../../utils/arrayUtils";

export default function ViolationMessages({
  displayCommentIcon,
  formatMessage,
  selectedViolation,
  reduxKeysForViolatingData
}) {
  const NoViolationMessage = React.useCallback(
    () => (
      <div
        className="tooltip-violation-message"
        style={{
          borderBottom: "unset"
        }}
      >
        No Violations
      </div>
    ),
    []
  );

  return (
    <div className="violation-message-container">
      {isNonEmptyArray(reduxKeysForViolatingData) ? (
        reduxKeysForViolatingData.map((reduxKey, index) => (
          <ViolationMessage
            key={reduxKey}
            reduxKey={reduxKey}
            selectedViolation={selectedViolation}
            displayCommentIcon={displayCommentIcon}
            formatMessage={formatMessage}
            drawUnderline={index !== reduxKeysForViolatingData.length - 1}
          />
        ))
      ) : (
        <NoViolationMessage />
      )}
    </div>
  );
}
