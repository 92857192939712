import { Divider } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import {
  LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
  LOCAL_TIME_ZONE_NAME_KEY
} from "../../../constants/disruptions/disruptionSummary";
import { MILLISECONDS_IN_A_MINUTE } from "../../../constants/disruptions/timeline";
import { getTimezoneOffsetForGivenDateTimeAndUserSelectedZone } from "../../../utils/dateTimeUtils";
import { getleftMarginForEntity } from "../../../utils/timelineUtils";

/**
 * Draws the current time demarcation line
 */
export default function CurrentTimeDemaraction() {
  const dateRangeSelection = useSelector(
    store => store.searchCriteria.dateRange
  );
  const showGanttInUTC = useSelector(store => store.userInfo.showGanttInUTC);
  const userAttributes = useSelector(store => store.userInfo.userAttributes);

  const widthOfOneDay = dateRangeSelection.widthOfOneDay;

  const getTheOffsetToBeApplied = React.useCallback(
    () =>
      showGanttInUTC
        ? 0
        : getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
            new Date(),
            userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][
              LOCAL_TIME_ZONE_NAME_KEY
            ]
          ) * MILLISECONDS_IN_A_MINUTE,
    [showGanttInUTC, userAttributes]
  );

  /**
   * used to track the left position of the time demarcation
   */
  const [
    leftMarginForCurrentTimeDemarcation,
    setLeftMarginForCurrentTimeDemarcation
  ] = React.useState(
    getleftMarginForEntity(
      Number(Number(new Date()) + getTheOffsetToBeApplied()),
      widthOfOneDay,
      dateRangeSelection.startDate
    )
  );

  React.useEffect(() => {
    const updateCurrentTimeDemarcationPosition = setInterval(() => {
      setLeftMarginForCurrentTimeDemarcation(
        getleftMarginForEntity(
          Number(Number(new Date()) + getTheOffsetToBeApplied()),
          widthOfOneDay,
          dateRangeSelection.startDate
        )
      );
    }, 1000); //set it to 1 seconds as this will solve the problem of disjoited time line, NOTE: This will not rerender the GanttRows as the state is localized to this component only

    return () => clearInterval(updateCurrentTimeDemarcationPosition);
  }, [dateRangeSelection.startDate, widthOfOneDay, getTheOffsetToBeApplied]);

  /**
   * calculate the new left position after change in showGanttInUTC dependency
   */
  React.useEffect(
    () =>
      setLeftMarginForCurrentTimeDemarcation(
        getleftMarginForEntity(
          Number(Number(new Date()) + getTheOffsetToBeApplied()),
          widthOfOneDay,
          dateRangeSelection.startDate
        )
      ),
    [
      showGanttInUTC,
      getTheOffsetToBeApplied,
      dateRangeSelection.startDate,
      widthOfOneDay
    ]
  );

  return (
    <div
      className="current-time-demarcation"
      style={{ marginLeft: leftMarginForCurrentTimeDemarcation + "vw" }}
    >
      <Divider orientation={"vertical"} />
    </div>
  );
}
