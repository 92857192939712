export const IN_FLIGHT_RANKS = ["FA", "SFA"];
export const FLIGHT_OPS_RANKS = ["CA", "FO"];
export const DEFAULT_SCENARIO_ID = Number.MAX_SAFE_INTEGER;

export const TO_DATE_OFFSET_FOR_DATE_RANGE_FILTER = 2; //CRWEB-566 mentions to change the to-date offset to +2

export const FILTER_TYPES = {
  AIRLINE_AUTOCOMPLETE: "AIRLINE_AUTOCOMPLETE",
  AUTOCOMPLETE: "AUTOCOMPLETE",
  TEXTFIELD: "TEXTFIELD",
  DATE_RANGE: "DATE_RANGE",
  DASHBOARD: "DASHBOARD"
};

export const SORT_TYPE = {
  ALPHABETIC: "ALPHABETIC",
  NONE: "NONE"
};

/**
 * TODO: Incorporate this info to be received from backend along with /filter call
 */
export const FILTER_DISPLAY_CONFIG = [
  {
    filterName: "Date",
    filterKey: "date",
    type: FILTER_TYPES.DATE_RANGE
  },
  {
    filterName: "Airline",
    filterKey: "airline",
    type: FILTER_TYPES.AIRLINE_AUTOCOMPLETE,
    optionDisplayConfig: {
      sortType: SORT_TYPE.NONE
    }
  },
  {
    filterName: "Crew",
    filterKey: "crewName",
    filterLabel: "Crew Name / ID",
    type: FILTER_TYPES.TEXTFIELD
  },
  {
    filterName: "Pairing",
    filterKey: "pairing",
    type: FILTER_TYPES.AUTOCOMPLETE,
    optionDisplayConfig: {
      sortType: SORT_TYPE.NONE,
      isFreeFlow: true
    }
  },
  {
    filterName: "Base",
    filterKey: "base",
    type: FILTER_TYPES.AUTOCOMPLETE,
    optionDisplayConfig: {
      sortType: SORT_TYPE.ALPHABETIC
    }
  },
  {
    filterName: "Rank",
    filterKey: "rank",
    filterLabel: "Position",
    type: FILTER_TYPES.AUTOCOMPLETE,
    optionDisplayConfig: {
      sortType: SORT_TYPE.NONE
    }
  },
  {
    filterName: "Airport",
    filterKey: "airport",
    type: FILTER_TYPES.AUTOCOMPLETE,
    optionDisplayConfig: {
      sortType: SORT_TYPE.NONE
    }
  },
  {
    filterName: "FlightNumber",
    filterKey: "flightNumber",
    filterLabel: "Flight Number",
    type: FILTER_TYPES.TEXTFIELD
  },
  {
    filterName: "Violation",
    filterKey: "violation",
    type: FILTER_TYPES.DASHBOARD
  },
  {
    filterName: "Legal",
    filterKey: "legal",
    type: FILTER_TYPES.DASHBOARD
  }
];

/**
 * https://skyschedule.atlassian.net/browse/REPDESK-21?focusedCommentId=12161
 * For CA- any dequal (CA or FO) should highlight in red,
 * For FO- only FO,
 * For FA- anything but TF
 * For TF- only TF
 */
export const DEQUAL_STATUS_MAP = {
  CA: ["CA", "FO"],
  FO: ["FO"],
  FA: ["FA", "CA", "FO"],
  TF: ["TF"],
  SFA: ["SFA"]
};
