import React from "react";
import { Dialog, Button } from "@material-ui/core";
import "./ConfirmDialog.scss";

export default function ConfirmDialog({
  title,
  question,
  confirmHandler,
  cancelHandler,
  open,
  isAlert
}) {
  return (
    <Dialog open={open}>
      <div className="confirm-dialog-container">
        <div className="header">{title}</div>
        <div className="body">{question}</div>
        <div className="footer">
          {/**disable cancel button if its an alert */}
          {!isAlert && (
            <Button id="cancel-button" onClick={cancelHandler}>
              Cancel
            </Button>
          )}
          <Button
            id="confirm-button"
            className={`${isAlert ? "is-alert" : ""}`}
            onClick={confirmHandler}
          >
            {isAlert ? "Okay" : "Confirm"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
