const ACTIONS = {
  setLocale: "SET_LOCALE"
};

const initialState = {
  locale: "en"
};

export function i18nReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_LOCALE":
      newState = {
        ...prevState,
        locale: action.locale
      };
      break;
    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setLocaleAction(locale) {
  return { type: ACTIONS.setLocale, locale };
}
