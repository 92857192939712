import React from "react";
import CommentSection from "./CommentSection";
import ViolationMessages from "./ViolationMessages";

export default function ViolationMessagesWithCommentSection({
  displayCommentIcon,
  selectedViolation,
  reduxKeysForViolatingData
}) {
  return (
    <div className="violation-message-with-chat-view-container">
      <div className="violation-picker-section">
        <ViolationMessages
          displayCommentIcon={displayCommentIcon}
          selectedViolation={selectedViolation}
          formatMessage
          reduxKeysForViolatingData={reduxKeysForViolatingData}
        />
      </div>
      <>
        <CommentSection selectedViolation={selectedViolation} />
      </>
    </div>
  );
}
