const ACTIONS = {
  setPairingInfoForPairing: "SET_PAIRING_INFO_FOR_PAIRING",
  resetPairingInfoForAllPairings: "RESET_PAIRING_INFO_FOR_ALL_PAIRINGS"
};

const initialState = {};

export function pairingInfoReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_PAIRING_INFO_FOR_PAIRING":
      newState = {
        ...prevState,
        [action.pairingId]: action.pairingInfo
      };
      break;
    case "RESET_PAIRING_INFO_FOR_ALL_PAIRINGS":
      newState = {};
      break;
    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setPairingInfoForPairingAction(pairingId, pairingInfo) {
  return { type: ACTIONS.setPairingInfoForPairing, pairingId, pairingInfo };
}

export function resetPairingInfoForAllPairingsAction() {
  return { type: ACTIONS.resetPairingInfoForAllPairings };
}
