import { ENTITY_TYPE } from "../constants/disruptions/crewGanttViewer";
import { isInArray } from "./arrayUtils";
import { getTimeStringForDateObject, getTimeStringForString } from "./dateTimeUtils";
import { convertCommaSeperatedStringToArrayOfStrings } from "./filterUtils";
import {
  findUtcDepartureTimeOfFlight,
  findUtcArrivalTimeOfFlight
} from "./pairingUtils";
import { getDifferenceInDays } from "./timelineUtils";

export function findFlightsAndPairingWithOpenPositions(flights, pairings) {
  let returnObject = {
    openFlightIds: [],
    openPairingIds: []
  };

  if (typeof flights === "object") {
    let flightsWithOpenPositions = [...getEntitiesWithOpenPositions(flights)];
    returnObject.openFlightIds = stackOverlappingEntities(
      flightsWithOpenPositions,
      flights,
      ENTITY_TYPE.FLIGHT
    );
  }

  if (typeof pairings === "object") {
    let pairingsWithOpenPositions = [...getEntitiesWithOpenPositions(pairings)];
    returnObject.openPairingIds = stackOverlappingEntities(
      pairingsWithOpenPositions,
      pairings,
      ENTITY_TYPE.PAIRING
    );
  }

  return returnObject;
}

function collide(array, entityId, allEntities, entityType) {
  let collision = false;

  for (let item of array) {
    if (isColliding(allEntities[item], allEntities[entityId], entityType)) {
      collision = true;
      break;
    }
  }
  return collision;
}

function isColliding(entityOne, entityTwo, entityType) {
  if (entityType === ENTITY_TYPE.FLIGHT) {
    let flightOneStart = new Date(findUtcDepartureTimeOfFlight(entityOne));
    let flightOneEnd = new Date(findUtcArrivalTimeOfFlight(entityOne));

    let flightTwoStart = new Date(findUtcDepartureTimeOfFlight(entityTwo));
    let flightTwoEnd = new Date(findUtcArrivalTimeOfFlight(entityTwo));

    if (flightOneEnd < flightTwoStart || flightTwoEnd < flightOneStart)
      return false;
    return true;
  } else if (entityType === ENTITY_TYPE.PAIRING) {
    let pairingOneStart = new Date(entityOne.utcStartTime);
    let pairingOneEnd = new Date(entityOne.utcEndTime);

    let pairingTwoStart = new Date(entityTwo.utcStartTime);
    let pairingTwoEnd = new Date(entityTwo.utcEndTime);

    if (pairingOneEnd < pairingTwoStart || pairingTwoEnd < pairingOneStart)
      return false;
    return true;
  }
}

export function findOpenPairings(flightsWithOpenPositions, pairings, duties) {
  let openPairingIds = [];
  let openDutyIds = [];
  flightsWithOpenPositions.forEach(flightId => {
    Object.keys(duties)
      .map(dutyId => parseInt(dutyId, 10))
      .forEach(dutyId => {
        if (isInArray(flightId, duties[dutyId].flightIds)) {
          if (!isInArray(dutyId, openDutyIds)) openDutyIds.push(dutyId);
        }
      });
  });
  openDutyIds.forEach(dutyId => {
    Object.keys(pairings).forEach(pairingId => {
      if (isInArray(dutyId, pairings[pairingId].dutyIds)) {
        openPairingIds.push(pairingId);
      }
    });
  });

  return openPairingIds;
}

/**
 * returns the updated time window based on the currentStartDate and currentEndDate of the timeline
 * and the fetchStartDate and fetchEndDate of the current fetch
 */
export function updateTimeWindowForPairingsIfDataNotWithinRange(
  currentStartDate,
  fetchedStartDate,
  currentEndDate,
  fetchedEndDate
) {
  //assign the current timeline start and end to the return object
  let returnObject = {
    earliestStartDate: new Date(currentStartDate),
    latestEndDate: new Date(currentEndDate)
  };

  //if current timeline start is greater than fetched start then assign fetched start as the start of the gantt
  if (returnObject.earliestStartDate > new Date(fetchedStartDate)) {
    returnObject.earliestStartDate = new Date(fetchedStartDate);
  }

  //if current fetch end is greater than timeline end then assign fetched end as the end of the gantt
  if (new Date(fetchedEndDate) > returnObject.latestEndDate) {
    returnObject.latestEndDate = new Date(fetchedEndDate);
  }

  //return the updated start and end time with the totalWindowIndays between them
  return {
    ...returnObject,
    totalWindowInDays:
      getDifferenceInDays(
        returnObject.earliestStartDate,
        returnObject.latestEndDate,
        { shouldRoundOff: true, getAbsoluteValue: true }
      ) + 3 //added 3 days extra so that the timeline is always scrollable
  };
}

export const calculateOpenTimesToBeSentToPartialRefresh = (
  allFlights,
  allPairings,
  openTimeIds
) => {
  // 1. get all open flights
  let allOpenFlightIds = [
    ...getAllOpenEntitiesForPartialRefresh(openTimeIds.openFlightIds)
  ];

  // 2. get all open pairings
  let allOpenPairingIds = [
    ...getAllOpenEntitiesForPartialRefresh(openTimeIds.openPairingIds)
  ];

  // return all open position info to be sent for partial refresh
  return [
    ...getOpenPositionsInfoForEntities(
      allOpenFlightIds,
      ENTITY_TYPE.FLIGHT,
      allFlights
    ),
    ...getOpenPositionsInfoForEntities(
      allOpenPairingIds,
      ENTITY_TYPE.PAIRING,
      allPairings
    )
  ];
};

export const getEntitiesWithOpenPositions = allEntities => {
  let entitiesWithOpenPositions = [];

  Object.keys(allEntities)
    .map(entityId => parseInt(entityId, 10))
    .forEach(entityId => {
      if (
        allEntities[entityId] &&
        allEntities[entityId].openPositions &&
        Object.keys(allEntities[entityId].openPositions).length > 0
      )
        entitiesWithOpenPositions.push(entityId);
    });

  return entitiesWithOpenPositions;
};

export const stackOverlappingEntities = (
  entitiesWithOpenPositions,
  allEntities,
  entityType
) => {
  let nonOverlappingResult = [[]];

  entitiesWithOpenPositions.forEach(entityId => {
    let added = false;

    if (nonOverlappingResult.length === 0) {
      nonOverlappingResult[0][0] = allEntities[entityId];
    } else {
      for (let item of nonOverlappingResult) {
        if (!collide(item, entityId, allEntities, entityType)) {
          item.push(entityId);
          added = true;
          break;
        }
      }
      if (!added) {
        nonOverlappingResult.push([entityId]);
      }
    }
  });

  return nonOverlappingResult;
};

const getAllOpenEntitiesForPartialRefresh = openEntityIds => {
  let allOpenEntityIds = [];

  openEntityIds.forEach(openEntityIdArray => {
    allOpenEntityIds = [...allOpenEntityIds, ...openEntityIdArray];
  });

  return allOpenEntityIds;
};

const getOpenPositionsInfoForEntities = (
  allOpenEntityIds,
  entityType,
  allEntities
) => {
  let returnArray = [];
  allOpenEntityIds.forEach(openEntityId =>
    returnArray.push({
      id: openEntityId,
      type: entityType,
      openPositions: allEntities[openEntityId].openPositions
    })
  );
  return returnArray;
};

export const getPairingIdsSortedByTime = (arrayOfpairingIds, allPairings) => {
  return arrayOfpairingIds.sort((a, b) => {
    if (allPairings[a] === undefined || allPairings[b] === undefined) return 0;

    return (
      new Date(allPairings[a].utcStartTime) -
      new Date(allPairings[b].utcStartTime)
    );
  });
};

export const processSelectedFilterBeforeApplying = selectedFilter => {
  let returnObject = { ...selectedFilter };

  /**
   * convert the selected airline object which is an array of {name:xxx, code:xx} to an array of IATA code, as this is what the backend is expecting
   */
  if (returnObject["airline"] && returnObject["airline"].length > 0) {
    let arrayOfIATACode = [];

    returnObject["airline"].forEach(selectedAirline =>
      arrayOfIATACode.push(selectedAirline.code)
    );

    returnObject["airline"] = arrayOfIATACode;
  }

  returnObject.crewName = [
    ...convertCommaSeperatedStringToArrayOfStrings(selectedFilter.crewName)
  ];

  returnObject.flightNumber = [
    ...convertCommaSeperatedStringToArrayOfStrings(selectedFilter.flightNumber)
  ];

  /**
   * convert the selected airport array into array of origin and destinations
   */
  let arrayOfOriginAndDestination = { origin: [], destination: [] };

  returnObject["airport"] &&
    returnObject["airport"].forEach(airport => {
      arrayOfOriginAndDestination.origin.push(airport);
      arrayOfOriginAndDestination.destination.push(airport);
    });

  returnObject["airport"] = arrayOfOriginAndDestination;

  //update date here 

  returnObject["date"] = processDateFilter(selectedFilter.date)

  return returnObject;
};

export function waitFor(delay) {
  return new Promise(resolve => {
    delay = delay || 1000;
    setTimeout(function () {
      resolve();
    }, delay);
  });
}

/**
 * checks if the payload is chunked into multiple parts or not
 * @param {*} totalChunks
 * @returns returns true if the payload is partitioned/chunked
 */
export const isPayloadChunked = totalChunks => totalChunks !== 0;

/**
 * checks if the received chunk is that which was expected
 * @param {*} expected
 * @param {*} observed
 * @returns  returns true if the received chunk sequence is inline with the expected chuck sequence
 */
export const isChunkInSequence = (expected, observed) => expected === observed;

/**
 * takes in intermediate data, concatenates the fetched data and dispatches info to store
 * @param {*} intermediateData
 * @param {*} fetchedData
 * @param {*} dispatcher
 * @param {*} action
 */
export const updateIntermediateDataToStore = (
  intermediateData,
  fetchedData,
  dispatcher,
  action
) => {
  /**
   * concatenate the data string to the already existing one
   */
  intermediateData.dataString = intermediateData.dataString + fetchedData;

  /**
   * increment nextChunk property by 1 to allow for the next chunk to come in
   */
  intermediateData.nextChunk++;

  /**
   * dispatch the updated info to store
   */
  dispatcher(action(intermediateData));
};

/**
 * checks if all the data has been received and the current chunk is the last chunk
 * @param {*} currentChunkNumber
 * @param {*} totalChunks
 * @returns true if the currentChunkNumber is equal to the total number of chunks
 */
export const isAllDataReceived = (currentChunkNumber, totalChunks) =>
  currentChunkNumber === Math.floor(totalChunks);

/**
 * parses the data that has been received till now and returns the complete data
 * @param {*} intermediateData
 * @param {*} currentFetchedData
 * @param {*} dispatcher
 * @param {*} action
 * @returns
 */
export const getAllRecievedDataForProcessing = (
  intermediateData,
  currentFetchedData,
  dispatcher,
  action
) => {
  /**
   * if the intermediate data string is not empty then there is intermediate data which needs to be constructed to an object before processing
   */
  if (intermediateData.dataString.length > 0) {
    let returnObject = JSON.parse(intermediateData.dataString);

    resetIntermediateData(dispatcher, action);

    return returnObject;
  } else {
    //case where the data received is not partitioned
    return currentFetchedData;
  }
};

/**
 * resets the intermediate data for a route
 * @param {*} dispatcher
 * @param {*} action
 */
export const resetIntermediateData = (dispatcher, action) =>
  dispatcher(action({ nextChunk: 0, dataString: "" }));

/**
 * takes in the received chunk and processes it:
 * @param {*} eventData
 * @param {*} intermediateData
 * @param {*} fetchedData
 * @param {*} dispatcher
 * @param {*} action
 */
export const processReceivedChunk = (
  eventData,
  intermediateData,
  fetchedData,
  dispatcher,
  action
) => {
  if (!isPayloadChunked(eventData.totalChunks)) {
    return getAllRecievedDataForProcessing(
      intermediateData,
      fetchedData,
      dispatcher,
      action
    );
  } else {
    /**
     * process the chunk only if it is in sequence
     */
    if (
      isChunkInSequence(eventData.chunkSequence, intermediateData.nextChunk)
    ) {
      /**
       * check if payload is chunked, if yes then store intermediate data to the redux store
       */

      updateIntermediateDataToStore(
        intermediateData,
        fetchedData,
        dispatcher,
        action
      );

      /**
       * ifAddDataReceived is true then return all the received data after converting as an object
       * else return null
       */
      return isAllDataReceived(eventData.chunkSequence, eventData.totalChunks)
        ? getAllRecievedDataForProcessing(
          intermediateData,
          fetchedData,
          dispatcher,
          action
        )
        : null;
    } else {
      return false;
    }
  }
};

/**
 * takes necessary actions based on processedPayload
 * @param {*} processedPayload
 * @param {*} processAndUpdateStore
 * @param {*} functionArgs
 * @returns
 */
export const takeActionForPayload = (
  processedPayload,
  processAndUpdateStore,
  functionArgs
) => {
  if (!processedPayload) {
    /**
     * more data are yet to come do nothing
     */
    if (processedPayload === null) return;

    /**
     * error out and do nothing
     */
    console.error("Data received out of sync, please try again...");
    return;
  }

  /**
   * execute the function passed with the arguments received
   */
  processAndUpdateStore({ ...functionArgs, payload: processedPayload });
};

export const processViolationPreferencesToBeApplied = violationMetadata => {
  const entityMutedViolations = [];
  const entityUnMutedViolations = [];
  const timeMutedViolations = [];
  const timeUnMutedViolations = [];

  const processViolationsMetadataForType = (
    type,
    mutedViolationsArray,
    unMutedViolationsArray
  ) => {
    Object.keys(violationMetadata[type]).forEach(violationId => {
      if (
        violationMetadata[type][violationId].originalMuteStatus ===
        violationMetadata[type][violationId].changedMuteStatus
      ) {
        return;
      }

      if (violationMetadata[type][violationId].changedMuteStatus) {
        mutedViolationsArray.push(violationId);
      } else {
        unMutedViolationsArray.push(violationId);
      }
    });
  };

  processViolationsMetadataForType(
    "timeViolation",
    timeMutedViolations,
    timeUnMutedViolations
  );
  processViolationsMetadataForType(
    "entityViolation",
    entityMutedViolations,
    entityUnMutedViolations
  );

  return {
    entity: { muted: entityMutedViolations, unmuted: entityUnMutedViolations },
    time: { muted: timeMutedViolations, unmuted: timeUnMutedViolations }
  };
};

export const getStartAndEndTimeOfOpenEntity = (entity, entityType) => {
  let startTime,
    endTime = null;

  if (entityType === ENTITY_TYPE.FLIGHT) {
    startTime = new Date(findUtcDepartureTimeOfFlight(entity));
    endTime = new Date(findUtcArrivalTimeOfFlight(entity));
  } else {
    startTime = new Date(entity.utcStartTime);
    endTime = new Date(entity.utcEndTime);
  }

  return { startTime, endTime };
};


export const processDateFilter = (selectedDateFilter) => {

  const { from, to } = selectedDateFilter;

  let modifiedFrom = '';
  let modifiedTo = ''

  if (typeof from === 'object') {
    modifiedFrom = getTimeStringForDateObject(from);
  } else {
    modifiedFrom = getTimeStringForString(from)
  }

  if (typeof to === 'object') {
    modifiedTo = getTimeStringForDateObject(to);
  } else {
    modifiedTo = getTimeStringForString(to)
  }

  return { from: modifiedFrom, to: modifiedTo }
}
