const ACTIONS = {
  login: "LOGIN",
  logout: "LOGOUT",
  loginInProgress: "LOGIN_IN_PROGRESS",
  loginError: "LOGIN_ERROR",
  setUserObject: "SET_USER_OBJECT"
};

/**
 * initial state of the reducer
 */
const initialState = {
  isLoggedIn: false,
  loading: false,
  error: undefined,
  userObject: undefined
};

/**
 * login reducer will handle actions like login, logout, login in progress, etc
 * @param {Object} prevState
 * @param {String} action
 */
export function loginReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case ACTIONS.login:
      newState = {
        ...prevState,
        isLoggedIn: true,
        loading: false,
        error: undefined
      };
      break;

    case ACTIONS.loginInProgress:
      newState = {
        ...prevState,
        isLoggedIn: false,

        loading: true,
        error: undefined
      };
      break;
    case ACTIONS.loginError:
      newState = {
        ...prevState,
        isLoggedIn: false,

        loading: false,
        error: action.error
      };
      break;
    case ACTIONS.logout:
      newState = {
        ...prevState,
        isLoggedIn: false,

        loading: false,
        error: undefined
      };
      break;
    case ACTIONS.setUserObject:
      newState = {
        ...prevState,
        userObject: action.userObject
      };
      break;

    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function loginAction(userData) {
  return { type: ACTIONS.login };
}

export function loginInProgressAction() {
  return { type: ACTIONS.loginInProgress };
}

export function loginErrorAction(error) {
  return {
    type: ACTIONS.loginError,
    error: error
  };
}

export function logoutAction() {
  return { type: ACTIONS.logout };
}

export function setUserObjectAction(userObject) {
  return { type: ACTIONS.setUserObject, userObject: userObject };
}
