export const WARNING = "#C5BF6F";
export const VIOLATION = "#C17373";
export const STANDARD_FLIGHT = "#92A5B5";
export const DEADHEAD_FLIGHT =
  "repeating-linear-gradient(-45deg, #92A5B5, #92A5B5 5px, rgb(168, 168, 168) 5px, grey 7px)";
export const BORDER_COLOR_AFTER_SOLVER_RESPONSE = "#2F71AB";
export const CANCELLED = "#B45EDE";
export const VIOLATING_DEADHEAD_FLIGHT = `repeating-linear-gradient(-45deg, :replacementColor , :replacementColor  6px, black 7px)`;
export const RTG_FLIGHT = "#414141";

export const SIT_TIME_BACKGROUND = "#C2C2C2";
export const LAYOVER_BACKGROUND = "#D9D9D9";

export const DUTY_BORDER = "#9F9F9F";
export const LAYOVER_BORDER = "#C8C8C8";
export const CREW_GANTT_BORDER = "#d6d6d6";
export const WARNING_BORDER = "rgb(238, 238, 8)";

export const SITE_ORANGE_COLOR = "#e39049";
export const SITE_BLUE_COLOR = "#2F71AB";

export const SOLVER_SCENARIO_IN_PROGRESS_FILL_COLOR = "#FFCC00";
