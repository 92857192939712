import React from "react";
import {
  OPEN_FLIGHT_GANTT_SECTION_LOADING_MESSAGE,
  OPEN_FLIGHTS_LOADING_HEIGHT,
} from "../../../../../constants/disruptions/crewGanttViewer";
import LoadingState from "../../../../partials/LoadingState/LoadingState";

export default function OpenTimeLoadingState() {
  return (
    <LoadingState
      loadingMessage={OPEN_FLIGHT_GANTT_SECTION_LOADING_MESSAGE}
      height={OPEN_FLIGHTS_LOADING_HEIGHT}
    />
  );
}
