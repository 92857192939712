import Axios from "axios";
import { BASE_ENDPOINT, GET_PAIRING_INFO } from "../../../constants/api";
import { isNonEmptyArray } from "../../../utils/arrayUtils";
import { getAccessTokenForUser } from "../../../utils/authUtils";
import { getRuleNameToDisplay } from "../../../utils/pairingUtils";

export const processCommentCountData = (violatingData, isFlightOps) => {
  let processedData = {
    totalNumberOfComments: 0,
    violationToNumberOfCommentMap: {}
  };

  const relavantViolatingData = getRelavantViolatingData(
    violatingData,
    isFlightOps
  );

  relavantViolatingData.forEach(_violatingData => {
    processedData.violationToNumberOfCommentMap[
      _violatingData.ruleIdentity.violationId
    ] = _violatingData.ruleIdentity.commentCount;
    processedData.totalNumberOfComments += Number(
      _violatingData.ruleIdentity.commentCount
    );
  });

  return processedData;
};

export const getPairingId = (pairingFromRedux, pairingId) => {
  return `${
    pairingFromRedux.externalId ? pairingFromRedux.externalId : pairingId
  }`;
};

export const getPairingDate = (pairingFromRedux, pairingId) =>
  pairingFromRedux.pairingDate
    ? ` - ${pairingFromRedux.pairingDate.replace(
        /(\d{4})(\d{2})(\d{2})/g,
        "$2/$3/$1"
      )}`
    : "";

export const getAndStorePairingInfo = async (
  pairingId,
  setPairingInfoForThePairing,
  tooltipRef,
  crewObject
) => {
  const token = await getAccessTokenForUser();
  const url = BASE_ENDPOINT + GET_PAIRING_INFO;

  const body = {
    pairingId: pairingId,
    crewId: crewObject && crewObject.crewId ? crewObject.crewId : null
  };

  const headers = {
    Authorization: token
  };
  const pairingInfoResponse = await Axios.post(url, body, {
    headers: headers
  });
  setPairingInfoForThePairing({
    isFetched: true,
    pairingInfo: pairingInfoResponse.data
  });

  /**
   * Commenting out the dispatch to the store as part of CRWEB-540, as this is causing the tooltip to close after the data is fetched, will revisit this later
   */
  // dispatch(
  //   setPairingInfoForPairingAction(pairingId, pairingInfoResponse.data)
  // );
  //update tshe tooltip after the pairingInfo data is received
  tooltipRef.current.scheduleUpdate();
};

export const getRelavantViolatingData = (
  violatingData,
  isFlightOps = undefined
) => {
  let arrayOfViolationMessages = [];
  let mapOfViolationMessages = [];

  if (isNonEmptyArray(violatingData)) {
    violatingData.forEach(violation => {
      const ruleName = getRuleNameToDisplay(violation);

      const ruleCategory = violation.ruleIdentity
        ? violation.ruleIdentity.ruleCategory
        : violation.ruleCategory;

      const ruleType = violation.ruleIdentity
        ? violation.ruleIdentity.ruleType
        : violation.ruleType;

      const key = violation.entityBefore
        ? `${violation.entityBefore.entityId}-${violation.entityBefore.entityType}-${violation.parent.entityId}-${violation.parent.entityType}-${ruleName}-${ruleType}`
        : `${violation.violator.entityId}-${violation.violator.entityType}-${ruleName}-${ruleType}`;

      /**
       * create a map that stores messages for a particular entity against its corresponding ruleCategory
       */
      mapOfViolationMessages[key] = mapOfViolationMessages[key]
        ? mapOfViolationMessages[key]
        : { IN_FLIGHT: null, FLIGHT_OPS: null };

      mapOfViolationMessages[key][ruleCategory] = violation;
    });

    Object.keys(mapOfViolationMessages).forEach(key => {
      let ruleCategory = getRuleCategory(
        isFlightOps,
        mapOfViolationMessages,
        key
      );

      if (mapOfViolationMessages[key][ruleCategory])
        arrayOfViolationMessages.push(
          mapOfViolationMessages[key][ruleCategory]
        );
    });
  }

  return arrayOfViolationMessages;
};

/**
 * choose a particular category to pick messages from based on the isFlightOps flag
 * isFlightOps -> true | pick FLIGHT_OPS
 * isFlightOps -> false | pick IN_FLIGHT
 * isFlightOps -> undefined i.e open-time | pick the category which is not null
 */
const getRuleCategory = (isFlightOps, mapOfViolationMessages, key) => {
  if (isFlightOps === undefined) {
    return mapOfViolationMessages[key]["FLIGHT_OPS"]
      ? "FLIGHT_OPS"
      : "IN_FLIGHT";
  } else {
    return isFlightOps ? "FLIGHT_OPS" : "IN_FLIGHT";
  }
};
