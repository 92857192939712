/**
 * the default values for dateRangeSelect component
 */
export const DATE_RANGE_SELECT_VALUES = [0.5, 1, 2, 4, 7];

/**
 * the default values for showHideSelect component
 */
export const SHOW_HIDE_SELECT_VALUES = [
  { displayName: "Show all", value: "SHOW_ALL" },
  { displayName: "Show selected", value: "HIDE_UNSELECTED" }
];

export const SELECT_ALL_DESELECT_ALL_VALUES_MAP = {
  SELECT_ALL: true,
  DESELECT_ALL: false
};
