import { isInArray, isNonEmptyArray } from "./arrayUtils";
import { ENTITY_TYPE } from "../constants/disruptions/crewGanttViewer";
import { LIST_OF_CLASSES_TO_BE_EXCLUDED_TO_PREVENT_OPENING_TOOLTIP_ON_CLICK_INSIDE_TOOLTIP } from "../constants/disruptions/pairing";
import { getRelavantViolatingData } from "../components/partials/tooltip/tooltipWithViolationMessageAndPairingInfoUtils";
import {
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN,
  MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN_WHEN_RTG
} from "../constants/disruptions/waitingTime";
import { DST_CHANGE_TYPES } from "../constants/daylightSavings";
import {
  LOCAL_TIME_ZONE_ATTRIBUTE_KEY,
  LOCAL_TIME_ZONE_NAME_KEY
} from "../constants/disruptions/disruptionSummary";
import { getTimezoneOffsetForGivenDateTimeAndUserSelectedZone } from "./dateTimeUtils";
import { MILLISECONDS_IN_A_MINUTE } from "../constants/disruptions/timeline";

/**
 * checks if the violatorId is present in the arrayOfViolator
 * @param {*} violatiorId
 * @param {*} arrayOfViolators
 */
export function checkIfViolating(violatorId, arrayOfViolators) {
  let flag = false;

  arrayOfViolators.forEach(idOfViolator => {
    if (String(idOfViolator) === String(violatorId)) flag = true;
  });
  return flag;
}

/**
 * returns an object that contains the violationMessage and the overridable property
 * @param {*} entityId
 * @param {*} arrayOfViolator
 */
export function getViolationDataForEntity(entityId, arrayOfViolator) {
  let returnArray = [];

  arrayOfViolator.forEach(entity => {
    if (String(entityId) === String(entity.violator.entityId)) {
      returnArray.push({
        violationMessage: entity.message,
        isOverridable: entity.ruleIdentity.isOverridable,
        ruleName: entity.ruleIdentity.ruleName,
        ruleCategory: entity.ruleIdentity.ruleCategory,
        ruleType: entity.ruleIdentity.ruleType
      });
    }
  });

  return returnArray;
}

export function getAllViolationMessageAndMuteStatus(
  violatingData,
  isFlightOps = undefined
) {
  let arrayOfViolationMessages = [];
  let mapOfViolationMessages = [];

  if (isNonEmptyArray(violatingData)) {
    violatingData.forEach(violation => {
      const ruleName = getRuleNameToDisplay(violation);

      const violationType = violation.violator ? "ENTITY" : "TIME";

      const ruleCategory = violation.ruleIdentity
        ? violation.ruleIdentity.ruleCategory
        : violation.ruleCategory;

      const ruleType = violation.ruleIdentity
        ? violation.ruleIdentity.ruleType
        : violation.ruleType;

      const message = violation.violationMessage
        ? `${ruleName} - ${violation.violationMessage}`
        : `${ruleName} - ${violation.message}`;

      const key = violation.entityBefore
        ? `${violation.entityBefore.entityId}-${violation.entityBefore.entityType}-${violation.parent.entityId}-${violation.parent.entityType}-${ruleName}-${ruleType}`
        : `${violation.violator.entityId}-${violation.violator.entityType}-${ruleName}-${ruleType}`;

      /**
       * create a map that stores messages for a particular entity against its corresponding ruleCategory
       */
      mapOfViolationMessages[key] = mapOfViolationMessages[key]
        ? mapOfViolationMessages[key]
        : { IN_FLIGHT: "", FLIGHT_OPS: "" };

      mapOfViolationMessages[key][ruleCategory] = {
        message,
        violationType,
        violationId: violation.ruleIdentity.violationId
      };
    });

    Object.keys(mapOfViolationMessages).forEach(key => {
      /**
       * choose a particular category to pick messages from based on the isFlightOps flag
       * isFlightOps -> true | pick FLIGHT_OPS
       * isFlightOps -> false | pick IN_FLIGHT
       * isFlightOps -> undefined i.e open-time | pick the category which is not empty
       */
      let ruleCategory =
        isFlightOps === undefined
          ? mapOfViolationMessages[key]["FLIGHT_OPS"].length > 0
            ? "FLIGHT_OPS"
            : "IN_FLIGHT"
          : isFlightOps
          ? "FLIGHT_OPS"
          : "IN_FLIGHT";

      if (
        mapOfViolationMessages[key][ruleCategory].message &&
        mapOfViolationMessages[key][ruleCategory].message.length > 0
      )
        arrayOfViolationMessages.push(
          mapOfViolationMessages[key][ruleCategory]
        );
    });
  }

  return arrayOfViolationMessages;
}

export function getAllApplicableViolations(
  violatingData,
  isFlightOps = undefined
) {
  let arrayOfApplicableViolations = [];

  if (isNonEmptyArray(violatingData)) {
    violatingData.forEach(violation => {
      const ruleCategory = violation.ruleIdentity
        ? violation.ruleIdentity.ruleCategory
        : violation.ruleCategory;
      if (
        (isFlightOps !== undefined &&
          (ruleCategory === "FLIGHT_OPS") === isFlightOps) ||
        isFlightOps === undefined
      ) {
        arrayOfApplicableViolations.push(violation);
      }
    });
  }

  return arrayOfApplicableViolations;
}

export function checkAndReturnIfCrewViolating(
  crewId,
  timeViolations,
  crewDutyViolationIdPairs,
  crewPairingViolationIdPairs
) {
  const returnArray = [];

  if (
    Array.isArray(crewDutyViolationIdPairs) &&
    crewDutyViolationIdPairs.length > 0
  ) {
    crewDutyViolationIdPairs.forEach(crewDutyIdPair => {
      if (crewDutyIdPair.crewId === crewId) {
        timeViolations.forEach(entity => {
          if (
            String(crewDutyIdPair.crewId) === String(entity.parent.entityId) &&
            entity.entityBefore.entityType === ENTITY_TYPE.DUTY &&
            String(crewDutyIdPair.dutyId) ===
              String(entity.entityBefore.entityId)
          ) {
            returnArray.push({
              violationMessage: entity.message,
              isOverridable: entity.ruleIdentity.isOverridable,
              ruleName: entity.ruleIdentity.ruleName,
              entityBeforeType: entity.entityBefore.entityType,
              entityBeforeId: entity.entityBefore.entityId,
              ruleCategory: entity.ruleIdentity.ruleCategory,
              ruleType: entity.ruleIdentity.ruleType
            });
          }
        });
      }
    });
  }

  if (
    Array.isArray(crewPairingViolationIdPairs) &&
    crewPairingViolationIdPairs.length > 0
  ) {
    crewPairingViolationIdPairs.forEach(crewPairingIdPair => {
      if (crewPairingIdPair.crewId === crewId) {
        timeViolations.forEach(entity => {
          if (
            String(crewPairingIdPair.crewId) ===
              String(entity.parent.entityId) &&
            entity.entityBefore.entityType === ENTITY_TYPE.PAIRING &&
            String(crewPairingIdPair.pairingId) ===
              String(entity.entityBefore.entityId)
          ) {
            returnArray.push({
              violationMessage: entity.message,
              isOverridable: entity.ruleIdentity.isOverridable,
              ruleName: entity.ruleIdentity.ruleName,
              entityBeforeType: entity.entityBefore.entityType,
              entityBeforeId: entity.entityBefore.entityId,
              ruleCategory: entity.ruleIdentity.ruleCategory,
              ruleType: entity.ruleIdentity.ruleType
            });
          }
        });
      }
    });
  }

  return returnArray.length !== 0 ? returnArray : null;
}

export function findEarliestFlightForDuty(flights, flightIds) {
  let earliestFlight = undefined;
  if (isNonEmptyArray(flightIds) && flights) {
    earliestFlight = flights[flightIds[0]];
    flightIds.forEach(flightId => {
      if (
        findUtcDepartureTimeOfFlight(flights[flightId]) <
        findUtcDepartureTimeOfFlight(earliestFlight)
      ) {
        earliestFlight = flights[flightId];
      }
    });
  }
  return earliestFlight;
}

export function findLatestFlightForDuty(flights, flightIds) {
  let earliestFlight = undefined;
  if (isNonEmptyArray(flightIds) && flights) {
    earliestFlight = flights[flightIds[0]];
    flightIds.forEach(flightId => {
      if (
        findUtcArrivalTimeOfFlight(flights[flightId]) >
        findUtcArrivalTimeOfFlight(earliestFlight)
      ) {
        earliestFlight = flights[flightId];
      }
    });
  }
  return earliestFlight;
}

export function findColorForViolation(
  violation,
  ruleDisplayConfig,
  isFlightOps = undefined
) {
  let color = null;
  if (violation.ruleIdentity) {
    violation = violation.ruleIdentity;
  }

  if (
    violation &&
    ruleDisplayConfig &&
    ((isFlightOps !== undefined &&
      (violation.ruleCategory === "FLIGHT_OPS") === isFlightOps) ||
      isFlightOps === undefined)
  ) {
    const ruleDisplatConfigForViolation = ruleDisplayConfig[violation.ruleName];

    if (
      ruleDisplatConfigForViolation &&
      ruleDisplatConfigForViolation[violation.ruleCategory] &&
      ruleDisplatConfigForViolation[violation.ruleCategory][violation.ruleType]
    ) {
      color =
        ruleDisplatConfigForViolation[violation.ruleCategory][
          violation.ruleType
        ].color;
    }
  }
  return color;
}

export function findUtcDepartureTimeOfFlight(flight) {
  return flight.utcActualDepDateTime
    ? flight.utcActualDepDateTime
    : flight.utcEffectiveDepDateTime
    ? flight.utcEffectiveDepDateTime
    : flight.utcScheduledDepDateTime;
}

export function findUtcArrivalTimeOfFlight(flight) {
  return flight.utcActualArrDateTime
    ? flight.utcActualArrDateTime
    : flight.utcEffectiveArrDateTime
    ? flight.utcEffectiveArrDateTime
    : flight.utcScheduledArrDateTime;
}

export function handleOnClick(
  event,
  isChildrenHavingTooltipOpen,
  showToolTip,
  setShowTooltip,
  parentRef
) {
  //checks to avoid duty tooltip from popping up due to click inside the tooltip eg. for interation with expansion panel and so on
  let isOnClickFromInsideTooltip = false;
  //get the class list of the current target
  let arrayOfClasses = Array.from(event.target.classList);
  //check if the click occured inside the tooltip
  for (
    let loopVariable = 0;
    loopVariable < arrayOfClasses.length;
    loopVariable++
  ) {
    if (
      isInArray(
        arrayOfClasses[loopVariable],
        LIST_OF_CLASSES_TO_BE_EXCLUDED_TO_PREVENT_OPENING_TOOLTIP_ON_CLICK_INSIDE_TOOLTIP
      )
    )
      isOnClickFromInsideTooltip = true;
    break;
  }

  setShowTooltip(true);

  if (!showToolTip && !isOnClickFromInsideTooltip) {
    setShowTooltip(true);
  }
}

export function handleClickAway(
  event,
  isChildrenHavingTooltipOpen,
  showToolTip,
  setShowTooltip,
  parentRef
) {
  let isTheClickAwayCausedByClickInsideToolTip = false;

  let path = getClickPath(event);

  path &&
    path.forEach((path, index) => {
      if (path.className === "violation-message-pairing-info-container") {
        isTheClickAwayCausedByClickInsideToolTip = true;
      }
    });

  if (!isTheClickAwayCausedByClickInsideToolTip && showToolTip) {
    setShowTooltip(false);
    if (isChildrenHavingTooltipOpen !== null)
      isChildrenHavingTooltipOpen.current = false;
    else parentRef.current.style.zIndex = 0;
  }
}
export const getStartAndEndOfDuty = duty => {
  return {
    utcStartTime: duty.utcScheduledStartTime
      ? duty.utcScheduledStartTime
      : duty.utcStartTime,
    utcEndTime: duty.utcActualEndDateTime
      ? duty.utcActualEndDateTime
      : duty.utcActualEndTime
      ? duty.utcActualEndTime
      : duty.utcEffectiveEndDateTime
      ? duty.utcEffectiveEndDateTime
      : duty.utcEffectiveEndTime
      ? duty.utcEffectiveEndTime
      : duty.utcScheduledEndTime
      ? duty.utcScheduledEndTime
      : duty.utcEndTime
  };
};

export const getRuleNameToDisplay = violation =>
  violation.ruleIdentity
    ? violation.ruleIdentity.ruleDisplayName
      ? violation.ruleIdentity.ruleDisplayName
      : violation.ruleIdentity.ruleName
    : violation.ruleDisplayName
    ? violation.ruleDisplayName
    : violation.ruleName;

export const getClickPath = eventObj =>
  eventObj.path || (eventObj.composedPath && eventObj.composedPath());

export const getCommentMarkerDataUtil = (violatingData, isFlightOps) => {
  const applicableViolations = getRelavantViolatingData(
    violatingData,
    isFlightOps
  );

  let showMarker = false;
  if (isNonEmptyArray(applicableViolations)) {
    applicableViolations.forEach(violations => {
      if (Number(violations.ruleIdentity.commentCount) > 0) {
        showMarker = true;
      }
    });
  }
  return { showMarker: showMarker, violatingData: applicableViolations };
};

export const shouldShowFlightDetails = (
  rangeInDaysInSingleViewPort,
  flightData
) => {
  return (
    rangeInDaysInSingleViewPort < 2 &&
    ((!flightData.isRTG &&
      flightData.flightLengthInViewWidthUnits >
        MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN) ||
      (flightData.isRTG &&
        flightData.flightLengthInViewWidthUnits >
          MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN_WHEN_RTG))
  );
};

export const shouldShowFlightDetailsV2 = (
  rangeInDaysInSingleViewPort,
  isRTG,
  length
) => {
  return (
    rangeInDaysInSingleViewPort < 2 &&
    ((!isRTG && length > MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN) ||
      (isRTG &&
        length > MINIMUM_VIEW_WIDTH_FOR_FLIGHT_DETAILS_TO_BE_SHOWN_WHEN_RTG))
  );
};

/**
 * Checks if there is a change in timezone offset across start and end of an entity
 * if change exists then it updates dstRelatedInfo object with the type of change and the dst offset
 * @param {*} start
 * @param {*} end
 * @param {*} userAttributes
 * @param {*} dstRelatedInfo
 */
export const checkAndUpdateTypeOfDSTChangeInTheTimeRangeOfEntity = (
  start,
  end,
  userAttributes,
  dstRelatedInfo
) => {
  const offsetStart = getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
    start,
    userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][LOCAL_TIME_ZONE_NAME_KEY]
  );
  const offsetEnd = getTimezoneOffsetForGivenDateTimeAndUserSelectedZone(
    end,
    userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY][LOCAL_TIME_ZONE_NAME_KEY]
  );

  if (offsetStart !== offsetEnd) {
    dstRelatedInfo.dstOffset = offsetEnd - offsetStart;
    dstRelatedInfo.typeOfDSTChange =
      offsetStart < offsetEnd
        ? DST_CHANGE_TYPES.DST_CHANGE_POSITIVE
        : DST_CHANGE_TYPES.DST_CHANGE_NEGATIVE;
  }
};

/**
 *
 * @param {*} time
 * @param {*} dstRelatedInfo
 * @param {*} key
 * @returns
 */
export const getTimeAfterApplyingOffsetsBasedOnDstInfo = (
  time,
  dstRelatedInfo,
  key
) =>
  dstRelatedInfo.typeOfDSTChange === DST_CHANGE_TYPES[key]
    ? Number(time) + MILLISECONDS_IN_A_MINUTE * dstRelatedInfo.dstOffset
    : Number(time);

/**
 * Additional check, to counter the corner case encountered in REPDESK-81
 * Another approach to resolve this issue is being currently worked upon archiving the function here
 */
// const checkIfRestViolationStillExists = React.useCallback(
//   (hours, minutes) => {
//     let actualRest = new Date(0, 0, 0, hours, minutes);

//     if (isFlightOps) {
//       return actualRest < REQUIRED_MIN_REST_TIME_FLIGHT_OPS;
//     } else {
//       return actualRest < REQUIRED_MIN_REST_TIME_IN_FLIGHT;
//     }
//   },
//   [isFlightOps]
// );
