import React, { useState, useCallback, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  setStartDateForSolver,
  setEndDateForSolver
} from "../../../../redux/searchCriteriaReducer";
import { setIsRangePickerOverlayVisible } from "../../../../redux/showAndHideReducer";

import {
  getTimeInEpochMillisecondsForMargin,
  getPercentageUsingRangeProvided,
  getPositionsForRangePickerHandles,
  getWhichPickerToMove
} from "../../../../utils/rangePickerUtils";

import {
  MILLISECONDS_IN_A_DAY,
  VIEWWIDTH_COVERED_BY_GANTT_TIMELINE
} from "../../../../constants/disruptions/timeline";
import { SLIDER_MIN_RANGE } from "../../../../constants/disruptions/timeline";
import { LIMIT_ON_THE_RUN_SOLVER_WINDOW } from "../../../../constants/disruptions/disruptionSummary";

import "./RangePicker.scss";

export default function RangePicker() {
  /**
   * refs for range selectors(input[type="range"])
   */
  const leftRangeSelectorTimelineRef = useRef();
  const rightRangeSelectorTimelineRef = useRef();
  const setRangeSelectorRef = React.useRef();

  /**
   * range -> track(area) between two pickers
   * leftPicker -> used for selecting the start
   * rightPicker -> used for selecting the end
   */

  /**
   * refs for timeline range and rangepickers
   */
  const timelineRangeRef = useRef();
  const timelineLeftPickerRef = useRef();
  const timelineRightPickerRef = useRef();

  /**
   * refs for gantt range and rangepickers
   */
  const ganttRangeRef = useRef();
  const ganttLeftPickerRef = useRef();
  const ganttRightPickerRef = useRef();

  /**
   * the initial max value of silders
   */
  const [sliderMaxRange, setSliderMaxRange] = useState(
    VIEWWIDTH_COVERED_BY_GANTT_TIMELINE
  );

  /**
   * used to update the searchCriteriaReducer with start and end range
   */
  const dispatcher = useDispatch();

  const dateRangeSelection = useSelector(
    store => store.searchCriteria.dateRange
  );

  /**
   *  maintained as a global state rather than a component slide to change the z-index all-disp-container
   */
  const isRangePickerOverlayVisible = useSelector(
    store => store.showAndHide.isRangePickerOverlayVisible
  );

  const rangeSelectionForSolver = useSelector(
    store => store.searchCriteria.rangeSelectionForSolver
  );
  /**
   * updates the position of the left picker and range for timeline and gantt
   */
  const updateLeft = useCallback(
    percentage => {
      timelineLeftPickerRef.current.style.left = percentage + "%";
      timelineRangeRef.current.style.left = percentage + "%";
      if (
        ganttLeftPickerRef.current !== undefined &&
        ganttLeftPickerRef.current !== null
      ) {
        ganttLeftPickerRef.current.style.left = percentage + "%";
        ganttRangeRef.current.style.left = percentage + "%";
      }
    },
    [ganttLeftPickerRef, ganttRangeRef, timelineLeftPickerRef, timelineRangeRef]
  );

  /**
   * updates the position of the right picker and range for timeline and gantt
   */
  const updateRight = useCallback(
    percentage => {
      timelineRightPickerRef.current.style.right = 100 - percentage + "%";
      timelineRangeRef.current.style.right = 100 - percentage + "%";
      if (
        ganttRightPickerRef.current !== undefined &&
        ganttRightPickerRef.current !== null
      ) {
        ganttRightPickerRef.current.style.right = 100 - percentage + "%";
        ganttRangeRef.current.style.right = 100 - percentage + "%";
      }
    },
    [
      ganttRightPickerRef,
      ganttRangeRef,
      timelineRightPickerRef,
      timelineRangeRef
    ]
  );

  /**
   * updates the position of the pickers and range,
   * when there is a change in the selection for number of days
   */
  useLayoutEffect(() => {
    setSliderMaxRange(
      dateRangeSelection.widthOfOneDay * dateRangeSelection.entireRangeInDays
    );

    if (leftRangeSelectorTimelineRef.current !== undefined) {
      leftRangeSelectorTimelineRef.current.max = sliderMaxRange;
      let percentage =
        ((leftRangeSelectorTimelineRef.current.value - SLIDER_MIN_RANGE) /
          (sliderMaxRange - SLIDER_MIN_RANGE)) *
        100;
      updateLeft(percentage);
    }
    if (rightRangeSelectorTimelineRef.current !== undefined) {
      rightRangeSelectorTimelineRef.current.max = sliderMaxRange;
      let percentage =
        ((rightRangeSelectorTimelineRef.current.value - SLIDER_MIN_RANGE) /
          (sliderMaxRange - SLIDER_MIN_RANGE)) *
        100;
      updateRight(percentage);
    }
  }, [
    dateRangeSelection.widthOfOneDay,
    dateRangeSelection.entireRangeInDays,
    leftRangeSelectorTimelineRef,
    rightRangeSelectorTimelineRef,
    sliderMaxRange,
    updateLeft,
    updateRight
  ]);

  /**
   * update the range picker start and end when the user change start and end from the RunSolverDialog component
   */
  React.useEffect(() => {
    if (leftRangeSelectorTimelineRef.current !== undefined) {
      leftRangeSelectorTimelineRef.current.max = sliderMaxRange;
      let percentage =
        ((leftRangeSelectorTimelineRef.current.value - SLIDER_MIN_RANGE) /
          (sliderMaxRange - SLIDER_MIN_RANGE)) *
        100;
      updateLeft(percentage);
    }
    if (rightRangeSelectorTimelineRef.current !== undefined) {
      rightRangeSelectorTimelineRef.current.max = sliderMaxRange;
      let percentage =
        ((rightRangeSelectorTimelineRef.current.value - SLIDER_MIN_RANGE) /
          (sliderMaxRange - SLIDER_MIN_RANGE)) *
        100;
      updateRight(percentage);
    }
  }, [rangeSelectionForSolver, sliderMaxRange, updateLeft, updateRight]);

  /**
   * handles the inputEvent on the left slider,it does the following things:
   *  1) Ensures that the leftpicker does not surpass the right picker
   *  2) Gets the position of the slider in percentages, and updates the same for left range and picker
   *  3) Updates the same in redux store
   * @param {Event} event
   */
  const handleLeftSlider = React.useCallback(
    event => {
      event.target.value = Math.min(
        parseFloat(event.target.value),
        parseFloat(rightRangeSelectorTimelineRef.current.value) - 1
      );

      let percentage = getPercentageUsingRangeProvided(
        event.target.value,
        SLIDER_MIN_RANGE,
        sliderMaxRange
      );

      updateLeft(percentage);
      let startTimeInEpochMilliseconds = getTimeInEpochMillisecondsForMargin(
        event.target.value,
        dateRangeSelection.widthOfOneDay,
        dateRangeSelection.startDate
      );

      dispatcher(setStartDateForSolver(startTimeInEpochMilliseconds));
    },
    [
      dateRangeSelection.startDate,
      dateRangeSelection.widthOfOneDay,
      dispatcher,
      sliderMaxRange,
      updateLeft
    ]
  );

  /**
   * handles the inputEvent on the right slider,it does the following things:
   *  1) Ensures that the rightpicker does not surpass the left picker
   *  2) Gets the position of the slider in percentages, and updates the same for right range and picker
   *  3) Updates the same in redux store
   * @param {Event} event
   */
  const handleRightSlider = React.useCallback(
    event => {
      event.target.value = Math.max(
        parseFloat(event.target.value),
        parseFloat(leftRangeSelectorTimelineRef.current.value) + 1
      );

      let percentage = getPercentageUsingRangeProvided(
        event.target.value,
        SLIDER_MIN_RANGE,
        sliderMaxRange
      );

      updateRight(percentage);

      let endTimeInEpochMilliseconds = getTimeInEpochMillisecondsForMargin(
        event.target.value,
        dateRangeSelection.widthOfOneDay,
        dateRangeSelection.startDate
      );

      dispatcher(setEndDateForSolver(endTimeInEpochMilliseconds));
    },
    [
      dateRangeSelection.startDate,
      dateRangeSelection.widthOfOneDay,
      dispatcher,
      sliderMaxRange,
      updateRight
    ]
  );

  /**
   * sets the zIndex of #set-range-selector
   */
  const setZIndexOfSetRangeSelector = React.useCallback(zIndex => {
    if (setRangeSelectorRef.current) {
      setRangeSelectorRef.current.style.zIndex = zIndex;

      //change the pickers zIndex accordingly to make sure that the #set-range-selector's zIndex is set back to 2
      timelineLeftPickerRef.current.style.zIndex = zIndex === 4 ? 4 : -1;
      timelineRightPickerRef.current.style.zIndex = zIndex === 4 ? 4 : -1;
    }
  }, []);

  /**
   * callback to handle click on #set-range-selector, helps in placing the left/right picker to be placed on the required position
   */
  const handleChangeOnSetSelector = React.useCallback(
    event => {
      /**
       * value of the position where the left/right picker should be placed
       */
      const currentValue = Number(event.target.value);
      /**
       * epoch corresponding to current value
       */
      let selectedRangeDateTime = getTimeInEpochMillisecondsForMargin(
        currentValue,
        dateRangeSelection.widthOfOneDay,
        dateRangeSelection.startDate
      );

      /**
       * current value of the left picker/ start picker
       */
      const startValue = Number(leftRangeSelectorTimelineRef.current.value);

      /**
       * current value of the right picker
       */
      const endValue = Number(rightRangeSelectorTimelineRef.current.value);

      const [pickerToMove, shouldMoveEndPickerWithStartPicker] =
        getWhichPickerToMove(
          currentValue,
          startValue,
          endValue,
          rangeSelectionForSolver,
          selectedRangeDateTime
        );

      /**
       * the percentage at which the left/right picker must be placed
       */
      let percentage = getPercentageUsingRangeProvided(
        currentValue,
        SLIDER_MIN_RANGE,
        sliderMaxRange
      );

      if (pickerToMove === "start") {
        updateLeft(percentage);
        let startTimeInEpochMilliseconds = getTimeInEpochMillisecondsForMargin(
          event.target.value,
          dateRangeSelection.widthOfOneDay,
          dateRangeSelection.startDate
        );

        dispatcher(setStartDateForSolver(startTimeInEpochMilliseconds));

        if (shouldMoveEndPickerWithStartPicker) {
          dispatcher(
            setEndDateForSolver(
              new Date(
                startTimeInEpochMilliseconds +
                  LIMIT_ON_THE_RUN_SOLVER_WINDOW * MILLISECONDS_IN_A_DAY
              )
            )
          );
        }
      } else {
        updateRight(percentage);

        let endTimeInEpochMilliseconds = getTimeInEpochMillisecondsForMargin(
          currentValue,
          dateRangeSelection.widthOfOneDay,
          dateRangeSelection.startDate
        );

        dispatcher(setEndDateForSolver(endTimeInEpochMilliseconds));
      }
      //set the z-index of#set-range-selector to 2, to allow the pickers to be draggable again
      setZIndexOfSetRangeSelector(2);
    },
    [
      dateRangeSelection.startDate,
      dateRangeSelection.widthOfOneDay,
      dispatcher,
      sliderMaxRange,
      updateLeft,
      updateRight,
      setZIndexOfSetRangeSelector,
      rangeSelectionForSolver
    ]
  );

  return (
    <div className="range-picker-container">
      <div className="multi-range-selector">
        <div className="left-right">
          {/**
           *   .left-selector - contains the input[type="range"] element which is used to select the start time
           */}
          <div className="left-selector">
            <input
              type="range"
              id="left-range-selector"
              min="0"
              max={`${sliderMaxRange}`}
              value={getPositionsForRangePickerHandles(
                rangeSelectionForSolver.startDateAndTime,
                dateRangeSelection.widthOfOneDay,
                dateRangeSelection.startDate
              )}
              ref={leftRangeSelectorTimelineRef}
              onChange={handleLeftSlider}
              onMouseEnter={() =>
                dispatcher(setIsRangePickerOverlayVisible(true))
              }
              onMouseLeave={() =>
                dispatcher(setIsRangePickerOverlayVisible(false))
              }
              step="0.1"
            ></input>
          </div>
          {/**
           *   .right-selector - contains the input[type="range"] element which is used to select the end time
           */}
          <div className="right-selector">
            <input
              type="range"
              id="right-range-selector"
              min="0"
              max={`${sliderMaxRange}`}
              value={getPositionsForRangePickerHandles(
                rangeSelectionForSolver.endDateAndTime,
                dateRangeSelection.widthOfOneDay,
                dateRangeSelection.startDate
              )}
              ref={rightRangeSelectorTimelineRef}
              onChange={handleRightSlider}
              onMouseEnter={() =>
                dispatcher(setIsRangePickerOverlayVisible(true))
              }
              onMouseLeave={() =>
                dispatcher(setIsRangePickerOverlayVisible(false))
              }
              step="0.1"
            ></input>
          </div>
        </div>
        {/**
         *   .set-selector - contains the input[type="range"] element which is used to pan the required #right-range-selector and #left-range-selector to the required position
         */}
        <div className="set-selector">
          <input
            type="range"
            id="set-range-selector"
            min="0"
            max={`${sliderMaxRange}`}
            step="0.1"
            onClick={handleChangeOnSetSelector}
            ref={setRangeSelectorRef}
            onMouseLeave={() => setZIndexOfSetRangeSelector(2)} //set the z-index of#set-range-selector to 2, to allow the pickers to be draggable again
          ></input>
        </div>
        <div className="timeline-slider">
          {/**
           *   .range - the track or the blue area that spans from start time to end time
           */}
          <div
            className="range"
            ref={timelineRangeRef}
            style={{ left: "25%", right: "25%" }}
            onMouseEnter={() =>
              setZIndexOfSetRangeSelector(4)
            } /* set the z index of #set-range-selector to 4, to allow the area
            between start and end to be clickable */
          />
          {/**
           *   .picker .left - the left blue triangle that marks the start of the recovery window
           */}
          <div
            className="picker left"
            ref={timelineLeftPickerRef}
            style={{ left: "25%" }}
            onMouseEnter={() => {
              setZIndexOfSetRangeSelector(2);
            }}
          />
          {/**
           *   .picker .right - the right blue triangle that marks the end of the recovery window
           */}
          <div
            className="picker right"
            ref={timelineRightPickerRef}
            style={{ right: "25%" }}
            onMouseEnter={() => {
              setZIndexOfSetRangeSelector(2);
            }}
          />
          {isRangePickerOverlayVisible && (
            <div className="gantt-slider">
              <div
                className="gantt-range"
                ref={ganttRangeRef}
                style={{
                  left: `${
                    (timelineRangeRef.current &&
                      timelineRangeRef.current.style.left) ||
                    "25%"
                  }`,
                  right: `${
                    (timelineRangeRef.current &&
                      timelineRangeRef.current.style.right) ||
                    "25%"
                  }`
                }}
              ></div>
              <div
                className="gantt-picker left"
                ref={ganttLeftPickerRef}
                style={{
                  left: `${
                    (timelineRangeRef.current &&
                      timelineLeftPickerRef.current.style.left) ||
                    "25%"
                  }`
                }}
              ></div>
              <div
                className="gantt-picker right"
                ref={ganttRightPickerRef}
                style={{
                  right: `${
                    (timelineRangeRef.current &&
                      timelineRightPickerRef.current.style.right) ||
                    "25%"
                  }`
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
