import React from "react";
import { useSelector } from "react-redux";
import Drawer from "../../../partials/drawer/Drawer";
import ViolationMessageDrilledDown from "../../../partials/violation-drill-down-section/ViolationMessageDrilledDown";
import RulesManager from "../RulesAndStrategyManager/RulesManager";
import StrategyManager from "../RulesAndStrategyManager/StrategyManager";
import UserProfile from "../RulesAndStrategyManager/UserProfile";

export default function DrawerContainer() {
  const isRuleManagerDrawerVisible = useSelector(
    store => store.showAndHide.isRuleManagerDrawerVisible
  );

  const isStrategyManagerDrawerVisible = useSelector(
    store => store.showAndHide.isStrategyManagerDrawerVisible
  );

  const isUserProfileDrawerVisible = useSelector(
    store => store.showAndHide.isUserProfileDrawerVisible
  );

  const isViolationDrilledDownDrawerVisible = useSelector(
    store => store.showAndHide.isViolationDrilledDownDrawerVisible
  );

  const [drawerInfo, setDrawerInfo] = React.useState({
    drawerToBeShown: null,
    drawerId: ""
  });

  React.useEffect(() => {
    isRuleManagerDrawerVisible
      ? setDrawerInfo({
          drawerToBeShown: RulesManager,
          drawerId: "rule-manager-drawer"
        })
      : isStrategyManagerDrawerVisible
      ? setDrawerInfo({
          drawerToBeShown: StrategyManager,
          drawerId: "strategy-manager-drawer"
        })
      : isUserProfileDrawerVisible
      ? setDrawerInfo({
          drawerToBeShown: UserProfile,
          drawerId: "user-profile-drawer"
        })
      : isViolationDrilledDownDrawerVisible
      ? setDrawerInfo({
          drawerToBeShown: ViolationMessageDrilledDown,
          drawerId: "violation-drill-down-drawer"
        })
      : setDrawerInfo({
          drawerToBeShown: null,
          drawerId: ""
        });
  }, [
    isRuleManagerDrawerVisible,
    isStrategyManagerDrawerVisible,
    isUserProfileDrawerVisible,
    isViolationDrilledDownDrawerVisible
  ]);

  const shouldShowDrawer = React.useCallback(
    () => drawerInfo.drawerToBeShown != null,
    [drawerInfo.drawerToBeShown]
  );

  return (
    <>
      {shouldShowDrawer() ? (
        <div className="drawer-conatiner">
          <Drawer
            open
            ComponentToBeDisplayed={drawerInfo.drawerToBeShown}
            id={drawerInfo.drawerId}
          />
        </div>
      ) : null}
    </>
  );
}
