import { MILLISECONDS_IN_AN_HOUR } from "./disruptions/timeline";

export const DEFAULT_UI_CONFIG = {
  rangePicker: {
    range: 24 * 60 * 60 * 1000, //86400000 milliseconds or 1 day
    startOffset: 2 * MILLISECONDS_IN_AN_HOUR
  }
};

export const DEFAULT_THEME = "light";

export const DEFAULT_LOCALE = "en";
