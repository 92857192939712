import React from "react";
import { useSelector } from "react-redux";

import {
  getDaysArray,
  getHoursArray,
  getGranularity,
  showHours
} from "../../../../utils/timelineUtils";
import Day from "./Day";
import Hour from "./Hour";
import "./SelectionTimelineContainer.scss";
import RangePicker from "./RangePicker";

export function Timeline() {
  const dateRangeSelection = useSelector(
    store => store.searchCriteria.dateRange
  );

  const widthOfOneDay = dateRangeSelection.widthOfOneDay;
  const numberOfDays = dateRangeSelection.entireRangeInDays;
  const rangeSelection = dateRangeSelection.rangeInDaysInSingleViewPort;

  /**
   * get all dates to be displayed
   * */
  const allDatesArray = getDaysArray(
    numberOfDays,
    dateRangeSelection.startDate
  );

  /**
   * get all hours to be displayed
   * */
  const allHoursArray =
    showHours(rangeSelection) && getHoursArray(rangeSelection);

  /**
   * get the granularity in which the days should be displayed
   * */
  const granularityOfDayInfo = getGranularity(rangeSelection);

  return (
    <div
      className="timeline-main"
      style={{
        width: widthOfOneDay * numberOfDays + "vw"
      }}
    >
      <div className="day-info">
        {/*map through the allDatesArray and render Day componenet*/}
        {dateRangeSelection.rangeInDaysInSingleViewPort &&
          allDatesArray.map(date => {
            return (
              <div key={date}>
                <Day
                  date={date}
                  width={widthOfOneDay}
                  granularity={granularityOfDayInfo}
                  key={date}
                />
                <div
                  className="hour-info"
                  style={{
                    width: widthOfOneDay + "vw"
                  }}
                >
                  {/*map through the allHoursArray and render Hour componenet*/}
                  {allHoursArray &&
                    allHoursArray.map(hour => (
                      <Hour
                        hour={hour}
                        width={widthOfOneDay / allHoursArray.length}
                        key={hour}
                      />
                    ))}
                </div>
              </div>
            );
          })}
      </div>
      <RangePicker />
    </div>
  );
}

/**
 * This will ensure that the Timeline component is not repainted if no change in JSX
 */
export default React.memo(Timeline);
