import { isInArray, isNonEmptyArray } from "./arrayUtils";
import { convertCommaSeperatedStringToArrayOfStrings } from "./filterUtils";

export function getFilteredOutCrewMembers(currentScenario, filters) {
  let filteredOutCrewMembers = [...currentScenario.crewMembers];

  /**
   * filter crew by airline
   */
  filteredOutCrewMembers =
    filters.airline.length > 0
      ? [
          ...filterCrewMemberByAirline(
            currentScenario,
            filters.airline,
            filteredOutCrewMembers
          )
        ]
      : [...filteredOutCrewMembers];

  /**
   * filter by crewName
   */
  filteredOutCrewMembers =
    filters.crewName.length > 0
      ? [...filterCrewMemberbyName(filteredOutCrewMembers, filters.crewName)]
      : [...filteredOutCrewMembers];

  /**
   * filter by base
   */
  filteredOutCrewMembers =
    filters.base.length > 0
      ? [...filterCrewMemberbyBase(filteredOutCrewMembers, filters.base)]
      : [...filteredOutCrewMembers];

  /**
   * filter by rank
   */
  filteredOutCrewMembers =
    filters.rank.length > 0
      ? [...filterCrewMemberByRank(filteredOutCrewMembers, filters.rank)]
      : [...filteredOutCrewMembers];

  /**
   * filter by pairingId
   */
  if (filters.pairing)
    filteredOutCrewMembers =
      filters.pairing.length > 0
        ? [
            ...filterCrewMemberByPairing(
              filteredOutCrewMembers,
              filters.pairing
            )
          ]
        : [...filteredOutCrewMembers];

  /**
   *filter by airport
   */
  filteredOutCrewMembers =
    filters.airport.length > 0
      ? [
          ...filterCrewMemberByAirport(
            currentScenario,
            filters,
            filteredOutCrewMembers
          )
        ]
      : [...filteredOutCrewMembers];

  filteredOutCrewMembers =
    filters.flightNumber.length > 0
      ? [
          ...filterCrewMemberByFlightNumber(
            currentScenario,
            filters,
            filteredOutCrewMembers
          )
        ]
      : [...filteredOutCrewMembers];

  return filteredOutCrewMembers;
}

export function filterCrewMemberbyName(crewMembers, nameFilter) {
  let arrayOfCrewNames =
    convertCommaSeperatedStringToArrayOfStrings(nameFilter);

  let filteredCrewMembers = [];

  crewMembers.forEach(crewMember => {
    let firstNameAndSecondNameCombinedWithNoWhiteSpacesForEachCrew = String(
      String(crewMember.firstname) + String(crewMember.lastname)
    )
      .split(/\s/)
      .join("")
      .toUpperCase();

    for (
      let loopVariable = 0;
      loopVariable < arrayOfCrewNames.length;
      loopVariable++
    ) {
      let userProvidedFirstNameAndSecondNameCombinedWithNoWhiteSpaces =
        arrayOfCrewNames[loopVariable].split(/\s/).join("").toUpperCase();

      if (
        firstNameAndSecondNameCombinedWithNoWhiteSpacesForEachCrew.includes(
          userProvidedFirstNameAndSecondNameCombinedWithNoWhiteSpaces
        )
      ) {
        filteredCrewMembers.push(crewMember);
        break;
      }
    }
  });

  return filteredCrewMembers;
}

export function filterCrewMemberbyBase(crewMembers, base) {
  return crewMembers.filter(crewMember => isInArray(crewMember.base, base));
}

export function filterCrewMemberByRank(crewMembers, rank) {
  return crewMembers.filter(crewMembers => isInArray(crewMembers.rank, rank));
}

export function filterCrewMemberByPairing(
  crewMembers,
  pairing,
  isAirlineFilterApplied
) {
  let arrayOfCrewMembersContainingOnlyTheRequiredPairings = [];

  crewMembers.forEach(crewMember => {
    /**
     * Check if there are any input pairings in the solver filter request
     */
    let solverInputPairingPresentInFilterRequest = [];
    crewMember.pairings.forEach(pairingId => {
      if (isInArray(String(pairingId), pairing)) {
        solverInputPairingPresentInFilterRequest.push(pairingId);
      }
    });

    /**
     * Check if there are any output pairings in the solver filter request
     */
    let solverOutputPairingPresentInFilterRequest = [];
    crewMember.solvedPairings.forEach(solvedPairing => {
      if (isInArray(String(solvedPairing.pairingId), pairing)) {
        solverOutputPairingPresentInFilterRequest.push(solvedPairing);
      }
    });

    //if it contains atleast one of the input or output pairing then add the crewMember to the return array after modifying the crew with only the required pairings
    if (
      solverInputPairingPresentInFilterRequest.length > 0 ||
      solverOutputPairingPresentInFilterRequest.length > 0
    ) {
      /**case when pairing filter is applied include only the required pairings */
      if (!isAirlineFilterApplied) {
        arrayOfCrewMembersContainingOnlyTheRequiredPairings.push({
          ...crewMember,
          pairings: solverInputPairingPresentInFilterRequest,
          solvedPairings: solverOutputPairingPresentInFilterRequest
        });
      } else {
        /**
         * case when airline filter is applied
         * check if the current crew is not already added in the array of crewMembers
         */
        if (
          !isInArray(
            String(crewMember.crewId),
            getCrewIdsFromCrewObjects(
              arrayOfCrewMembersContainingOnlyTheRequiredPairings
            )
          ) ||
          arrayOfCrewMembersContainingOnlyTheRequiredPairings.length === 0
        ) {
          //update if there are input and output pairings that fall in the window of the filter
          arrayOfCrewMembersContainingOnlyTheRequiredPairings.push({
            ...crewMember,
            pairings:
              solverInputPairingPresentInFilterRequest.length > 0
                ? solverInputPairingPresentInFilterRequest
                : crewMember.pairings,
            solvedPairings:
              solverOutputPairingPresentInFilterRequest.length > 0
                ? solverOutputPairingPresentInFilterRequest
                : crewMember.solvedPairings
          });
        } else {
          //else i.e if there exists a crewMember then find and update the crewMember object

          let existingCrewMember =
            arrayOfCrewMembersContainingOnlyTheRequiredPairings[
              findIndexOfCrewMember(
                crewMember,
                arrayOfCrewMembersContainingOnlyTheRequiredPairings
              )
            ];

          existingCrewMember.pairings = [
            ...existingCrewMember.pairings,
            ...solverInputPairingPresentInFilterRequest
          ];

          existingCrewMember.solvedPairings = [
            ...existingCrewMember.solvedPairings,
            ...solverOutputPairingPresentInFilterRequest
          ];

          arrayOfCrewMembersContainingOnlyTheRequiredPairings.push({
            ...existingCrewMember
          });
        }
      }
    }
  });

  return arrayOfCrewMembersContainingOnlyTheRequiredPairings;
}

export function filterCrewMemberByAirline(
  currentScenario,
  filters,
  filteredOutCrewMembers
) {
  let filteredPairings = [];
  if (isNonEmptyArray(Object.keys(currentScenario.pairings))) {
    const arrayOfAllPairings = [...Object.keys(currentScenario.pairings)];

    for (
      let loopVariableToIterateThroughPairings = 0;
      loopVariableToIterateThroughPairings < arrayOfAllPairings.length;
      loopVariableToIterateThroughPairings++
    ) {
      let currentPairing =
        currentScenario.pairings[
          arrayOfAllPairings[loopVariableToIterateThroughPairings]
        ];

      let dutiesInCurrentPairing = currentPairing.dutyIds;

      let breakOutOfDutyLoop = false;
      for (
        let loopVariableToIterateThroughDuties = 0;
        loopVariableToIterateThroughDuties < dutiesInCurrentPairing.length &&
        !breakOutOfDutyLoop;
        loopVariableToIterateThroughDuties++
      ) {
        let currentDuty =
          currentScenario.duties[
            dutiesInCurrentPairing[loopVariableToIterateThroughDuties]
          ];

        let flightsInCurrentDuty = currentDuty.flightIds;

        for (
          let loopVariableToIterateThroughFlights = 0;
          loopVariableToIterateThroughFlights < flightsInCurrentDuty.length;
          loopVariableToIterateThroughFlights++
        ) {
          let currentFlight =
            currentScenario.flights[
              flightsInCurrentDuty[loopVariableToIterateThroughFlights]
            ];

          if (isInArray(currentFlight.airline, filters)) {
            filteredPairings.push(currentPairing);
            breakOutOfDutyLoop = true;
            break;
          }
        }
      }
    }
  }

  let crewsFallingInTheGivenTimeWindow = [];
  if (filteredPairings.length !== 0)
    crewsFallingInTheGivenTimeWindow = filterCrewMemberByPairing(
      filteredOutCrewMembers,
      getPairingIdsFromPairingObjects(filteredPairings),
      true
    );

  return crewsFallingInTheGivenTimeWindow;
}

/**
 * Iterate through pairings, duties and flights to find if there are any flights that fly to/from the airport given in the filter
 * @param {*} currentScenario
 * @param {*} filters
 * @param {*} filteredOutCrewMembers
 */
export function filterCrewMemberByAirport(
  currentScenario,
  filters,
  filteredOutCrewMembers
) {
  let filterStartDate = new Date(filters.date.from);
  let filterEndDate = new Date(filters.date.to);

  let filteredPairings = [];

  /**
   * TODO: remove this once proper implementation of airportfilter is done
   */
  let airportFilter = { to: filters.airport[0], from: filters.airport[0] };

  if (isNonEmptyArray(Object.keys(currentScenario.pairings))) {
    const arrayOfAllPairings = [...Object.keys(currentScenario.pairings)];

    for (
      let loopVariableToIterateThroughPairings = 0;
      loopVariableToIterateThroughPairings < arrayOfAllPairings.length;
      loopVariableToIterateThroughPairings++
    ) {
      let currentPairing =
        currentScenario.pairings[
          arrayOfAllPairings[loopVariableToIterateThroughPairings]
        ];

      let dutiesInCurrentPairing = currentPairing.dutyIds;

      let pairingStartDateString = String(currentPairing.utcStartTime);

      let pairingEndDateString = String(currentPairing.utcEndTime);

      let pairingStartDate = new Date(
        pairingStartDateString.substring(0, pairingStartDateString.length - 2)
      );
      let pairingEndDate = new Date(
        pairingEndDateString.substring(0, pairingEndDateString.length - 2)
      );

      /**
       * apply date range filter to the solver output
       * filter out data that do not fall in the given filter start and end
       */
      if (
        pairingStartDate < filterEndDate &&
        pairingEndDate > filterStartDate
      ) {
        let breakOutOfDutyLoop = false;
        for (
          let loopVariableToIterateThroughDuties = 0;
          loopVariableToIterateThroughDuties < dutiesInCurrentPairing.length &&
          !breakOutOfDutyLoop;
          loopVariableToIterateThroughDuties++
        ) {
          let currentDuty =
            currentScenario.duties[
              dutiesInCurrentPairing[loopVariableToIterateThroughDuties]
            ];

          let flightsInCurrentDuty = currentDuty.flightIds;

          for (
            let loopVariableToIterateThroughFlights = 0;
            loopVariableToIterateThroughFlights < flightsInCurrentDuty.length;
            loopVariableToIterateThroughFlights++
          ) {
            let currentFlight =
              currentScenario.flights[
                flightsInCurrentDuty[loopVariableToIterateThroughFlights]
              ];

            if (
              currentFlight.destination === airportFilter.to ||
              currentFlight.origin === airportFilter.from
            ) {
              filteredPairings.push(currentPairing);
              breakOutOfDutyLoop = true;
              break;
            }
          }
        }
      }
    }
  }

  let crewsFallingInTheGivenTimeWindow = [];
  if (filteredPairings.length !== 0)
    crewsFallingInTheGivenTimeWindow = filterCrewMemberByPairing(
      filteredOutCrewMembers,
      getPairingIdsFromPairingObjects(filteredPairings),
      true
    );

  return crewsFallingInTheGivenTimeWindow;
}

/**
 * takes in array of pairingObjects and returns array of pairingIds
 * @param {*} pairingObjects
 */
export function getPairingIdsFromPairingObjects(pairingObjects) {
  let returnArray = [];

  pairingObjects.forEach(pairing =>
    returnArray.push(String(pairing.pairingId))
  );

  return returnArray;
}

/**
 * takes in array of crew objects and returns array of crewIds
 * @param {*} crewObjects
 */
export function getCrewIdsFromCrewObjects(crewObjects) {
  let returnArray = [];

  crewObjects.forEach(crew => returnArray.push(String(crew.crewId)));

  return returnArray;
}

/**
 * returns the index of crewMember from the given array
 * @param {*} crewMember
 * @param {*} arrayOfCrewMembers
 */
export function findIndexOfCrewMember(crewMember, arrayOfCrewMembers) {
  for (
    let loopVariable = 0;
    loopVariable < arrayOfCrewMembers.length;
    loopVariable++
  ) {
    if (
      String(arrayOfCrewMembers[loopVariable].crewId) ===
      String(crewMember.crewId)
    ) {
      return loopVariable;
    }
  }
}

/**
 * retuns the crew members who have the required flight assigned to them
 * @param {*} currentScenario
 * @param {*} filters
 * @param {*} filteredOutCrewMembers
 */
export function filterCrewMemberByFlightNumber(
  currentScenario,
  filters,
  filteredOutCrewMembers
) {
  let pairingsContainingTheFlight = [];

  let arrayOfFlightIds = convertCommaSeperatedStringToArrayOfStrings(
    filters.flightNumber
  );

  if (isNonEmptyArray(Object.keys(currentScenario.pairings))) {
    const arrayOfAllPairings = [...Object.keys(currentScenario.pairings)];

    for (
      let loopVariableToIterateThroughPairings = 0;
      loopVariableToIterateThroughPairings < arrayOfAllPairings.length;
      loopVariableToIterateThroughPairings++
    ) {
      let currentPairing =
        currentScenario.pairings[
          arrayOfAllPairings[loopVariableToIterateThroughPairings]
        ];

      let dutiesInCurrentPairing = currentPairing.dutyIds;

      let breakOutOfDutyLoop = false;
      for (
        let loopVariableToIterateThroughDuties = 0;
        loopVariableToIterateThroughDuties < dutiesInCurrentPairing.length &&
        !breakOutOfDutyLoop;
        loopVariableToIterateThroughDuties++
      ) {
        let currentDuty =
          currentScenario.duties[
            dutiesInCurrentPairing[loopVariableToIterateThroughDuties]
          ];

        let flightsInCurrentDuty = currentDuty.flightIds;

        for (
          let loopVariableToIterateThroughFlights = 0;
          loopVariableToIterateThroughFlights < flightsInCurrentDuty.length;
          loopVariableToIterateThroughFlights++
        ) {
          let currentFlight =
            currentScenario.flights[
              flightsInCurrentDuty[loopVariableToIterateThroughFlights]
            ];

          if (isInArray(currentFlight.flightNumber, arrayOfFlightIds)) {
            pairingsContainingTheFlight.push(currentPairing);
            breakOutOfDutyLoop = true;
            break;
          }
        }
      }
    }
  }

  let crewWithTheGivenFlight = [];
  if (pairingsContainingTheFlight.length !== 0)
    crewWithTheGivenFlight = filterCrewMemberByPairing(
      filteredOutCrewMembers,
      getPairingIdsFromPairingObjects(pairingsContainingTheFlight),
      true
    );

  return crewWithTheGivenFlight;
}

/**
 * For each newScenario in the newScenarios array
 * check if a corresponding entry is already present in the previousScenarios array
 * If exists then update it
 * If not create a new entry
 */
export const upsertScenarioDropdownData = (previousScenarios, newScenarios) => {
  /**
   * Creating a map with key as scenarioName because id is created only after the first batch of results are received
   */
  const scenarioMap = {};

  /**
   * Add all the old scenario metadata to the map
   */
  Array.isArray(previousScenarios) &&
    previousScenarios.forEach(
      previousScenario =>
        (scenarioMap[previousScenario.scenarioName] = previousScenario)
    );

  /**
   * replace an old scenario meata data with new one if exists
   */
  Array.isArray(newScenarios) &&
    newScenarios.forEach(newScenario => {
      scenarioMap[newScenario.scenarioName] = newScenario;
    });

  return Array.from(Object.values(scenarioMap));
};
