import { getDateObjectForOneMonthAgo } from "../utils/dateTimeUtils"

const ACTIONS = {
  setSearchData: 'SET_SEARCH_DATA',
  setCumulativeTimeData: 'SET_CUMULATIVE_TIME_DATA',
  clearCumulativeTimeData: 'CLEAR_CUMULATIVE_TIME_DATA',
  setIsDataFetched: 'SET_IS_DATA_FETCHED',
  setIsLoading: 'SET_IS_LOADING',
  setParentTableVisibleColumnCount: 'SET_PARENT_TABLE_VISIBLE_COLUMN_COUNT',
}

/**
 * initial state of the reducer
 */
const initialState = {
  searchData: {
    crewId: '',
    date: {
      start: getDateObjectForOneMonthAgo(new Date()),
      end: new Date(),
    },
  },
  cumulativeTimeData: {
    crewDetails: {},
    columns: [],
    data: [],
    /* 
    * keeps count of columns that are visible in the parent table
    * table, used in setting the colSpan value,
    * for the bottom border span of the GridRow component
    */
    parentTableVisibleColumnCount: 0,
  },
  isDataFetched: false,
  isLoading: false,
}

export const cumulativeTimeReportReducer = (
  prevState = initialState,
  action
) => {
  let newState

  switch (action.type) {
    case 'SET_CUMULATIVE_TIME_DATA':
      newState = {
        ...prevState,
        cumulativeTimeData: {
          parentTableVisibleColumnCount: prevState.cumulativeTimeData.parentTableVisibleColumnCount,
          crewDetails: action.cumulativeTimeData.metadata.crewDetails,
          columns: action.cumulativeTimeData.metadata.columns,
          data: action.cumulativeTimeData.data,
        },
        isLoading: false,
      }
      break

    case 'CLEAR_CUMULATIVE_TIME_DATA':
      newState = {
        ...prevState,
        cumulativeTimeData: {
          crewDetails: {},
          columns: [],
          data: [],
          parentTableVisibleColumnCount: 0,
        },
        isLoading: true,
      }
      break

    case 'SET_IS_LOADING':
      newState = {
        ...prevState,
        isLoading: action.isLoading,
      }
      break

    case 'SET_SEARCH_DATA':
      newState = {
        ...prevState,
        searchData: {
          ...prevState.searchData,
          [action.searchData.key]: action.searchData.value,
        },
      }
      break

    case 'SET_IS_DATA_FETCHED':
      newState = {
        ...prevState,
        isDataFetched: action.isDataFetched,
      }
      break

    case 'SET_PARENT_TABLE_VISIBLE_COLUMN_COUNT':
      newState = {
        ...prevState,
        cumulativeTimeData: {
          ...prevState.cumulativeTimeData,
          parentTableVisibleColumnCount: action.columnCount,
        },
      }
      break

    default:
      newState = prevState
      break
  }

  return newState
}

export const setSearchDataAction = (searchData) => {
  return {
    type: ACTIONS.setSearchData,
    searchData,
  }
}

export const setCumulativeTimeDataAction = (cumulativeTimeData) => {
  return {
    type: ACTIONS.setCumulativeTimeData,
    cumulativeTimeData,
  }
}

export const clearCumulativeTimeDataAction = () => {
  return {
    type: ACTIONS.clearCumulativeTimeData,
  }
}

export const setIsDataFetchedAction = (isDataFetched) => {
  return {
    type: ACTIONS.setIsDataFetched,
    isDataFetched,
  }
}

export const setIsLoadingValueAction = (isLoading) => {
  return {
    type: ACTIONS.setIsLoading,
    isLoading,
  }
}

export const setParentTableVisibleColumnCount = (columnCount) => {
  return {
    type: ACTIONS.setParentTableVisibleColumnCount,
    columnCount,
  }
}