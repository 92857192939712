import React from "react";
import { useSelector } from "react-redux";

import Pairing from "../Pairing/Pairing";
import Flight from "../Pairing/Flight";

import CurrentTimeDemaraction from "../../../../partials/Demarcation/CurrentTimeDemaraction";
import DayDemarcation from "../../../../partials/Demarcation/DayDemarcation";
import {
  OPEN_PAIRING,
  OPEN_SEGMENT
} from "../../../../../constants/disruptions/openTime";

export default function OpenTimeGantt() {
  const crewSchedules = useSelector(store => store.crewSchedules);
  const startTime = useSelector(
    store => store.searchCriteria.dateRange.startDate
  );
  const visibleDateRange = useSelector(store => store.visibleDateRange);

  return (
    <div className="open-flight-and-pairing-container">
      <div className="open-flight-container">
        {crewSchedules.openTimeIds.openFlightIds &&
          crewSchedules.openTimeIds.openFlightIds.length > 0 &&
          crewSchedules.openTimeIds.openFlightIds[0].length !== 0 &&
          crewSchedules.openTimeIds.openFlightIds.map(
            (arrayOfNonOverLappingFlights, index) => (
              <div key={index} className="right-side-row">
                {arrayOfNonOverLappingFlights.map(openFlightId =>
                  crewSchedules.flights[openFlightId] ? (
                    <span key={openFlightId} className="pairing-main">
                      <span className="duty-main" style={{ border: "none" }}>
                        <Flight
                          key={openFlightId}
                          flight={crewSchedules.flights[openFlightId]}
                          flightId={openFlightId}
                          dutyStartTime={startTime}
                          isOpen={true}
                          violatingData={
                            crewSchedules.processedViolations.entityViolations
                              .flight[openFlightId]
                              ? crewSchedules.processedViolations
                                  .entityViolations.flight[openFlightId]
                              : null
                          }
                          openFlyingType={OPEN_SEGMENT}
                        />
                      </span>
                    </span>
                  ) : null
                )}
              </div>
            )
          )}
      </div>

      <div className="open-pairing-container">
        {crewSchedules.openTimeIds.openPairingIds &&
          crewSchedules.openTimeIds.openPairingIds.length > 0 &&
          crewSchedules.openTimeIds.openPairingIds[0].length !== 0 &&
          crewSchedules.openTimeIds.openPairingIds.map(
            (arrayOfNonOverLappingPairing, index) => (
              <div key={index} className="right-side-row">
                {arrayOfNonOverLappingPairing.map(openPairingId => {
                  const currentPairing = crewSchedules.pairings[openPairingId];
                  if (
                    // check if current pairing overlaps with visible date range
                    currentPairing &&
                    Number(new Date(currentPairing.utcEndTime)) >=
                      Number(new Date(visibleDateRange.startDate)) &&
                    Number(new Date(visibleDateRange.endDate)) >=
                      Number(new Date(currentPairing.utcStartTime))
                  ) {
                    return (
                      <Pairing
                        key={openPairingId}
                        pairing={currentPairing}
                        violatingData={
                          crewSchedules.processedViolations.entityViolations
                            .pairing[openPairingId]
                            ? crewSchedules.processedViolations.entityViolations
                                .pairing[openPairingId]
                            : null
                        }
                        pairingId={openPairingId}
                        isOpen={true}
                        openFlyingType={OPEN_PAIRING}
                      />
                    );
                  } else return null;
                })}
              </div>
            )
          )}
      </div>
      <>
        <DayDemarcation />
      </>
      <>
        <CurrentTimeDemaraction />
      </>
    </div>
  );
}
