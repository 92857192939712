import React from "react";
import { Redirect } from "react-router-dom";
import { UPLOAD } from "../../constants/routes";

const UploadNavItem = () => {
  const [redirectToUpload, setRedirectToUpload] = React.useState(false);

  function handleClick() {
    setRedirectToUpload(true);
  }

  if (redirectToUpload) {
    return <Redirect to={UPLOAD} />;
  }

  return <span onClick={handleClick}>Upload</span>;
};

export default UploadNavItem;
