import React from "react";

import {
  TimelineItem as CommentItem,
  TimelineSeparator as SectionSeparator,
  TimelineConnector as CommentConnector,
  TimelineContent as RightSection,
  TimelineOppositeContent as LeftSection,
  TimelineDot as CommentMarker
} from "@material-ui/lab";

import CommentMessage from "./CommentMessage";

function Comment({
  commentId,
  comment,
  commentBy,
  commentDateTime,
  shouldDrawConnector,
  selectedViolation,
  isCommentByCurrentUser
}) {
  return (
    <CommentItem>
      <LeftSection>
        <NameDateAndTime name={commentBy} dateTime={commentDateTime} />
      </LeftSection>
      <SectionSeparator>
        <CommentMarker
          variant={"outlined"}
          className={isCommentByCurrentUser ? "current-user" : ""}
        />
        {shouldDrawConnector && <CommentConnector />}
      </SectionSeparator>
      <RightSection>
        <CommentMessage
          comment={comment}
          commentId={commentId}
          isCommentByUser={isCommentByCurrentUser}
          reduxKey={selectedViolation.reduxKey}
          violationId={selectedViolation.violationId}
          violationType={selectedViolation.violationType}
        />
      </RightSection>
    </CommentItem>
  );
}

const NameDateAndTime = ({ name, dateTime }) => {
  return (
    <div className="name-time-container">
      <div className="name">{name}</div>
      <div className="time">{dateTime}</div>
    </div>
  );
};

export default React.memo(Comment);
