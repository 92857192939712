import React from "react";
import Axios from "axios";

import { CircularProgress, IconButton, Paper } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import { BASE_ENDPOINT, GET_VIOLATION_COMMENT } from "../../../constants/api";

import { getAccessTokenForUser } from "../../../utils/authUtils";
import { useDispatch, useSelector } from "react-redux";
import { setIsDeletingCommentAction } from "../../../redux/violationDrillDownReducer";

export default function CommentMessage({
  comment,
  commentId,
  isCommentByUser,
  violationId,
  violationType
}) {
  const dispatch = useDispatch();

  const isDeleting = useSelector(
    state => state.violationDrilledDownData.isDeletingComment
  );

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const [isMouseOverComment, setIsMouseOverComment] = React.useState(false);

  const [isDeletingLocal, setIsDeletingLocal] = React.useState(false);

  const Message = React.useCallback(
    ({ message }) => <div className="comment-message">{message}</div>,
    []
  );

  React.useEffect(() => {
    if (!isDeleting) setIsDeletingLocal(false);
  }, [isDeleting]);

  const handleClickOnDeleteCommentButton = React.useCallback(async () => {
    dispatch(setIsDeletingCommentAction(true));
    setIsDeletingLocal(true);

    const token = await getAccessTokenForUser();
    const url = BASE_ENDPOINT + GET_VIOLATION_COMMENT;
    const headers = {
      Authorization: token
    };

    const body = {
      commentId: commentId,
      violationMetaData: {
        violationId: violationId,
        violationType: violationType
      }
    };

    Axios.post(url, body, {
      headers: headers
    })
      .then(response => {
        setIsMouseOverComment(false);
        console.log("DELETED COMMENT", response.data);
      })
      .catch(err => console.log(err));
  }, [commentId, dispatch, violationId, violationType]);

  return (
    <div
      className={isMouseOverComment || isDeletingLocal ? "dark-background" : ""}
    >
      <Paper
        elevation={3}
        onMouseEnter={() => setIsMouseOverComment(true)}
        onMouseLeave={() => setIsMouseOverComment(false)}
      >
        <div className="comment-underlay-section">
          {!isShowingASolverRun && //no deleting in the solver output section
            isCommentByUser &&
            (isMouseOverComment || isDeletingLocal) && (
              <IconButton onClick={() => handleClickOnDeleteCommentButton()}>
                {isDeletingLocal ? <CircularProgress /> : <Delete />}
              </IconButton>
            )}
        </div>
        <Message message={comment} />
      </Paper>
    </div>
  );
}
