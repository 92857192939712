/**
 * returns the violation pertaining to the previous time block if its too small to be viewed on the UI
 * @param {*} timeViolationInfo
 * @param {*} violationKey
 */
export function getPreviousTimeViolation(timeViolationInfo, violationKey) {
  return timeViolationInfo &&
    timeViolationInfo[violationKey] &&
    timeViolationInfo[violationKey].length > 0 &&
    timeViolationInfo.width < 0.1
    ? timeViolationInfo[violationKey]
    : null;
}
