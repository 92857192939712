import React from "react";
import { batch } from "react-redux";
import { toast } from "react-toastify";

/**
 * import of all React components
 */
import { ShowScenarioCompletedMessage } from "../components/HomePage/DisruptionsSummary/RunSolver/RunSolver";
import RunSolverProgressToast from "../components/HomePage/DisruptionsSummary/RunSolver/RunSolverProgressToast/RunSolverProgressToast";

/**
 * imports of all actions
 */
import * as actions from "../redux/webSocketReducer";
import {
  setIsFetchingMoreCrewAction,
  resetReduxData,
  setCanFetchMoreCrewsAction,
  setShouldRefreshAction,
  showLoaderOnGanttAction
} from "../redux/crewSchedulesReducer";

import {
  setScheduleDataGridAction,
  setIsFilterBeingAppliedAction,
  resetDateRangeAction,
  setCountInfoAction,
  setIncludedCrewMemberForSolverAction
} from "../redux/searchCriteriaReducer";
import {
  setAllScenarioMetadataAction,
  setIsLoadingSolverOutputDataAction
} from "../redux/solverDataReducer";
import {
  setIntermediateDataForPartialRefreshAction,
  setIntermediateDataForSchedulesAction,
  setIntermediateDataForLegalityAction,
  setIntermediateDataForSolverAction
} from "../redux/websocketIntermediateDataReducer";
import { setIsErrorModalVisibleAction } from "../redux/showAndHideReducer";

/**
 * imports of all utils
 */
import {
  findStartAndEndDateForHorizontalScheduleFetch,
  getAnchorDate
} from "../utils/allGanttRowsUtils";
import {
  calculateOpenTimesToBeSentToPartialRefresh,
  processSelectedFilterBeforeApplying,
  waitFor,
  processReceivedChunk,
  takeActionForPayload,
  processViolationPreferencesToBeApplied,
  resetIntermediateData
} from "../utils/middlewareUtils";
import { getAccessTokenForUser } from "../utils/authUtils";
import { processScheduleResponseAndUpdateStore } from "../utils/getScheduleUtils";
import {
  processLegalityResponseAndUpdateStore,
  updateStoreWithNewScheduleData
} from "../utils/legalityDataUtils";
import { processPartialRefreshDataAndUpdateStore } from "../utils/partialRefreshUtils";
import { processSolverOutputAndUpdateStore } from "../utils/solverOutputDataUtils";
import { processCommentRefreshDataAndUpdateStore } from "../utils/commentRefreshUtils";

/**
 * imports of all constants
 */
import {
  SCHEDULES_RESPONSE,
  GET_SCHEDULES_RESOURCE_NAME,
  PING_WEB_SOCKET,
  LEGALITY_RESPONSE,
  SOLVER_COMPLETE_NOTIFICATION_RESPONSE,
  SOLVER_DATA_RESPONSE,
  PING_RESPONSE,
  SOLVER_REQUEST,
  SOLVER_OUTPUT_REQUEST,
  APPLY_FILTER_RESOURCE_NAME,
  WEBSOCKET_BASE_ENDPOINT,
  SCHEDULE_REFRESH,
  PARTIAL_REFRESH_REQUEST,
  PARTIAL_REFRESH_DATA,
  COUNT_RESPONSE,
  COMMENT_REFESH,
  OPEN_FLYING_DATA
} from "../constants/api";
import { DEFAULT_SCENARIO_ID } from "../constants/disruptions/filter";
import { NUMBER_OF_CREW_PER_PAGE } from "../constants/disruptions/crewGanttViewer";
import { LOCAL_TIME_ZONE_ATTRIBUTE_KEY } from "../constants/disruptions/disruptionSummary";
import { SELECT_ALL_DESELECT_ALL_VALUES_MAP } from "../constants/disruptions/durationSelect";
import { fillOpenFlyingData } from "../utils/solverUtil";
import {
  getOpenFlyingData,
  handlerForOpenFlyingData
} from "../utils/openFlyingDataUtils";
import { OPEN_FLYING_DATA_STATUS } from "../constants/disruptions/openTime";
import { WebWorkerServiceSingleton } from "../webWorker/webWorker";

const socketMiddleware = () => {
  let socket = null;

  let currentPosition = { x: 1, y: 0 };

  /**
   * flags to aid in data processing depending on various types of fetch
   */

  const middlewareFlags = {
    isFilterBeingApplied: false,
    isFirstFetch: false,
    isVertical: false,
    isAnOpenFlightFetch: true,
    scheduleProcessingComplete: false
  };

  let startAndEndOfCurrentFetch = {
    start: "",
    end: ""
  };

  /**
   * local variable used to indicated whether the response coming from getSchedule route is after a filter is applied or not
   */
  const anchorDate = getAnchorDate();

  const solverToastMappings = {};

  const solverScenarioMetadataMappings = {};

  const webWorkerService = WebWorkerServiceSingleton.getInstance();

  const onOpen =
    ({
      store,
      isReconnecting,
      isScheduleFetchEnqued,
      isApplyFilterEnqued,
      isSolverRequestEnqued,
      isPingActionEnqued,
      isSolverOutputRequestEnqued,
      isGetOpenFlyingDataEnqued,
      makeScheduleCallOnConnect
    }) =>
    async event => {
      if (!isReconnecting) {
        //first fetch
        store.dispatch(actions.wsConnected(event.target.url));
        const isCrewSchedulesNotDefined =
          store.getState().crewSchedules.isCrewSchedulesNotDefined;

        if (isCrewSchedulesNotDefined) {
          let initialFetchParams =
            findStartAndEndDateForHorizontalScheduleFetch(
              0,
              0,
              true,
              store.getState().searchCriteria.dateRange,
              {
                isFirstFetch: true,
                isVertical: false,
                isRefresh: false
              }
            );

          /**
           *timezone info is fetched via an async call to Cognito wait till the data is fetched and then invoke the getSchedule route
           */
          while (
            store.getState().userInfo.userAttributes[
              LOCAL_TIME_ZONE_ATTRIBUTE_KEY
            ] === undefined
          )
            await waitFor(1000);

          if (makeScheduleCallOnConnect)
            store.dispatch(actions.fetchSchedulesAction(initialFetchParams));
        }

        // calling the ping API every 50 seconds to keep the connection alive
        setInterval(() => {
          if (
            !checkWebSocketConnectionAndConnectIfNotConnected(store.dispatch, {
              isReconnecting: true,
              isPingActionEnqued: true
            })
          ) {
            store.dispatch(actions.pingAction());
          }
        }, 50000);
      } else {
        //if reconnecting
        //perform the action that was previously enqued

        if (isScheduleFetchEnqued) {
          fetchSchedules(isScheduleFetchEnqued, store);
        }
        if (isApplyFilterEnqued) {
          applyFilter(isApplyFilterEnqued, store);
        }

        if (isSolverRequestEnqued) {
          placeSolverRequest(isSolverRequestEnqued);
        }

        if (isPingActionEnqued) {
          store.dispatch(actions.pingAction());
        }

        if (isSolverOutputRequestEnqued) {
          placeSolverOutputRequest(isSolverOutputRequestEnqued);
        }

        if (isGetOpenFlyingDataEnqued) {
          getOpenFlyingData(store);
        }
      }
    };

  const onClose = store => () => {
    store.dispatch(actions.wsDisconnected());
  };

  const onMessage = store => async event => {
    const payload = JSON.parse(event.data);
    switch (payload.action) {
      case SCHEDULES_RESPONSE:
        handlerForScheduleResponse(store, payload);
        break;
      case LEGALITY_RESPONSE:
        while (!middlewareFlags.scheduleProcessingComplete) await waitFor(1000);
        handlerForLegalityResponse(store, payload);
        break;
      case PING_RESPONSE:
        console.log("pong received from websocket");
        break;
      case SOLVER_COMPLETE_NOTIFICATION_RESPONSE:
        processSolverCompleteNotification(store, payload);
        break;
      case SOLVER_DATA_RESPONSE:
        handlerForSolverDataResponse(store, payload);
        break;
      case SCHEDULE_REFRESH:
        if (!store.getState().searchCriteria.isShowingASolverRun) {
          store.dispatch(
            actions.sendPartailRefreshRequestAction(payload.utcLastModifiedTime)
          );
        }
        break;
      case PARTIAL_REFRESH_DATA:
        if (!store.getState().searchCriteria.isShowingASolverRun) {
          handlerForPartialRefreshResponse(store, payload);
        }
        break;
      case COUNT_RESPONSE:
        processCountResponseAndUpdateStore(store, payload);
        break;
      case COMMENT_REFESH:
        handlerForCommentRefresh(store, payload);
        break;
      case OPEN_FLYING_DATA:
        handlerForOpenFlyingData(store, payload);
        break;

      default:
        console.log("no handler for new message from socket - ", payload);
        break;
    }
  };

  const processSolverCompleteNotification = (store, payload) => {
    /**
     * status object is sent on request failure
     */
    let didRequestFail = payload.status ? true : false;

    didRequestFail && console.error("FAILED SOLVER REQUEST:", payload.status);

    /**
     * store responses in redux only if the request was successful
     */
    if (!didRequestFail)
      store.dispatch(setAllScenarioMetadataAction({ ...payload.data }));
    toast.dismiss(solverToastMappings[payload.data.scenarioName]);
    toast(
      <ShowScenarioCompletedMessage
        scenarioId={payload.data.id}
        scenarioName={payload.data.scenarioName}
        didRequestFail={didRequestFail}
      />,
      {
        type: didRequestFail ? "error" : "default" //show the toast with an error theme if the request was failed else show normal
      }
    );
  };

  const handlerForPartialRefreshResponse = (store, eventData) => {
    const dataFetched = eventData.data;
    const intermediateDataForPartialRefresh = {
      ...store.getState().websocketIntermediateData
        .intermediateDataForPartialRefresh
    };

    let processedPayload = processReceivedChunk(
      eventData,
      intermediateDataForPartialRefresh,
      dataFetched,
      store.dispatch,
      setIntermediateDataForPartialRefreshAction
    );

    takeActionForPayload(
      processedPayload,
      processPartialRefreshDataAndUpdateStore,
      { store: store, webWorkerService }
    );
  };

  const handlerForSolverDataResponse = (store, eventData) => {
    const solverOutputFetched = eventData.data;
    const selectedScenarioId = store.getState().solverData.selectedScenario.id;
    const intermediateDataForSolver = {
      ...store.getState().websocketIntermediateData.intermediateDataForSolver
    };

    const isAPreDatedRun = eventData.metadata.isPreDatedRun;
    const didErrorOccur = eventData.metadata.error;

    const showGanttInUTC = store.getState().userInfo.showGanttInUTC;
    const userAttributes = store.getState().userInfo.userAttributes;
    const dateRange = store.getState().searchCriteria.dateRange;

    if (didErrorOccur) {
      console.error("ERROR: ", didErrorOccur);
      store.dispatch(
        setIsErrorModalVisibleAction({
          isVisible: true,
          message:
            "Failed to fetch scenario! Please check the URL and try again"
        })
      );
    }

    if (isAPreDatedRun) {
      let processedPayload = processReceivedChunk(
        eventData,
        intermediateDataForSolver,
        solverOutputFetched,
        store.dispatch,
        setIntermediateDataForSolverAction
      );

      /**
       * Pass scenario metadata as part of processedPayload
       */
      processedPayload = {
        ...processedPayload,
        metadata: {
          ...eventData.metadata
        }
      };

      takeActionForPayload(
        processedPayload,
        processSolverOutputAndUpdateStore,
        {
          store: store,
          dispatcher: store.dispatch,
          selectedScenarioId: processedPayload.metadata.scenarioId,
          middlewareFlags: middlewareFlags,
          showGanttInUTC,
          userAttributes,
          dateRange
        }
      );
    } else {
      /**
       * Handles case where UI recieves a solver output response for which there is no mapping present
       * this case can occur when user runs the solver and for some reasons page reloads
       */
      if (!solverScenarioMetadataMappings[eventData.metadata.scenarioName]) {
        solverScenarioMetadataMappings[eventData.metadata.scenarioName] = {
          percentageCompleted: 0
        };
      }

      /**
       * Check if the partial solver results are out of sync, wont cause a problem since as the subsequent solver result will contain the cummulative result
       */
      if (
        Number(eventData.metadata.percentageCompleted) >=
        Number(
          solverScenarioMetadataMappings[eventData.metadata.scenarioName]
            .percentageCompleted
        )
      ) {
        //updating the percentage completed
        solverScenarioMetadataMappings[
          eventData.metadata.scenarioName
        ].percentageCompleted = eventData.metadata.percentageCompleted;

        let processedPayload = processReceivedChunk(
          eventData,
          intermediateDataForSolver,
          solverOutputFetched,
          store.dispatch,
          setIntermediateDataForSolverAction
        );

        if (processedPayload) {
          //add metadata into the processedPayload object
          processedPayload = {
            ...processedPayload,
            metadata: {
              scenarioName: eventData.metadata.scenarioName,
              scenarioId: eventData.metadata.id,
              percentageCompleted: eventData.metadata.percentageCompleted,
              start: eventData.metadata.start
            }
          };

          const didRequestFail = processedPayload.status.statusCode !== 200;
          if (didRequestFail) {
            toast.dismiss(solverToastMappings[eventData.metadata.scenarioName]);
            toast(
              <ShowScenarioCompletedMessage
                scenarioId={eventData.metadata.scenarioId}
                scenarioName={eventData.metadata.scenarioName}
                didRequestFail={true}
              />,
              {
                type: "error"
              }
            );

            store.dispatch(
              setAllScenarioMetadataAction({
                scenarioName: processedPayload.metadata.scenarioName,
                id: processedPayload.metadata.scenarioId,
                percentageCompleted: 100, //setting this as 100 to remove the circular loader
                error: true
              })
            );
          } else {
            //update the toast with percentage
            if (solverToastMappings[processedPayload.metadata.scenarioName]) {
              toast.update(
                solverToastMappings[processedPayload.metadata.scenarioName],
                {
                  render: (
                    <RunSolverProgressToast
                      percentageCompleted={
                        processedPayload.metadata.percentageCompleted
                      }
                      scenarioName={processedPayload.metadata.scenarioName}
                      scenarioId={processedPayload.metadata.scenarioId}
                      scenarioStart={processedPayload.metadata.scenarioStart}
                    />
                  )
                }
              );
            } else {
              /**
               * if the toast was closed then show it up with the updated percentage
               */
              let solverToast = toast(
                <RunSolverProgressToast
                  percentageCompleted={
                    processedPayload.metadata.percentageCompleted
                  }
                  scenarioName={processedPayload.metadata.scenarioName}
                  scenarioId={processedPayload.metadata.scenarioId}
                  scenarioStart={processedPayload.metadata.scenarioStart}
                />,
                {
                  autoClose: false,
                  closeOnClick: false,
                  onClose: () => {
                    delete solverToastMappings[
                      processedPayload.metadata.scenarioName
                    ];
                  }
                }
              );

              solverToastMappings[processedPayload.metadata.scenarioName] =
                solverToast;
            }

            takeActionForPayload(
              processedPayload,
              processSolverOutputAndUpdateStore,
              {
                store: store,
                dispatcher: store.dispatch,
                selectedScenarioId: selectedScenarioId,
                middlewareFlags: middlewareFlags,
                showGanttInUTC,
                userAttributes,
                dateRange
              }
            );

            /**
             * Update the corresponding option in the scenario drop down
             */
            store.dispatch(
              setAllScenarioMetadataAction({
                scenarioName: processedPayload.metadata.scenarioName,
                id: processedPayload.metadata.scenarioId,
                percentageCompleted:
                  processedPayload.metadata.percentageCompleted,
                start: processedPayload.metadata.start
              })
            );
          }
        }
      } else {
        resetIntermediateData(
          store.dispatch,
          setIntermediateDataForSolverAction
        );
      }
    }
  };

  const handlerForScheduleResponse = (store, eventData) => {
    const scheduleFetchedData = eventData.data;
    const crewSchedules = store.getState().crewSchedules;
    const intermediateDataForSchedules = {
      ...store.getState().websocketIntermediateData.intermediateDataForSchedules
    };
    const uiConfig = store.getState().uiConfig;
    const userInfo = store.getState().userInfo;
    const dateRange = store.getState().searchCriteria.dateRange;

    let processedPayload = processReceivedChunk(
      eventData,
      intermediateDataForSchedules,
      scheduleFetchedData,
      store.dispatch,
      setIntermediateDataForSchedulesAction
    );

    takeActionForPayload(
      processedPayload,
      processScheduleResponseAndUpdateStore,
      {
        store: store,
        eventData: eventData,
        startAndEndOfCurrentFetch: startAndEndOfCurrentFetch,
        middlewareFlags: middlewareFlags,
        crewSchedules: crewSchedules,
        currentPosition: currentPosition,
        anchorDate: anchorDate,
        uiConfig: uiConfig,
        userInfo: userInfo,
        dateRange,
        webWorkerService
      }
    );
  };

  function handlerForLegalityResponse(store, eventData) {
    const legalityDataFetched = eventData.data;
    const alreadyProcessedViolations =
      store.getState().crewSchedules.processedViolations;

    const currentScheduleViolationMetadata =
      store.getState().crewSchedules.violationMetadata;

    const intermediateDataForLegality = {
      ...store.getState().websocketIntermediateData.intermediateDataForLegality
    };

    const userAttributes = { ...store.getState().userInfo.userAttributes };

    const dateRange = { ...store.getState().searchCriteria.dateRange };

    const scheduleData = {
      crewMembers: [...store.getState().crewSchedules.crewMembers],
      flights: { ...store.getState().crewSchedules.flights },
      duties: { ...store.getState().crewSchedules.duties },
      pairings: { ...store.getState().crewSchedules.pairings },
      lookUpData: { ...store.getState().crewSchedules.lookUpData }
    };

    let processedPayload = processReceivedChunk(
      eventData,
      intermediateDataForLegality,
      legalityDataFetched,
      store.dispatch,
      setIntermediateDataForLegalityAction
    );

    takeActionForPayload(
      processedPayload,
      processLegalityResponseAndUpdateStore,
      {
        alreadyProcessedViolations: alreadyProcessedViolations,
        currentScheduleViolationMetadata: currentScheduleViolationMetadata,
        dispatcher: store.dispatch,
        scheduleData,
        userAttributes,
        dateRange,
        webWorkerService,
        middlewareFlags
      }
    );
  }

  function processCountResponseAndUpdateStore(store, payload) {
    const receivedCountData = payload.data;

    const processedCountData = { violation: {}, legal: {} };

    receivedCountData.violation &&
      receivedCountData.violation.map(
        ({ violationCategory, violationLevel, count }) =>
          (processedCountData.violation[violationCategory] = {
            ...processedCountData.violation[violationCategory],
            [violationLevel]: count
          })
      );

    receivedCountData.legal &&
      receivedCountData.legal.map(
        ({ legalCategory, count }) =>
          (processedCountData.legal[legalCategory] = count)
      );

    store.dispatch(setCountInfoAction(processedCountData));
  }

  function handlerForCommentRefresh(store, payload) {
    const commentChangeData = payload.data;

    const currentScheduleViolationMetadata =
      store.getState().crewSchedules.violationMetadata;

    const commentData = store.getState().violationDrilledDownData.commentData;

    processCommentRefreshDataAndUpdateStore(
      commentChangeData,
      currentScheduleViolationMetadata,
      commentData,
      store.dispatch
    );
  }

  /**
   * checks if the socket connection is still on, if yes then returns false if connected else connect and return true
   * @param {*} socket
   * @param {*} dispatch
   * @param {*} actionParams
   */
  function checkWebSocketConnectionAndConnectIfNotConnected(
    dispatch,
    actionParams
  ) {
    //to handle case where the internet connection is back on but the websocket is not connected yet
    //check if the socket is in CONNECTED state before sending out the request
    if (!socket || socket.readyState !== 1) {
      getAccessTokenForUser().then(accessToken => {
        const host = `${WEBSOCKET_BASE_ENDPOINT}?token=${accessToken}`;
        dispatch(actions.wsConnect({ ...actionParams, host: host }));
      });
      return true;
    } else {
      return false;
    }
  }

  /**
   * contains the required statements to perform the fetchSchedules action
   * @param {*} action
   * @param {*} store
   */
  function fetchSchedules(action, store) {
    currentPosition = action.requestParams.currentPosition;
    middlewareFlags.isFirstFetch = action.requestParams.isFirstFetch;
    middlewareFlags.isVertical = action.requestParams.isVertical;

    startAndEndOfCurrentFetch = {
      start: action.requestParams.startDate,
      end: action.requestParams.endDate
    };
    //if it is a refresh call then wipe out the grid and then set the should refresh flag to false
    if (action.requestParams.isRefresh) {
      store.dispatch(
        setScheduleDataGridAction({
          scheduleDataGrid: [],
          yPositionMap: [
            ...store.getState().searchCriteria.getSchedulesParameters
              .yPositionMap
          ]
        })
      );
      store.dispatch(setShouldRefreshAction(false));
    }

    const openFlyingDataStatus =
      store.getState().crewSchedules.openFlyingDataStatus;

    let isOpenFlightRequest = false;

    /**
     * case 1: if it is a schedule-refresh call
     * case 2: if the call is for page 0 in the vertical direction
     * case 3: if the call is for a page !=0 in the vertical direction, and is fetching a new window horizontally
     */
    if (
      action.requestParams.isRefresh ||
      (action.requestParams.currentPosition.y === 0 &&
        openFlyingDataStatus === OPEN_FLYING_DATA_STATUS.FETCHED) ||
      (action.requestParams.currentPosition.y !== 0 &&
        !action.requestParams.isVertical &&
        openFlyingDataStatus === OPEN_FLYING_DATA_STATUS.FETCHED)
    ) {
      isOpenFlightRequest = true;
    }

    middlewareFlags.isAnOpenFlightFetch = isOpenFlightRequest;

    socket &&
      socket.send(
        JSON.stringify({
          action: GET_SCHEDULES_RESOURCE_NAME,
          limit: NUMBER_OF_CREW_PER_PAGE,
          page: action.requestParams.currentPosition.y
            ? action.requestParams.currentPosition.y
            : 0,
          start: action.requestParams.startDate,
          end: action.requestParams.endDate,
          isOpenFlightRequest: isOpenFlightRequest,
          isFirstFetch: middlewareFlags.isFirstFetch,
          isGanttShownInUTC: store.getState().userInfo.showGanttInUTC,
          showWarnings: store.getState().userInfo.showWarnings,
          userLocalTimeZone:
            store.getState().userInfo.userAttributes[
              LOCAL_TIME_ZONE_ATTRIBUTE_KEY
            ]["zone"]
        })
      );
  }

  /**
   * contains the required statements to perform the applyFilter action
   * @param {*} action
   * @param {*} store
   */
  function applyFilter(action, store) {
    const scheduleParameters =
      store.getState().searchCriteria.getSchedulesParameters;

    let selectedFilter = processSelectedFilterBeforeApplying(
      store.getState().searchCriteria.selectedFilter
    );

    let changedViolationPreferences = processViolationPreferencesToBeApplied(
      store.getState().crewSchedules.violationMetadata
    );

    const openFlyingDataStatus =
      store.getState().crewSchedules.openFlyingDataStatus;

    middlewareFlags.isAnOpenFlightFetch =
      openFlyingDataStatus === OPEN_FLYING_DATA_STATUS.FETCHED;

    socket.send(
      JSON.stringify({
        action: APPLY_FILTER_RESOURCE_NAME,
        selectedFilter: selectedFilter,
        limit:
          scheduleParameters.limit *
          (scheduleParameters.page === 0 ? 1 : scheduleParameters.page),
        page: 0,
        isOpenFlightRequest: middlewareFlags.isAnOpenFlightFetch,
        isGanttShownInUTC: store.getState().userInfo.showGanttInUTC,
        userLocalTimeZone:
          store.getState().userInfo.userAttributes[
            LOCAL_TIME_ZONE_ATTRIBUTE_KEY
          ]["zone"],
        violationPreferences: changedViolationPreferences
      })
    );
    store.dispatch(
      setScheduleDataGridAction({
        scheduleDataGrid: [],
        yPositionMap: []
      })
    );

    /**
     * Clean up actions after hitting apply filter - batching them to perform update redux as a single event
     */
    batch(() => {
      store.dispatch(resetDateRangeAction());
      store.dispatch(resetReduxData());
      store.dispatch(setCountInfoAction({ violation: {}, legal: {} })); //clear count data when an applyFilter call is made
      store.dispatch(setIsFetchingMoreCrewAction(true));
      store.dispatch(setIsFilterBeingAppliedAction(true));
      store.dispatch(setCanFetchMoreCrewsAction(true));
      store.dispatch(setIncludedCrewMemberForSolverAction([])); // clear included crew members for solver
    });

    currentPosition.y = 0;

    let startDate = selectedFilter.date.from;
    let endDate = selectedFilter.date.to;

    startAndEndOfCurrentFetch = {
      start: startDate,
      end: endDate
    };

    middlewareFlags.isVertical = true;
    middlewareFlags.isFilterBeingApplied = true;
  }

  /**
   * contains the required statements to request the partial refresh data from backend
   * @param {*} action
   * @param {*} store
   */
  function sendPartialRefreshRequest(action, store) {
    const allCrewsWithPairings = store
      .getState()
      .crewSchedules.crewMembers.map(crew => {
        return { crewId: crew.crewId, pairings: crew.pairings };
      });
    const allPairingIds = Object.keys(
      store.getState().crewSchedules.pairings
    ).map(pairingId => parseInt(pairingId));

    const allOpenTimeIdsWithOpenPositions =
      calculateOpenTimesToBeSentToPartialRefresh(
        store.getState().crewSchedules.flights,
        store.getState().crewSchedules.pairings,
        store.getState().crewSchedules.openTimeIds
      );

    socket.send(
      JSON.stringify({
        action: PARTIAL_REFRESH_REQUEST,
        crewWithPairings: allCrewsWithPairings,
        pairingIds: allPairingIds,
        dateRange: {
          start: store.getState().searchCriteria.dateRange.startDate,
          end: store.getState().searchCriteria.dateRange.endDate
        },
        utcLastModifiedTime: action.utcLastModifiedTime,
        allOpenTimeIdsWithOpenPositions,
        showWarnings:
          store.getState().searchCriteria.selectedFilter.showWarnings
      })
    );
  }

  /**
   * contains the required statements to perform the solverRequest action
   * @param {*} action
   */
  function placeSolverRequest(action, store) {
    const selectAllOrDeselectAllState =
      store.getState().searchCriteria.selectAllOrDeselectAllState;

    const openTimeData = store.getState().crewSchedules.openTimeIds;

    const openFlyingData =
      selectAllOrDeselectAllState ===
      SELECT_ALL_DESELECT_ALL_VALUES_MAP.SELECT_ALL
        ? {
            includedOpenSegmentRows: [],
            includedOpenPairingRows: []
          }
        : fillOpenFlyingData(
            store.getState().solverData.requestData,
            openTimeData
          );

    /**
     * Prioritizing contextual solve over select_all checkboxes
     */
    const includedCrewForSolverRun = action.requestParams.isContextualSolve
      ? action.requestParams.includedCrewIds
      : selectAllOrDeselectAllState ===
        SELECT_ALL_DESELECT_ALL_VALUES_MAP.SELECT_ALL
      ? []
      : action.requestParams.includedCrewIds;

    let requestObject = {
      action: SOLVER_REQUEST,
      name: action.requestParams.scenarioName,
      start: action.requestParams.startDate,
      end: action.requestParams.endDate,
      openFlights: [],
      ruleSetId: action.requestParams.ruleSetId,
      strategyId: action.requestParams.strategyId,
      includedCrewIds: includedCrewForSolverRun,
      excludedCrewIds: action.requestParams.excludedCrewIds,
      fixOpenFlights: action.requestParams.fixOpenFlights,
      openFlyingData: openFlyingData
    };

    socket.send(JSON.stringify(requestObject));

    /**
     * Show the toast as and when the request is placed to the backend.
     */
    let solverToast = toast(
      <RunSolverProgressToast
        percentageCompleted={0}
        scenarioName={action.requestParams.scenarioName}
      />,
      {
        autoClose: false,
        onClose: () => {
          delete solverToastMappings[action.requestParams.scenarioName];
        }
      }
    );

    solverToastMappings[action.requestParams.scenarioName] = solverToast;
    solverScenarioMetadataMappings[action.requestParams.scenarioName] = {
      percentageCompleted: 0
    };

    /**
     * add an option to the solver scenario drop down with percentageCompleted as 0 -
     * this will allow user to see the circular loader as soon as the user hits run solver
     */
    store.dispatch(
      setAllScenarioMetadataAction({
        scenarioName: action.requestParams.scenarioName,
        id: DEFAULT_SCENARIO_ID - 1, //scenario drop down is sorted via id's making the id as 1 less than current schedule's id to keep it below current schedule
        percentageCompleted: 0
      })
    );
  }

  /**
   * contains the required statements to perform the solverOutputRequest action
   * @param {*} action
   * @param {*} store
   */
  function placeSolverOutputRequest(action, store) {
    if (action.requestParams.id !== DEFAULT_SCENARIO_ID) {
      store.dispatch(setIsLoadingSolverOutputDataAction(true));
      socket.send(
        JSON.stringify({
          action: SOLVER_OUTPUT_REQUEST,
          scenarioName: action.requestParams.scenarioName,
          id: action.requestParams.id
        })
      );
    }
  }

  return store => next => action => {
    switch (action.type) {
      case "WS_CONNECT":
        if (socket !== null) {
          socket.close();
        }

        // connect to the remote host
        socket = new WebSocket(action.connectionParam.host);

        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen({
          store: store,
          isReconnecting: action.connectionParam.isReconnecting,
          isScheduleFetchEnqued: action.connectionParam.isScheduleFetchEnqued,
          isApplyFilterEnqued: action.connectionParam.isApplyFilterEnqued,
          isSolverRequestEnqued: action.connectionParam.isSolverRequestEnqued,
          isPingActionEnqued: action.connectionParam.isPingActionEnqued,
          isSolverOutputRequestEnqued:
            action.connectionParam.isSolverOutputRequestEnqued,
          isPartialRefreshRequestEnqued:
            action.connectionParam.isPartialRefreshRequestEnqued,
          makeScheduleCallOnConnect:
            action.connectionParam.makeScheduleCallOnConnect
        });

        break;
      case "WS_DISCONNECT":
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      case "WS_FETCH_SCHEDULES":
        /**if the websocket is connected then a request is placed
         * or else the request is enqued and sent after the connection is re-established
         **/
        if (
          !checkWebSocketConnectionAndConnectIfNotConnected(store.dispatch, {
            isReconnecting: true,
            isScheduleFetchEnqued: action
          })
        ) {
          //if CONNECTED perform fetch
          fetchSchedules(action, store);
        }

        break;
      case "WS_PING":
        socket.send(
          JSON.stringify({
            action: PING_WEB_SOCKET
          })
        );
        break;
      case "WS_SOLVER_REQUEST":
        /**if the websocket is connected then a request is placed
         * or else the request is enqued and sent after the connection is re-established
         **/
        if (
          !checkWebSocketConnectionAndConnectIfNotConnected(store.dispatch, {
            isReconnecting: true,
            isSolverRequestEnqued: action
          })
        ) {
          placeSolverRequest(action, store);
        }
        break;

      case "WS_SOLVER_OUTPUT_REQUEST":
        /**if the websocket is connected then a request is placed
         * or else the request is enqued and sent after the connection is re-established
         **/
        if (
          !checkWebSocketConnectionAndConnectIfNotConnected(store.dispatch, {
            isReconnecting: true,
            isSolverOutputRequestEnqued: action
          })
        ) {
          placeSolverOutputRequest(action, store);
        }
        break;

      case "WS_APPLY_FILTER":
        /**if the websocket is connected then a request is placed
         * or else the request is enqued and sent after the connection is re-established
         **/
        if (
          !checkWebSocketConnectionAndConnectIfNotConnected(store.dispatch, {
            isReconnecting: true,
            isApplyFilterEnqued: action
          })
        ) {
          applyFilter(action, store);
        }
        break;
      case "WS_PARTIAL_REFRESH_REQUEST":
        /**if the websocket is connected then a request is placed
         * or else the request is enqued and sent after the connection is re-established
         **/
        if (
          !checkWebSocketConnectionAndConnectIfNotConnected(store.dispatch, {
            isReconnecting: true,
            isPartialRefreshRequestEnqued: action
          })
        ) {
          sendPartialRefreshRequest(action, store);
        }
        break;

      case "WS_GET_OPEN_FLYING_DATA":
        if (
          !checkWebSocketConnectionAndConnectIfNotConnected(store.dispatch, {
            isReconnecting: true,
            isGetOpenFlyingDataEnqued: action
          })
        ) {
          getOpenFlyingData(socket, store);
        }
        break;

      case "UPDATE_GANTT_ON_USER_ACTION":
        const {
          crewMembers,
          pairings,
          duties,
          flights,
          processedViolations: {
            timeViolations,
            entityViolations,
            ruleDisplayConfig
          },
          violationMetadata
        } = (action.schedule &&
          action.schedule.changedSchedule &&
          action.schedule.changedSchedule) ||
        store.getState().crewSchedules;

        const lookUpData = store.getState().lookUpData;

        const { showGanttInUTC, userAttributes } = store.getState().userInfo;

        const dateRange = store.getState().searchCriteria.dateRange;

        const scheduleData = {
          scheduleFetched: {
            crewMembers,
            pairings,
            duties,
            flights
          }
        };

        if (action.schedule.doNotRefreshScreen === false) {
          store.dispatch(showLoaderOnGanttAction(true));
        }

        webWorkerService.invokeScheduleProcessorAndLegalityProcessor({
          eventData: {
            scheduleData,
            processedEntityViolation: entityViolations,
            processedTimeViolation: timeViolations,
            ruleDisplayConfig,
            updatedViolationMetadata: violationMetadata,
            userAttributes,
            dateRange,
            showGanttInUTC,
            lookUpData
          },
          context: {
            postProcessingFunction: updateStoreWithNewScheduleData,
            postProcessingFunctionParameters: {
              dispatcher: store.dispatch,
              middlewareFlags
            }
          }
        });

        break;

      case "NEW_MESSAGE":
        socket.send(
          JSON.stringify({
            command: "NEW_MESSAGE",
            message: action.msg
          })
        );
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
