import Axios from "axios";
import { BASE_ENDPOINT, GET_VIOLATION_COMMENT } from "../../../constants/api";
import { ENTITY_TYPE } from "../../../constants/disruptions/crewGanttViewer";
import { REST_BASED_RULENAMES } from "../../../constants/disruptions/rules";
import { setCommentDataAction } from "../../../redux/violationDrillDownReducer";
import { getSpreadSafeArray } from "../../../utils/arrayUtils";
import { getAccessTokenForUser } from "../../../utils/authUtils";
import { getTwoDigitStringFromNumber } from "../../../utils/timelineUtils";

const entityTypeToReduxKeyMapping = {
  FLIGHT: "flight",
  DUTY: "duty",
  PAIRING: "pairing"
};

export const getAllViolationMessages = violatingData =>
  getSpreadSafeArray(violatingData).map(violation => {
    const ruleIdentity = violation.ruleIdentity;
    const violationType = violation.violator ? "ENTITY" : "TIME";
    const ruleIdentityAsKey = `${ruleIdentity.ruleName}-${ruleIdentity.ruleCategory}-${ruleIdentity.ruleType}`;

    let reduxKey = "";

    if (violationType === "ENTITY") {
      reduxKey = `entityViolations/${getEntityTypeKey(
        violationType,
        violation
      )}/${violation.violator.entityId}/${ruleIdentityAsKey}`;
    } else {
      const keyToBeInserted = `${violation.parent.entityId}-${violation.entityBefore.entityId}`;

      reduxKey = `timeViolations/${getEntityTypeKey(
        violationType,
        violation
      )}/${keyToBeInserted}/${ruleIdentityAsKey}`;
    }

    return {
      message: `${ruleIdentity.ruleDisplayName} - ${violation.message}`,
      commentCount: ruleIdentity.commentCount,
      violationId: ruleIdentity.violationId,
      violationType: violationType,
      reduxKey: reduxKey
    };
  });

export const getCommentDateTime = commentDateTime => {
  const dateObj = new Date(commentDateTime);

  const dateString = `${getTwoDigitStringFromNumber(
    dateObj.getMonth()+1
  )}/${getTwoDigitStringFromNumber(
    dateObj.getDate()
  )}/${dateObj.getFullYear()}`;

  const timeString = `${getTwoDigitStringFromNumber(
    dateObj.getHours()
  )}:${getTwoDigitStringFromNumber(dateObj.getMinutes())}`;

  return `${dateString} ${timeString}`;
};

export const processCommentData = (commentData, selectedViolation) => {
  const processedCommentData = [];
  const key =
    selectedViolation.violationId + "_" + selectedViolation.violationType;

  commentData.forEach(comment => {
    const commentObj = {
      commentId: comment.commentId,
      comment: comment.comment,
      commentBy: comment.commentBy,
      commentDateTime: getCommentDateTime(comment.commentDateTime)
    };
    processedCommentData.push(commentObj);
  });

  return { violationKey: key, comments: processedCommentData };
};

export const getCommentForSelectedViolation = async (
  selectedViolation,
  setIsLoading,
  dispatch
) => {
  /**
   * do not place request if no id
   */
  if (selectedViolation.violationId) {
    const token = await getAccessTokenForUser();
    const url = BASE_ENDPOINT + GET_VIOLATION_COMMENT;
    const headers = {
      Authorization: token
    };
    const body = {
      violationId: selectedViolation.violationId,
      violationType: selectedViolation.violationType
    };

    const violationCommentResonse = await Axios.post(url, body, {
      headers: headers
    });

    dispatch(
      setCommentDataAction(
        processCommentData(violationCommentResonse.data, selectedViolation)
      )
    );
    setIsLoading(false);
  } else {
    dispatch(
      setCommentDataAction({ violationKey: "NO_ID_NO_TYPE", comments: [] })
    );
    setIsLoading(false);
  }
};

export const getEntityTypeKey = (violationType, violation) => {
  if (violationType === "ENTITY") {
    return entityTypeToReduxKeyMapping[violation.violator.entityType];
  } else {
    if (violation.parent.entityType === ENTITY_TYPE.DUTY) {
      return "duty";
    }
    if (violation.parent.entityType === ENTITY_TYPE.PAIRING) {
      return "pairing";
    }
    if (
      violation.parent.entityType === ENTITY_TYPE.CREW &&
      violation.entityBefore.entityType === ENTITY_TYPE.DUTY
    ) {
      return "crew/crewDutyViolation";
    }
    if (
      violation.parent.entityType === ENTITY_TYPE.CREW &&
      violation.entityBefore.entityType === ENTITY_TYPE.PAIRING &&
      REST_BASED_RULENAMES.indexOf(violation.ruleIdentity.ruleName) < 0
    ) {
      return "crew/crewPairingViolation";
    }
    if (
      violation.parent.entityType === ENTITY_TYPE.CREW &&
      violation.entityBefore.entityType === ENTITY_TYPE.PAIRING &&
      REST_BASED_RULENAMES.indexOf(violation.ruleIdentity.ruleName) > -1
    ) {
      return "crew/restViolation";
    }
  }
};

export const getViolatingDataAfterUpdate = (
  prevData,
  updatedData,
  commentCount
) => {
  const returnArray = [];

  getSpreadSafeArray(prevData).forEach(violation => {
    if (updatedData.violationId === violation.ruleIdentity.violationId) {
      let updatedViolation = {
        ...violation,
        ruleIdentity: {
          ...violation.ruleIdentity,
          commentCount: Number(commentCount)
        }
      };
      returnArray.push(updatedViolation);
    } else {
      returnArray.push(violation);
    }
  });

  return returnArray;
};

export const removeDeletedComment = (previousCommentData, removedCommentId) =>
  previousCommentData.filter(
    comment => String(comment.commentId) !== String(removedCommentId)
  );
