import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { LinearProgress } from "@material-ui/core";

import { setIsErrorModalVisibleAction } from "../../../../../redux/showAndHideReducer";

import { handleClickOnRunSolverProgressToast } from "../../../../../utils/solverUtil";

import "./RunSolverProgressToast.scss";

export default function RunSolverProgressToast({
  scenarioId,
  scenarioName,
  percentageCompleted
}) {
  const dispatch = useDispatch();

  const cachedSolverOutput = useSelector(
    store => store.solverData.cachedSolverOutput
  );

  const crewSchedules = useSelector(store => store.crewSchedules);

  const dateRangeSelection = useSelector(
    store => store.searchCriteria.dateRange
  );

  const userAttributes = useSelector(store => store.userInfo.userAttributes);

  const toastInfoText =
    Number(percentageCompleted) >= 100
      ? `Successfully completed scenario - `
      : `Solving scenario - `;

  /**
   * do not do anything until atleast one chunk of solver output is received
   */
  const handleClickOnToast = () => {
    if (percentageCompleted === 0) {
      dispatch(
        setIsErrorModalVisibleAction({
          isVisible: true,
          message:
            "No solution has been generated for the current scenario yet. We will notify you when a solution is generated"
        })
      );
    } else {
      handleClickOnRunSolverProgressToast(
        scenarioId,
        cachedSolverOutput,
        crewSchedules,
        dateRangeSelection,
        dispatch,
        scenarioName,
        userAttributes
      );
    }
  };

  return (
    <div className="run-solver-progress-toast" onClick={handleClickOnToast}>
      <div className="scenario-detail">
        {toastInfoText}
        <span className="scenario-name-label">{scenarioName}</span>
        {/**
         * TODO: Change the logic to determine if a scenario is completed based on the scenario status which is being implemented as part of CRWEB-798
         */}
      </div>
      {Number(percentageCompleted) < 100 && (
        <div className="scenario-progress-indicator">
          <LinearProgress
            value={Number(percentageCompleted)}
            variant={"determinate"}
          />
          <div className="percentage-completed">{`${percentageCompleted}%`}</div>
        </div>
      )}
    </div>
  );
}
