import { DEFAULT_SCENARIO_ID } from "../constants/disruptions/filter";
import { upsertScenarioDropdownData } from "../utils/reduxUtils";
import { getReduxStoreKeyForOpenFlying } from "../utils/solverUtil";

const ACTIONS = {
  setAllScenarioMetadata: "SET_ALL_SCENARIO_METADATA",
  setSelectedScenario: "SET_SELECTED_SCENARIO",
  setSelectedScenarioSolverOutput: "SET_SELECTED_SCENARIO_SOLVER_OUTPUT",
  setCachedSolverOutput: "SET_CACHED_SOLVER_OUTPUT",
  setIsLoadingSolverOutputData: "SET_IS_LOADING_SOLVER_OUTPUT_DATA",
  setIncludedOpenFlyingRows: "SET_INCLUDED_OPEN_FLYING_ROWS"
};

const initialState = {
  allScenarioMetadata: [
    { scenarioName: "Current Schedule", id: DEFAULT_SCENARIO_ID }
  ],
  selectedScenario: {
    scenarioName: "Current Schedule",
    id: DEFAULT_SCENARIO_ID
  },
  selectedScenarioSolverOutput: {},
  cachedSolverOutput: {},
  isLoadingSolverOutputData: false,
  requestData: {
    includedOpenSegmentRows: [],
    includedOpenPairingRows: []
  }
};

/**
 * Reducer for storing data related to solver runs, ie,
 * - all scenarios - name and ids
 * - selected scenario
 * - solver's output for the selected scenario
 * - solver request parameters
 * @param {Object} prevState
 * @param {Object} action
 */
export function solverDataReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_ALL_SCENARIO_METADATA":
      const newScenarios = Array.isArray(action.scenarioMetadata)
        ? action.scenarioMetadata
        : [action.scenarioMetadata];

      newState = {
        ...prevState,
        allScenarioMetadata: [
          ...upsertScenarioDropdownData(
            prevState.allScenarioMetadata,
            newScenarios
          )
        ]
      };
      break;
    case "SET_SELECTED_SCENARIO":
      newState = {
        ...prevState,
        selectedScenario: action.scenario
      };
      break;
    case "SET_SELECTED_SCENARIO_SOLVER_OUTPUT":
      newState = {
        ...prevState,
        selectedScenarioSolverOutput: action.solverOuput
      };
      break;
    case "SET_CACHED_SOLVER_OUTPUT":
      newState = {
        ...prevState,
        cachedSolverOutput: {
          ...prevState.cachedSolverOutput,
          [action.cachedSolverOutput.scenarioId]:
            action.cachedSolverOutput.solution
        }
      };
      break;
    case "SET_IS_LOADING_SOLVER_OUTPUT_DATA":
      newState = {
        ...prevState,
        isLoadingSolverOutputData: action.isLoadingSolverOutputData
      };
      break;
    case "SET_INCLUDED_OPEN_FLYING_ROWS":
      const reduxStoreKey = getReduxStoreKeyForOpenFlying(
        action.openFlyingData.type
      );
      newState = {
        ...prevState,
        requestData: {
          ...prevState.requestData,
          [reduxStoreKey]: [...action.openFlyingData.includedRows]
        }
      };
      break;
    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setAllScenarioMetadataAction(scenarioMetadata) {
  return { type: ACTIONS.setAllScenarioMetadata, scenarioMetadata };
}

export function setSelectedScenarioAction(scenario) {
  return { type: ACTIONS.setSelectedScenario, scenario };
}

export function setSelectedScenarioSolverOutputAction(solverOuput) {
  return { type: ACTIONS.setSelectedScenarioSolverOutput, solverOuput };
}

export function setCachedSolverOutputAction(cachedSolverOutput) {
  return { type: ACTIONS.setCachedSolverOutput, cachedSolverOutput };
}

export function setIsLoadingSolverOutputDataAction(isLoadingSolverOutputData) {
  return {
    type: ACTIONS.setIsLoadingSolverOutputData,
    isLoadingSolverOutputData
  };
}

export function setIncludedOpenFlyingRowsAction(openFlyingData) {
  return {
    type: ACTIONS.setIncludedOpenFlyingRows,
    openFlyingData
  };
}
