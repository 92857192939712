const ACTIONS = {
  SET_UI_CONFIG: "SET_UI_CONFIG"
};

/**
 * initial state of the reducer
 */
const initialState = {};
/**
 * userInfoReducer  will hold per tenant configuration for UI components
 * @param {Object} prevState
 * @param {Object} action
 */
export function uiConfigReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_UI_CONFIG":
      newState = {
        ...prevState,
        ...action.uiConfig
      };
      break;

    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setUIConfigAction(uiConfig) {
  return {
    type: ACTIONS.SET_UI_CONFIG,
    uiConfig
  };
}
