export const SOLVER_INPUT_KPI_METADATA = [
  {
    displayName: "Total crew",
    key: "numCrewsInInput"
  },
  {
    displayName: "Disrupted crew",
    key: "numDisruptedCrewsInInput"
  },
  {
    displayName: "Open flights",
    key: "numOpenFlightsInInput"
  },
  {
    displayName: "Open pairings",
    key: "numOpenPairingsInInput"
  },
  {
    displayName: "Reserve crew",
    key: "numReserveCrewsInInput"
  },
  {
    displayName: "Deadheads",
    key: "numDeadheadsInInput"
  }
];

export const SOLVER_SOLUTION_KPI_METADATA = [
  {
    displayText: ({
      numDisruptedCrewsInInput,
      numUnrecoverableCrews,
      numDisruptedCrewsInOutput
    }) => {
      const value =
        numDisruptedCrewsInInput -
        (numUnrecoverableCrews + numDisruptedCrewsInOutput);

      return `${replaceValueWithNoWhenZero(
        value
      )} disrupted ${getPluralOrSingularFormOfWord(
        "crew",
        value
      )} ${chooseBetweenWasOrWere(value)} resolved by the solver.`;
    },
    propertiesRequired: [
      "numDisruptedCrewsInInput",
      "numUnrecoverableCrews",
      "numDisruptedCrewsInOutput"
    ]
  },
  {
    displayText: ({ numDisruptedCrewsInOutput, numUnrecoverableCrews }) => {
      const value = numDisruptedCrewsInOutput + numUnrecoverableCrews;

      return `${value} ${getPluralOrSingularFormOfWord(
        "crew",
        value
      )} did not have a solution.`;
    },
    propertiesRequired: ["numDisruptedCrewsInOutput", "numUnrecoverableCrews"]
  },
  {
    displayText: ({ numOpenFlightsInOutput }) =>
      `${replaceValueWithNoWhenZero(
        numOpenFlightsInOutput
      )} ${getPluralOrSingularFormOfWord(
        "flight",
        numOpenFlightsInOutput
      )} ${chooseBetweenIsOrAre(numOpenFlightsInOutput)} open after resolving.`,
    propertiesRequired: ["numOpenFlightsInOutput"]
  },
  {
    displayText: ({ numOpenPairingsInOutput }) =>
      `${replaceValueWithNoWhenZero(
        numOpenPairingsInOutput
      )} ${getPluralOrSingularFormOfWord(
        "pairing",
        numOpenPairingsInOutput
      )} ${chooseBetweenIsOrAre(
        numOpenPairingsInOutput
      )} open after resolving.`,
    propertiesRequired: ["numOpenPairingsInOutput"]
  },
  {
    displayText: ({ numDeadheadsUsed }) =>
      `${replaceValueWithNoWhenZero(
        numDeadheadsUsed
      )} ${getPluralOrSingularFormOfWord(
        "deadhead",
        numDeadheadsUsed
      )} ${chooseBetweenWasOrWere(numDeadheadsUsed)} used for resolving.`,
    propertiesRequired: ["numDeadheadsUsed"]
  },
  {
    displayText: ({ numReservesUsed }) =>
      `${replaceValueWithNoWhenZero(
        numReservesUsed
      )} ${getPluralOrSingularFormOfWord(
        "reserve",
        numReservesUsed
      )} ${chooseBetweenWasOrWere(numReservesUsed)} used for resolving.`,
    propertiesRequired: ["numReservesUsed"]
  }
];

const replaceValueWithNoWhenZero = value => (value === 0 ? "No" : value);

const chooseBetweenIsOrAre = value => (value > 1 ? "are" : "is");

const chooseBetweenWasOrWere = value => (value > 1 ? "were" : "was");

const getPluralOrSingularFormOfWord = (word, value) =>
  value > 1 ? `${word}s` : word;

export const SCENARIO_STILL_UNDER_PROGRESS_USER_INFO_TEXT =
  "Scenario is still being solved";
export const SCENARIO_FAILED_USER_INFO_TEXT =
  "Solve for this scenario failed with an error";
export const NO_INFO_ON_SOLVER_STATUS_USER_INFO_TEXT =
  "Status info not available for this run";
