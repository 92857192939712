import { getTMinusOneDateString } from "../utils/allGanttRowsUtils";

const ACTIONS = {
  setVisibleDateRange: "SET_VISIBLE_DATE_RANGE"
};

/**
 * initial state of the reducer
 */
const initialState = {
  startDate: new Date(getTMinusOneDateString()),
  endDate: new Date(getTMinusOneDateString())
};
/**
 * VisibleDateRange reducer will handle actions like setting of VisibleDateRange
 * @param {Object} prevState
 * @param {String} action
 */
export function visibleDateRangeReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_VISIBLE_DATE_RANGE":
      newState = action.visibleDateRange;
      break;
    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setVisibleDateRangeAction(visibleDateRange) {
  return {
    type: ACTIONS.setVisibleDateRange,
    visibleDateRange: visibleDateRange
  };
}
