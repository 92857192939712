export const SIGN_UP_STATES = {
  requestAccess: {
    headingText: "Request Access to Control Room",
    detailsText: undefined
  },
  confirmEmail: {
    headingText: "Enter Code",
    detailsText: "Please check your email for a one time code to enter below."
  },
  accessRequestRecieved: {
    headingText: "Access Request Received",
    detailsText:
      "Thank you for submitting your access request. An administrator will review your account request. Once confirmed, you will receive an email with access instructions."
  }
};

export const REQUEST_ACCESS = "requestAccess";
export const CONFIRM_EMAIL = "confirmEmail";
export const ACCESS_REQUEST_RECIEVED = "accessRequestRecieved";

export const ADMIN_APPROVE_REJECT_STATES = {
  accessRequest: {
    headingText: "Access Request",
    detailsTextPart1:
      "The following control room features have been requested by ",
    detailsTextPart2:
      ". You can control user permissions here by selecting or deselecting the features from the list below before approving the user."
  },
  userApproved: {
    headingText: "has been granted access",
    detailsText:
      "If you need to change anything to the user access or permissions, please contact the SkySchedule team at usermanagement@skyschedule.com"
  },
  userRejected: {
    headingText: "has been denied access",
    detailsText:
      "If you need to change anything to the user access or permissions, please contact the SkySchedule team at usermanagement@skyschedule.com"
  }
};

export const ADMIN_ACCESS_REQUEST = "accessRequest";
export const USER_APPROVED = "userApproved";
export const USER_REJECTED = "userRejected";

export const USER_ROLES_DESCRIPTION = {
  Simulate:
    "View disruptions, change rules locally, run SkySchedule Solver, and save and share solutions with others.",
  Publish: "Make schedule changes.",
  Strategy:
    "Make changes to the Strategies configured globally- allow deadheads, reserve utilization etc.",
  Rules: "Make changes to the Contractual rules configured globally.",
  Admin: "User access management."
};

export const USER_ROLES = {
  Simulate: "Simulate",
  Publish: "Publish",
  Strategy: "Strategy",
  Rules: "Rules",
  Admin: "Admin"
};
