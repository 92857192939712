export const DUTY_REPORT_TIME_RULENAMES = [
  "DUTY_REPORT_TIME_AT_BASE",
  "DUTY_REPORT_TIME_AT_FIELD_STATION"
];
export const DUTY_RELEASE_TIME_RULENAMES = [
  "DUTY_RELEASE_TIME_NO_CUSTOMS",
  "DUTY_RELEASE_TIME_CUSTOMS"
];

export const REST_BASED_RULENAMES = [
  "MIN_REST_TIME",
  "PILOT_MIN_REST_REQUIRED",
  "CABIN_MIN_REST_REQUIRED"
];

export const TABLE_B_RULES = ["MAX_FDP_LENGTH", "MAX_SPLIT_DUTY_FDP_LENGTH"];

export const TABLE_A_RULES = ["TABLE_A_MAX"];

export const REQUIRED_MIN_REST_TIME_FLIGHT_OPS = new Date(0, 0, 0, 10); //TODO: get the 10(x) value from tenant config

export const REQUIRED_MIN_REST_TIME_IN_FLIGHT = new Date(0, 0, 0, 10); //TODO: get the 9(x) value from tenant config

export const RULE_TYPE = {
  FAR: "FAR",
  CONTRACTUAL: "CONTRACTUAL",
  GENERAL: "GENERAL"
};

export const DIAGNOSTIC_LEVEL = {
  WARNING: "WARNING",
  VIOLATION: "VIOLATION"
};
