import {
  ClickAwayListener,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { setIsDateTimeDialogVisibleAction } from "../../../redux/showAndHideReducer";
import { getTwoDigitStringFromNumber } from "../../../utils/timelineUtils";
import useDidMountEffect from "../../customHooks/useDidMountEffect";
import Arrow from "../arrow/Arrow";
import { setSelectedFilterAction } from "../../../redux/searchCriteriaReducer";

/**
 * Style sheet for react calendar
 */
import "react-calendar/dist/Calendar.css";

const initialState = {
  fromDate: new Date(),
  fromHours: 1,
  fromMinutes: 1,
  showFromCalendar: false,
  toDate: new Date(),
  toHours: 1,
  toMinutes: 1,
  showToCalendar: false
};

const dateTimeDataReducer = (prevState, action) => {
  switch (action.type) {
    case "UPDATE_DATA":
      return {
        ...prevState,
        [action.field]: action.value
      };
    default:
      return prevState;
  }
};

export default function DateRangeFilter() {
  const dispatch = useDispatch();

  const [dateTimeData, componentLevelDispatcher] = React.useReducer(
    dateTimeDataReducer,
    initialState
  );

  const isDateTimeDialogVisible = useSelector(
    store => store.showAndHide.isDateTimeDialogVisible
  );

  const selectedFilter = useSelector(
    store => store.searchCriteria.selectedFilter
  );

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const selectedFilterForSolverOutput = useSelector(
    store => store.searchCriteria.selectedFilterForSolverOutput
  );

  const [dateRangeDialogError] = React.useState({
    error: false,
    errorMessage: ""
  });

  const preventSettingNewDateValues = React.useRef(false);

  React.useEffect(() => {
    preventSettingNewDateValues.current = true;
    let fromDate = new Date(
      isShowingASolverRun
        ? selectedFilterForSolverOutput.date.from
        : selectedFilter.date.from
    );


    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "fromDate",
      value: fromDate
    });
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "fromHours",
      value: fromDate.getHours()
    });
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "fromMinutes",
      value: fromDate.getMinutes()
    });

    let toDate = new Date(
      isShowingASolverRun
        ? selectedFilterForSolverOutput.date.to
        : selectedFilter.date.to
    );

    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "toDate",
      value: toDate
    });
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "toHours",
      value: toDate.getHours()
    });
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "toMinutes",
      value: toDate.getMinutes()
    });
  }, [
    selectedFilter.date.from,
    selectedFilter.date.to,
    isShowingASolverRun,
    selectedFilterForSolverOutput.date
  ]);

  const showAndHideFromCalendar = React.useCallback(() => {
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showFromCalendar",
      value: !dateTimeData.showFromCalendar
    });
    /**
     * close the to calendar if open
     */
    if (dateTimeData.showToCalendar)
      componentLevelDispatcher({
        type: "UPDATE_DATA",
        field: "showToCalendar",
        value: false
      });
  }, [dateTimeData.showFromCalendar, dateTimeData.showToCalendar]);

  const showAndHideToCalendar = React.useCallback(() => {
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showToCalendar",
      value: !dateTimeData.showToCalendar
    });
    /**
     * close the from calendar if open
     */
    if (dateTimeData.showFromCalendar)
      componentLevelDispatcher({
        type: "UPDATE_DATA",
        field: "showFromCalendar",
        value: false
      });
  }, [dateTimeData.showToCalendar, dateTimeData.showFromCalendar]);

  useDidMountEffect(() => {
    //update redux on date range change
    if (preventSettingNewDateValues.current) {
      preventSettingNewDateValues.current = false;
    } else {
      dispatch(
        setSelectedFilterAction({
          isCurrentSchedule: isShowingASolverRun ? false : true,
          filterName: "date",
          selectedFilter: {
            from: getTimeString(
              dateTimeData.fromDate,
              dateTimeData.fromHours,
              dateTimeData.fromMinutes
            ),
            to: getTimeString(
              dateTimeData.toDate,
              dateTimeData.toHours,
              dateTimeData.toMinutes
            )
          }
        })
      );
    }
  }, [dateTimeData, dispatch, isShowingASolverRun]);

  const fromDateChangeHandler = React.useCallback(date => {
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "fromDate",
      value: date
    });
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showFromCalendar",
      value: false
    });
  }, []);

  const toDateChangeHandler = React.useCallback(date => {
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "toDate",
      value: date
    });
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showToCalendar",
      value: false
    });
  }, []);

  const handleClickAwayForFromCalendar = React.useCallback(event => {
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showFromCalendar",
      value: false
    });
  }, []);

  const handleClickAwayForToCalendar = React.useCallback(() => {
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showToCalendar",
      value: false
    });
  }, []);

  const handleClickAwayFromDateTimePicker = React.useCallback(() => {
    dispatch(setIsDateTimeDialogVisibleAction(false));
    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showFromCalendar",
      value: false
    });

    componentLevelDispatcher({
      type: "UPDATE_DATA",
      field: "showToCalendar",
      value: false
    });
  }, [dispatch]);

  const formatDate = React.useCallback(date => {
    return `${getTwoDigitStringFromNumber(
      date.getMonth() + 1
    )}/${getTwoDigitStringFromNumber(date.getDate())} - ${getTwoDigitStringFromNumber(date.getHours()) +
    getTwoDigitStringFromNumber(date.getMinutes())
      }`;
  }, []);

  const getTimeString = React.useCallback((dateTimeData, hours, minutes) => {
    return `${dateTimeData.getFullYear()}-${getTwoDigitStringFromNumber(
      dateTimeData.getMonth() + 1
    )}-${getTwoDigitStringFromNumber(
      dateTimeData.getDate()
    )}T${getTwoDigitStringFromNumber(hours)}:${getTwoDigitStringFromNumber(
      minutes
    )}`;
  }, []);

  const handleClickOnDateTimeSection = React.useCallback(() => {
    dispatch(setIsDateTimeDialogVisibleAction(!isDateTimeDialogVisible));
  }, [isDateTimeDialogVisible, dispatch]);

  return (
    <div className="date-time-container">
      <div
        className="date-time-section auto-complete"
        onClick={handleClickOnDateTimeSection}
      >
        <Autocomplete
          renderInput={params => (
            <TextField
              {...params}
              label={
                formatDate(
                  new Date(
                    dateTimeData.fromDate.getFullYear(),
                    dateTimeData.fromDate.getMonth(),
                    dateTimeData.fromDate.getDate(),
                    dateTimeData.fromHours,
                    dateTimeData.fromMinutes
                  )
                ) +
                " to " +
                formatDate(
                  new Date(
                    dateTimeData.toDate.getFullYear(),
                    dateTimeData.toDate.getMonth(),
                    dateTimeData.toDate.getDate(),
                    dateTimeData.toHours,
                    dateTimeData.toMinutes
                  )
                )
              }
              variant="standard"
            />
          )}
          size="small"
          popupIcon={isDateTimeDialogVisible ? <Arrow isInverted /> : <Arrow />}
          options={[]}
          open={false}
          value={[]}
          disableClearable
          getOptionLabel={() => ""}
        />
      </div>
      {isDateTimeDialogVisible ? (
        <ClickAwayListener onClickAway={handleClickAwayFromDateTimePicker}>
          <Paper className="date-time-range-picker">
            <div className="option select-time-frame">
              <div className="to-from-heading">
                <span>From</span>
                <span>To</span>
              </div>
              <div className="to-from">
                <div className="from-date">
                  <span className="heading">Date</span>
                  <span className="heading">Hours</span>
                  <span className="heading">Minutes</span>
                </div>
                <div className="from-date">
                  <span className="heading">Date</span>
                  <span className="heading">Hours</span>
                  <span className="heading">Minutes</span>
                </div>
              </div>
              <div className="to-from">
                <div className="from-date">
                  <div className="date-div">
                    <span onClick={showAndHideFromCalendar}>
                      {dateTimeData.fromDate.getMonth() + 1}/
                      {dateTimeData.fromDate.getDate()}/
                      {dateTimeData.fromDate.getFullYear()}
                    </span>
                    {dateTimeData.showFromCalendar ? (
                      <ClickAwayListener
                        onClickAway={handleClickAwayForFromCalendar}
                      >
                        <div className="calendar-div">
                          <Calendar
                            onChange={fromDateChangeHandler}
                            value={dateTimeData.fromDate}
                          />
                        </div>
                      </ClickAwayListener>
                    ) : null}
                  </div>
                  <Select
                    id="showHide"
                    onChange={event => {
                      componentLevelDispatcher({
                        type: "UPDATE_DATA",
                        field: "fromHours",
                        value: event.target.value
                      });
                    }}
                    IconComponent={props => <Arrow iconClass={props} />}
                    disableUnderline={true}
                    value={dateTimeData.fromHours}
                    MenuProps={{ disablePortal: true }}
                  >
                    {[...Array(24).keys()].map((value, index) => (
                      <MenuItem
                        className="showhide-select-option"
                        value={value}
                        key={index}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    id="showHide"
                    onChange={event => {
                      componentLevelDispatcher({
                        type: "UPDATE_DATA",
                        field: "fromMinutes",
                        value: event.target.value
                      });
                    }}
                    IconComponent={props => <Arrow iconClass={props} />}
                    disableUnderline={true}
                    value={dateTimeData.fromMinutes}
                    MenuProps={{ disablePortal: true }}
                  >
                    {[...Array(60).keys()].map((value, index) => (
                      <MenuItem
                        className="showhide-select-option"
                        value={value}
                        key={index}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="from-date">
                  <div className="date-div">
                    <span onClick={showAndHideToCalendar}>
                      {dateTimeData.toDate.getMonth() + 1}/
                      {dateTimeData.toDate.getDate()}/
                      {dateTimeData.toDate.getFullYear()}
                    </span>
                    {dateTimeData.showToCalendar ? (
                      <ClickAwayListener
                        onClickAway={handleClickAwayForToCalendar}
                      >
                        <div className="calendar-div">
                          <Calendar
                            onChange={toDateChangeHandler}
                            value={dateTimeData.toDate}
                          />
                        </div>
                      </ClickAwayListener>
                    ) : null}
                  </div>
                  <Select
                    id="showHide"
                    onChange={event => {
                      componentLevelDispatcher({
                        type: "UPDATE_DATA",
                        field: "toHours",
                        value: event.target.value
                      });
                    }}
                    IconComponent={props => <Arrow iconClass={props} />}
                    disableUnderline={true}
                    value={dateTimeData.toHours}
                    MenuProps={{ disablePortal: true }}
                  >
                    {[...Array(24).keys()].map((value, index) => (
                      <MenuItem
                        className="showhide-select-option"
                        value={value}
                        key={index}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    id="showHide"
                    onChange={event => {
                      componentLevelDispatcher({
                        type: "UPDATE_DATA",
                        field: "toMinutes",
                        value: event.target.value
                      });
                    }}
                    IconComponent={props => <Arrow iconClass={props} />}
                    disableUnderline={true}
                    value={dateTimeData.toMinutes}
                    MenuProps={{ disablePortal: true }}
                  >
                    {[...Array(60).keys()].map((value, index) => (
                      <MenuItem
                        className="showhide-select-option"
                        value={value}
                        key={index}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            {dateRangeDialogError.error && (
              <div className="date-range-error-section">
                <Error />
                <span className="date-range-error-message">
                  {dateRangeDialogError.errorMessage}
                </span>
              </div>
            )}
          </Paper>
        </ClickAwayListener>
      ) : null}
    </div>
  );
}

/**
 *  <div
          className="date-time-heading"
          style={{
            color: isDateTimeDialogVisible
              ? BORDER_COLOR_AFTER_SOLVER_RESPONSE
              : BLACK_COLOR_WITH_ALPHA_80
          }}
        >
          {formatDate(
            new Date(
              dateTimeData.fromDate.getFullYear(),
              dateTimeData.fromDate.getMonth(),
              dateTimeData.fromDate.getDate(),
              dateTimeData.fromHours,
              dateTimeData.fromMinutes
            )
          ) +
            " to " +
            formatDate(
              new Date(
                dateTimeData.toDate.getFullYear(),
                dateTimeData.toDate.getMonth(),
                dateTimeData.toDate.getDate(),
                dateTimeData.toHours,
                dateTimeData.toMinutes
              )
            )}
        </div>
        <div className="date-time-arrow-icon">
          <Arrow isInverted={isDateTimeDialogVisible} />
        </div>
 */
