import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Accordion,
  AccordionSummary,
  Button,
  ClickAwayListener
} from "@material-ui/core";
import { Close, ExpandMore } from "@material-ui/icons";

import ViolationMessagesWithCommentSection from "./ViolationMessagesWithCommentSection";

import { setIsViolationDrilledDownDrawerVisibleAction } from "../../../redux/showAndHideReducer";

import "./ViolationMessageDrilledDown.scss";

export default function ViolationMessageDrilledDown() {
  const dispatch = useDispatch();

  const reduxKeysForViolatingData = useSelector(
    store => store.violationDrilledDownData.reduxKeysForViolatingData
  );

  const selectedViolation = useSelector(
    store => store.violationDrilledDownData.selectedViolation
  );

  const closeDrawer = React.useCallback(
    () => dispatch(setIsViolationDrilledDownDrawerVisibleAction(false)),
    [dispatch]
  );

  return (
    <div>
      <ClickAwayListener onClickAway={closeDrawer}>
        <div className="violation-message-drilled-down-conatiner">
          <>
            <div className="header">
              <div className="header-text">Violation Comments Section</div>
              <div className="close">
                <Button onClick={closeDrawer}>
                  <Close color="primary" fontSize={"small"} />
                </Button>
              </div>
            </div>
          </>
          <>
            <div className="violation-message-drilled-down-body">
              <>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    Violations
                  </AccordionSummary>
                  <ViolationMessagesWithCommentSection
                    displayCommentIcon
                    selectedViolation={selectedViolation}
                    reduxKeysForViolatingData={reduxKeysForViolatingData}
                  />
                </Accordion>
              </>
            </div>
          </>
        </div>
      </ClickAwayListener>
    </div>
  );
}
