import React from "react";

import "./PageNotFound.scss";

// TODO: Localize text of this component

function PageNotFound(props) {
  return (
    <div>
      <h1>Page Not Found</h1>
      <div className="message">{props.children}</div>
    </div>
  );
}

export default PageNotFound;
