import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AllFiltersV2 from "./AllFiltersV2/AllFiltersV2";
import RunSolver from "./RunSolver/RunSolver";
import DashboardSummary from "./DashboardSummary/DashboardSummary";
import SummaryInfo from "./SummaryInfo/SummaryInfo";
import SolverRunInfo from "./SolverRunInfo/SolverRunInfo";

import { SOLVER_SCENARIO } from "../../../constants/routes";

import "./DisruptionSummary.scss";

export default function DisruptionSummary() {
  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  const location = useLocation();
  const isShareScreen = location.pathname.includes(SOLVER_SCENARIO);

  return (
    <div>
      <div
        className={`disp-summary-main-header ${isShareScreen ? "disp-summary-main-share-header" : ""
          }`}
      >
        <>
          <img src={"/images/opslabBlackLogo.png"} alt="OpsLab logo" />
        </>
        <>{isShowingASolverRun ? <SolverRunInfo /> : <DashboardSummary />}</>
        <>
          <SummaryInfo />
        </>
        <>
          <RunSolver />
        </>
      </div>
      <div className="disp-summary-main">
        <AllFiltersV2 />
      </div>
    </div>
  );
}
