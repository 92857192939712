import React from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";

import { logoutAction } from "../../../redux/loginReducer";

const SignOutButton = () => {
  const dispatch = useDispatch();

  const signOut = React.useCallback(() => {
    // By doing this, we are revoking all the auth tokens(id token, access token and refresh token)
    Auth.signOut(/* { global: true } - means the user is signed out from all the devices */)
      .then(data => {
        dispatch(logoutAction());
      })
      .catch(err => console.log("error in signout", err.message));
  }, [dispatch]);

  return <span onClick={signOut}>Sign Out</span>;
};

export default SignOutButton;
