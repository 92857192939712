import React from "react";
import { useDispatch, useSelector } from "react-redux";

import TimelineContainer from "./TimelineContainer/SelectionTimelineContainer";
import CrewGanttContainer from "./CrewGanttContainer/CrewGanttContainer";
import useBottomScrollListener from "../../customHooks/useBottomScrollListener";
import { setGetSchedulesParametersPageAction } from "../../../redux/searchCriteriaReducer";
import { setLoadingOnScrollAction } from "../../../redux/crewSchedulesReducer";
import { fetchSchedulesAction } from "../../../redux/webSocketReducer";

export default function AllDisruptions() {
  const dispatch = useDispatch();

  const getSchedulesParametersPage = useSelector(
    store => store.searchCriteria.getSchedulesParameters.page
  );

  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );

  let ganttListElement = useBottomScrollListener(() => {
    if (!isShowingASolverRun) {
      dispatch(
        setGetSchedulesParametersPageAction(getSchedulesParametersPage + 1)
      );
      dispatch(setLoadingOnScrollAction(true));
      dispatch(fetchSchedulesAction());
    }
  });
  let timelineElement = React.useRef(null);
  let crewPanelElement = React.useRef(null);

  const ganttAreaLeftScrollHandler = React.useCallback(() => {
    timelineElement.current.scrollLeft = ganttListElement.current.scrollLeft;
    crewPanelElement.current.scrollTop = ganttListElement.current.scrollTop;
  }, [ganttListElement]);

  return (
    <>
      {/**
       * This component is divided into 2 parts:
       * 1. The timeline section which stays fixed at the top and has 2 parts:
       * - a. The left fixed section that has info about visible duration of the timeline as well option for all crew selection.
       * - b. The right section having the timeline divided in days and hours and a draggable section to denote solver's time scope
       * 2. The CrewGanttContainer section which contains the gantt charts and crew details and has 2 parts:
       * - a. The left fixed section that has info about crew and open flights which is only vertically scrollable
       * - b. The right section having the gantt charts for crew pairings which is horizontally and vertically scrollable
       */}
      <TimelineContainer forwardRef={timelineElement} />
      <CrewGanttContainer
        forwardRef={ganttListElement}
        leftScrollHandler={ganttAreaLeftScrollHandler}
        crewPanelElement={crewPanelElement}
      />
    </>
  );
}
