import React from "react";
import { useSelector } from "react-redux";

import Pairing from "./Pairing/Pairing";
import DayDemarcation from "../../../partials/Demarcation/DayDemarcation";
import CurrentTimeDemaraction from "../../../partials/Demarcation/CurrentTimeDemaraction";
import DequalificationUnderlay from "../../../partials/dequals/DequalificationUnderlay";

import { DEFAULT_ROW_HEIGHT } from "../../../../constants/disruptions/ganttCrewCommon";
import { PAIRING_TYPE } from "../../../../constants/disruptions/crewGanttViewer";

import { isNonEmptyArray } from "../../../../utils/arrayUtils";

import "./CrewGanttContainer.scss";
import { getWidthForEntity } from "../../../../utils/timelineUtils";

export function GanttRow({ crewMember, rowNumber, crewIndexInStore }) {
  const pairings = useSelector(store => store.crewSchedules.pairings);

  const processedViolations = useSelector(
    store => store.crewSchedules.processedViolations
  );
  const isShowingASolverRun = useSelector(
    store => store.searchCriteria.isShowingASolverRun
  );
  const showSolverInputRows = useSelector(
    store => store.searchCriteria.showSolverInputRows
  );

  const visibleDateRange = useSelector(store => store.visibleDateRange);

  const dateRange = useSelector(store => store.searchCriteria.dateRange);

  const ActualPairing = ({
    pairingId,
    currentPairing,
    index,
    crewIndexInStore
  }) => {
    return (
      <Pairing
        key={pairingId}
        pairing={currentPairing}
        violatingData={processedViolations.entityViolations.pairing[pairingId]}
        pairingId={pairingId}
        crewBase={crewMember && crewMember.base ? crewMember.base : null}
        crewId={crewMember.crewId}
        isFlightOps={crewMember.flightOps}
        nextPairingData={
          processedViolations.timeViolations.crew.restViolation[
            `${crewMember.crewId}-${pairingId}`
          ] &&
          isNonEmptyArray(
            Object.values(
              processedViolations.timeViolations.crew.restViolation[
                `${crewMember.crewId}-${pairingId}`
              ]
            )
          ) &&
          crewMember.pairings[index + 1]
            ? {
                nextPairing: pairings[crewMember.pairings[index + 1]],
                crewSpecificRestViolatingData: Object.values(
                  processedViolations.timeViolations.crew.restViolation[
                    `${crewMember.crewId}-${pairingId}`
                  ]
                )
              }
            : null
        }
        /**
         * this prop is being passed to tackle the issue where the rest is too small to be viewed on the UI, REF: REPDESK-44
         * here, we pass the rest violation of the previous pairing (which is not visible) as a prop to the next pairing
         * this violation should be visible on the next duty
         */
        previousPairingRestViolation={
          checkIfPreviousPairingHasSmallRest(
            pairings[crewMember.pairings[index - 1]], //previous pairing
            currentPairing
          )
            ? Object.values(
                processedViolations.timeViolations.crew.restViolation[
                  `${crewMember.crewId}-${
                    pairings[crewMember.pairings[index - 1]].pairingId
                  }`
                ]
              )
            : null
        }
        crewIndexInStore={crewIndexInStore}
      />
    );
  };

  /**
   * retuns true/false after checking if the previous pairing has a rest violation that is visible or not respectively
   */
  const checkIfPreviousPairingHasSmallRest = React.useCallback(
    (previousPairing, currentPairing) => {
      let hasSmallRest = false;

      if (previousPairing)
        hasSmallRest =
          processedViolations.timeViolations.crew.restViolation[
            `${crewMember.crewId}-${previousPairing.pairingId}`
          ] &&
          isNonEmptyArray(
            Object.values(
              processedViolations.timeViolations.crew.restViolation[
                `${crewMember.crewId}-${previousPairing.pairingId}`
              ]
            )
          ) &&
          getWidthForEntity(
            Number(new Date(currentPairing.utcStartTime)),
            Number(new Date(previousPairing.utcEndTime)),
            dateRange.widthOfOneDay
          ) < 0.1;

      return hasSmallRest;
    },
    [
      crewMember.crewId,
      dateRange.widthOfOneDay,
      processedViolations.timeViolations.crew.restViolation
    ]
  );

  return (
    <>
      <div>
        {crewMember &&
        ((isShowingASolverRun && crewMember.solvedPairings) ||
          !isShowingASolverRun) ? (
          <div
            key={rowNumber}
            className="right-side-row"
            style={{
              height:
                isShowingASolverRun && showSolverInputRows
                  ? DEFAULT_ROW_HEIGHT * 2 + "px"
                  : DEFAULT_ROW_HEIGHT + "px"
            }}
          >
            <div>
              <div key={crewMember.crewId}>
                {isShowingASolverRun && crewMember.solvedPairings //render solved pairings for the current selected scenario
                  ? crewMember.solvedPairings.map(({ pairingId }) => (
                      <Pairing
                        key={pairingId}
                        pairing={pairings[pairingId]}
                        solvedRow
                        violatingData={
                          processedViolations.entityViolations.pairing[
                            pairingId
                          ]
                        }
                        pairingId={pairingId}
                        zIndex={2}
                        crewIndexInStore={crewIndexInStore}
                      />
                    ))
                  : isShowingASolverRun && crewMember.pairings
                  ? crewMember.pairings.map(pairingId => (
                      /**TODO: add post solver run based pairings here */
                      <Pairing
                        key={pairingId}
                        pairing={pairings[pairingId]}
                        solvedRow
                        violatingData={
                          processedViolations.entityViolations.pairing[
                            pairingId
                          ]
                        }
                        pairingId={pairingId}
                        crewIndexInStore={crewIndexInStore}
                      />
                    ))
                  : null}
                {isShowingASolverRun
                  ? crewMember.pairings.map(pairingId => (
                      <div
                        key={pairingId}
                        style={{
                          position: "relative"
                        }}
                      >
                        <Pairing
                          key={pairingId}
                          pairing={pairings[pairingId]}
                          pairingId={pairingId}
                          type={PAIRING_TYPE.SHELL}
                          crewIndexInStore={crewIndexInStore}
                        />
                      </div>
                    ))
                  : null}

                {isShowingASolverRun && showSolverInputRows
                  ? crewMember.pairings.map((pairingId, index) => {
                      const currentPairing = pairings[pairingId];

                      return (
                        <div
                          key={pairingId}
                          style={{
                            position: "relative",
                            top: DEFAULT_ROW_HEIGHT + "px"
                          }}
                        >
                          <ActualPairing
                            currentPairing={currentPairing}
                            pairingId={pairingId}
                            index={index}
                            crewIndexInStore={crewIndexInStore}
                          />
                        </div>
                      );
                    })
                  : null}
                {!isShowingASolverRun
                  ? crewMember.pairings.map((pairingId, index) => {
                      const currentPairing = pairings[pairingId];
                      if (
                        // check if current pairing overlaps with visible date range
                        currentPairing &&
                        currentPairing.utcEndTime &&
                        currentPairing.utcStartTime &&
                        Number(new Date(currentPairing.utcEndTime)) >=
                          Number(new Date(visibleDateRange.startDate)) &&
                        Number(new Date(visibleDateRange.endDate)) >=
                          Number(new Date(currentPairing.utcStartTime))
                      ) {
                        return (
                          <ActualPairing
                            currentPairing={currentPairing}
                            pairingId={pairingId}
                            index={index}
                            key={index}
                            crewIndexInStore={crewIndexInStore}
                          />
                        );
                      } else {
                        return null;
                      }
                    })
                  : null}
              </div>
            </div>
          </div>
        ) : null}
        <>
          <DayDemarcation key={crewMember.crewId} />
        </>
        <>
          <CurrentTimeDemaraction key={crewMember.crewId} />
        </>
        <>
          {/**
           * if the crew is dequalified then show a red background in the gantt from the day of dequalification
           */}
          {crewMember.dequalificationInfo &&
            crewMember.dequalificationInfo.isCrewDequalified &&
            crewMember.dequalificationInfo.indices &&
            crewMember.dequalificationInfo.indices.length > 0 && (
              <DequalificationUnderlay
                key={crewMember.crewId}
                dequalificationInfo={crewMember.dequalificationInfo}
              />
            )}
        </>
      </div>
    </>
  );
}

export default React.memo(GanttRow); /**to not repaint if no change in JSX */

/**
 * The code below is used to memoize redux subscription using reselect - commented for now as part of  CRWEB-617
 */
// creating a memoized redux subscription only for flights associated with this duty
// const getPairingForCrew = useMemo(getPairingForCrewSelector, []);
// const pairings = useSelector(state =>
//   getPairingForCrew(state, {
//     pairings: crewMember.pairings,
//     solvedPairings: crewMember.solvedPairings
//   })
// );

// const getPairingForCrewSelector = () =>
//   createSelector(
//     state => state.crewSchedules.pairings,
//     (_, pairingIds) => pairingIds,
//     (pairings, pairingIds) =>
//       Object.fromEntries(
//         Object.entries(pairings).filter(
//           ([key, value]) =>
//             pairingIds.pairings.indexOf(parseInt(key)) > -1 ||
//             (pairingIds.solvedPairings &&
//               pairingIds.solvedPairings.findIndex(
//                 solvedPairing => solvedPairing.pairingId === parseInt(key)
//               ) > -1)
//         )
//       )
//   );
