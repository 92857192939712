import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { ClickAwayListener } from "@material-ui/core";
import { setRuleColorAction } from "../../../../redux/ruleAndStrategyManagerReducer";
import {
  CREW_TYPE,
  RULE_TYPE_TO_REDUX_PROPERTY_MAP
} from "../../../../constants/disruptions/disruptionSummary";

export default function ColorPicker({
  color,
  ruleName,
  type,
  isContractual,
  dispatch,
  hasPermissionToEdit
}) {
  const [
    componentLevelColorState,
    setComponentLevelColorState
  ] = React.useState(color);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  React.useEffect(() => {
    setComponentLevelColorState(color);
  }, [color]);

  const handleOnChangeComplete = React.useCallback(
    color => {
      dispatch(
        setRuleColorAction({
          value: color.hex,
          ruleName: ruleName,
          type:
            type === CREW_TYPE.FLIGHT_OPS
              ? RULE_TYPE_TO_REDUX_PROPERTY_MAP.FLIGHT_OPS_RULES
              : RULE_TYPE_TO_REDUX_PROPERTY_MAP.IN_FLIGHT_RULES,
          isContractual: isContractual
        })
      );
    },
    [dispatch, isContractual, ruleName, type]
  );

  const handleChange = React.useCallback(color => {
    setComponentLevelColorState(color.hex);
  }, []);

  const handleClickOnColorBlock = React.useCallback(() => {
    if (hasPermissionToEdit) setIsColorPickerOpen(true);
  }, [hasPermissionToEdit]);

  const createTooltipForColorPicker = () => {
    return (
      <ClickAwayListener onClickAway={() => setIsColorPickerOpen(false)}>
        <div className="sketch-picker-container">
          <SketchPicker
            color={componentLevelColorState}
            onChangeComplete={handleOnChangeComplete}
            onChange={handleChange}
            disableAlpha
          />
        </div>
      </ClickAwayListener>
    );
  };

  return (
    <div
      className="color-block-and-hex-string"
      onClick={handleClickOnColorBlock}
    >
      <div
        className="color-block"
        style={{ backgroundColor: componentLevelColorState }}
      ></div>
      {isColorPickerOpen && createTooltipForColorPicker()}
    </div>
  );
}
