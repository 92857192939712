import React from 'react'
import { Link } from 'react-router-dom'
import { CUMULATIVE_TIME, REPORTS } from '../../../constants/routes'

export default function CumulativeTimeReportRedirect() {
  return (
    <Link
      to={`${REPORTS}${CUMULATIVE_TIME}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Cumulative Time Report
    </Link>
  )
}
