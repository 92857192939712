export const LIST_OF_CLASSES_TO_BE_EXCLUDED_TO_PREVENT_OPENING_TOOLTIP_ON_CLICK_INSIDE_TOOLTIP =
  [
    "MuiAccordionSummary-content",
    "MuiButtonBase-root",
    "MuiAccordionSummary-root",
    "Mui-expanded",
    "MuiSvgIcon-root",
    "tooltip-violation-message",
    "duty-period-container",
    "duty-period-unexpanded",
    "block",
    "header",
    "violation-message-pairing-info-container",
    "pairing-info-container",
    "pairing-info-header",
    "pairing-info-header-text",
    "pairing-info-header-pairing-id-container",
    "pairing-info-header-pairing-id",
    "pairing-info-header-pairing-date",
    "pairing-info-header-pairing-copy",
    "violation-message-header",
    "tooltip-violation-container",
    "violations-label"
  ];

export const CREW_POSITION = {
  FA: "FA",
  FO: "FO",
  CA: "CA"
};

// flight object related constants
export const FLIGHT_STATUS_KEY = "flightStatus";
export const UNPLANNED_FLIGHT_DIVERSION_STATUSES = ["tsfs-diversion"];

export const FLIGHT_NUMBER_DISPLAY_ENUM = {
  DO_NOT_SHOW_FLIGHT_NUMBER_OR_ICON: "DO_NOT_SHOW_FLIGHT_NUMBER",
  SHOW_FLIGHT_NUMBER: "SHOW_FLIGHT_NUMBER",
  SHOW_ICON: "SHOW_ICON",
  SHOW_FLIGHT_NUMBER_ICON_AND_DESC: "SHOW_FLIGHT_NUMBER_ICON_AND_DESC",
  RTG_BLOCK: "RTG_BLOCK"
};
