const ENDPOINTS = {
  development: {
    BASE_ENDPOINT: "https://api-dev.skyschedule.com",
    SOCKET_ENDPOINT: "wss://socket-dev.skyschedule.com"
  },
  qa: {
    BASE_ENDPOINT: "https://api-qa.skyschedule.com",
    SOCKET_ENDPOINT: "wss://socket-qa.skyschedule.com"
  },
  production: {
    BASE_ENDPOINT: "https://api.skyschedule.com",
    SOCKET_ENDPOINT: "wss://socket.skyschedule.com"
  }
};

export const BASE_ENDPOINT =
  ENDPOINTS[process.env.REACT_APP_ENVIRONMENT || "development"].BASE_ENDPOINT;
export const WEBSOCKET_BASE_ENDPOINT =
  ENDPOINTS[process.env.REACT_APP_ENVIRONMENT || "development"].SOCKET_ENDPOINT;

export const TENANT_URI = "/auth/tenant";
export const USER_URI = "/auth/user";
export const ADMIN_APPROVE_URI = "/auth/admin/approve";
export const ADMIN_REJECT_URI = "/auth/admin/reject";
export const LIST_SOLVER_SCENARIOS = "/solver/scenarios";
export const GET_SCHEDULES_RESOURCE_NAME = "getSchedule";
export const SCHEDULES_RESPONSE = "schedule-data";
export const LEGALITY_RESPONSE = "legality-data";
export const SOLVER_REQUEST = "runSolver";
export const PARTIAL_REFRESH_REQUEST = "partialRefresh";
export const SOLVER_OUTPUT_REQUEST = "getSolverRun";
export const SOLVER_COMPLETE_NOTIFICATION_RESPONSE = "solver-complete";
export const SOLVER_DATA_RESPONSE = "solver-data";
export const PING_WEB_SOCKET = "ping";
export const PING_RESPONSE = "pong";
export const LIST_RULE_SETS = "/solver/rulesets";
export const LIST_STRATEGIES = "/solver/strategies";
export const FILTERS = "/solver/filters";
export const APPLY_FILTER_RESOURCE_NAME = "applyFilter";
export const GET_PAIRING_INFO = "/solver/pairing-info/";
export const SCHEDULE_REFRESH = "schedule-refresh";
export const PARTIAL_REFRESH_DATA = "partial-refresh-data";
export const GET_TIMEZONES = "/solver/timezones";
export const COUNT_RESPONSE = "count-data";
export const GET_VIOLATION_COMMENT = "/solver/violation-comment/";
export const COMMENT_REFESH = "comment-refresh";
export const DEADHEAD_REPORT = "/solver/deadhead-report/";
export const CUMULATIVE_TIME_REPORT = "/solver/lookback";
export const GET_OPEN_FLYING_DATA_RESOURCE_NAME = "getOpenFlyingData";
export const OPEN_FLYING_DATA = "open-flying-data";
export const SHARE_SCENARIO = "/solver/scenarios/share";
export const ERROR_REPORTER = "/solver/error-reporter";

export const API_SUCCESS_CODE = 200;

export const DATA_RETURNED_ON_SUCCESSFUL_UPDATION_OF_CUSTOM_COGNITO_ATTRIBUTE =
  "SUCCESS";
