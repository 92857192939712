import { OPEN_FLYING_DATA_STATUS } from "../constants/disruptions/openTime";
import { getFilteredOutCrewMembers } from "../utils/reduxUtils";

const ACTIONS = {
  setCrewSchedules: "SET_CREW_SCHEDULES",
  setIsCrewSchedulesNotDefined: "SET_IS_CREW_SCHEDULES_NOT_DEFINED",
  setLoadingOnScroll: "SET_LOADING_ON_SCROLL",
  setViolations: "SET_VIOLATIONS",
  setTimeViolationParentIds: "SET_TIME_VIOLATION_PARENT_IDS",
  setEntityViolationIds: "SET_ENTITY_VIOLATION_IDS",
  setIsOpenTimeNotDefined: "SET_IS_OPEN_TIME_NOT_DEFINED",
  setOpenTimeIds: "SET_OPEN_TIME_IDS",
  setCanFetchMoreCrews: "SET_CAN_FETCH_MORE_CREWS",
  setIsFetchingMoreCrew: "SET_IS_FETCHING_MORE_CREW",
  resetReduxData: "RESET_REDUX_DATA",
  setProcessedViolations: "SET_PROCESSED_VIOLATIONS",
  setCopyOfScheduleWhileShowingSolverRun: "SET_COPY_OF_SCHEDULES",
  setShouldRefresh: "SET_SHOULD_REFRESH",
  resetAllLegalityData: "RESET_ALL_PROCESSED_VIOLATIONS",
  applyFilterForSolverOutput: "APPLY_FILTER_FOR_SOLVER_OUTPUT",
  resetCurrentSolverScenarioSchedule: "RESET_CURRENT_SOLVER_SCENARIO_SCHEDULE",
  updateScheduleOnPartialRefresh: "UPDATE_SCHEUDLE_ON_PARTIAL_REFRESH",
  updateCommentCountForViolation: "UPDATE_RULE_IDENTITY_FOR_VIOLATION",
  updateCommentCountForCrewTimeViolation:
    "UPDATE_RULE_IDENTITY_FOR_CREW_TIME_VIOLATION",
  setViolationMetadata: "SET_VIOLATION_METADATA",
  setViolationMetadataForAViolation: "SET_VIOLATION_METADATA_FOR_A_VIOLATION",
  setOpenFlyingDataStatus: "SET_OPEN_FLYING_DATA_STATUS",
  showLoaderOnGantt: "SHOW_LOADER_ON_GANTT"
};

const initialState = {
  crewMembers: [],
  duties: {},
  flights: {},
  pairings: {},
  isCrewSchedulesNotDefined: true,
  loadingOnScroll: false,
  entityViolationIds: {
    violatingFlightIds: [],
    violatingPairingIds: [],
    violatingDutyIds: []
  },
  violations: {
    entityViolations: [],
    timeViolations: [],
    ruleDisplayConfig: {}
  },
  timeViolationParentIds: {
    sitViolationParentIds: [],
    layoverViolationParentIds: [],
    restViolationParentIds: [],
    crewDutyViolationIdPairs: [],
    crewPairingViolationIdPairs: []
  },
  isOpenTimeNotDefined: true,
  openTimeIds: {
    openFlightIds: [],
    openPairingIds: []
  },
  canFetchMoreCrews: true,
  isFetchingMoreCrew: true,
  processedViolations: {
    entityViolations: { pairing: {}, duty: {}, flight: {} },
    timeViolations: {
      pairing: {},
      duty: {},
      crew: {
        crewDutyViolation: {},
        crewPairingViolation: {},
        restViolation: {}
      }
    },
    ruleDisplayConfig: {}
  },
  violationMetadata: {
    timeViolation: {},
    entityViolation: {}
  },
  copyOfScheduleWhileShowingSolverRun: {},
  shouldRefresh: false,
  copyOfCurrentScenario: {},
  openFlyingDataStatus: OPEN_FLYING_DATA_STATUS.YET_TO_FETCH,
  lookUpData: {},
  showLoaderOnGantt: true
};

export function crewSchedulesReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_CREW_SCHEDULES":
      newState = {
        ...prevState,
        ...action.schedule,
        entityViolationIds: {
          ...prevState.entityViolationIds
        },
        timeViolationParentIds: {
          ...prevState.timeViolationParentIds
        },
        violations: {
          ...prevState.violations
        },
        processedViolations: {
          ...prevState.processedViolations
        },
        copyOfCurrentScenario: action.schedule.shouldDuplicate
          ? action.schedule
          : prevState.copyOfCurrentScenario,
        isFetchingMoreCrew: false
      };
      break;
    case "SET_IS_CREW_SCHEDULES_NOT_DEFINED":
      newState = {
        ...prevState,
        isCrewSchedulesNotDefined: action.isCrewSchedulesNotDefined
      };
      break;
    case "SET_LOADING_ON_SCROLL":
      newState = {
        ...prevState,
        loadingOnScroll: action.loadingOnScroll
      };
      break;
    case "SET_VIOLATIONS":
      newState = {
        ...prevState,
        violations: action.violations
      };
      break;
    case "SET_TIME_VIOLATION_PARENT_IDS":
      newState = {
        ...prevState,
        timeViolationParentIds: action.timeViolationParentIds
      };
      break;
    case "SET_ENTITY_VIOLATION_IDS":
      newState = {
        ...prevState,
        entityViolationIds: {
          violatingFlightIds: [
            ...prevState.entityViolationIds.violatingFlightIds,
            ...action.entityViolationIds.violatingFlightIds
          ],
          violatingPairingIds: [
            ...prevState.entityViolationIds.violatingPairingIds,
            ...action.entityViolationIds.violatingPairingIds
          ],
          violatingDutyIds: [
            ...prevState.entityViolationIds.violatingDutyIds,
            ...action.entityViolationIds.violatingDutyIds
          ]
        }
      };
      break;
    case "SET_IS_OPEN_TIME_NOT_DEFINED":
      newState = {
        ...prevState,
        isOpenTimeNotDefined: action.isOpenTimeNotDefined
      };
      break;
    case "SET_OPEN_TIME_IDS":
      newState = {
        ...prevState,
        openTimeIds: action.openTimeIds
      };
      break;
    case "SET_CAN_FETCH_MORE_CREWS":
      newState = {
        ...prevState,
        canFetchMoreCrews: action.canFetchMoreCrews
      };
      break;
    case "SET_IS_FETCHING_MORE_CREW":
      newState = {
        ...prevState,
        isFetchingMoreCrew: action.isFetchingMoreCrew
      };
      break;
    case "RESET_REDUX_DATA":
      newState = {
        ...prevState,
        crewMembers: [],
        duties: {},
        flights: {},
        pairings: {},
        isCrewSchedulesNotDefined: true,
        loadingOnScroll: false,
        entityViolationIds: {
          violatingFlightIds: [],
          violatingPairingIds: [],
          violatingDutyIds: []
        },
        violations: {
          entityViolations: [],
          timeViolations: []
        },
        timeViolationParentIds: {
          sitViolationParentIds: [],
          layoverViolationParentIds: [],
          restViolationParentIds: []
        },
        isOpenTimeNotDefined: true,
        openTimeIds: {
          openFlightIds: [],
          openPairingIds: []
        },
        canFetchMoreCrews: true,
        isFetchingMoreCrew: false,
        processedViolations: {
          entityViolations: { pairing: {}, duty: {}, flight: {} },
          timeViolations: {
            pairing: {},
            duty: {},
            crew: {
              crewDutyViolation: {},
              crewPairingViolation: {},
              restViolation: {}
            }
          },
          ruleDisplayConfig: {}
        },
        copyOfScheduleWhileShowingSolverRun: {}
      };
      break;
    case "SET_PROCESSED_VIOLATIONS":
      newState = {
        ...prevState,
        processedViolations: action.processedViolations
      };
      break;
    case "SET_COPY_OF_SCHEDULES":
      newState = {
        ...prevState,
        copyOfScheduleWhileShowingSolverRun:
          action.copyOfScheduleWhileShowingSolverRun
      };
      break;
    case "SET_SHOULD_REFRESH":
      newState = {
        ...prevState,
        shouldRefresh: action.shouldRefresh
      };
      break;
    case "RESET_ALL_PROCESSED_VIOLATIONS":
      newState = {
        ...prevState,
        processedViolations: { ...initialState.processedViolations }
      };
      break;
    case "APPLY_FILTER_FOR_SOLVER_OUTPUT":
      newState = {
        ...prevState,
        crewMembers: [
          ...getFilteredOutCrewMembers(
            prevState.copyOfCurrentScenario,
            action.filters
          )
        ]
      };
      break;
    case "RESET_CURRENT_SOLVER_SCENARIO_SCHEDULE":
      newState = {
        ...prevState,
        ...prevState.copyOfCurrentScenario
      };
      break;
    case "UPDATE_SCHEUDLE_ON_PARTIAL_REFRESH":
      newState = {
        ...prevState,
        ...action.scheduleDataOnPartialRefresh
      };
      break;
    case "UPDATE_RULE_IDENTITY_FOR_VIOLATION":
      const reduxKeyArray = action.updateInfo.reduxKey.split("/");
      newState = {
        ...prevState,
        processedViolations: {
          ...prevState.processedViolations,
          [reduxKeyArray[0]]: {
            ...prevState.processedViolations[reduxKeyArray[0]],
            [reduxKeyArray[1]]: {
              ...prevState.processedViolations[reduxKeyArray[0]][
                reduxKeyArray[1]
              ],
              [reduxKeyArray[2]]: {
                ...prevState.processedViolations[reduxKeyArray[0]][
                  reduxKeyArray[1]
                ][reduxKeyArray[2]],
                [reduxKeyArray[3]]: {
                  ...prevState.processedViolations[reduxKeyArray[0]][
                    reduxKeyArray[1]
                  ][reduxKeyArray[2]][reduxKeyArray[3]],
                  ruleIdentity: {
                    ...prevState.processedViolations[reduxKeyArray[0]][
                      reduxKeyArray[1]
                    ][reduxKeyArray[2]][reduxKeyArray[3]]["ruleIdentity"],
                    [action.updateInfo.key]:
                      action.updateInfo.key === "commentCount"
                        ? Number(
                            prevState.processedViolations[reduxKeyArray[0]][
                              reduxKeyArray[1]
                            ][reduxKeyArray[2]][reduxKeyArray[3]][
                              "ruleIdentity"
                            ][action.updateInfo.key]
                          ) + Number(action.updateInfo.value)
                        : action.updateInfo.value
                  }
                }
              }
            }
          }
        }
      };
      break;
    case "UPDATE_RULE_IDENTITY_FOR_CREW_TIME_VIOLATION":
      const reduxKeyArrayCrew = action.updateInfo.reduxKey.split("/");

      newState = {
        ...prevState,
        processedViolations: {
          ...prevState.processedViolations,
          [reduxKeyArrayCrew[0]]: {
            ...prevState.processedViolations[reduxKeyArrayCrew[0]],
            [reduxKeyArrayCrew[1]]: {
              ...prevState.processedViolations[reduxKeyArrayCrew[0]][
                reduxKeyArrayCrew[1]
              ],
              [reduxKeyArrayCrew[2]]: {
                ...prevState.processedViolations[reduxKeyArrayCrew[0]][
                  reduxKeyArrayCrew[1]
                ][reduxKeyArrayCrew[2]],
                [reduxKeyArrayCrew[3]]: {
                  ...prevState.processedViolations[reduxKeyArrayCrew[0]][
                    reduxKeyArrayCrew[1]
                  ][reduxKeyArrayCrew[2]][reduxKeyArrayCrew[3]],
                  [reduxKeyArrayCrew[4]]: {
                    ...prevState.processedViolations[reduxKeyArrayCrew[0]][
                      reduxKeyArrayCrew[1]
                    ][reduxKeyArrayCrew[2]][reduxKeyArrayCrew[3]][
                      reduxKeyArrayCrew[4]
                    ],
                    ruleIdentity: {
                      ...prevState.processedViolations[reduxKeyArrayCrew[0]][
                        reduxKeyArrayCrew[1]
                      ][reduxKeyArrayCrew[2]][reduxKeyArrayCrew[3]][
                        reduxKeyArrayCrew[4]
                      ]["ruleIdentity"],
                      [action.updateInfo.key]:
                        action.updateInfo.key === "commentCount"
                          ? Number(
                              prevState.processedViolations[
                                reduxKeyArrayCrew[0]
                              ][reduxKeyArrayCrew[1]][reduxKeyArrayCrew[2]][
                                reduxKeyArrayCrew[3]
                              ][reduxKeyArrayCrew[4]]["ruleIdentity"][
                                action.updateInfo.key
                              ]
                            ) + Number(action.updateInfo.value)
                          : action.updateInfo.value
                    }
                  }
                }
              }
            }
          }
        }
      };
      break;
    case "SET_VIOLATION_METADATA":
      newState = {
        ...prevState,
        violationMetadata: {
          entityViolation: {
            ...prevState.violationMetadata.entityViolation,
            ...action.violationMetadata.entityViolation
          },
          timeViolation: {
            ...prevState.violationMetadata.timeViolation,
            ...action.violationMetadata.timeViolation
          }
        }
      };
      break;
    case "SET_VIOLATION_METADATA_FOR_A_VIOLATION":
      if (action.violationMetadata.violationType === "ENTITY") {
        newState = {
          ...prevState,
          violationMetadata: {
            entityViolation: {
              ...prevState.violationMetadata.entityViolation,
              [action.violationMetadata.violationId]: {
                ...prevState.violationMetadata.entityViolation[
                  action.violationMetadata.violationId
                ],
                changedMuteStatus: action.violationMetadata.muteStatus
              }
            },
            timeViolation: {
              ...prevState.violationMetadata.timeViolation
            }
          }
        };
      } else {
        newState = {
          ...prevState,
          violationMetadata: {
            entityViolation: {
              ...prevState.violationMetadata.entityViolation
            },
            timeViolation: {
              ...prevState.violationMetadata.timeViolation,
              [action.violationMetadata.violationId]: {
                ...prevState.violationMetadata.timeViolation[
                  action.violationMetadata.violationId
                ],
                changedMuteStatus: action.violationMetadata.muteStatus
              }
            }
          }
        };
      }
      break;
    case "SET_OPEN_FLYING_DATA_STATUS":
      newState = {
        ...prevState,
        openFlyingDataStatus: action.openFlyingDataStatus
      };
      break;
    case "SHOW_LOADER_ON_GANTT":
      newState = {
        ...prevState,
        showLoaderOnGantt: action.showLoaderOnGantt
      };
      break;
    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setCrewSchedulesAction(schedule) {
  return { type: ACTIONS.setCrewSchedules, schedule };
}

export function setIsCrewSchedulesNotDefinedAction(isCrewSchedulesNotDefined) {
  return {
    type: ACTIONS.setIsCrewSchedulesNotDefined,
    isCrewSchedulesNotDefined: isCrewSchedulesNotDefined
  };
}

export function setLoadingOnScrollAction(loadingOnScroll) {
  return { type: ACTIONS.setLoadingOnScroll, loadingOnScroll: loadingOnScroll };
}

export function setViolationsAction(violations) {
  return { type: ACTIONS.setViolations, violations };
}

export function setTimeViolationParentIdsAction(timeViolationParentIds) {
  return { type: ACTIONS.setTimeViolationParentIds, timeViolationParentIds };
}

export function setEntityViolationIdsAction(entityViolationIds) {
  return { type: ACTIONS.setEntityViolationIds, entityViolationIds };
}

export function setIsOpenTimeNotDefinedAction(isOpenTimeNotDefined) {
  return { type: ACTIONS.setIsOpenTimeNotDefined, isOpenTimeNotDefined };
}

export function setOpenTimeAction(openTimeIds) {
  return { type: ACTIONS.setOpenTimeIds, openTimeIds };
}

export function setCanFetchMoreCrewsAction(canFetchMoreCrews) {
  return { type: ACTIONS.setCanFetchMoreCrews, canFetchMoreCrews };
}

export function setIsFetchingMoreCrewAction(isFetchingMoreCrew) {
  return { type: ACTIONS.setIsFetchingMoreCrew, isFetchingMoreCrew };
}

export function resetReduxData() {
  return { type: ACTIONS.resetReduxData };
}

export function setProcessedViolationsAction(processedViolations) {
  return { type: ACTIONS.setProcessedViolations, processedViolations };
}

export function setCopyOfScheduleWhileShowingSolverRunAction(
  copyOfScheduleWhileShowingSolverRun
) {
  return {
    type: ACTIONS.setCopyOfScheduleWhileShowingSolverRun,
    copyOfScheduleWhileShowingSolverRun
  };
}

export function setShouldRefreshAction(shouldRefresh) {
  return { type: ACTIONS.setShouldRefresh, shouldRefresh };
}

export function resetAllLegalityDataAction() {
  return { type: ACTIONS.resetAllLegalityData };
}

export function applyFilterForSolverOutputAction(filters) {
  return {
    type: ACTIONS.applyFilterForSolverOutput,
    filters
  };
}

export function resetCurrentSolverScenarioScheduleAction() {
  return {
    type: ACTIONS.resetCurrentSolverScenarioSchedule
  };
}

export function updateScheduleOnPartialRefreshAction(
  scheduleDataOnPartialRefresh
) {
  return {
    type: ACTIONS.updateScheduleOnPartialRefresh,
    scheduleDataOnPartialRefresh
  };
}

export function updateRuleIdentityForViolationAction(updateInfo) {
  return {
    type: ACTIONS.updateCommentCountForViolation,
    updateInfo
  };
}

export function updateRuleIdentityForCrewTimeViolationAction(updateInfo) {
  return {
    type: ACTIONS.updateCommentCountForCrewTimeViolation,
    updateInfo
  };
}

export function setViolationMetadataAction(violationMetadata) {
  return {
    type: ACTIONS.setViolationMetadata,
    violationMetadata
  };
}

export function setViolationMetadataForAViolationAction(violationMetadata) {
  return {
    type: ACTIONS.setViolationMetadataForAViolation,
    violationMetadata
  };
}

export function setOpenFlyingDataStatusAction(openFlyingDataStatus) {
  return {
    type: ACTIONS.setOpenFlyingDataStatus,
    openFlyingDataStatus
  };
}

export const showLoaderOnGanttAction = showLoaderOnGantt => {
  return {
    type: ACTIONS.showLoaderOnGantt,
    showLoaderOnGantt
  };
};
