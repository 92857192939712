import { useEffect, useRef } from "react";

/**
 * This custom hook ensures that the function is not called when the component is first mounted
 * @param {*} func
 * @param {*} deps
 */
const useDidMountEffect = (func, deps: Array) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useDidMountEffect;
