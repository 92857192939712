import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export const logErrorMessageForMonitoring = message =>
  Sentry.captureException(new Error(message));

export const initializeErrorMonitoringPlugin = () => {
  Sentry.init({
    dsn: `https://${process.env.REACT_APP_SENTRY_SERVER_ADDRESS}.ingest.sentry.io/${process.env.REACT_APP_SENTRY_PROJECT_ID}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5
  });
};

/** function that sets loggedIn user email for Sentry logging
 * @param {string} userEmail
 */
export const setSentryScopeToSetUser = (userEmail) => {
  Sentry.configureScope(function(scope) {
    scope.setUser({
      email: userEmail,
    });
  });
}