import { LOCAL_TIME_ZONE_ATTRIBUTE_KEY } from "../constants/disruptions/disruptionSummary";

const ACTIONS = {
  setShowGanttInUTC: "SET_SHOW_GANTT_IN_UTC",
  setUserAttributes: "SET_USER_ATTRIBUTES",
  setUserGroups: "SET_USER_GROUPS"
};

/**
 * initial state of the reducer
 */
const initialState = {
  showGanttInUTC: true,
  userAttributes: {},
  userGroups: []
};
/**
 * userInfoReducer  will handle actions for user related data
 * @param {Object} prevState
 * @param {Object} action
 */
export function userInfoReducer(prevState = initialState, action) {
  let newState;

  switch (action.type) {
    case "SET_SHOW_GANTT_IN_UTC":
      newState = {
        ...prevState,
        showGanttInUTC: action.showGanttInUTC
      };
      break;

    case "SET_USER_ATTRIBUTES":
      newState = {
        ...prevState,
        userAttributes: {
          ...action.userAttributes
        },
        showGanttInUTC:
          action.userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY]
            .showGanttInUTC === undefined ||
          action.userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY]
            .showGanttInUTC === null
            ? false
            : action.userAttributes[LOCAL_TIME_ZONE_ATTRIBUTE_KEY]
                .showGanttInUTC
      };
      break;
    case "SET_USER_GROUPS":
      newState = {
        ...prevState,
        userGroups: [...action.userGroups]
      };
      break;
    default:
      newState = prevState;
      break;
  }

  return newState;
}

export function setShowGanttInUTCAction(showGanttInUTC) {
  return {
    type: ACTIONS.setShowGanttInUTC,
    showGanttInUTC
  };
}

export function setUserAttributesAction(userAttributes) {
  return {
    type: ACTIONS.setUserAttributes,
    userAttributes
  };
}

export function setUserGroupsAction(userGroups) {
  return {
    type: ACTIONS.setUserGroups,
    userGroups
  };
}
