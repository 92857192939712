import React from "react";
import {
  GRANULARITY_OF_DAY_INFO,
  DAY_TO_NAME_MAPPING
} from "../../../../constants/disruptions/timeline";

/**
 *
 * @param {Object}
 *    item-1 | date | Date | contains day info to be displayed
 *    item-2 | width | number | width each day should take
 *    item-3 | granularity | String | granularity with which the day info should be displayed
 */
export default function Day({ date, width, granularity }) {
  //Conditionally render different views on the screen depending on the granularity sent as a prop

  /**
   * getMonth() returns values in the range {0-11} 0 being Jan and 11 being dec.
   * Since we are displaying the number of the month and the convention is to start from 1 i.e January,
   * so 1 is added to the result of getMonth()
   */

  //display in the format {<long name of the day> MM/DD/YY} - {Monday 5/23/20}
  if (granularity === GRANULARITY_OF_DAY_INFO.LONG_DAY_AND_FULL_DATE) {
    return (
      <div>
        <div className="day" style={{ width: width + "vw" }}>
          {DAY_TO_NAME_MAPPING[date.getUTCDay()].long}&nbsp;
          {date.getUTCMonth() + 1}/{date.getUTCDate()}/
          {String(date.getUTCFullYear()).substring(2)}
        </div>
      </div>
    );
  }
  //display in the format {<short name of the day> MM/DD/YY}- {Mon 5/23/20}
  else if (granularity === GRANULARITY_OF_DAY_INFO.SHORT_DAY_AND_FULL_DATE) {
    return (
      <div>
        <div className="day" style={{ width: width + "vw" }}>
          {DAY_TO_NAME_MAPPING[date.getUTCDay()].short}
          &nbsp;{date.getUTCMonth() + 1}/{date.getUTCDate()}/
          {String(date.getUTCFullYear()).substring(2)}
        </div>
      </div>
    );
  }
  //display in the format {MM/DD} - 5/23
  else if (granularity === GRANULARITY_OF_DAY_INFO.NO_DAY_AND_PARTIAL_DATE) {
    return (
      <div>
        <div className="day" style={{ width: width + "vw" }}>
          {date.getUTCMonth() + 1}/{date.getUTCDate()}
        </div>
      </div>
    );
  }
}
